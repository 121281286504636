import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil, filter } from 'rxjs/operators';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  destroyer$: ReplaySubject<any> = new ReplaySubject<any>();
  loading = false;
  resetPasswordData: any = null;
  username: string = null;
  error: string = null;
  message = 'This is the message we have here like this abandoned me.';
  usernameForm: FormGroup;
  successMessage = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private appService: AppService<any>
  ) { }

  ngOnInit() {
    this.setupPage();
  }

  private setupPage() {
    // Make the form
    this.usernameForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(1)]]
    });
    this.authService.isAuthenticating().pipe(takeUntil(this.destroyer$)).subscribe(isAuthenticating => this.loading = isAuthenticating);
    this.authService.getAuthError().pipe(takeUntil(this.destroyer$)).subscribe(error => this.error = error);
  }

  onForgotPassword() {
    if (this.usernameForm.valid) {
      this.loading = true;
      this.error = null;
      this.username = this.usernameForm.value.username;
      this.appService.post('authentication', 'forgot-password', null, {username: this.username}, false, false)
        .then(response => {
          if (response.message === 'Temporary Password sent.') {
            this.successMessage = response.message;
          } else {
            this.router.navigate(['/auth/reset-password'], {queryParams: {message: 'Reset password information has been sent to your email', username: this.username}});
          }
        })
        .catch(error => this.error = error.response.data.message.message)
        .finally(() => this.loading = false)
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }

}
