import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import uniq from 'lodash/uniq';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { CompaniesService } from '@modules/companies/services/companies.service';
import { Attribute, CompanyDetails } from '@modules/companies/interfaces/companies';
import { AddCompanyComponent } from '@modules/companies/components/add-company/add-company.component';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnDestroy {
  private destroyer$ = new Subject();
  companyDetails: CompanyDetails = null;
  companyId: string = null;
  error: string = null;
  loading = true;
  allAttributes: string[] = [];
  attributes: { [key: string]: Attribute };

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompaniesService,
    private dialog: MatDialog
  ) {
    // get the company ID
    this.activatedRoute.params.pipe(takeUntil(this.destroyer$)).subscribe(
      (params) => {
        if (params && params.id) {
          this.companyId = params.id;
          // fetch the company details
          this.fetchCompanyDetails(params.id);
        }
      }
    )
  }

  fetchCompanyDetails(companyId: string): void {
    this.loading = true;
    this.error = null;
    forkJoin({
      details: this.companyService.fetchCompanyDetails(companyId),
      attributes: this.companyService.fetchCompanyAttributes()
    }).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
      next: (resp) => {
        const { details, attributes } = resp;
        this.companyDetails = details;
        this.attributes = attributes;
        this.allAttributes = uniq(Object.keys(attributes));
      },
      error: (error) => {
        this.error = error.message;
      }
    })
  }

  getCompanyAttributeValue(attrKey: string): string {
    return this.companyDetails.attributes.hasOwnProperty(attrKey) ? this.companyDetails.attributes[attrKey] : 'Not Availble';
  }

  getAttributeFriendly(attrKey): string {
    return this.attributes[attrKey]?.friendly ?? attrKey;
  }

  hasAttributes(attrKey: string): boolean {
    return this.companyDetails.attributes.hasOwnProperty(attrKey) && !!this.companyDetails.attributes[attrKey];
  }

  getAttributeDescription(attrKey: string): string {
    return this.attributes[attrKey].description;
  }

  editCompany(): void {
    this.dialog.open(AddCompanyComponent, {
      disableClose: true,
      width: '70vw',
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Edit Company',
        companyDetails: this.companyDetails,
      }
    }).afterClosed().pipe(takeUntil(this.destroyer$)).subscribe(data => {
      if (data) {
        this.companyDetails = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
