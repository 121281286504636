<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<!-- <mat-card> -->
<!-- <mat-card-content> -->
<div fxLayout="column" class="card-container">
  <ng-content></ng-content>
  <div *ngIf="doubleScrollbar" class="dummy-div-for-scrolling" #scrollTop>
    <div class="scroll-bar"></div>
  </div>
  <div class="table-container" #scrollBottom>
    <ng-container *ngIf="!!headerPaginator">
      <div class="header-paginator">
        <mat-paginator (page)="handlePaginationChange($event)" [pageIndex]="paginatorConfig?.pageIndex"
          [length]="paginatorConfig?.length" [pageSize]="paginatorConfig?.pageSize || pageSize"
          [pageSizeOptions]="[5, 10, 25, 50, 100, 250, 500]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </ng-container>
    <mat-table cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="displayedRows"
      (cdkDropListDropped)="drop($event)" [dataSource]="dataSource" matSort
      [ngClass]="{'scrollable-table': scrollable}">
      <ng-container matColumnDef="details">
        <mat-header-cell fxFlex *matHeaderCellDef cdkDrag cdkDragLockAxis="x"></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="emitClickEvent('VIEW_CLICK', element)">
            <mat-icon>visibility</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="header.name" *ngFor="let header of displayedColumnsWithoutAction;">
        <mat-header-cell fxFlex *matHeaderCellDef cdkDrag cdkDragLockAxis="x" mat-sort-header>
          {{header.friendly}}
        </mat-header-cell>
        <mat-cell (click)="emitClickEvent('ROW_CLICK', element)" fxFlex *matCellDef="let element"
          [style.border-left]="cellDecorator && cellDecorator(element, header.name)">
          <span *ngIf="checkbox && checkbox.show && checkbox.headerName === header.name">
            <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
              (change)="checkbox.execute(element, $event)"></mat-checkbox>
          </span>
          <span *ngIf="urgency && urgency.header === header.name; else: noUrgencyLevels"
            [ngClass]="'has_urgency urgency_' + urgency.run(element)">
            <span *ngIf="header.type === 'TimeStamp'; else nonDate">{{element[header.name] | userDate }}</span>
            <ng-template #nonDate>{{element[header.name]}}</ng-template>
          </span>
          <ng-template #noUrgencyLevels>
            <span *ngIf="header.type === 'TimeStamp'; else nonDate">{{element[header.name] | userDate }}</span>
            <ng-template #nonDate>{{element[header.name]}}</ng-template>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex *matHeaderCellDef cdkDrag cdkDragLockAxis="x">Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ $implicit: element }">
            </ng-container>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedRows; sticky:true"></mat-header-row>
      <mat-row matRipple class="row_content" *matRowDef="let row; columns: displayedRows;"
        [ngClass]="{'new-row': newRow && newRow(row), 'greyed-out': greyOut && greyOut(row), 'highlight': highlight && highlight(row)}">
      </mat-row>
    </mat-table>
    <div fxLayout='row' fxLayoutAlign='center' fxLayoutGap='20px' *ngIf="dataSource.data.length === 0 && !loading">No
      records found
    </div>
  </div>
  <!--  We need to revisit this part as all tables are defaulting to 5 as the number of rows to show -->
  <mat-paginator (page)="handlePaginationChange($event)" [pageIndex]="paginatorConfig?.pageIndex"
    [length]="paginatorConfig?.length" [pageSize]="paginatorConfig?.pageSize"
    [pageSizeOptions]="[5, 10, 25, 50, 100, 250, 500]" showFirstLastButtons>
  </mat-paginator>
</div>
