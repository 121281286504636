import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { EventsService } from '@modules/events/services/events.service';
import { IndexField, SortField } from '@modules/events/interfaces/events';


@Component({
  selector: 'app-column-manager',
  templateUrl: './column-manager.component.html',
  styleUrls: ['./column-manager.component.scss']
})
export class ColumnManagerComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  @Input() availableColumns: IndexField[] = [];
  @Input() selectedColumns: string[] = [];
  @Input() indexId: string = null;

  @Output() action: EventEmitter<{}> = new EventEmitter();

  actionHandler: EventEmitter<any> = new EventEmitter(null);
  sorting: FormControl = new FormControl(false, []);
  addColumnCtrl = false;
  columnsToAdd: IndexField[] = [];

  // sorting
  addSortingColumnCtrl = false;
  loading = false;


  constructor(
    private eventService: EventsService,
    private dialogRef: MatDialogRef<ColumnManagerComponent>) { }

  ngOnInit(): void {
    this.columnsToAdd = this.availableColumns.filter(col => !this.selectedColumns.includes(col.value));
  }

  isAllColumnsSelected(): boolean {
    return this.selectedColumns.length === this.availableColumns.length;
  }

  getField(columnId: string): IndexField {
    return this.availableColumns.find(col => col.value === columnId);
  }

  drop(event: CdkDragDrop<IndexField[]>): void {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
  }

  onAddColumnClick(): void {
    this.addColumnCtrl = !this.addColumnCtrl;
  }

  addSelectedColumn(evt: MatSelectChange): void {
    this.selectedColumns.push(evt.value.value);
    this.action.emit({ name: 'UPDATE', type: 'COLUMN', data: this.selectedColumns });
    this.onAddColumnClick();
  }

  indexItemActionHandler(evt: { name: string, type: 'SORTING' | 'COLUMN', data: IndexField | SortField }): void {
    const { name, type, data } = evt;
    if (name === 'REMOVE') {
      const d: IndexField = data as IndexField;
      if (type === 'COLUMN') {
        this.selectedColumns = this.selectedColumns.filter(col => col !== d.value);
        this.action.emit({ name: 'UPDATE', type: 'COLUMN', data: this.selectedColumns });
      }
    }
  }

  onSelectAllColumns(evt: MatCheckboxChange): void {
    if (evt.checked) {
      // select all columns
      this.selectedColumns = this.availableColumns.map(col => col.value);
      this.columnsToAdd = [];
    } else {
      // unselected everything
      this.selectedColumns = [];
      this.columnsToAdd = this.availableColumns;
    }
  }


  onAddSortingColumnClick(): void {
    this.addSortingColumnCtrl = !this.addSortingColumnCtrl;
  }

  dismiss(): void {
    this.dialogRef.close(null);
  }

  updateFieldSet(): void {
    this.loading = true;
    this.eventService.updateUserFieldSet(this.indexId, this.selectedColumns)
      .pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
        next: (res) => {
          this.action.emit({ name: 'UPDATE_FIELD', data: res });
          this.dialogRef.close(res);
        },
        error: (err) => {
          console.log('response from updating field sets', err);
        }
      })
  }

  filterField(value: string): void {
    let cols = this.availableColumns.filter(col => !this.selectedColumns.includes(col.value));
    this.columnsToAdd = cols.filter(col => col.friendly.toLowerCase().includes(value.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
