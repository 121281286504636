import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Company} from '../../admin/store/company.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ThemePalette} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LogstreamService} from '../services/logstream.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';
import * as companyActions from '../../admin/store/company.actions';
import {CompaniesService} from '../../../shared-services/companies.service';
import {LogStreamCertificateInterface, LogStreamInterface} from '../../../sweet-shared/models/logstream.model';
import {ConfirmationDialogComponent} from '../../../sweet-shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SelectOptionInterface} from '../../../sweet-shared/components/form-builder/form-builder.component';

@Component({
  selector: 'app-log-stream-create',
  templateUrl: './log-stream-create.component.html',
  styleUrls: ['./log-stream-create.component.scss']
})
export class LogStreamCreateComponent implements OnInit, OnDestroy {
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  logStreamData: LogStreamInterface;
  createLogStream = false;
  loading: boolean;

  companies$: Observable<Company[] | { label: string, value: string }[]>;
  logStreamForm: FormGroup;
  certificates: FormArray;
  companyList: SelectOptionInterface[];
  color: ThemePalette = 'primary';

  tlsVersions: SelectOptionInterface[] = [
    {value: 'tls11', friendly: 'TLS1.1+'},
    {value: 'tls12', friendly: 'TLS1.2+'},
  ];

  transportMethods: SelectOptionInterface[] = [
    {value: 'rfc3164securesyslog', friendly: 'RFC3164 Secure Syslog'},
    {value: 'rfc5424securesyslog', friendly: 'RFC5424 Secure Syslog'},
    {value: 'rfc3164securejson', friendly: 'RFC3164 Secure JSON'},
    {value: 'rfc5424securejson', friendly: 'RFC5424 Secure JSON'},
  ];

  sources: SelectOptionInterface[] = [
    {value: 'everything', friendly: 'All Events'},
    {value: 'base', friendly: 'Base Events'},
    {value: 'correlated', friendly: 'Correlated Events'}
  ];

  sampleLogPreviewText: string;


  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private logstreamService: LogstreamService,
    private store: Store<State>,
    private companiesService: CompaniesService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Get all companies
    this.store.dispatch(companyActions.loadCompanies());
    this.store.select(state => state.companies.companies).pipe(
      filter(Boolean),
      takeUntil(this.destroyer$)
    ).subscribe((companies: any[]) => {
      // format for dropdown options
      this.companyList = this.companiesService.companyNameAndFriendly(companies);
    });

    // If creating a logstream we want a blank form
    if (this.createLogStream) {
      this.initForm();
    } else {
      // otherwise we want to populate form with existing logstream values
      this.activatedRoute.params.pipe(
        filter(data => data.customerId && data.id)
      ).subscribe(paramMap => {
        this.logstreamService.selectLogstream(paramMap.customerId, paramMap.id);
        this.logstreamService.getSelectedCompanyLogstream().pipe(
          filter( data => !!data),
          takeUntil(this.destroyer$)
        ).subscribe(val => {
          this.logStreamData = val;
          this.setSampleLogText(val);
          this.initForm();
        });
      });
    }

    // subscribe to loading state of logstream
    this.logstreamService.getLoading().pipe(
      takeUntil(this.destroyer$)
    ).subscribe(isLoading => {
      this.loading = isLoading;
      if (isLoading && this.logStreamForm) {
        setTimeout(() => {
          this.logStreamForm.disable();
        });
      } else if (!isLoading && this.logStreamForm) {
        setTimeout(() => {
          this.logStreamForm.enable();
          if (!this.createLogStream) {
            this.logStreamForm.get('customerId').disable();
          }
        });
      }
    });
  }

  initForm() {
    this.logStreamForm = this.fb.group({
      enabled: [{value: this.logStreamData?.enabled || false, disabled: false}],
      streamData: [{value: this.logStreamData?.streamData || false, disabled: false}],
      concurrentConnections: [{value: this.logStreamData?.concurrentConnections || 1, disabled: false}, [Validators.required, Validators.min(1), Validators.max(5)]],
      tlsVersion: [{value: this.logStreamData?.tlsVersion || '', disabled: false}, Validators.required],
      transport: [{value: this.logStreamData?.transport || '', disabled: false}, Validators.required],
      source: [{value: this.logStreamData?.source || '', disabled: false}, Validators.required],
      // Customer Id should be disabled if editing an already existing logstream
      customerId: [{value: this.logStreamData?.customerId || '', disabled: !this.createLogStream}, Validators.required],
      targetHost: [
        {value: this.logStreamData?.targetHost || '', disabled: false}, [Validators.required, Validators.pattern('^([a-zA-Z0-9\\.\\-]+)$')]
      ],
      targetPort: [{value: this.logStreamData?.targetPort || 6514, disabled: false}, [Validators.required, Validators.pattern('^651[4-9]$')]],
      certificates: this.fb.array((this.buildCertForms(this.logStreamData?.certificates))),
    });
    if (!this.createLogStream) {
      // prompt form to show errors
      this.logStreamForm.markAllAsTouched();
    }
  }

  buildCertForms(certs: LogStreamCertificateInterface[]): FormGroup[] {
    // If no certificates, return an array with one blank form for creating a certificate
    if (!certs || !certs.length) {
      return [this.createTrustedCertificate()];
    }
    // otherwise return an array with as many forms as certificates with values populated
    return certs.map(cert => {
      return this.createTrustedCertificate(cert);
    });
  }

  createTrustedCertificate(cert?: LogStreamCertificateInterface): FormGroup {
    return this.fb.group({
      title: [{value: cert?.title || '', disabled: false}, []],
      body: [{value: cert?.body || null, disabled: false}, [Validators.pattern('^-----BEGIN CERTIFICATE-----[\\/+a-zA-Z0-9=\\s]+-----END CERTIFICATE-----$')]]
    });
  }

  getControls() {
    return (this.logStreamForm.get('certificates') as FormArray).controls;
  }

  addFieldTrustedCertificate(): void {
    (this.logStreamForm.get('certificates') as FormArray).push(this.createTrustedCertificate());
  }

  removeCert(index: number): void {
    (this.logStreamForm.get('certificates') as FormArray).removeAt(index);
  }

  // returns a certificate body text - for copying
  getCertBody(index: number): string {
    return (this.logStreamForm.get('certificates') as FormArray).get([index]).value.body;
  }

  onSubmit(eventName) {
    if (eventName === 'submit') {
      const logStreamFormData = {
        ...this.logStreamForm.value,
        // TargetPort must be a string for backend, but number-input gives number
        targetPort: this.logStreamForm.value.targetPort.toString()
      };
      this.actionEvents.emit({name: eventName, data: logStreamFormData});
    } else  {
      this.actionEvents.emit({name: eventName, data: null});
    }
  }

  onUpdateLogStream(): void {
    const logStreamFormData = {
      ...this.logStreamForm.getRawValue(),
      // TargetPort must be a string for backend, but number-input gives number
      targetPort: this.logStreamForm.value.targetPort.toString()
    };
    this.logstreamService.updateLogStream(logStreamFormData, this.logStreamData.id).then(res => {
      this.logStreamData = res;
    });
  }

  onDelete(): void {
    // Ask user to confirm deletion
    const matDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class'
    });
    matDialogRef.componentInstance.title = 'Delete Log Stream';
    matDialogRef.componentInstance.message = `Are you sure you want to delete this Log Stream?`;
    matDialogRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.logstreamService.deleteLogStream(this.logStreamData.id, this.logStreamData.customerId)
          .then(() => {
            // After deleting, user should be redirected
            this.router.navigate(['/log-stream']).then(() => {});
          });
      }
    });
  }

  setSampleLogText(logStream: LogStreamInterface) {
    if (!logStream.transport) {
      return;
    }
    if (logStream.transport === 'rfc5424securesyslog') {
      this.sampleLogPreviewText = '<34>1 2003-10-11T22:14:15.003Z logstream.mss.centurylink.com firewalld - ID47 - Sample event from 127.0.0.1:8080';
    } else if (logStream.transport === 'rfc5424securejson') {
      this.sampleLogPreviewText = '<34>1 2003-10-11T22:14:15.003Z logstream.mss.centurylink.com firewalld - ID47 - {"JCEF_srcIP":"127.0.0.1"}, {"JCEF_srcPort": "8080"}';
    } else if (logStream.transport === 'rfc3164securesyslog') {
      this.sampleLogPreviewText = '<34>Oct 10 22:14:15 logstream.mss.centurylink.com firewalld: Sample event from 127.0.0.1:8080';
    } else if (logStream.transport === 'rfc3164securejson') {
      this.sampleLogPreviewText = '<34>Oct 10 22:14:15 logstream.mss.centurylink.com firewalld: {"JCEF_srcIP":"127.0.0.1"}, {"JCEF_srcPort": "8080"}';
    }
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
