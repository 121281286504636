import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TableHeader } from '../../../../sweet-shared/components/table/table.component';
import { PermissionService } from '../../../../sweet-shared/services/permission.service';
import {
  FieldInterface,
  FormBuilderComponent,
  SelectOptionInterface
} from '../../../../sweet-shared/components/form-builder/form-builder.component';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { ModalComponent } from '../../../../sweet-shared/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportModel, ReportRateEnum } from '../../report.model';
import { SnackbarService } from '../../../../shared-services/snackbar.service';
import { CreateReportComponent } from '../create-report/create-report.component';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { filter, take, takeUntil } from 'rxjs/operators';
import { loadReports } from '../../store/report.actions';
import * as reportActions from '../../store/report.actions';
import { ReportingService } from '../../services/forms/reporting.service';
import { CompaniesService } from 'src/app/shared-services/companies.service';


export enum RunDatesEnum {
  ONCE = 'once',
  RECURRING = 'recurring'
}

export enum ReportStatusEnum {
  COMPLETED = 'completed',
  RUNNING = 'running',
  QUEUED = 'queued',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  SCHEDULED = 'scheduled'
}

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})

export class ReportingComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$: Subject<any> = new Subject<any>();
  submittingReport = false;
  loaderMessage = null;
  reportsDataStream: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  loadingReports = false;
  loadingReportError$: Observable<string>;
  reportsHeadersStream: BehaviorSubject<TableHeader[]> = new BehaviorSubject<any>([]);
  reportHeaders: TableHeader[] = [
    { name: 'title', friendly: 'Title' },
    { name: 'description', friendly: 'Description' },
    { name: 'dashboard_name', friendly: 'Dashboard Name' },
    { name: 'related_dashboard_id', friendly: 'Dashboard ID' },
    { name: 'report_id', friendly: 'Report ID' },
    { name: 'created_at', friendly: 'Created Date', type: 'TimeStamp' },
    { name: 'is_active', friendly: 'Is Active' },
    { name: 'created_by', friendly: 'Created By' }
  ];

  reportsData = [];
  dashboardId = '';
  readonly reportActions = {
    readDashboard: 'dashboard.read',
    deleteReport: 'report.delete',
    editReport: 'report.edit'
  };

  reportStatusOptions: SelectOptionInterface[];
  runOptions: SelectOptionInterface[];
  searchFieldDetails: FieldInterface[];
  deletingReport: boolean;
  pageSize = 25;
  companyOptions = [];

  @ViewChild('reportsSearch') reportsSearch: FormBuilderComponent;

  constructor(
    private permissionService: PermissionService,
    private titleCasePipe: TitleCasePipe,
    private activatedRoute: ActivatedRoute,
    private reportsAppService: AppService<ReportModel>,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private store: Store<State>,
    private reportingFormService: ReportingService,
    public reportingSvc: ReportingService,
    private companiesService: CompaniesService
  ) {
  }

  ngAfterViewInit(): void {
    this.setupSearch();
  }

  ngOnInit() {
    this.store.select(state => state.reports.deletingReport).pipe(takeUntil(this.destroyed$)).subscribe(deleteReport => this.deletingReport = deleteReport);
    this.store.select(state => state.reports.reports).pipe(takeUntil(this.destroyed$)).subscribe(reports => {
      this.reportsDataStream.next(reports);
    });
    this.store.select(state => state.companies).pipe(filter(c => !!c.companies), take(1)).subscribe(companies => this.companyOptions = this.companiesService.companyNameAndFriendly(companies.companies))

    // set up options for filter controls
    this.runOptions = Object.values(ReportRateEnum).map(option => {
      return { value: option, friendly: this.titleCasePipe.transform(option) };
    });
    this.reportStatusOptions = Object.values(ReportStatusEnum).map(option => {
      return { value: option, friendly: this.titleCasePipe.transform(option) };
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.filter) {
        this.dashboardId = params.filter;
      } else if (!params.filter) {
        this.dashboardId = '';
      }
    });

    // set form-builder fields
    this.searchFieldDetails = [
      {
        label: 'Report Status',
        component: 'input-select',
        name: 'reportStatus',
        placeholder: 'Report Status',
        flex: '',
        signalOnChanged: true,
        defaultValue: '',
        selectMultiple: true,
        options: this.reportStatusOptions
      },
      {
        label: 'Run Dates',
        component: 'input-select',
        name: 'runDates',
        placeholder: 'Run Dates',
        flex: '',
        signalOnChanged: true,
        defaultValue: '',
        selectMultiple: true,
        options: this.runOptions
      }
    ];

    // check is user has permissions to any of the report actions
    for (const key in this.reportActions) {
      if (this.permissionService.hasPermission(this.reportActions[key])) {
        this.reportHeaders.push({ name: 'actions', friendly: 'Actions' });
        break;
      }
    }
    // set table columns
    this.reportsHeadersStream.next(this.reportHeaders);

    this.store.select(state => state.reports.reports).pipe(takeUntil(this.destroyed$))
      .subscribe((reports: any[]) => this.reportsDataStream.next(reports));
    this.store.select(state => state.reports.loadingReports).pipe(takeUntil(this.destroyed$)).subscribe((loading: boolean) => this.loadingReports = loading);

    this.loadingReportError$ = this.store.select(state => state.reports.loadingReportsError);
    this.store.dispatch(loadReports());

  }

  setupSearch() {
    if (this.reportsSearch) {
      this.reportsSearch.form.valueChanges
        .subscribe(formValues => {
          let newData = [...this.reportsData];
          if (formValues.reportStatus.length) {
            newData = newData.filter(report => {
              return formValues.reportStatus.includes(report.status);
            });
          }
          if (formValues.runDates.length) {
            newData = newData.filter(report => {
              // if frequency is not 'once', set it to 'range'
              const frequency = report.rate === ReportRateEnum.ONCE ? report.rate : ReportRateEnum.RANGE;
              return formValues.runDates.includes(frequency);
            });
          }
          this.reportsDataStream.next(newData);
        });
    }
  }

  onRowClick(event) {
    const requiredPermission = 'report.view-history';
    if (this.permissionService.hasPermission(requiredPermission)) {
      this.router.navigate([`/reporting/${event.data.report_id}`]);
    } else {
      const dialogRef = this.dialog.open(ModalComponent);
      dialogRef.componentInstance.message = `
            <p>You are missing the required permission('${requiredPermission}') to view the report history.</p>
            <p>Please contact your admin for assistance.</p>
        `;
      dialogRef.componentInstance.title = `Missing Required Permission`;
    }
  }

  onDelete(report: ReportModel) {
    const dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true,
    });

    dialogRef.componentInstance.actionButtons = {
      flex: 'end center',
      buttons: [
        {
          label: 'No',
          iconName: null,
          name: 'No',
          color: 'warn',
          type: 'basic',
        },
        {
          label: 'Yes',
          iconName: null,
          name: 'Yes',
          color: 'primary',
          type: 'stroked',
        },
      ],
    };
    dialogRef.componentInstance.title = `Are you sure you would like to delete the report '${report.title}'.`;
    dialogRef.componentInstance.loading = false;

    dialogRef.componentInstance.actionEvents.subscribe((evt) => {
      switch (evt.name) {
        case 'Yes':
          dialogRef.componentInstance.loading = this.deletingReport;
          this.reportsAppService
            .delete('reports', `${report.report_id}`, null, null)
            .then((res) => {
              this.store.dispatch(reportActions.deleteReportSuccess({ reportId: report.report_id }));
              dialogRef.close();
              this.snackbarService.open(`'${report.name}' report deleted`);
            })
            .catch((error) => {
              dialogRef.close();
              this.snackbarService.open('Unable to delete report at this time.  Please try again later.');
            });
          break;
        case 'No':
          dialogRef.close();
          break;
      }
    });
  }

  onEdit(report: any): void {
    const generalInfo = report.params.general;
    this.dialog.open(CreateReportComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Edit Report',
        dashboardId: report.related_dashboard_id,
        selectedCompany: generalInfo.company,
        companies: this.companyOptions,
        report
      }
    });
  }

  runReport(report): void {
    this.loaderMessage = "Loading Report Details...";
    this.submittingReport = true;
    this.reportingFormService.getInputVariables(report.related_dashboard_id)
      .then(inputVariables => {
        this.submitReportRun(inputVariables, report.report_id);
        this.submittingReport = false;
      })
      .catch(err => this.snackbarService.open("Could not submit report run.", "OK"))
      .finally(() => {
        this.submittingReport = false;
        this.loaderMessage = null;
      });
  }


  private submitReportRun(inputVariables, reportId): void {
    this.reportsAppService.post("reports", reportId, null, inputVariables)
      .then(res => {
        // add the action here to go to the history details page.
        this.snackbarService.open("Report submitted successfully", "OK");
      })
      .catch(err => this.snackbarService.open("Error Running the report.", "OK"))
      .finally(() => this.submittingReport = false)
  }

  handlePaginationChange(changes: any) {
    this.reportingSvc.paginatorConfig = { ...changes?.payload };

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
