import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import { AppService } from '@app/app.service';
import { ExternalUser } from '@modules/user/interfaces/external-user';
import { User } from '@app/pages/admin/store/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: AppService<any>
  ) { }

  fetchExternalUserDetails(domain: string, realm: string, username: string): Observable<ExternalUser> {
    return from(this.httpClient.get('external-account', `/${domain}/${realm}/${username}`, null)).pipe(
      map(user => {
        return { ...user, gender: user.gender.split(',') }
      })
    );
  }

  deleteExternalUser(domain: string, realm: string, username: string): Observable<any> {
    return from(this.httpClient.delete('external-account', `/${domain}/${realm}/${username}`, null, null));
  }

  updateExternalUser(user: ExternalUser): Observable<ExternalUser> {
    const payload = { ...user, gender: user.gender.join(',') };
    return from(this.httpClient.put('external-account', `/${user.domain}/${user.realm}/${user.username}`, null, payload)).pipe(
      map(response => {
        if (response) {
          return user;
        }
        throw new Error("Failed to update.");
      })
    );
  }

  createExternalAccount(user: ExternalUser): Observable<ExternalUser> {
    const payload = { ...user, gender: user.gender.join(',') }
    return from(this.httpClient.post('external-account', '', null, payload));
  }

  createUser(user: User): Observable<User> {
    return from(this.httpClient.post('user', '', null, user));
  }
}
