import { Store } from '@ngrx/store';
import { Incident } from './../../incidents/store/incident.model';
import { AppService } from 'src/app/app.service';
import { switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as userPreferenceActions from './user-preference.actions';
import * as incidentActions from '../../incidents/store/incident.actions';
import { UserPreference } from './user-preference.model';
import { of } from 'rxjs';
import {SnackbarService} from '../../../shared-services/snackbar.service';
import { State } from '../../../reducers/index';



@Injectable()
export class UserPreferenceEffects {



  constructor(
    private actions$: Actions,
    private userPreferenceService: AppService<UserPreference[]>,
    private snackBarService: SnackbarService,
    private store: Store<State>
  ) {}

  @Effect({dispatch: true})
  loadUserPreference$ = this.actions$.pipe(
    ofType(userPreferenceActions.loadUserPreferences),
    switchMap((action: any) => {
      // First check in the local storage if it is present, and return it
      const userPreferences = localStorage.getItem('userPreferences');
      if (userPreferences) {
        // parse it and return it to the store
        return of(userPreferenceActions.loadUserPreferencesSuccess({userPreferences: JSON.parse(userPreferences)}));
      } else {
        // Here it has not been set yet. We need to get it from the backend
        return this.userPreferenceService.get('userPreferences', '', null)
          .then((res: UserPreference) => {
            return userPreferenceActions.loadUserPreferencesSuccess({userPreferences: res});
          })
          .catch(
            (error) => userPreferenceActions.loadUserPreferencesError({error: error.response ? error.response.message : error.message}));
      }
    })
  );

  @Effect({dispatch: false})
  loadUserPreferencesSuccess$ = this.actions$.pipe(
    ofType(userPreferenceActions.loadUserPreferencesSuccess),
    map((action: {userPreferences: UserPreference}) => {
      // Persist the user preference into the loacal storage
      localStorage.setItem('userPreferences', JSON.stringify(action.userPreferences));
    })
  );

  @Effect({dispatch: true})
  updateUserPreferences$ = this.actions$.pipe(
    ofType(userPreferenceActions.updateUserPreferences),
    switchMap((action: {userPreferences: UserPreference, notify: boolean, incident?: Incident}) => {
      const userPrefPayload: UserPreference = {
        activeInvestigation: action.userPreferences.activeInvestigation || '-',
        dateFormat: action.userPreferences.dateFormat || 'shortDate',
        dateRange: action.userPreferences.dateRange || 'last 4 hours',
        incidentsSearch: action.userPreferences.incidentsSearch || {
          assignee: [],
          classifications: [],
          closureState: [],
          companies: [],
          priority: [],
          state: [],
          tier: []
        },
        searchFields: action.userPreferences.searchFields || [],
        searchRefreshInterval: action.userPreferences.searchRefreshInterval || '60 minutes',
        owner_id: action.userPreferences.owner_id
      };
      return this.userPreferenceService.put('userPreferences', '', {}, userPrefPayload)
        .then((res: UserPreference) => {
          // Persist the updated user preferences into the local storage
          localStorage.setItem('userPreferences', JSON.stringify(action.userPreferences));
          if (action.notify) {
            this.snackBarService.open('Update Successful!');
          }
          if (action.incident) {
            this.store.dispatch(incidentActions.updateIncidentSuccess({incident: action.incident}));
          }
          return userPreferenceActions.loadUserPreferencesSuccess({userPreferences: res});
        })
        .catch((error: any) => {
          this.snackBarService.open('Could not update. Please try again later.');
          return userPreferenceActions.updateUserPreferencesError({error: error.message});
        });
    })
  );

  @Effect({dispatch: true})
  dismissUserPreferences$ = this.actions$.pipe(
    ofType(userPreferenceActions.dismissUserPreferences),
    switchMap((action: {userPreferences: UserPreference, notify: boolean, incident?: Incident}) => {
      const userPrefPayload: UserPreference = {
        activeInvestigation: action.userPreferences.activeInvestigation || '-',
        dateFormat: action.userPreferences.dateFormat || 'shortDate',
        dateRange: action.userPreferences.dateRange || 'last 4 hours',
        incidentsSearch: action.userPreferences.incidentsSearch || {
          assignee: [],
          classifications: [],
          closureState: [],
          companies: [],
          priority: [],
          state: [],
          tier: []
        },
        searchFields: action.userPreferences.searchFields || [],
        searchRefreshInterval: action.userPreferences.searchRefreshInterval || '60 minutes',
        owner_id: action.userPreferences.owner_id
      };
      return this.userPreferenceService.put('userPreferences', '', {}, userPrefPayload)
        .then((res: UserPreference) => {
          // Persist the updated user preferences into the local storage
          localStorage.setItem('userPreferences', JSON.stringify(action.userPreferences));
          if (action.notify) {
            this.snackBarService.open('Incident Dismissed!');
          }
          if (action.incident) {
            this.store.dispatch(incidentActions.dismissIncidentSuccess({incident: action.incident}));
          }
          return userPreferenceActions.loadUserPreferencesSuccess({userPreferences: res});
        })
        .catch((error: any) => {
          this.snackBarService.open('Could not update. Please try again later.');
          return userPreferenceActions.dismissUserPreferencesError({error: error.message});
        });
    })
  );

}
