import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateService } from 'src/app/shared-services/date.service';


export interface IDateRangeOption {
  label: string;
  value: string;
}

// export type DateRangeValueType = 

export interface IDateRangeConfig {
  style: NgStyle;
  selected: string;
  appearance: "outline" | "fill" | "standard";
  placeholder: string;
  label: string;
}

@Component({
  selector: 'app-relative-date',
  templateUrl: './relative-date.component.html',
  styleUrls: ['./relative-date.component.scss']
})
export class RelativeDateComponent implements OnInit {

  defaultDateList: IDateRangeOption[] = [
    { label: 'Now', value: 'now' },
    { label: 'last 5 minutes', value: 'last 5 minutes' },
    { label: 'last 15 minutes', value: 'last 15 minutes' },
    { label: 'last 60 minutes', value: 'last 60 minutes' },
    { label: '4 hours ago', value: 'last 4 hours' },
    { label: '24 hours ago', value: 'last 24 hours' },
    { label: '7 days ago', value: 'last 7 days' },
    { label: '30 days ago', value: 'last 30 days' },
    { label: 'last 1 year', value: 'last 1 year' },
  ];

  private _options: IDateRangeOption[] = [...this.defaultDateList];
  private _config: IDateRangeConfig;
  relativeDateFC: FormControl;

  @Input()
  set options(opts: IDateRangeOption[]) {
      this._options = [...opts]
  }

  get options(): IDateRangeOption[] {
    return this._options;
  }
  
  @Input()
  set config(conf: IDateRangeConfig) {
    if (!!!conf) {
      this._config = {
        style: null,
        appearance: 'standard',
        label: '',
        placeholder: '',
        selected: ''
      }

      return;
    }

    this._config = { ...conf }
  }

  get config(): IDateRangeConfig {
    return this._config;
  }

  @Input() selected: string = '';
  @Output() selection = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.relativeDateFC = new FormControl(this.selected)
  }

}
