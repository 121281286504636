<div fxLayout="column" class='incident-details'>
  <mat-card class="incident-details-card" [style.border-top]="computeIncidentGrade(incident)" fxFlex>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title><strong>{{incident.number}}</strong></mat-card-title>
      <mat-menu #actionsMenu="matMenu">
        <button *ifUserCan="'response.add-worklog'" mat-menu-item (click)="onAddWorklog()">
          <mat-icon>add</mat-icon>Add Worklog
        </button>
      </mat-menu>
      <button mat-icon-button [mat-menu-trigger-for]="actionsMenu" *ifUserCan="['response.add-worklog']">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-card-subtitle>{{incident.short_description}}</mat-card-subtitle>
    <mat-card-content>
      <form [formGroup]="form" fxLayout="row wrap" novalidate>
        <mat-form-field fxFlex>
          <mat-select placeholder="Classification" formControlName="category">
            <mat-option [value]="classification.value" *ngFor="let classification of classificationOptions ">
              {{classification.friendly}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select placeholder="Priority" formControlName="priority">
            <mat-option [value]="priority.value" *ngFor="let priority of priorityOptions ">{{priority.friendly}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select placeholder="Company" formControlName="company">
            <mat-option [value]="company.value" *ngFor="let company of companies">{{company.friendly}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select placeholder="Assignee" formControlName="u_unlisted_affected_user">
            <mat-optgroup *ngFor="let group of users" [label]="group.groupName | titlecase" [disabled]="group.disabled">
              <mat-option *ngFor="let value of group.option" [value]="value.value">
                {{value.friendly}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select placeholder="Queue" formControlName="u_queue">
            <mat-option [value]="tier.value" *ngFor="let tier of tierOptions">{{tier.friendly}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select placeholder="State" formControlName="incident_state">
            <mat-option [value]="state.value" *ngFor="let state of stateOptions ">{{state.friendly}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-card-content>

    <mat-card-actions fxLayout="row" fxLayoutAlign="space-between end">
      <div>
        <button mat-raised-button color="warn" [disabled]=' loading'
                (click)="doAction('DISMISS', incident)" *ngIf='canDismiss(incident)'>
          <mat-icon [inline]="true">work_off</mat-icon>
          Dismiss
        </button>
        <ng-container *ifUserCan="'response.assign-inv'">
          <button mat-raised-button color="primary" [disabled]='handleDisabling()'
                  (click)="doAction('ASSIGN_SELF_AND_MAKE_ACTIVE', incident)" *ngIf='!canDismiss(incident)'>
            <mat-icon [inline]="true">local_library</mat-icon>
            Investigate
          </button>
        </ng-container>
        <button *ifUserCan="['response.edit-inv-properties', 'response.edit-inv-state', 'response.assign-other-inv']; any: true" mat-stroked-button color="primary" (click)="doAction('UPDATE', incident)"
                [disabled]='form.pristine || handleDisabling()'>
          <mat-icon [inline]="true">assignment_ind</mat-icon>
          Update
        </button>

        <button mat-button [disabled]='loading' *ngIf="showDetailsButton"
                (click)="doAction('SHOW_DETAILS', incident)">
          <mat-icon [inline]="true">receipt</mat-icon>
          Details
        </button>
      </div>
      <div fxLayout="row" class="incident-dates">
        <h4><strong>Created:</strong> {{incident.sys_created_on | userDate }}</h4>
        <h4><strong>Updated:</strong> {{incident.sys_updated_on | userDate }}</h4>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
