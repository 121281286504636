import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {KbService} from '../../../pages/knowledge-base/kb.service';

export interface HelpInfoModal {
  email: string;
}

@Component({
  selector: 'app-incident-help-dialog',
  templateUrl: './help-dialog-modal.component.html',
  styleUrls: ['./help-dialog-modal.component.scss']
})

export class HelpDialogModalComponent implements OnInit {
  @Input() articleTitle: string;

  constructor(
      public dialogRef: MatDialogRef<HelpDialogModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  private knowledgeBaseService = new KbService();
  helpArticle = this.knowledgeBaseService.findSingleArticle(this.data.articleTitle);

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
