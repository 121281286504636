<section class="company-details" *ngIf="!loading && !error; else loadingDiv">
  <!-- Back button and add company -->
  <div class="btn-container">
    <a type="button" mat-stroked-button routerLink="/admin/company">
      <mat-icon>chevron_left</mat-icon>
    </a>

    <!-- Add company button -->
    <button mat-stroked-button class="primary" (click)="editCompany()">
      <mat-icon>edit</mat-icon>
      Company
    </button>
  </div>

  <!-- company attributes -->
  <section class="details" *ngIf="!loading && companyDetails">
    <div class="name">
      <p class="company-name">{{ companyDetails.name }}</p>
      <p class="company-id">
        <span class="company-id">{{ companyDetails.id }}</span>
      </p>
    </div>
    <section class="company-attributes">
      <!-- product tree -->
      <app-product-tree
        [companyId]="companyId"
        [companyDetails]="companyDetails"
      ></app-product-tree>
    </section>
  </section>

  <section class="networks" *ngIf="!loading && companyDetails">
    <div>
      <h3>IPv4 Networks</h3>
      <div
        class="product-list"
        *ngIf="companyDetails.sourceIPV4CIDRs.length; else notAvailable"
      >
        <span *ngFor="let item of companyDetails.sourceIPV4CIDRs">{{
          item
        }}</span>
      </div>
      <ng-template #notAvailable> - </ng-template>
    </div>
    <div>
      <h3>IPv4 Addresses</h3>
      <div
        class="product-list"
        *ngIf="companyDetails.sourceIPs.length > 0; else notAvailable"
      >
        <span *ngFor="let ip of companyDetails.sourceIPs">{{ ip }}</span>
      </div>
      <ng-template #notAvailable> - </ng-template>
    </div>
    <div>
      <h3>Hostnames</h3>
      <div
        class="product-list"
        *ngIf="companyDetails.sourceHosts.length > 0; else notAvailable"
      >
        <span *ngFor="let hostname of companyDetails.sourceHosts">{{
          hostname
        }}</span>
      </div>
      <ng-template #notAvailable> - </ng-template>
    </div>
  </section>
</section>
<ng-template #loadingDiv>
  <div class="error-container">
    <mat-spinner *ngIf="loading; else errorDiv"></mat-spinner>
    <ng-template #errorDiv>
      <mat-card>
        <mat-card-content>
          <span class="error-icon">
            <mat-icon color="warn">error</mat-icon>
          </span>
          <p>
            Could not find details about company with ID
            <span>{{ companyId }}</span
            >.
          </p>
          <a type="button" mat-stroked-button routerLink="/admin/company">
            <mat-icon>chevron_left</mat-icon>
            Back To Company list Page
          </a>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </div>
</ng-template>
