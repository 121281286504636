import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Auth } from 'aws-amplify';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil, filter } from 'rxjs/operators';

import { DatetimeService } from '@services/datetime.service';
import { Company } from '@modules/companies/interfaces/companies';
import { EventsService } from '@modules/events/services/events.service';
import { EventField } from '@app/shared-stores/event-params/event-params.model';
import { CompaniesService } from '@modules/companies/services/companies.service';
import { CompanyIndex, DateRange, FilterAction, IndexField, QueryData, SortField } from '@modules/events/interfaces/events';
import { ColumnManagerComponent } from '../column-manager/column-manager.component';
import { QueryBuilderComponent, RuleGroup } from '@sweet-shared/components/query-builder/query-builder.component';
import { HelpDialogModalComponent } from '@sweet-shared/components/help-dialog-modal/help-dialog-modal.component';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss']
})
export class EventFilterComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  private allColumns: EventField[] = [];
  private _refreshInterval: number | null = null;
  private _refreshIntervalCtrl = null;
  loading = false;

  // Input for the loader
  @Input() searching = false;
  @Input() error = null;
  @Input() activateColumnManager = new EventEmitter();


  // Output for the action
  @Output() action: EventEmitter<FilterAction> = new EventEmitter();


  // Filters options
  dateRangeOptions: DateRange[] = [];
  columnOptions: IndexField[] = [];
  companiesOptions: Company[] = [];
  filteredCompaniesOptions: Company[] = [];
  indexOptions: CompanyIndex[] = [];
  filteredIndexOptions: CompanyIndex[] = [];
  dateRange: { from: string, to: string } = null;
  refreshInterval: number | null = null;

  // selected cols
  selectedColumns: string[] = [];
  selectedSortColumns: any[] = [];
  queryRuleGroups: RuleGroup[];
  queryRuleGroups$ = new BehaviorSubject<{ ruleGroups: RuleGroup[], data: string }>(null);

  // Filter form
  form: FormGroup;
  queryFilterValue: string = null;

  constructor(
    private fb: FormBuilder,
    private eventService: EventsService,
    private companyService: CompaniesService,
    private datetimeService: DatetimeService,
    private dialog: MatDialog
  ) {
    // Get the event filter from the session storage if any
    this.form = this.fb.group({
      dateRange: [null, [Validators.required]],
      company: [null, [Validators.required]],
      index: [null, [Validators.required]],
      selectedFields: [[], []]
    });

    this.form.controls.company.valueChanges.pipe(takeUntil(this.destroyer$), filter(c => !!c)).subscribe(company => {
      this.eventService.fetchIndexes(company).pipe(takeUntil(this.destroyer$)).subscribe((indexes: CompanyIndex[]) => {
        this.indexOptions = indexes.sort((a, b) => a.friendly.toLowerCase() > b.friendly.toLowerCase() ? 1 : -1);
        this.filterIndex('');
        const selectedIndex = this.form.controls.index.value;
        if (selectedIndex) {
          this.updateAvailableColumns(selectedIndex);
        }
      });
    });

    this.form.controls.dateRange.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(value => this.dateChangeHandler(value));
    this.eventService.searching$.pipe(takeUntil(this.destroyer$)).subscribe(searching => {
      if (searching) {
        // stop counter.
        if (this._refreshIntervalCtrl) {
          clearInterval(this._refreshIntervalCtrl)
        }
      } else {
        this.setRefreshInterval();
      }
    })

  }

  private updateAvailableColumns(indexId: string): void {
    let indexData = this.indexOptions.find(index => index.id === indexId);
    if (!indexData) {
      indexData = this.indexOptions.find(index => index.id === 'default');
    }
    if (indexData) {
      this.columnOptions = indexData.fields;
      this.updateSelectedColumns(indexId, indexData.default_headers);

      // emit all available cols
      this.action.emit({ name: 'AVAILABLE_COLUMNS', data: indexData.fields });
    }
  }


  private updateSelectedColumns(indexId: string, defaultHeaders): void {
    this.eventService.fecthUserFieldSet(indexId).pipe(takeUntil(this.destroyer$)).subscribe({
      next: (response: any) => {
        this.selectedColumns = response.headers;
        this.selectedSortColumns = response.sort_by ? [response.sort_by] : [];
        this.action.emit({ name: 'COLUMN', data: response.headers });
        this.action.emit({ name: 'UPDATE_FIELD', data: response });
      },
      error: (error) => {
        // not found create one
        this.eventService.updateUserFieldSet(indexId, defaultHeaders).pipe(takeUntil(this.destroyer$)).subscribe({
          next: (res) => {
            this.selectedColumns = res.headers;
            this.action.emit({ name: 'COLUMN', data: res.headers });
            this.action.emit({ name: 'UPDATE_FIELD', data: res });
          },
          error: (er) => {
            this.selectedColumns = defaultHeaders;
            this.action.emit({ name: 'COLUMN', data: defaultHeaders });
            this.action.emit({ name: 'UPDATE_FIELD', data: { headers: defaultHeaders } });
          }
        })
      }
    })
  }

  private setupIndexChangeHandler(): void {
    this.form.controls.index.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(indexId => this.updateAvailableColumns(indexId));
    this.form.updateValueAndValidity();
  }

  private prepopulateQueryFilter(): void {
    this.queryRuleGroups = JSON.parse(sessionStorage.getItem('event-query') || '[]');
    if (this.queryRuleGroups) {
      this.queryFilterValue = QueryBuilderComponent.queryStringBuilder(this.queryRuleGroups, false);
    }
  }

  ngOnInit(): void {
    const eventFilters = JSON.parse(sessionStorage.getItem('event-filters') || 'null');
    forkJoin({
      eventParams: this.eventService.fetchEventParams(),
      user: Auth.currentAuthenticatedUser(),
      companies: this.companyService.fetchCompanies(),
      userPreferences: this.eventService.fetchUserPreference(),
    }).pipe(
      takeUntil(this.destroyer$),
      finalize(() => this.loading = false)
    ).subscribe({
      next: (response) => {
        if (response.userPreferences.searchRefreshInterval) {
          this._refreshInterval = 60 * parseInt(response.userPreferences.searchRefreshInterval);
          this.setRefreshInterval();
        }

        this.dateRangeOptions = response.eventParams.eventDateRanges;
        this.allColumns = response.eventParams.fields;
        this.companiesOptions = response.companies.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this.filteredCompaniesOptions = this.companiesOptions;
        const sessionDateRange = JSON.parse(sessionStorage.getItem('event-dateRange') || 'null');
        // Set the default value of the form
        this.form.patchValue({
          company: eventFilters?.company ?? response.user.attributes.profile,
          index: eventFilters?.index ?? 'default',
          dateRange: eventFilters?.dateRange ?? sessionDateRange ?? response.userPreferences.dateRange
        });
        this.setupIndexChangeHandler();
      },
      error: (error) => {
        this.error = 'Failed to initialize the page';
      }
    });

    this.queryRuleGroups$.pipe(takeUntil(this.destroyer$)).subscribe(value => {
      if (value) {
        this.queryFilterValue = value.data;
        this.queryRuleGroups = value.ruleGroups;
      }
    });

    this.prepopulateQueryFilter();

    // subscribe on opening the column manager
    this.activateColumnManager.pipe(takeUntil(this.destroyer$)).subscribe(_ => this.openColumnManager());

    // subscribe to the event
    this.eventService.event$.pipe(
      takeUntil(this.destroyer$),
      filter(evt => ['UPDATED_QUERY_FILTER', 'SEARCHING'].includes(evt.name)),
    ).subscribe(evt => {
      const { name, data } = evt;
      if (name === 'UPDATED_QUERY_FILTER') {
        this.queryRuleGroups$.next({ ruleGroups: data, data: QueryBuilderComponent.queryStringBuilder(data, false) });
      } else if (name === 'SEARCHING') {
        if (data) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      }
    });
  }

  filterCompany(searchTerm: string): void {
    this.filteredCompaniesOptions = this.companiesOptions.filter(c => c.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  filterIndex(searchTerm: string): void {
    this.filteredIndexOptions = this.indexOptions.filter(i => i.friendly.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  setRefreshInterval(): void {
    this.refreshInterval = this._refreshInterval;
    if (this._refreshIntervalCtrl) {
      clearInterval(this._refreshIntervalCtrl);
    }
    this._refreshIntervalCtrl = setInterval(() => {
      this.refreshInterval -= 1;
      if (this.refreshInterval === 0) {
        this.performSearch();
      }
    }, 1000);
  }

  openHelpDialog(): void {
    this.dialog.open(HelpDialogModalComponent,
      {
        height: '80vh',
        data: {
          articleTitle: 'Events Section Overview...'
        }
      }
    );
  }

  // comparator for date time range
  dateTimeComparator(option, value): boolean {
    return option.type === value.type && option.from === value.from && option.to === value.to;
  }

  dateChangeHandler(value): void {
    if (value) {
      forkJoin({
        from: this.datetimeService.validateMayTime(value.from),
        to: this.datetimeService.validateMayTime(value.to)
      }).pipe(takeUntil(this.destroyer$)).subscribe({
        next: (response) => {
          this.dateRange = { from: new Date(response.from.date).toISOString(), to: new Date(response.to.date).toISOString() };

          // persist it in session storage
          sessionStorage.setItem('event-dateRange', JSON.stringify(value));
        },
        error: (error) => {
          this.error = error.message;
        }
      });
    }
  }

  updateColumnOrSorting(evt): void {
    const { name, type, data } = evt;
    if (name === 'UPDATE') {
      if (type === 'COLUMN') {
        this.selectedColumns = data;
        this.action.emit({ name: 'COLUMN', data })
      }
    } else if (name === 'UPDATE_FIELD') {
      this.action.emit({ name: 'UPDATE_FIELD', data });
    }
  }

  openColumnManager(): void {
    const dialogRef = this.dialog.open(ColumnManagerComponent, {
      width: '80%',
      height: '70vh',
      disableClose: true,
    });

    // set the input and output
    dialogRef.componentInstance.availableColumns = this.columnOptions;
    dialogRef.componentInstance.selectedColumns = this.selectedColumns;
    dialogRef.componentInstance.indexId = this.form.controls.index.value;
    dialogRef.componentInstance.action.pipe(takeUntil(this.destroyer$)).subscribe(evt => this.updateColumnOrSorting(evt));
  }

  openQueryBuilder() {
    const data: QueryData = {
      rules: [{ field: '', operator: '=', value: '' }],
      formatWithColon: false,
      page: 'event'
    };
    let sessionRules: any = sessionStorage.getItem(`${data.page}-query`);
    sessionRules = sessionRules ? JSON.parse(sessionRules) : sessionRules = [];
    (sessionRules[0] && sessionRules[0].rules && sessionRules[0].rules.length) ? data.ruleGroups = sessionRules : data.ruleGroups = [{ condition: 'AND', rules: data.rules }];
    const dialogRef = this.dialog.open(QueryBuilderComponent, {
      disableClose: false,
      panelClass: 'ctl-panel-class',
      data
    });
    dialogRef.componentInstance.fields = this.columnOptions;
    dialogRef.componentInstance.inputData = data;

    dialogRef.componentInstance.queryBody.pipe(
      takeUntil(this.destroyer$)
    ).subscribe(event => {
      if (event.name === 'submit') {
        this.queryRuleGroups$.next(event);
        dialogRef.close();
      } else if (event.name === 'cancel') {
        dialogRef.close();
      } else if (event.name === 'RESET_QUERY') {
        this.queryRuleGroups$.next({ ruleGroups: [], data: '' });
      }
    });
  }

  performSearch(): void {
    const payload = {
      query: this.queryRuleGroups ?? [],
      dateFrom: this.dateRange.from,
      dateTo: this.dateRange.to,
      index_name: this.form.controls.index.value,
      companyFilter: this.form.controls.company.value,
      size: 500,
    };
    this.eventService.setEventFilters({ ...payload, dateRange: this.form.controls.dateRange.value });
    this.action.emit({ name: 'SEARCH', data: payload });

    // restart the refresh here
    this.setRefreshInterval();
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
    clearInterval(this._refreshIntervalCtrl);
  }

}
