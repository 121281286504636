import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Attribute, CompanyDetails, Product } from '@modules/companies/interfaces/companies';
import { CompaniesService } from '@modules/companies/services/companies.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-tree',
  templateUrl: './product-tree.component.html',
  styleUrls: ['./product-tree.component.scss']
})
export class ProductTreeComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  @Input() companyId: string;
  @Input() companyDetails: CompanyDetails;

  attributes: { [key: string]: Attribute } = {};
  products = [];
  checkedMap = {};
  sourceIPV4CIDRs: string[] = [];
  sourceIPs: string[] = [];
  sourceHosts: string[] = [];
  productForm: FormGroup;

  dataSource: MatTreeNestedDataSource<any> = new MatTreeNestedDataSource();
  treeControl: NestedTreeControl<any> = new NestedTreeControl(node => node.children);

  constructor(
    private companyService: CompaniesService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    forkJoin({
      products: this.companyService.fetchProducts(),
      attributes: this.companyService.fetchCompanyAttributes(),
    }).pipe(takeUntil(this.destroyer$)).subscribe({
      next: (resp) => {
        const { products, attributes } = resp;
        this.products = products;
        this.attributes = attributes;

        // build the product fields
        const productFields = products.reduce((acc: any, next: Product) => {
          acc[next.name] = [''];
          this.checkedMap[next.name] = this.companyDetails?.products?.includes(next.name) ?? false;
          if (next.attributes && next.attributes.length) {
            const initValue = this.companyDetails?.attributes[next.name] ?? '';
            acc[`${next.name}_input`] = [{ value: initValue, disabled: true }, []];
          }
          return acc;
        }, {});

        // Generate the product form
        this.productForm = this.fb.group({ ...productFields });

        // set the datasource of the tree
        this.dataSource.data = this.buildProductTree(products);
        this.patchForm(this.companyDetails);
      }
    })
  }

  hasChild = (_: number, node: any) => {
    return !!node.children && node.children.length > 0;
  }

  patchForm(details: CompanyDetails): void {
    if (details) {
      this.sourceHosts = details.sourceHosts;
      this.sourceIPV4CIDRs = details.sourceIPV4CIDRs;
      this.sourceIPs = details.sourceIPs;
    }
  }

  buildProductTree(products: Product[]) {
    const roots = [];
    const productTree = products.map((product: Product) => {
      return {
        ...product,
        children: [],
        level: 0
      };
    });
    let counter = 0;
    const nameAndIndices = productTree.reduce((acc, next) => {
      acc[next.name] = counter;
      counter = counter + 1;
      return acc;
    }, {});

    for (let i = 0; i < productTree.length; i++) {
      let node = productTree[i];
      if (node.parent && node.parent[0]) {
        node.level = this.findLevel(productTree, nameAndIndices, node, node.level);
        productTree[nameAndIndices[node.parent[0]]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  findLevel(list, obj, node, count): number {
    count = count || 0;
    if (!node.parent.length) {
      return count;
    } else {
      count += 1;
      const parentNode = list[obj[node.parent[0]]];
      return this.findLevel(list, obj, parentNode, count);
    }
  }

  onCheck(node: Partial<Product & { children: Product[] }>, checked: boolean): void {
    // If a child product is checked, set to true in map
    if (checked) {
      this.checkedMap[node.name] = true;
      // enable attribute input if checkbox checked
      if (this.productForm.controls[`${node.name}_input`]) {
        this.productForm.controls[`${node.name}_input`].enable();
      }
      // exit recursion
      if (!node.parent || !node.parent.length) {
        return;
      }
      const parentNode = this.products.find(product => product.name === node.parent[0]);
      // repeat for parent
      return this.onCheck(parentNode, true);
    } else {
      // If a parent is unchecked, set to false in map
      this.checkedMap[node.name] = false;
      // disable attribute input if checkbox unchecked
      if (this.productForm.controls[`${node.name}_input`]) {
        this.productForm.controls[`${node.name}_input`].disable();
      }
      // exit recursion
      if (!node.children || !node.children.length) {
        return;
      }
      // repeat for children
      node.children.forEach(child => {
        return this.onCheck(child, false);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
