import { Injectable } from '@angular/core';
import { IEnvironment } from '../i-environment';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {

    name: string = environment.name;
    production: boolean = environment.production;
    amplifyConfig: any = environment.amplifyConfig;
    regions: any[] = environment.regions;
    ssoOptions: any[] = environment.ssoOptions;
    gradeColors: any = environment.gradeColors;
    landingPage: string = environment.landingPage;
    hiddenFromProduction: any[] = environment.hiddenFromProduction;
    hiddenFromProductionAdminLinks: any[] = environment.hiddenFromProductionAdminLinks;
    version: any = environment.version;
    regionDomains = environment.regionDomains;
    flexmonsterLicense = environment.flexmonsterLicense;

    constructor() {
    }

    getDomainRegion(url: string) {
        const config = this.regionDomains[url];
        let region = '';

        if (!!!config) {
            return localStorage.getItem('region');
        }


        region = config.region;
        localStorage.setItem('region', region)

        return region;
    }


}
