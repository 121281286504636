export const kbArticles = [
    {
        title: 'Template...',
        summary: '',
        content: '',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: '',
        tags: ['']
    },
    {
        title: 'Dashboard Overview...',
        summary: 'The dashboard section allows a user to create charts, tables, and visualizations of their data using different types of widgets. The Dashboard section is useful for viewing high level summarizations of data or identifying specific actionable items of interest.',
        content: '# Portal 4.0 - Dashboard Overview\n' +
            'The dashboard section allows a user to create charts, tables, and visualizations of their data using different types of widgets. The Dashboard section is useful for viewing high level summarizations of data or identifying specific actionable items of interest.\n' +
            '## Common Dashboard Tasks\n' +
            '- Create a Dashboard\n' +
            '- Edit a Dashboard\'s Name and Description\n' +
            '- Delete a Dashboard\n' +
            '- Create a Report\n' +
            '- View Reports (Navigates to Report History Section)\n' +
            '- Duplicates a Dashboard\n' +
            '- Drill into a Dashboard from the list of Dashboards\n' +
            '\n' +
            '## Features\n' +
            '- Headers can be relocated by the user\n' +
            '- Rows can be sorted by column ascending or descending\n' +
            '- Number of items per page can be adjusted depending on preference',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'create', 'make', 'copy', 'new', 'generate', 'produce', 'fabricate',
            'build', 'construct', 'form', 'devise', 'add']
    },
    {
        title: 'How to Create a Dashboard...',
        summary: 'Create a new dashboard by following the below steps...',
        content: '# Portal 4.0 - Create Dashboard(s)\n' +
            '\n' +
            '## Create a Dashboard\n' +
            '1.\tClick \'Dashboard\' on the top left side navigation bar. The dashboard section loads and your list of Dashboards will load into a table if present.\n' +
            '2.\tClick the icon on the upper right corner labeled ‘Create New Dashboard’ to prompt the Dashboard create wizard. The \'Create Dashboard(s)\' window appears.\n' +
            '3.\tSelect a Dashboard Template from the first dropdown.  Depending on your products associated with your company, multiple default templates will appear.  To create a dashboard from scratch, select the Custom Template at the bottom of the list.\n' +
            '4.\tEnter a name for the dashboard on the ‘Title\' line or stick with the default name.\n' +
            '5.\tSelect an optional description of the dashboard if desired\n' +
            '6.\tRepeat the process by pressing the Add Another Button or click Submit.\n' +
            '7.\tYour new Dashboard(s) will be provisioned in a few seconds.\n' +
            '\n' +
            '\n' +
            '## Tips\n' +
            '- Use a descriptive name for a dashboard.',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'create', 'make', 'copy', 'new', 'generate', 'produce', 'fabricate',
            'build', 'construct', 'form', 'devise', 'add']
    },
    {
        title: 'How to Delete a Dashboard...',
        summary: 'There are two ways to delete a Dashboard.  Both methods will remove the dashboard, all widgets on the dashboard, assiocated schedule reports, and assiocated report histories.',
        content: '# Portal 4.0 - Delete Dashboard(s)\n' +
            '\n' +
            'There are two ways to delete a Dashboard.  Both methods will remove the dashboard, all widgets on the dashboard, assiocated schedule reports, and assiocated report histories. \n' +
            '\n' +
            '## Delete Dashboard(s)\n' +
            '1.\tClick \'Dashboard\' on the top left navigation bar. The dashboard section loads and your list of Dashboards will load into a table if present.\n' +
            '2.\tClick on the square boxes next to the title of the Dashboard(s) you wish to delete.\n' +
            '3.\tOnce at least one box is selected, the Delete Dashboard(s) button will be enabled.  A popup will appear asking you if your are sure you want to delete your dashboard and assiocated reports.  If you wish to continue, click Yes.\n' +
            '\n' +
            '## Alternative Delete Method\n' +
            '1.\tIn the Dashboard Overview section, click on the Action icon for a single Dashboard.\n' +
            '2.\tA Dropdown menu will appear, select Delete.\n' +
            '3.\tA confirmation popup will appear, select Yes.\n' +
            '\n' +
            '## Tips\n' +
            '- Reports are now directly linked to dashboards.  When a dashboard is deleted, any reports related to that dashboard will be deleted as well, including any future scheduled reports.  This data will be irrevocably lost.  Delete a dashboard only if you are absolutley sure you don\'t need the widgets or report histories any longer.    \n',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'delete', 'remove', 'get rid of', 'cancel', 'kill', 'erase',
            'subtract']
    },
    {
        title: 'How to Edit a Dashboard  ...',
        summary: 'A Dashboard can be have it\'s Title and Description updated after creation. ',
        content: '# Portal 4.0 - Edit a Dashboard\n' +
            '\n' +
            'A Dashboard can be have its Title and Description updated after creation. \n' +
            '\n' +
            '## Edit Dashboard(s)\n' +
            '1.\tClick \'Dashboard\' on the top left side navigation bar. The dashboard section loads and your list of Dashboards will load into a table if present.\n' +
            '2.\tClick on the Actions Icon next to the title of the Dashboard(s) you wish to update.\n' +
            '3.\tOnce the Actions Icon is clicked, a dropdown menu will appear with Edit action, select Edit, opening a new popup window called Edit Dashboard.\n' +
            '4.\tUpdate either the Dashboard Name or Description and press Save.\n' +
            '\n' +
            '## Tips\n' +
            '- Use descriptive Titles and Descriptions to help easily identify dashboards.  ',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'edit', 'update', 'modify', 'correct', 'revise', 'amend', 'rename']
    },
    {
        title: 'How to Open a Dashboard  ...',
        summary: 'From a list of the Dashboards in the Dashboard Overview section, a user can open a dashboard to view and add widgets, update global parameters, download data from certain widgets, and create a report.',
        content: '# Portal 4.0 - Open a Dashboard\n' +
            '\n' +
            'From a list of the Dashboards in the Dashboard Overview section, a user can open a dashboard to view view and add widgets, update global parameters, download data from certain widgets, and create a report. \n' +
            '\n' +
            '## Open a Dashboard\n' +
            '1.\tClick \'Dashboard\' on the top left side navigation bar. The dashboard section loads and your list of Dashboards will load into a table if present.\n' +
            '2.\tWith the exception of the Check box next to title and the Actions Icon in the action column, the entire row of the Dashboard Table List is a button that will navigate the user to the dashboard clicked on.\n' +
            '3.\tOnce the Actions Icon is clicked, a dropdown menu will appear with Edit action, select Edit, opening a new popup window called Edit Dashboard.\n' +
            '4.\tThe Dashboard Detail page will load.\n' +
            '\n' +
            '## Tips\n' +
            '- Depending on the Date Range user Default (found in the User Preferences Section under account Preferences) the Dashboard may take several moments to load.  7 Days is the recommended Default Search Range for quicker load times of the dashboard.  ',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'use', 'purpose', 'interact', 'Open']
    },
    {
        title: 'How to Create a New Widget...',
        summary: 'In the Dashboard Detail Section, widgets are the primary source of visual information.  Users can leverage pre-made widgets by creating a dashboard from one of the many templates available (product specific.) Users can also make a new widget in either a template Dashboard or in a custom Dashboard. ',
        content: '# Portal 4.0 - New Widgets\n' +
            '\n' +
            'In the Dashboard Detail Section, widgets are the primary source of visual information.  Users can leverage pre-made widgets by creating a dashboard from one of the many templates available (product specific.) Users can also make a new widget in either a template Dashboard or in a custom Dashboard.   \n' +
            '\n' +
            '## Create a Widget\n' +
            '1.\tIn the Dashboard Detail section of a Dashboard, click \'Dashboard Actions\' button on the upper right hand corner of the page.  A dropdown menu should appear.  Click on New Widget / Filter.  Alternatively, if you have an empy dashboard, click on the \'+\' button in the middle of the page to open up the widget creation wizard.\n' +
            '2.\tDifferent types of widgets can be created, select the type of widget you want to create first.  This may alter the number of steps in the wizard as different widgets may/do require different parameters.  Fill in the rest of the wizard with the require information and Press Submit on the last step. \n' +
            '3.\tThe new widget will be created and populate at the bottom of the Dashboard.  You may need to reload/refresh/click reapply the page to load the widget data.  This can be done individually on each widget by clicking on the Action Icon on the upper right hand corner of a widget.\n' +
            '\n' +
            '## Tips\n' +
            '- Too many widgets on a single dashboard can create longer loading times.  Try to keep your widgets focused and relevant to a specific Dashboard.',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: [ 'dashboards', 'widgets', 'widget', 'chart', 'table', 'graph', 'line', 'bar', 'pie', 'create', 'make', 'new', 'fabricate', 'generate', 'produce', 'build', 'construct', 'form', 'devise', 'add']
    },
    {
        title: 'How to Edit a Widget...',
        summary: 'A widget can be updated after it has been created.   \n',
        content: '# Portal 4.0 - Edit Widgets\n' +
            '\n' +
            'A widget can be updated after it has been created.   \n' +
            '\n' +
            '## Edit a Widget\n' +
            '1.\tIn the Dashboard Detail section of a Dashboard, click on the upper right hand corner of the widget to be edited.  \n' +
            '2.\tA dropdown menu should appear.  Click on Edit option.  \n' +
            '3.\tThe widget edit wizard should open up, make any changes as necessary, double check to ensure all fields have been set to the desired values.  Press the submit button at the end of the wizard prompt.  \n' +
            '4.\tThe widget will then be updated.  A refresh of the widget or the page may be necessary to update the widget data.\n' +
            '\n' +
            '## Tips\n' +
            '- Generally, any attributes of the widget can be updated, however, it is highly recommended to keep an existing widget type the same.  For instance, if a multi-line widget was created it would be advisble to avoid changing the type to a pie or gauge widget.  This can introduce errors in the updating process.  If a different widget type is needed, cancel out of the edit widget wizard and create a new widget with the desired widget type.  ',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'widget', 'widgets', 'edit', 'update', 'modify', 'correct', 'revise', 'amend']
    },
    {
        title: 'How to Refresh a Widget...',
        summary: 'Widgets can be refreshed as a group or individually.\n',
        content: '# Portal 4.0 - Refresh Widgets\n' +
            '\n' +
            'Widgets can be refreshed as a group or individually.  \n' +
            '\n' +
            '## Refresh a Widget\n' +
            '1.\tTo refresh a group of widgets, the Apply button can be pressed from the Dashboard Widget Global Parameters section, at the top of the Dashboard Detail page.\n' +
            '2.\tAlternatively, a single widget can be updated by clicking on the actions button of the widget (upper right hand corner Actions Icon) and selecting Refresh from the dropdown.  \n' +
            '\n' +
            '## Tips\n' +
            '- In order to improve responsiveness, widgets are scheduled to load sequentially.  Depending on the length of the time interval, widgets may take longer to load than shorter time intervals.  Typcially time spans longer than 30 days will cause the widgets to take longer to load. ',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'widgets', 'widget', 'refresh', 'reload']
    },
    {
        title: 'How to Move and Re-size Widgets...',
        summary: 'Widgets can be moved around and re-sized depending on preference of the user.  \n' +
            'Certain widgets have minimum/maximum height restrictions.  You can tell you\'ve hit the max./min. when the widget snaps back to it\'s previous state.',
        content: '# Portal 4.0 - Move and Size Widgets\n' +
            '\n' +
            'Widgets can be moved around and re-sized depending on preference of the user.  \n' +
            'Certain widgets have minimum/maximum height restrictions.  You can tell you\'ve hit the max./min. when the widget snaps back to it\'s previous state.\n' +
            '\n' +
            '## Move and Size a Widget\n' +
            '1.\tTo move widgets around, on the Dashboard Detail page, first unlock the grid the widgets are on.  To do so, click on the little padlock located at the top right of the corner of the widget grid.\n' +
            '2.\tTo easily adjust the location and size of the widget, grab the bottom most widget and pull it down towards the bottom of the page.  You should start to see a grey space form.\n' +
            '3.\tOnce there is an area of grey space, widgets can be moved around to a new location.\n' +
            '4.\tAdditionaly, widgets can be made smaller or bigger in both height and width.  This is highly dependent upon screen/page size.  Most widgets will come in at a default size that is appropriate for the amount/type of data being displayed. \n' +
            '5.\tWhen widgets are sized and positioned appropriately, re-click the padlock on the upper right hand corner of the widget grid, this will save the position of the widgets.  Failure to do so will result in the widgets reverting to their previous state on reload.\n' +
            '\n' +
            '## Tips\n' +
            '- If a widget is loaded and data appears to be hidden or cut off, try re-sizing the widget to be taller or wider.  This may correct the default state of the widget if it\'s showing cut off data or visuals.  ',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'widgets', 'widget', 'move', 'resize', 'size', 're-size', 'relocate', 'height', 'width']
    },
    {
        title: 'How to Delete a Widget...',
        summary: 'A single widget can be deleted if it\'s no longer needed.  If all widgets needed to be deleted, it\'s best to delete the entire Dashboard and start over.',
        content: '# Portal 4.0 - Delete Widgets\n' +
            '\n' +
            'A single widget can be deleted if it\'s no longer needed.  If all widgets needed to be deleted, it\'s best to delete the entire Dashboard and start over.\n' +
            '\n' +
            '## Delete a Widget\n' +
            '1.\tTo Delete a widget, on the Dashboard Detail page, click on the Action Icon of the desired widget to delete.\n' +
            '2.\tA drop down menu should display with delete as an option. Click on the delete option.\n' +
            '3.\tA confirmation window will open up, click Yes to delete the widget.\n' +
            '\n' +
            '## Tips\n' +
            '- Only one widget can be deleted at a time. ',
        createdOn: '2021-10-00T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'dashboards',
        tags: ['dashboards', 'dashboard', 'widgets', 'widget', 'delete', 'remove', 'kill', 'dispose']
    },
    {
        title: 'Events Section Overview...',
        summary: 'The Events section allows analysts, researchers, and customers to search logs. This is the primary way of finding specific data that has been sent. The Events section consists of a single tab named “Search” that contains these features. Searches are limited to 500 results maximum. If you need more than 500 results, please see the “Reporting” Section for how to generate a report to retrieve your data.',
        content: '# Portal 4.0 - Events Section Overview\n' +
            '\n' +
            'The Events section allows analysts, researchers, and customers to search logs. This is the primary way of finding specific data that has been sent. The Events section consists of a single tab named “Search” that contains these features. Searches are limited to 500 results maximum. If you need more than 500 results, please see the “Reporting” Section for how to generate a report to retrieve your data.\n' +
            '\n' +
            '## **Common Analysis Tasks**\n' +
            '### **Search Events**\n' +
            '- Click \'Events\' on the top left side navigation bar. The \'Events\' section loads to the \'Search\' tab.\n' +
            '- Click \'Date Range\' and select the appropriate time range from the . The search will only return results that fall inside this time range. For \'Custom\' date ranges, select the \'Date\' value and fill in the \'hh\' (Hour), \'mm\' (Minute), and \'ss\' (Second) values for both \'From\' and \'To\'. The start time must be before the end time.\n' +
            '- Click the \'Columns Selected\' drop down to change the default fields to display from left to right.\n' +
            '- Click the \'Query Builder\' button to build your query, and press \'Submit\'.  The query you built will appear - above the Query Builder button. \n' +
            '- Click the \'Search\' icon to the right of the line to run the query.\n' +
            '\n' +
            '### **View Search Results**\n' +
            'Each matching record (up to 500) is displayed as a row in the results table. At the bottom of the table, use the \'Results per page\' drop down to switch the number of results displayed on each page.  Use the \'|<\',\'<\', \'>\', and \'>|\' buttons to navigate backwards and forwards through pages of results. Click the \'Details\' button (default left most Eye Icon) of each standard search result to see the event details, including all fields.\n' +
            '### **Update Field Columns and Column Ordering**\n' +
            '- To add a field column, select that field from the \'Columns Selected\' drop down, top left of page. The interface updates as you select each field.\n' +
            '- To remove a field as a column, deselect that field from the \'Columns Selected\' drop down. The interface updates as you deselect each field.\n' +
            '- To change column order, click and drag the header name of the column to it\'s new position.  Note, currently moved columns are a temporary state and will revert back to the default if this page is navigated away from.  Permanent placement will be a feature in an upcoming release.\n' +
            '\n' +
            '### **Update the Search Time Range**\n' +
            '- Click \'Date Range\' and select the appropriate time range from the drop down. The search will only return results that fall inside this time range.\n' +
            '- For \'Custom\' date ranges, select the \'Date\' value and fill in the \'hh\' (Hour), \'mm*\' (Minute), and \'ss\' (Second) values for both \'From\' and \'To\'. The start time must be before the end time.\n' +
            '\n' +
            '### **Performing a Search**\n' +
            '- Click the \'Query Builder\' Button.  A popup wizard will appear allowing you to construct a query.  A preview of the query will appear at the bottom of the popup in real time.  \n' +
            '- Press Submit to save your query and close the popup.  To run the query, which should be displayed above the query builder button, press the \'Search\' button.  \n' +
            '\n' +
            '### **Search Syntax**\n' +
            'Search Operators:  Common logical operators are supported.\n' +
            '\n' +
            '|Operator|Description|\n' +
            '|:--------|:-----------|\n' +
            '|AND|Logically ANDs two statements|\n' +
            '|OR|Logically ORs two statements|\n' +
            '|NOT|Negates the previous statement|\n' +
            '|like|Search for a specific string inside of a statement|\n' +
            '\n' +
            '### **Common comparison operators are supported.**\n' +
            '\n' +
            '|Operator|Description|\n' +
            '|:--------|:-----------|\n' +
            '|=|\tChecks whether a field is equal to a given value|\n' +
            '|!=|\tChecks whether a field is not equal to a given value|\n' +
            '|<|\tChecks whether a field is less than a given value|\n' +
            '|<=|\tChecks whether a field is less than or equal to a given value|\n' +
            '|>|\tChecks whether a field is greater than a given value|\n' +
            '|>=|\tChecks whether a field is greater than or equal to a given value|\n' +
            '\n' +
            '## **Search Examples**\n' +
            'Basic searches consist of a field name, a comparison operator, and a value in single quotes.\n' +
            '\n' +
            '|Example|Description|\n' +
            '|:--------|:-----------|\n' +
            '|Event Type=\'Lead\'|\tType Lead events|\n' +
            '|Event Type=\'Clue\'|\tType Clue events|\n' +
            '|Device Vendor!=\'Cisco\'|\tEvents whose vendor is not Cisco|\n' +
            '|Event Severity>=\'6\'|\tEvents whose severity is greater than or equal to 6|\n' +
            '|Category Object=\'Account\'|\tEvents that primarily involve accounts|\n' +
            '|Event Name=\'Category permitted\'|\tCategory permitted events|\n' +
            '|Source Address=\'10.0.0.1\'|\tSource address 10.0.0.1 events|\n' +
            '|Event Name like \'Scan%\'|\tEvent names starting with \'Scan\'|\n' +
            '|Event Name like \'%Scan%\'|\tEvent names containing \'Scan\'|\n' +
            '|Event Name like \'%Scan\'|\tEvent names ending with \'Scan\'|\n' +
            '\n' +
            '### Basic searches can be combined together with a logical operator.\n' +
            '\n' +
            '|Example|\tDescription|\n' +
            '|:-------|:--------------|\n' +
            '|Event Type=\'Lead\' AND Event Severity>=\'6\'|\tLead events that are 6 or greater in severity|\n' +
            '|Event Type=\'Clue\' AND Destination Address=\'10.0.0.2\'|\tClue events with a destination address of 10.0.0.2|\n' +
            '|Source Address=\'10.0.0.1\' AND Destination Port=\'80\'|\tEvents from source address 10.0.0.1 with a destination port of 80|\n' +
            '\n' +
            '## **Search Guidelines**\n' +
            '### **Date Range Drop Down**\n' +
            '- Date ranges using non-customized values (e.g. \'Last 60 Minutes\') are calculated from the current time.\n' +
            '- Date ranges can be customized to use a specific time interval by selecting \'Custom\' in the drop down, then defining the \'From\' and \'To\' values.\n' +
            '- Changing a date range value does NOT rerun the search query automatically. The search icon must be clicked to run the search over the newly selected date range.\n' +
            '\n' +
            '### **Company Drop Down**\n' +
            'Determines which company\'s data will be searched.  The drop down only display a single company to users who have access to only one company.  Changing a company value does NOT rerun the search query automatically. The search icon must be clicked to run the search over the newly selected Company.  Company values are sorted alphabetically by their friendly names. Only one company value can be selected at a time.\n' +
            '### **Columns Drop Down**\n' +
            'The columns drop down changes the currently visible columns.  Selecting or deselecting a value in the drop down automatically updates the search results to show or hide that column.\n' +
            '### **Manipulating Columns**\n' +
            'Columns can be moved left or right by clicking on the header name of the column and dragging left or right.  This state is temporary and will revert to the default ordering if the page is refreshed or navigated away from.  This will be updated in a future release to provide a savable state of column ordering.\n' +
            '### **Manipulating Search Results**\n' +
            'Searches are limited to 500 results.  Search results are based on the values of the \'Date Range\', \'Company\', and \'Search\' input when the search was submitted.  Each matching record is displayed as a row in the results table. Use the \'Results per page\' drop down to switch the number of results displayed on each page.  Use the \'Page\' drop down at the top to select a certain page number without having to cycle through each page.  Use the \'<\' and \'>\' buttons to navigate backwards and forwards through pages of results. Click the \'Details\' button to the left of each search result to see all event fields.',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'events',
        tags: ['events', 'event', 'use', 'purpose', 'interact', 'columns', 'syntax', 'operators', 'search']
    },
    {
        title: 'How to Search Incidents...',
        summary: 'To search for Incidents in Portal 4.0, first navigate to the Incidents section (formerly the Response section in SLM3.)\n' +
        '  If you have a current incident assigned to you, it will populate the space at the top of the page.  Use the search box below the active incident' +
        ' to begin the search process.',
        content: '# Portal 4.0 - How to Search Incidents\n' +
            'To search for Incidents in Portal 4.0, first navigate to the Incidents section (formerly the Response section in SLM3.)' +
            'If you have a current incident assigned to you, it will populate the space at the top of the page.  Use the search box below the active incident' +
            ' to begin the search process.\n\n' +
            '1. Created: The \'Created\' drop down, select the time range over which to search for incident.\n' +
            '2. Companies: Click the \'Companies\' drop down to change the default company fields.Multiple could be selected.\n' +
            '3. Assignee: Click the \'Assignee\' drop down to select a name.  Multiple could be selected, mostly likely, only one company will be avaiable. \n' +
            '4. Classification: Click the \'Classification\' drop down to select a category. Multiple could be selected\n' +
            '5. Queue: Click the \'Queue\' drop down to select a tier. Multiple could be selected\n' +
            '6. State: Click the \'State\' drop down to select a state. Multiple could be selected\n' +
            '7. Priority: Click the \'Priority\' drop down to select a priority. Multiple could be selected\n' +
            '8. Closure Code: Click the \'Closure Code\' drop down to select a priority. Multiple could be selected\n' +
            '9. Event Search: Click the Event Search to input a query or press \'Enter\' on your keyboard to type your query with assistance.\n' +
            '10. Search Button: Click the \'Search\' icon to begin your search.',
        createdOn: '2021-07-07T14:59:42+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'incidents',
        tags: ['incidents', 'incident', 'search', 'seek', 'find', 'locate', 'discover']
    },
    {
        title: 'Data Exfiltration...',
        summary: 'What is Data Exfiltration?  Data Exfiltration is when an unknown, probably malicious actor extracts information and data from a secured system.  Typically a threat actor will identify a candidate that has potential data that could be valuable, personal identifiable information (PII) like emails, passwords, social security numbers, credit cards, etc… Extracted information could also be sensitive information about the company, infrastructure, accounts, client lists, trade secrets, etc…',
        content: '## _What is Data Exfiltration?_\n  Data Exfiltration is when an unknown, probably malicious actor extracts information and data from a secured system.  Typically a threat actor will identify a candidate that has potential data that could be valuable, personal identifiable information (PII) like emails, passwords, social security numbers, credit cards, etc… Extracted information could also be sensitive information about the company, infrastructure, accounts, client lists, trade secrets, etc…\n'+
            'Once a threat actor has identified their target system, they begin a campaign of infiltrating the system via unsecured routes, unknown vulnerabilities, zero day bugs, or social engineering attacks.  Once in the system, the threat actor will begin to gather information and send it out of the secured environment and into their collection end points.\n'+ 
            'A Data Exfiltration hunt targets the specific act of moving data from the secured system to an outside endpoint.   In this hunt, the data is analyzed for anomalous amounts of data being moved outside of normal data traffic in the system.   For example, if a specific system typically moves 10 – 20 kilo bytes of data in an hour and then all of sudden sees a spike of 10 mega bytes of data being moved in a short burst, that would be a red flag indicator of potential malicous activity.\n'+
            '## _Our Process_\n'+
            '1. We sort all the transactions by the amount of bytes transferred\n'+
            '2. We highlight the ones dispropotionatly heavy (2 standard deviation above the mean) as potential exfiltrations.',
        createdOn: '2022-02-02T10:00:00+0000',
        images: [
            {id: 'image1', url: 'http://0.0.0.0'}
        ],
        section: 'threat',
        tags: ['threat', 'threat hunting', 'data exfiltration']
    },
];
