import { MatOption } from '@angular/material/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { State } from '@app/reducers';
import { AppService } from '@app/app.service';
import { ReportingService } from '@app/pages/reporting/services/forms/reporting.service';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';


export interface DialogData {
  companies: any[];
  dashboardId: string;
  selectedCompany: string;
  report?: any;
  tags?: string[];
}

@Component({
  selector: 'app-report-general-information',
  templateUrl: './report-general-information.component.html',
  styleUrls: ['./report-general-information.component.scss'],
})
export class ReportGeneralInformationComponent implements OnInit, OnDestroy {
  private destroyer$: Subject<any> = new Subject();
  private settingWidgetTypes: string[] = ['dropdown', 'query_builder', 'string_dropdown'];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  defaultSelectedDate = '';
  dateOptions = [];

  // list of companies the user can pick from.
  @Input() companies: any[] = [];
  @ViewChild('allSelected') private allSelected: MatOption;


  // general information form
  form: FormGroup;
  inputVariableForm: FormGroup;
  showInputVariableForm = false;
  inputVariables: any[];

  // output signal for correctness of the form
  @Output() formValid: BehaviorSubject<any> = new BehaviorSubject(null);
  @Input() report: any = null;

  constructor(
      private fb: FormBuilder,
      private appService: AppService<any>,
      public reportingFormService: ReportingService,
      private inputVariableService: InputVariableService,
      private store: Store<State>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit(): void {
    // generate the form for the step.
    this.form = this.fb.group({
      customer_id: [this.data.selectedCompany, [Validators.required]],
      title: [this.data?.report?.title ?? null, [Validators.required]],
      description: [this.data?.report?.description ?? '', []],
      related_dashboard_id: [this.data.dashboardId, [Validators.required]],
      inputVariables: this.fb.group({}),
      data_time_frame: this.fb.group({
        type: [null, [Validators.required]],
        date_from: [this.data?.report?.params?.data_time_frame?.date_from ?? null, [Validators.required]],
        date_to: [this.data?.report?.params?.data_time_frame?.date_to ?? null, [Validators.required]]
      })
    });
    this.setupPage();
  }


  private setupPage(): void {
    this.fetchDashboardWidgets();
    this.form.valueChanges.subscribe(value => {
      this.formValid.next({ valid: this.form.valid && this.inputVariableForm.valid, data: { ...value, inputVariables: this.inputVariableForm.value } });
    });

    this.store
        .pipe(
            map(st => st.eventParams.eventParams?.dashboardDateRanges)
        )
        .subscribe(options => {
          this.dateOptions = [...options];
        });
  }

  private fetchDashboardWidgets(): void {
    this.appService.get('dashboards', `${this.data.dashboardId}`, null)
        .then((dashboard: any) => {
          if (!dashboard.widgets) { return; }
          this.inputVariables = dashboard.widgets.reduce((prev, next) => {
            if (this.settingWidgetTypes.includes(next.type)) {

              this.inputVariableService.inputVariables.subscribe((val) => {
                for (const [key, value] of Object.entries(val)) {
                  if (next.params.inputName === key) {
                    prev.push({
                      input_variable_name: next.params.inputName,
                      isMultiSelect: next.params.isMultiSelect === 'true' || next.params.isMultiSelect === true ? true : next.params.isMultiSelect,
                      type: next.type,
                      settingWidgetValue: value,
                      options: JSON.parse(localStorage.getItem(next.params.inputName) || '[]')
                    });
                  }
                }
              });
            }
            return prev;
          }, []);
          this.addOrReplaceInputVariables(this.inputVariables);
        })
        .catch((error: any) => {
          console.log('Failed to get dashboard due to ', error);
        });
  }

  private addOrReplaceInputVariables(inputVariableDetails: any[]): void {
    this.formValid.next({ valid: false, data: null });
    this.inputVariableForm = this.fb.group({});
    if (inputVariableDetails?.length > 0) {
      for (let i = 0; i < inputVariableDetails.length; i++) {
        let defaultValue = inputVariableDetails[i].settingWidgetValue;
        if (inputVariableDetails[i].isMultiSelect) {
          defaultValue = typeof defaultValue !== 'object' ? defaultValue === '_Select_All' ? inputVariableDetails[i].options.map(option => option.value) : [defaultValue] : defaultValue;
        }
        this.inputVariableForm.addControl(inputVariableDetails[i].input_variable_name, new FormControl(defaultValue, inputVariableDetails[i].options.length > 0 ? [Validators.required] : []));
      }
      setTimeout(() => {
        this.showInputVariableForm = true;
      }, 500);
      this.inputVariableForm.valueChanges.subscribe(inputVariables => {
        // this.form.patchValue({inputVariables});
        this.formValid.next({
          valid: this.form.valid && this.inputVariableForm.valid,
          data: { ...this.form.value, inputVariables }
        });
      });

    } else {
      const data = {
        valid: true,
        data: this.form.getRawValue(),
      };
      this.formValid.next(data);
    }
  }

  remove(form, index, name): void {
    form.get(name).removeAt(index);
  }

  inputValueAdd(event, form: FormGroup, name: string) {
    const value = event.target.value;

    if ((value || '').trim()) {
      const control = form.get(name) as FormArray;
      control.push(this.fb.control(value.trim()));
    }
  }

  addValue(event: MatChipInputEvent, form: FormGroup, name: string): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const control = form.get(name) as FormArray;
      control.push(this.fb.control(value.trim()));
    }

    if (input) {
      input.value = '';
    }
  }

  // toggle all the option
  toggleAllSelection(controlName: string, fieldOptions: any[]) {
    // const allOptions = fieldOptions.map(option => option.value); = fieldOptions.map(option => option.value);
    const allOptions = fieldOptions;
    if (this.allSelected.selected) {
      this.form.controls[controlName].patchValue([...allOptions.map(item => item.value), 0]);
    } else {
      this.form.controls[controlName].patchValue([]);
    }
  }

  // when select all is select and user select one untoggle all
  toggleOne(controlName, all, fieldOptions) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.form.controls[controlName].value.length === fieldOptions.length) {
      this.allSelected.select();
    }
  }

  handleDateframeChanged(event): void {
    this.form.patchValue({ data_time_frame: { type: event.data.type, date_from: event.data.start, date_to: event.valid ? event.data.end : null } });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
