<mat-tab-group mat-align-tabs="center">

  <mat-tab label="Personal Information">
    <app-portal-loader [loadingStatus]="loading"></app-portal-loader>


    <div class="layout-padding">
      <mat-card-actions fxLayout="row" fxLayoutAlign="end start" *ifUserCan="['profile.edit']">

        <button mat-stroked-button (click)="generateApiKey()">
          <mat-icon>vpn_key</mat-icon>
          Generate API Key
        </button>

        <button mat-stroked-button *ngIf="!isEditing" (click)="editForm()">
          <mat-icon>edit</mat-icon>
          Edit Profile
        </button>
        <button mat-stroked-button *ngIf="!isEditing" (click)="changePassword()">
          <mat-icon>lock</mat-icon>
          Change Password
        </button>
        <button mat-button color="warn" *ngIf="isEditing" (click)="cancel()">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
        <button mat-raised-button color="primary" *ngIf="isEditing" (click)="saveForm()" [disabled]="!userForm.dirty">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </mat-card-actions>

      <mat-card [@fadeIn]="editMode" *ifUserCan="['profile.view']">
        <form [formGroup]='userForm' *ngIf="userForm">
          <mat-card-header fxLayout="row">
            <div mat-card-avatar>
              <app-user-display [profile]="userProfile"></app-user-display>
            </div>
            <div>
              <mat-card-title class="profile_user-name">{{userInfo.Attributes.given_name}}
                {{userInfo.Attributes.family_name}}</mat-card-title>
              <mat-card-subtitle>{{userInfo.Attributes.sub}}</mat-card-subtitle>
            </div>
          </mat-card-header>
          <mat-card-content>
            <app-profile-form *ngIf="editMode" [profile]="userProfile" (actionHandler)="eventHandler($event)">
            </app-profile-form>


            <mat-list *ngIf="!editMode">
              <mat-divider></mat-divider>
              <h3 mat-subheader>Email</h3>
              <mat-list-item>
                <mat-form-field fxFlexFill appearance="none">
                  <input required formControlName="email" matInput>
                </mat-form-field>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader>Email Verified</h3>
              <mat-list-item>
                <div class="email-info" *ngIf="!userProfile.email_verified">
                  <mat-icon color="warn">thumb_down</mat-icon>
                  <button mat-raised-button (click)="verifyEmail()">Verify Email</button>
                </div>
                <mat-icon color="primary" *ngIf="userProfile.email_verified">thumb_up</mat-icon>
              </mat-list-item>
              <mat-divider></mat-divider>

              <h3 mat-subheader>Role</h3>
              <mat-list-item>
                <mat-form-field fxFlexFill appearance="none">
                  <input required formControlName="gender" matInput>
                </mat-form-field>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader>First Name</h3>
              <mat-list-item>
                <mat-form-field fxFlexFill appearance="none">
                  <input required formControlName="given_name" matInput>
                </mat-form-field>
              </mat-list-item>
              <mat-divider></mat-divider>

              <h3 mat-subheader>Last Name</h3>
              <mat-list-item>

                <mat-form-field fxFlexFill appearance="none">
                  <input required formControlName="family_name" matInput>
                </mat-form-field>
              </mat-list-item>
              <mat-divider></mat-divider>

            </mat-list>
          </mat-card-content>
          <mat-card-actions *ngIf="!editMode" fxLayout="row" fxLayoutAlign="end end">
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
  </mat-tab>


  <mat-tab label="Your Permissions">
    <mat-card>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let permission of defaultPermissions">
          <mat-expansion-panel-header>
            <mat-panel-title fxFlex="20">
              {{permission.friendly}}
            </mat-panel-title>
            <mat-panel-description fxFlex="80">
              {{permission.description}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="permission" fxLayout="column" fxFlex fxLayoutAlign="start start">
            <mat-checkbox fxFlex="100" *ngFor="let action of permission.actions"
              [checked]="userHasPermission(permission.friendly, action.name)" [disabled]="true">{{action.friendly}}
            </mat-checkbox>

          </div>

        </mat-expansion-panel>

      </mat-accordion>
    </mat-card>


  </mat-tab>

  <mat-tab label="Your Company">
    <div *ngIf="currentUsersCompany" class="layout-padding">
      <div fxLayout.gt-sm="row" fxLayout="column">
        <mat-card fxFlex="50">
          <mat-card-title>{{currentUsersCompany.name}}</mat-card-title>
          <mat-card-content fxLayout="row">
            <div></div>
            <mat-list>
              <mat-divider></mat-divider>
              <h3 mat-subheader>Company ID</h3>
              <mat-list-item>
                <p>{{currentUsersCompany.id}}</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader>Attributes</h3>
              <mat-list-item>
                <p *ngIf="currentUsersCompany.attributes.ACCESS_ATTRIBUTES.length > 0; else notAvailable">
                  {{currentUsersCompany.attributes.ACCESS_ATTRIBUTES}}</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader>Parent</h3>
              <mat-list-item>
                <p>{{currentUsersCompany.parent}}</p>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>AWS Account Numbers:
                <p *ngIf="currentUsersCompany.attributes.AWS_ACCOUNTS; else notAvailable">
                  {{currentUsersCompany.attributes.AWS_ACCOUNTS}}</p>
              </mat-list-item>
              <mat-list-item>GB/Day:
                <p *ngIf="currentUsersCompany.attributes.GB_Per_Day; else notAvailable">
                  {{currentUsersCompany.attributes.GB_Per_Day}}</p>
              </mat-list-item>
              <mat-list-item>Storage Period:
                <p *ngIf="currentUsersCompany.attributes.Raw_Log_Period; else notAvailable">
                  {{currentUsersCompany.attributes.Raw_Log_Period}}</p>
              </mat-list-item>
              <mat-list-item>SDWAN Versa Tenant Name:
                <p *ngIf="currentUsersCompany.attributes.SDWAN_Versa_Tenant_Name; else notAvailable">
                  {{currentUsersCompany.attributes.SDWAN_Versa_Tenant_Name}}</p>
              </mat-list-item>
              <mat-list-item>Level 3 Billing Number (BAN):
                <p *ngIf="currentUsersCompany.attributes.LEVEL3_BAN; else notAvailable">
                  {{currentUsersCompany.attributes.LEVEL3_BAN}}</p>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
        <mat-card fxFlex="50">
          <mat-card-title>Products for {{currentUsersCompany.name}}</mat-card-title>
          <mat-card-content>
            <mat-chip-list disabled *ngIf="currentUsersCompany.products.length > 0; else notAvailable">
              <mat-chip *ngFor="let product of currentUsersCompany.products">{{product}}</mat-chip>
            </mat-chip-list>
          </mat-card-content>
        </mat-card>
      </div>


      <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between">
        <mat-card fxFlex>
          <mat-card-title>Source IPV4 CIDRS {{currentUsersCompany.name}}</mat-card-title>
          <mat-card-content>
            <mat-chip-list *ngIf="currentUsersCompany.sourceIPV4CIDRs.length > 0; else notAvailable" disabled>
              <mat-chip *ngFor="let cidr of currentUsersCompany.sourceIPV4CIDRs">{{cidr}}</mat-chip>
            </mat-chip-list>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex>
          <mat-card-title>Source IPs {{currentUsersCompany.name}}</mat-card-title>
          <mat-card-content>
            <mat-chip-list *ngIf="currentUsersCompany.sourceIPs.length > 0; else notAvailable" disabled>
              <mat-chip *ngFor="let ip of currentUsersCompany.sourceIPs">{{ip}}</mat-chip>
            </mat-chip-list>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex>
          <mat-card-title>Source Hosts {{currentUsersCompany.name}}</mat-card-title>
          <mat-card-content>
            <mat-chip-list *ngIf="currentUsersCompany.sourceHosts.length > 0; else notAvailable" disabled>
              <mat-chip *ngFor="let host of currentUsersCompany.sourceHosts">{{host}}</mat-chip>
            </mat-chip-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <ng-template #notAvailable>
      <p class="not-available"> Not Available</p>
    </ng-template>
  </mat-tab>


</mat-tab-group>

<mat-card *ngFor="let key of apiKeys" style="margin: 15px 0" class="layout-padding">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="start center">
      <mat-card-subtitle>API Key</mat-card-subtitle>
      <button mat-icon-button matTooltip="Delete API Key">
        <span class="material-icons gap-middle" (click)="delete(key)">remove_circle</span>
      </button>
    </div>
    <button mat-icon-button matTooltip="Copy API Key" class="material-icons" ngxClipboard
      (cbOnSuccess)="clipboardCopy($event)" color="apiKeyCopied" [cbContent]="key.apikey">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <span class="layout-padding">Expires: {{key.expiresAt | userDate}}</span>

  <div style="width: 100%; overflow-wrap: break-word;
  height: 70px; overflow-y: auto;">{{key.apikey}}</div>
  <br>
  <span>Routes Allowed by Key</span>
  <div *ngFor="let route of key.routes_allowed">{{route}}</div>

</mat-card>
