import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { AppService } from 'src/app/app.service';
import { State } from 'src/app/reducers';
import { ReportingService } from '../../services/forms/reporting.service';
import { createReportSuccess, updateReportSuccess } from '../../store/report.actions';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.scss']
})

export class CreateReportComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  reportDetails: any = {};
  isNew = true;
  maxStep = 3;
  error: string = null;
  currentStep = 0;
  stepValid = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  stepControllers: FormGroup[] = [];
  private relatedDashboardId: string = null;

  reportData: any = {};
  private reportDataValid = true;

  // report data for each steps in case of an edit
  generalInformation = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: AppService<any[]>,
    private store: Store<State>,
    private dialogRef: MatDialogRef<CreateReportComponent>,
    private reportService: ReportingService

  ) {
    for (let i = 0; i < this.maxStep; i++) {
      const c = new FormGroup({});
      c.setErrors({ invalid: true });
      this.stepControllers.push(c);
    }

    this.reportService.defaultRelatedDashBoard$.subscribe(v => this.relatedDashboardId = v);
  }

  ngOnInit(): void {
    this.reportData = {};
    const { report } = this.data;
    if (report) {
      this.isNew = false;
      this.reportDetails = this.data.report;
      this.stepControllers.forEach(step => step.setErrors(null));

      // set the step data
      this.generalInformation = {
        title: report.title,
        description: report.description,
        selectedCompany: report.params.general.company,
        general: report.params.general
      };
    }

  }

  cancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.reportDetails.is_active) {
      delete this.reportDetails?.params['scheduling'];
    }

    this.loading$.next(true);
    if (this.isNew) {
      this.reportDetails.related_dashboard_id = this.relatedDashboardId;
      this.httpService.post('reports', '', null, this.reportDetails)
        .then((report: any) => this.store.dispatch(createReportSuccess({ report })))
        .catch((error: any) => this.error = error.message).finally(() => {
          this.loading$.next(false);
          this.dialogRef.close();
        }
        ).finally(() => this.reportService.unsetRelatedDashboardId());
    } else {
      this.httpService.put('reports', this.reportDetails.report_id, null, this.reportDetails)
        .then((report: any) => this.store.dispatch(updateReportSuccess({ report })))
        .catch((error: any) => this.error = error.message).finally(() => {
          this.loading$.next(false);
          this.dialogRef.close();
        });
    }
  }

  goToStep(evt: any): void {
    this.currentStep = evt.selectedIndex;
    this.stepValid = this.stepControllers[evt.selectedIndex].valid;
  }

  generalStepHandler(evt): void {
    this.stepValid = evt && evt.hasOwnProperty('valid') ? evt.valid : false;

    if (!this.stepValid) {
      this.stepControllers[0].setErrors({ invalid: true, valid: false });
      return;
    }

    this.stepControllers[0].setErrors(null);
    const reportParams = this.reportDetails?.params ?? {};

    this.reportDetails = {
      ...this.reportDetails,
      title: evt.data.title,
      related_dashboard_id: evt.data.related_dashboard_id,
      description: evt.data.description,
      params: {
        ...reportParams,
        general: {
          company: evt.data.customer_id,
          input_variables: evt.data.inputVariables || {},
          data_time_frame: evt.data.data_time_frame
        }
      }
    };
  }

  schedulerStepHandler(evt): void {
    // check if there is a scheduling data
    let scheduling = this.reportDetails.params?.scheduling ?? {};
    this.reportDetails.is_active = evt.data.scheduling.is_active;

    const sData = evt?.data?.scheduling;

    const sRate = !sData?.schedule_rate.run_forever ? { ...sData.schedule_rate } : { run_forever: sData.schedule_rate.run_forever };

    scheduling = {
      ...scheduling,
      frequency: evt.data.scheduling.frequency,
      type: 'preset',
      schedule_rate: {
        ...sRate
      }
    }

    this.reportDataValid = evt.valid;
    if (evt.valid) {
      this.reportDetails = {
        ...this.reportDetails,
        params: {
          ...this.reportDetails.params,
          scheduling
        }
      };
    }

    this.stepValid = evt && evt.hasOwnProperty('valid') ? evt.valid : false;
    if (!this.stepValid) {
      this.stepControllers[1].setErrors({ invalid: true });
      return;
    }

    this.stepControllers[1].setErrors(null);
  }

  notificationStepHandler(evt: { valid: boolean, data: any }): void {
    this.reportDataValid = evt.valid;
    if (evt.valid) {
      this.reportDetails = {
        ...this.reportDetails,
        params: {
          ...this.reportDetails.params,
          ...evt.data
        }
      };

    }
    this.stepValid = evt && evt.hasOwnProperty('valid') ? evt.valid : false;
    if (!this.stepValid) {
      this.stepControllers[2].setErrors({ invalid: true });
      return;
    }

    this.stepControllers[2].setErrors(null);

  }

  next(): void {
    if (this.currentStep < this.maxStep) {
      this.myStepper.next();
    }
  }

  previous(): void {
    if (this.currentStep > 0) {
      this.myStepper.previous();
    }
  }
}
