import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as moment from 'moment';


export interface SelectOption {
  value: string;
  friendly: string;
};

@Component({
  selector: 'app-custom-date-range-picker',
  templateUrl: './custom-date-range-picker.component.html',
  styleUrls: ['./custom-date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDateRangePickerComponent),
      multi: true
    }
  ]
})
export class CustomDateRangePickerComponent implements ControlValueAccessor, OnInit {
  @Input() dateRangeOptions: SelectOption[] = [];
  @Input() useMaya: boolean = true;
  private mayaDatetime = ['relative'];

  onChanged: any = () => {};
  onTouched: any = () => {};
  showMayaDatePicker: boolean = false;
  _value: any = null;

  form: FormGroup = new FormGroup({
    dateRange: new FormControl('', [Validators.required]),
    date: new FormControl('', [])
  });

  set value(val) {
    this._value = val;
    this.onChanged(val);
    this.onTouched();
  }

  get value() {
    return this._value;
  }

  constructor() {}

  ngOnInit(): void {
    if (this.useMaya) {
      this.dateRangeOptions.push({friendly: 'Relative', value: 'relative'});
    }

    this.form.get('dateRange').valueChanges.subscribe(val => this.processDateRangeChange(val));
    this.form.get('date').valueChanges.subscribe(val => this.processMayaDate(val));

    this.form.valueChanges.subscribe(val => this.value = val);
  }

  registerOnChange(fn) {
    this.onChanged = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value): void {
    if (value) {
      this.value = value;
    }
  }

  private processDateRangeChange(val): void {
    const today = new Date();
    switch(val) {
      case 'last 12 hours':
        const date = {
          start: moment().subtract(12, 'hours').toDate(),
          end: today
        };
        this.form.patchValue({date})
        break;
      case 'last 24 hours':
        this.form.patchValue({
          date: {
            start: moment().subtract(24, 'hours').toDate(),
            end: today
          }
        })
        break;
      case 'last 7 days':
        this.form.patchValue({
          date: {
            start: moment().subtract(7, 'days').startOf('day').toDate(),
            end: today
          }
        });
        break;
      case 'last 30 days':
        this.form.patchValue({
          date: {
            start: moment().subtract(30, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
          }
        });
        break;
      case 'last 1 year':
        this.form.patchValue({
          date: {
            start: moment().subtract(1, 'years').startOf('year').toDate(),
            end: moment().subtract(1, 'years').endOf('year').toDate()
          }
        });
        break;
      case 'current month':
        this.form.patchValue({
          date: {
            start: moment().startOf('month').toDate(),
            end: moment().endOf('month').toDate()
          }
        });
        break;
      case 'current year':
        this.form.patchValue({
          date: {
            start: moment().startOf('year').toDate(),
            end: moment().endOf('year').toDate()
          }
        });
        break;
      case 'current day':
        this.form.patchValue({
          date: {
            start: moment().startOf('day').toDate(),
            end: moment().endOf('day').toDate()
          }
        });
        break;
      case 'current week':
        this.form.patchValue({
          date: {
            start: moment().startOf('week').toDate(),
            end: moment().endOf('week').toDate()
          }
        });
        break;
      case 'relative':
        this.showMayaDatePicker = this.mayaDatetime.includes(val);
        break;
      default:
        return;
    }
  }

  private processMayaDate(val): void {
    this.value = {
      ...this.value,
      date: val
    }
  }
}
