import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import * as eventActions from '../../store/event.actions';
import * as eventParamsAction from '../../../../shared-stores/event-params/event-params.actions';
import * as incidentActions from '../../../incidents/store/incident.actions';
import * as companyActions from '../../../admin/store/company.actions';
import * as userPreferenceActions from '../../../user-preference/store/user-preference.actions';
import * as eventParamsActions from '../../../../shared-stores/event-params/event-params.actions';
import { FieldInterface, FormBuilderComponent } from '../../../../sweet-shared/components/form-builder/form-builder.component';
import { MatDialog } from '@angular/material/dialog';
import { IncidentsService } from '../../../incidents/services/incidents.service';
import { Validators } from '@angular/forms';
import { TableHeader } from '../../../../sweet-shared/components/table/table.component';
import { UrlService } from '../../../../shared-services/url.service';


@Component({
  selector: 'app-analysis-details',
  templateUrl: './event-detail-page.component.html',
  styleUrls: ['./event-detail-page.component.scss']
})

export class EventDetailPageComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  event: any;
  fields: any[];
  loading = false;
  previousUrl: string;
  currentUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<State>,
    public matDialog: MatDialog,
    private incidentsService: IncidentsService,
    private urlService: UrlService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getEvent();
    this.currentUrl = this.urlService.setCurrentUrl(this.router.url);
    this.previousUrl = this.urlService.getPreviousUrl();
  }

  goBack(): void {
    this.router.navigateByUrl(this.urlService.getPreviousUrl());
  }

  private getEvent() {
    // attached to the router params
    this.activatedRoute.queryParamMap.subscribe((qsp: any) => {
      this.store.dispatch(eventActions.fetchEvent({
        searchId: qsp.params.id, eventId: qsp.params.eventId, companyId: qsp.params.companyFilter, isoDate: qsp.params.isoDate
      }));
      this.store.dispatch(eventParamsAction.loadEventFields());
    });

    // subscribing to it.
    this.store.select(state => state.events.selectedEvent).pipe(
      filter(evt => !!evt),
      takeUntil(this.destroyed$)
    ).subscribe(event => this.event = event);

    // Subscribe to the loader
    this.store.select(state => state.events.loading).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(loading => this.loading = loading);

    // subscribe to the fields
    this.store.select(state => state.eventParams.eventParams).pipe(
      filter(evtParams => !!evtParams && !!evtParams.fields),
      takeUntil(this.destroyed$)
    ).subscribe(val => {
      this.fields = val.fields.map(field => {
        return {
          ...field,
          name: field.name.toLowerCase()
        };
      });
    });
  }

  onCreateIncident() {
    this.store.dispatch(incidentActions.loadIncidentAttributes());
    this.store.dispatch(companyActions.loadCompanies());
    const companies$ = this.store.select(state => state.companies.companies).pipe(
      take(1),
    );
    const incidentAttributes$ = this.store.select(state => state.incidents.incidentAttributes).pipe(
      filter(ia => !!ia && !ia.incidentAttributesLoading && Object.entries(ia).length > 0),
      take(1)
    );
    combineLatest(companies$, incidentAttributes$).pipe(
      take(1)
    ).subscribe(both => {
      const [companies, priorities] = both;
      // Shape company and priorities for form
      const companyOptions = companies.map(company => {
        return { value: company.id, friendly: company.name };
      });
      const priorityOptions = priorities.priority.map(attr => {
        return {
          value: attr,
          friendly: attr
        };
      });
      // Get shared create incident config from service
      const formConfig = this.incidentsService.getCreateIncidentForm(companyOptions, priorityOptions);
      const dialogRef = this.matDialog.open(FormBuilderComponent, {
        disableClose: true
      });
      // Find index of company field in formConfig
      const companyFieldIndex = formConfig.incidentFormDetails.findIndex(field => {
        return field.name === 'customerId';
      });
      // Limit company options to  only company of event
      const newCompanyField: FieldInterface = {
        label: 'Company',
        component: 'input-select',
        name: 'customerId',
        placeholder: 'Company',
        flex: '100%',
        selectMultiple: false,
        defaultValue: this.event.CUSTOMERID,
        validators: [Validators.required],
        options: [companyOptions.find(company => {
          return company.value === this.event.CUSTOMERID;
        })],
        disabled: true
      };
      // Replace field from service with new field
      formConfig.incidentFormDetails.splice(companyFieldIndex, 1, newCompanyField);

      dialogRef.componentInstance.actionButtons = formConfig.formButtons;
      dialogRef.componentInstance.fieldDetails = formConfig.incidentFormDetails;
      dialogRef.componentInstance.title = formConfig.title;
      dialogRef.componentInstance.loading = false;
      dialogRef.componentInstance.actionEvents.subscribe(event => {
        switch (event.name) {
          case 'CANCEL':
            dialogRef.close();
            break;
          case 'CREATE_INCIDENT':
            this.createIncident(event.data, dialogRef);
            break;
          default:
            break;
        }
      });
    });
  }

  private createIncident(data: any, dialogRef: any) {
    // Need user preferences and event fields to find event values for markdown table
    this.store.dispatch(userPreferenceActions.loadUserPreferences());
    this.store.dispatch(eventParamsActions.loadEventFields());
    const userPreferences$ = this.store.select(state => state.userPreferences.userPreferences)
      .pipe(filter(val => !!val), take(1));
    const eventParams$ = this.store.select(state => state.eventParams.eventParams)
      .pipe(filter(val => !!val), take(1));
    // When both observables are ready..
    combineLatest(userPreferences$, eventParams$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(prefsAndParams => {
        const [userPreferences, eventParams] = prefsAndParams;
        const eventsHeaders: TableHeader[] = eventParams.fields.filter(evtField => userPreferences.searchFields.includes(evtField.name));
        const tableMarkdown = this.incidentsService.makeMdTableFromRow(this.event, eventsHeaders);
        data.message += tableMarkdown;
        data.customerId = this.event.CUSTOMERID;
        this.incidentsService.createIncident(dialogRef, data, userPreferences);
      });
  }

  ngOnDestroy() {
    this.store.dispatch(eventActions.clearEvent());
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // Used to compare eventParams FIELDS list to event RAW name to find friendly name for view
  getFriendly(rawName) {
    return this.fields.find((field) => field.name === rawName
    ).friendly;
  }

  // Checks if event value is timestamp, convert to short date format
  getValueType(key, value) {
    if (key === 'ISODATE') {
      return new Date(value);
    } else {
      return value;
    }

  }

}
