<div fxFlex fxLayout='column'>
  <mat-card>
    <mat-card-header class="widget-card-header">
      <mat-card-title-group>
        <mat-card-title>{{widget.title}} <span *ngIf="isNotResults()" class="no-data-message">
            No results found
            <mat-icon [inline]="true" matTooltip="Is your query valid? Current Query:   {{widget.params.query}}"
              [matTooltipPosition]="'above'">help</mat-icon>
          </span>
        </mat-card-title>
        <mat-card-subtitle>{{widget.description}}</mat-card-subtitle>
      </mat-card-title-group>
      <div class='header' fxLayout='row' fxLayoutAlign='end start' fxFlex>
        <button *ifUserCan="['widget.refresh', 'widget.edit', 'widget.deletes']; any: true" mat-icon-button
          [matMenuTriggerFor]="menu" aria-label="widget_menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)='openWidgetInfoDialog()'
            *ngIf="this.widget.input_variables && this.widget.input_variables.length >0">
            <mat-icon>info</mat-icon>
            <span>Info</span>
          </button>
          <button *ifUserCan="'widget.refresh'" mat-menu-item (click)='refresh()'>
            <mat-icon>refresh</mat-icon>
            <span>Refresh</span>
          </button>
          <button *ifUserCan="'widget.edit'" mat-menu-item (click)="editWidget()">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button *ifUserCan="'widget.delete'" mat-menu-item (click)='handleDelete()'>
            <mat-icon>delete_forever</mat-icon>
            <span>Delete</span>
          </button>
          <button *ngIf="widget.type === 'bar' && needsBreakAxis()" mat-menu-item (click)="convertToBreak()">
            <mat-icon>close_fullscreen</mat-icon>
            <span>Show with break axis</span>
          </button>
          <button *ngIf="isFullScreenOption()" mat-menu-item (click)="showAsFullScreen()">
            <mat-icon>fullscreen</mat-icon>
            <span>Full Screen</span>
          </button>
        </mat-menu>
      </div>
    </mat-card-header>
    <mat-card-content *ifUserCan="'widget.read'" [ngStyle]="{'height.px': height - 53 }">
      <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%" *ngIf="loading">
        <div class="indicator-container">
          <ngx-loading *ngIf="loading && !error" [show]="true"></ngx-loading>
        </div>
      </div>
      <div *ngIf='error && !loading'>
        <div fxLayout='column' fxFlex fxLayoutAlign='center center' class='error'>{{error}}</div>
      </div>
      <div [id]='widget.widget_id' [ngStyle]="{'height.px': height - 53, display: error || loading ? 'none' : 'flex' }">
      </div>

    </mat-card-content>
  </mat-card>
</div>
