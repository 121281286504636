import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../store/user/user.model';
import {AppService} from '../../../../app.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {SnackbarService} from '../../../../shared-services/snackbar.service';


@Component({
  selector: 'app-account-view',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {
  userForm: FormGroup;
  roles: any;
  @Input() loading;
  permissions: any;
  @Input() userData: User;

  @Output() editEvent: EventEmitter<any> = new EventEmitter();


  constructor(private fb: FormBuilder,
              private snackbarService: SnackbarService,
              private accountEditService: AppService<any>
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      sub: [{value: this.userData.sub, disabled: true}, Validators.required],
      username: [{value: this.userData.Username, disabled: true}, Validators.required],
      profile: [{value: this.userData.Attributes.profile, disabled: true}, Validators.required] ,
      given_name: [this.userData.Attributes.given_name, Validators.required],
      family_name: [this.userData.Attributes.family_name, Validators.required],
      email: [this.userData.Attributes.email, Validators.required],
      gender: [[this.userData.Attributes.gender], Validators.required],
    });

    this.permissions = _.sortBy(this.permissions, [(o) => o.friendly]);
    this.accountEditService.get('roles', '', null)
      .then((res) =>  this.roles = res.map((c: any) => ({
        value: c.name,
        friendly: c.name
      })))
      .catch();
  }

  onSubmit(value: string) {
    const userValue = {};
    userValue['sub'] = this.userData.sub;
    userValue['username'] = this.userData.Username;
    userValue['profile'] = this.userData.Attributes.profile;
    if (value === 'save') {
      this.loading = true
      this.editEvent.emit({name: value, data: Object.assign(this.userForm.value, userValue)});
    } else if (value === 'cancel') {
      this.editEvent.emit({name: value});
    }
  }


}
