import { FormControl, Validators } from '@angular/forms';
import { DateRange, IncidentField } from '@app/shared-stores/event-params/event-params.model';
import { ActionButtonInterface, FieldInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';
import { IncidentsSearchModel, UserPreference } from '../../../user-preference/store/user-preference.model';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';
import { Incident } from '../../store/incident.model';
import * as incidentActions from '../../store/incident.actions';
import * as fromIncident from '../../store/incident.actions';
import * as eventParamsActions from '../../../../shared-stores/event-params/event-params.actions';
import * as userPreferenceActions from '../../../user-preference/store/user-preference.actions';
import * as companyActions from '../../../admin/store/company.actions';
import * as userActions from '../../../admin/store/user/user.actions';
import { Auth } from 'aws-amplify';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, interval, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { TableComponent } from '@sweet-shared/components/table/table.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AppService } from '@app/app.service';
import { DateService } from '@shared-services/date.service';
import { QueryBuilderComponent } from '@sweet-shared/components/query-builder/query-builder.component';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { AppEmitterService } from '@app/app-emitter.service';
import * as _ from 'lodash';
import { UsersService } from '@shared-services/users.service';
import { IncidentsService } from '../../services/incidents.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import { PermissionService } from '@sweet-shared/services/permission.service';
import { CompaniesService } from '@shared-services/companies.service';
import { ModalComponent } from '@sweet-shared/components/modal/modal.component';
import { EventRequest } from 'src/app/pages/events/store/event.model';
import { HelpDialogModalComponent } from '@sweet-shared/components/help-dialog-modal/help-dialog-modal.component';
import { UrlService } from '@shared-services/url.service';
import { HeaderSortingService } from '@shared-services/header-sorting.service';
import { RefreshService } from '@sweet-shared/services/refresh.service';


export interface QueryData {
    rules: any[];
    ruleGroups?: any[];
    formatWithColon: boolean;
    page?: 'incident' | 'event' | 'dashboard';
}

@Component({
    selector: 'app-incidents-page',
    templateUrl: './incidents-page.component.html',
    styleUrls: ['./incidents-page.component.scss']
})
export class IncidentsPageComponent implements OnInit, OnDestroy {

    init$ = new BehaviorSubject(false);
    // Private destroyer used to kill all the subscription once the page is destroyed.
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

    private actionEvent$: Subject<any> = new Subject();
    hide: BehaviorSubject<{ flag: boolean, typ: string }> = new BehaviorSubject({ flag: true, typ: '' });

    // loader control for the search form
    loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // loader control for the table.
    searching = false;

    // Due to the fact we have super user(s) in the system, and also that searches are restricted to
    // particular user, we need to let the user choose the company in which he/she would like to
    // perform a search from. The list will be empty for a start and then will be populated when available.
    allCompanies: any[] = [];

    // Date range is also something we need for the user to choose from. It will be passed down to the filter
    // component to populate the dropdown. Again it will be set to an empty list until populated.
    dataRanges: DateRange[] = [];

    // Form Details
    searchFieldDetails: FieldInterface[] = null;

    // Search action buttons
    searchActionButtons: ActionButtonInterface = {
        flex: 'flex-start',
        buttons: [
            {
                iconName: 'search',
                name: 'SEARCH',
                label: 'Search',
                color: 'primary',
                type: 'stroked'
            },
        ]
    };

    // field updater
    fieldUpdater: EventEmitter<any> = new EventEmitter<any>();

    // all incident fields available
    incidentFields: IncidentField[] = [];
    sortItems = new HeaderSortingService();

    // Because the header is subject to change when the user decide to add/remove columns he/she does not need
    // we need to signal the table to update its headers. For that to be possible, we will use a BehaviorSubject
    incidentsHeadersSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

    // Data Stream to use to populate the table is also subject to change without the need of reloading the table
    // for that to be possible we will be using a behaviorSubject.
    incidentsDataStream: BehaviorSubject<Incident[]> = new BehaviorSubject([]);

    // Activate investigation holder
    activeInvestigation: Incident = null;

    // Incident data
    userPreferences: UserPreference = null;
    classificationOptions: any[] = [];
    tierOptions: any[] = [];
    priorityOptions: any[] = [];
    stateOptions: any[] = [];
    closureStateOptions: any[] = [];
    companies: any[] = [];
    users: any[] = [];
    assignedIncidentSubject: BehaviorSubject<Incident> = new BehaviorSubject(null);
    loading = false;
    // Currently, hard-coding, but may be added to userPreferences
    NEW_INCIDENT_MS = 3_600_000; // milliseconds in 1 hour

    // Dialog references
    dialogRef: any = null;

    // Updating user preferences
    updatingUserPreferences: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    showIncidentDisplayDetailsButton = true;

    userName: string;
    incidentPageUrl: string = null;
    pageSize = 25;


    nonClosedInvestigationOptions: string[];
    private currentRoute;
    private previousRoute;

    @ViewChild(FormBuilderComponent) formBuilderComponent: FormBuilderComponent;
    @ViewChild('search') search: FormBuilderComponent;
    @ViewChild('incidentsTable') incidentsTable: TableComponent;

    counter$ = new Subject();
    counter = 0;
    refresh$ = new Subject();

    constructor(
        private store: Store<State>,
        private matDialog: MatDialog,
        private router: Router,
        private appService: AppService<Incident>,
        private activatedRoute: ActivatedRoute,
        private dateService: DateService,
        private incidentService: AppService<Incident>,
        private appEmitterService: AppEmitterService,
        private usersService: UsersService,
        private incidentsService: IncidentsService,
        private snackbarService: SnackbarService,
        private permissionService: PermissionService,
        private companiesService: CompaniesService,
        public dialog: MatDialog,
        public urlService: UrlService,
        public refreshSrv: RefreshService
    ) {
        // listening to ShowAssigned event emitted from header component
        this.appEmitterService
            .on('SHOW_ASSIGNED', (emitted) => {
                // when these 2 variables have values, it will act as flag to determine default values of controls
                this.userName = emitted.data.userName;
                this.nonClosedInvestigationOptions = emitted.data.nonClosedInvestigationOptions;
                // will assign control values and start search if the search component exists
                if (this.search) {
                    this.search.form.controls.assignee.setValue([this.userName]);
                    this.search.form.controls.state.setValue(this.nonClosedInvestigationOptions);
                    this.search.form.controls.companies.setValue([emitted.data.userCompany]);
                    this.searchFilterActionHandler({ name: 'SEARCH', data: this.search.form.value });
                }
            }, this.destroyed$);
    }

    ngOnInit() {
        // next search or refresh countdown
        this.counter$.pipe(
            switchMap((minutes: number) => interval(1000).pipe(
                map(seconds => ({ minutes, seconds }))
            )),
            takeUntil(this.destroyed$),
        ).subscribe(pkg => {
            this.counter = pkg.minutes - pkg.seconds;
        })

        // handle logic to refresh search
        this.refresh$.
            pipe(
                switchMap((minutes: number) => {
                    const t = minutes * 1000;
                    this.counter$.next(minutes);
                    return interval(t);
                }),
            ).subscribe(d => {
                const pkg = {
                    "name": "SEARCH",
                    data: { ...this.search?.form?.value }
                }

                this.search.actionEvents.emit(pkg);
            })


        // listen for a refresh signal and refresh
        this.refreshSrv?.refreshInterval$.pipe(
            filter((minutes: any) => !!minutes),
        ).subscribe(v => this.refresh$.next(v))

        this.showHideDateTime();

        this.incidentPageUrl = this.activatedRoute.snapshot['_routerState'].url;

        // reset refresh counter when incidents data stream fires
        this.incidentsDataStream.subscribe(incidents => {
            if (!!incidents) {
                const rr = this.refreshSrv.refreshRate$.getValue();

                this.refresh$.next(this.refreshSrv.refreshRate$.getValue())
            }
        })

        // setting up all listener(s) and variable needed for the page.
        this.getIncidentDefaultValues();

        this.hide.subscribe(pkg => {
            if (!!this.searchFieldDetails) {
                this.searchFieldDetails = this.searchFieldDetails.map(s => {
                    if (s.name === 'dateTime') {
                        return { ...s, hide: pkg.flag };
                    }
                    return s;
                });
            }
        }
        );
        this.currentRoute = this.urlService.setCurrentUrl(this.router.url);
        this.previousRoute = this.urlService.getPreviousUrl();
    }
    handlePaginationChange(changes: any) {
        this.incidentsService.paginatorConfig = { ...changes?.payload };

    }

    saveIncidentsSearchPreferences(incidentsSearchValue: IncidentsSearchModel): void {
        const newUserPrefs: UserPreference = {
            ...this.userPreferences,
            incidentsSearch: incidentsSearchValue
        };
        this.store.dispatch(userPreferenceActions.updateUserPreferences({ userPreferences: newUserPrefs, notify: false }));
    }

    searchFilterActionHandler(event) {
        let eventRequest: EventRequest;
        this.actionEvent$.next(event);
        switch (event.name) {
            case 'SEARCH':
                this.saveIncidentsSearchPreferences({
                    companies: event.data.companies,
                    assignee: event.data.assignee,
                    classifications: event.data.classifications,
                    closureState: event.data.closureState,
                    priority: event.data.priority,
                    state: event.data.state,
                    tier: event.data.tier
                });
                let where: any = {
                    updatedAt: {
                        gte: event.data.dateRange?.from,
                        lte: event.data.dateRange?.to
                    },
                };
                if (event?.data?.query) {
                    where.query = event.data.query || '';
                }

                if (event?.data?.companies?.length !== 0) {
                    where.company = event.data.companies;
                }

                if (event?.data?.classifications?.length !== 0) {
                    where.category = event.data.classifications;
                }

                if (event?.data?.tier?.length !== 0) {
                    where.u_queue = event.data.tier;
                }

                if (event?.data?.state?.length !== 0) {
                    where.state = event.data.state;
                }

                if (event?.data?.priority?.length !== 0) {
                    where.priority = event.data.priority;
                }

                if (event?.data?.assignee?.length !== 0) {
                    where.u_unlisted_affected_user = event.data.assignee;
                }

                if (event?.data?.closureState?.length !== 0) {
                    where.close_code = event.data.closureState;
                }
                // if (event?.data?.dateRange === 'custom') {
                //     where.updatedAt.gte = moment(event.data.dateTime[0]).toDate().toISOString();
                //     where.updatedAt.lte = moment(event.data.dateTime[1]).toDate().toISOString();
                // } else if (event?.data.dateRange !== 'custom') {
                //     where.updatedAt = this.dateService.formatDate(event.data.dateRange);
                //     if (!where.updatedAt.lte) {
                //         where.updatedAt.lte = new Date().toISOString();
                //     }
                // }
                this.store.dispatch(incidentActions.searchIncidents({ searchParams: { where: JSON.stringify(where) } }));
                this.dateService.incidentsCreatedDate$.next(where.updatedAt)
                break;
            case 'QUERY':
                this.openQueryBuilder();
                break;
            case 'DOWNLOAD_RESULTS':
                break;
            case 'VALUE_CHANGED':
                // Here we will only need to react to changes of the company
                if (event.data.field === 'companies') {
                    // we need to fetch the new list of user and put it on assignee
                    if (!event.data.value.length) {
                        const userFieldIndex = this.searchFieldDetails.findIndex(field => field.name === 'assignee');
                        if (userFieldIndex >= 0) {
                            this.searchFieldDetails[userFieldIndex].groupOption = [];
                            this.searchFieldDetails[userFieldIndex].disabled = true;
                            this.fieldUpdater.emit(this.searchFieldDetails[userFieldIndex]);
                        }
                    } else {
                        this.usersService.fetchUsers(event.data.value).then(users => {
                            const userFieldIndex = this.searchFieldDetails.findIndex(field => field.name === 'assignee');
                            if (userFieldIndex >= 0) {
                                const userList = this.usersService.groupUsersByCompany(users);
                                this.searchFieldDetails[userFieldIndex].groupOption = userList;
                                this.searchFieldDetails[userFieldIndex].disabled = userList && userList.length ? false : true;
                                this.fieldUpdater.emit(this.searchFieldDetails[userFieldIndex]);
                            }
                        });
                    }
                }

                break;
            default:
                break;
        }
    }

    showHideDateTime(): void {
        this.actionEvent$.subscribe(event => {
            if (event.data && event.data.field === 'dateRange') {
                this.hide.next({ flag: event.data.value !== 'custom', typ: event.data.value });
            }
        });
    }

    onTableRowClick(event) {

        switch (event.type) {
            case 'ROW_CLICK':
                if (event.data.sys_id) {
                    const requiredPermission = 'response.view-inv-details';
                    if (this.permissionService.hasPermission(requiredPermission)) {
                        this.router.navigate([`/incidents/incident/${event.data.sys_id}`]).then(() => {
                        });
                    } else {
                        const dialogRef = this.matDialog.open(ModalComponent);
                        dialogRef.componentInstance.message = `
                <p>You are missing the required permission('${requiredPermission}') to view the investigation details.</p>
                <p>Please contact your admin for assistance.</p>
            `;
                        dialogRef.componentInstance.title = `Missing Required Permission`;
                    }
                }
                break;
            default:
                break;
        }
    }

    private getIncidentDefaultValues() {
        this.store.dispatch(userPreferenceActions.loadUserPreferences());
        this.store.dispatch(eventParamsActions.loadEventFields());
        this.store.dispatch(companyActions.loadCompanies());
        this.store.dispatch(incidentActions.loadIncidentAttributes());
        Auth.currentAuthenticatedUser()
            .then((user) => {
                const defaultCompanyFilter = user.attributes.profile;
                this.store.dispatch(userActions.loadUsers({ companyFilter: [defaultCompanyFilter] }));
                this.store.select(state => state.eventParams).pipe(
                    filter(val => !val.loading),
                    takeUntil(this.destroyed$)
                ).subscribe(evtParams => {
                    // Save the event fields
                    this.incidentFields = evtParams.eventParams.incidentFields;
                    // Sorts the events fields using the page flag
                    const sortedFields = this.sortItems.sortList(this.incidentFields, 'incident');
                    if (this.permissionService.hasPermission('response.add-worklog')) {
                        // Default sort headers
                        this.incidentsHeadersSubject.next([{ name: 'actions', friendly: 'Actions', description: '' }, ...sortedFields,]);
                    } else {
                        // Default sort headers
                        this.incidentsHeadersSubject.next(sortedFields);
                    }
                    // Get the user preferences
                    this.store.select(state => state.userPreferences.userPreferences)
                        .pipe(
                            filter(val => Object.keys(val).length > 0),
                            takeUntil(this.destroyed$)
                        ).subscribe((userPreferences: UserPreference) => {
                            // Fetch the users
                            this.store.select(state => state.users.users)
                                .pipe(
                                    filter(users => !!users),
                                    takeUntil(this.destroyed$)
                                ).subscribe(users => {
                                    // sorts users alphabetically and groups by company
                                    this.users = this.usersService.groupUsersByCompany(users);
                                    // store the user preferences
                                    this.userPreferences = userPreferences;
                                    // Getting the company list
                                    this.store.select(state => state.companies.companies).pipe(
                                        filter(c => !!c),
                                        takeUntil(this.destroyed$)
                                    ).subscribe(companies => {
                                        this.allCompanies = this.companiesService.companyNameAndFriendly(companies);

                                        // check for any assigned investigation
                                        if (userPreferences.activeInvestigation && userPreferences.activeInvestigation !== '-') {
                                            // dispatch a fetch for the activate investigation
                                            this.store.dispatch(incidentActions.getIncident({ incidentId: userPreferences.activeInvestigation }));
                                        } else {
                                            this.store.dispatch(incidentActions.clearSelectedIncident());
                                        }
                                        this.store.select(state => state.incidents.incidentAttributes).pipe(
                                            filter(ia => !!ia && !ia.incidentAttributesLoading && Object.entries(ia).length > 0),
                                            takeUntil(this.destroyed$)
                                        ).subscribe(incAttrs => {
                                            this.classificationOptions = incAttrs.classification.map(cls => {
                                                return {
                                                    friendly: cls,
                                                    value: cls
                                                };
                                            });

                                            this.tierOptions = incAttrs.tier.map(tier => {
                                                return { value: tier, friendly: tier };
                                            });

                                            this.stateOptions = incAttrs.state.map(s => {
                                                return { value: s, friendly: s };
                                            });

                                            this.priorityOptions = incAttrs.priority.map(p => {
                                                return { value: p, friendly: p };
                                            });

                                            this.closureStateOptions = incAttrs.closureState.map(cs => {
                                                return { value: cs, friendly: cs };
                                            });

                                            if (!!!this.init$.getValue()) {
                                                this.searchFieldDetails = [
                                                    {
                                                        label: 'Created',
                                                        component: 'input-datepicker',
                                                        name: 'dateRange',
                                                        placeholder: 'Created',
                                                        flex: '',
                                                        signalOnChanged: true,
                                                        defaultValue: userPreferences.dateRange,
                                                        validators: [Validators.required],
                                                        options: evtParams.eventParams.incidentDateRanges,
                                                        showClearButton: true
                                                    },
                                                    {
                                                        label: 'Date and Time',
                                                        component: 'input-date-time',
                                                        name: 'dateTime',
                                                        placeholder: 'Date and Time',
                                                        defaultValue: null,
                                                        flex: '100%',
                                                        signalOnChanged: true,
                                                        hide: true
                                                    },
                                                    {
                                                        label: 'Companies',
                                                        component: 'input-select',
                                                        name: 'companies',
                                                        placeholder: 'Companies',
                                                        flex: '',
                                                        selectMultiple: true,
                                                        signalOnChanged: true,
                                                        defaultValue: userPreferences.incidentsSearch?.companies || [],
                                                        validators: [],
                                                        options: this.allCompanies,
                                                        showSearch: true,
                                                        showSearchFormControl: new FormControl(),
                                                        showSelectAll: true,
                                                        showClearButton: true
                                                    },
                                                    {
                                                        label: 'Assignee',
                                                        component: 'input-select-group',
                                                        name: 'assignee',
                                                        placeholder: 'Assignee',
                                                        flex: '',
                                                        selectMultiple: true,
                                                        signalOnChanged: true,
                                                        defaultValue: userPreferences.incidentsSearch?.assignee || [],
                                                        validators: [],
                                                        options: this.users,
                                                        showSearch: true,
                                                        showSearchFormControl: new FormControl(),
                                                        showSelectAll: true,
                                                        showClearButton: true
                                                    },
                                                    {
                                                        label: 'Classification',
                                                        component: 'input-select',
                                                        name: 'classifications',
                                                        placeholder: 'Classification',
                                                        flex: '',
                                                        selectMultiple: true,
                                                        signalOnChanged: true,
                                                        defaultValue: userPreferences.incidentsSearch?.classifications || [],
                                                        validators: [],
                                                        options: this.classificationOptions,
                                                        showSearch: true, // TO DELETE
                                                        showSearchFormControl: new FormControl(),
                                                        showSelectAll: true,
                                                        showClearButton: true,
                                                    },
                                                    {
                                                        label: 'Queue',
                                                        component: 'input-select',
                                                        name: 'tier',
                                                        placeholder: 'Queue',
                                                        flex: '',
                                                        selectMultiple: true,
                                                        defaultValue: userPreferences.incidentsSearch?.tier || [],
                                                        validators: [],
                                                        options: this.tierOptions,
                                                        showClearButton: true,
                                                        showSelectAll: true,
                                                        signalOnChanged: true
                                                    },
                                                    {
                                                        label: 'State',
                                                        component: 'input-select',
                                                        name: 'state',
                                                        placeholder: 'State',
                                                        flex: '50%',
                                                        selectMultiple: true,
                                                        defaultValue: userPreferences.incidentsSearch?.state || [],
                                                        validators: [],
                                                        options: this.stateOptions,
                                                        showClearButton: true,
                                                        showSelectAll: true,
                                                        signalOnChanged: true
                                                    },
                                                    {
                                                        label: 'Priority',
                                                        component: 'input-select',
                                                        name: 'priority',
                                                        placeholder: 'Priority',
                                                        flex: '',
                                                        selectMultiple: true,
                                                        defaultValue: userPreferences.incidentsSearch?.priority || [],
                                                        validators: [],
                                                        options: this.priorityOptions,
                                                        showClearButton: true,
                                                        showSelectAll: true,
                                                        signalOnChanged: true
                                                    },
                                                    {
                                                        label: 'Closure Code',
                                                        component: 'input-select',
                                                        name: 'closureState',
                                                        placeholder: 'Closure Code',
                                                        flex: '',
                                                        selectMultiple: true,
                                                        defaultValue: userPreferences.incidentsSearch?.closureState || [],
                                                        validators: [],
                                                        options: this.closureStateOptions,
                                                        showClearButton: true,
                                                        showSelectAll: true,
                                                        signalOnChanged: true
                                                    },
                                                    {
                                                        label: 'Event Search. Ex: INC1141* or INC1141689',
                                                        component: 'input-text',
                                                        name: 'query',
                                                        placeholder: 'Event Search. Ex: INC1141* or INC1141689',
                                                        defaultValue: null,
                                                        flex: '100%',
                                                        validators: []
                                                    }
                                                ];
                                            }
                                            // if the search component exists and these values have been set,
                                            // 'SHOW_ASSIGNED' action has been emitted
                                            if (this.search && this.userName && this.nonClosedInvestigationOptions) {
                                                this.searchFilterActionHandler({ name: 'SEARCH', data: this.search.form.value });
                                            }

                                            this.init$.next(true);
                                        });
                                    });
                                });
                        }
                        );
                });
            }
            ).catch((err) => {
                this.router.navigate(['/']);
            });

        // Listen to the searching
        this.store.select(state => state.incidents.loading).pipe(takeUntil(this.destroyed$)).subscribe(
            loading => {
                this.searching = loading;
                this.loaderSubject.next(loading);
            }
        );

        // Get companies id -> name map
        const companiesMap$ = this.store.select(state => state.companies.companiesMap).pipe(
            filter(data => !!data),
            takeUntil(this.destroyed$));
        // Listen to the results
        const incidents$ = this.store.select(state => state.incidents.incidents).pipe(
            takeUntil(this.destroyed$)
        );
        combineLatest([companiesMap$, incidents$]).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(([companiesMap, incidents]) => {
            // Update incidents company field values to include company friendly name as well as id
            const incidentsWithFriendlyCompany = incidents.map(incident => {
                return {
                    ...incident,
                    company: `${companiesMap[incident.company]} (${incident.company})`
                };
            });
            const incidentsWithFriendlyCompanySorted = _.sortBy(_.sortBy(incidentsWithFriendlyCompany, [(o) => o['sys_updated_on']]),
                [(o) => o['u_contact_time']]).reverse(); // descending order

            this.incidentsDataStream.next(incidentsWithFriendlyCompanySorted);
        });

        // subscribe to the activate investigation
        this.store.select(state => state.incidents.selectedIncident).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(incident => {
            this.activeInvestigation = incident;
            this.assignedIncidentSubject.next(incident);
        });

        this.store.select(state => state.userPreferences.updating).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(val => this.updatingUserPreferences.next(val));
    }

    incidentActionHandler(event) {
        switch (event.type) {
            case 'DISMISS':
                // Should only remove from user preferences
                this.store.dispatch(userPreferenceActions.updateUserPreferences({
                    userPreferences: {
                        ...this.userPreferences,
                        activeInvestigation: '-'
                    },
                    notify: true
                }));
                break;
            case 'SHOW_DETAILS':
                if (event.incident.sys_id) {
                    this.router.navigate(['/incidents/incident', event.incident.sys_id]).then(() => {
                    });
                }
                break;
            case 'UPDATE':
                this.store.dispatch(fromIncident.updateIncident({ incidentId: event.incident.sys_id, value: event.data }));
                break;
            default:
                break;
        }
    }

    computeIncidentGrade(incident: Incident, key: string) {
        if (key && key !== 'number') {
            return '';
        }
        if (incident.priority.includes('1')) {
            return environment.gradeColors.grade1;
        } else if (incident.priority.includes('2')) {
            return environment.gradeColors.grade2;
        } else if (incident.priority.includes('3')) {
            return environment.gradeColors.grade3;
        } else if (incident.priority.includes('4')) {
            return environment.gradeColors.grade4;
        } else if (incident.priority.includes('5')) {
            return environment.gradeColors.grade5;
        } else {
            return '';
        }
    }

    highlightNewOrUpdatedIncidents(row: Incident): boolean {
        if (row && row.sys_updated_on) {
            const createdTime = moment(row.sys_updated_on).valueOf();
            const now = moment().valueOf();
            if ((now - createdTime) < this.NEW_INCIDENT_MS || row.u_contact_time.length > 0) {
                return true;
            }
        }
        return false;
    }

    onCreateIncident() {
        // Get shared config of create incident form from service
        const formConfig = this.incidentsService.getCreateIncidentForm(this.allCompanies, this.priorityOptions);
        this.dialogRef = this.matDialog.open(FormBuilderComponent, {
            disableClose: true
        });
        this.dialogRef.componentInstance.actionButtons = formConfig.formButtons;
        this.dialogRef.componentInstance.fieldDetails = formConfig.incidentFormDetails;
        this.dialogRef.componentInstance.title = formConfig.title;
        this.dialogRef.componentInstance.loading = false;
        this.dialogRef.componentInstance.actionEvents.subscribe(event => {
            switch (event.name) {
                case 'CANCEL':
                    this.dialogRef.close();
                    this.dialogRef = null;
                    break;
                case 'CREATE_INCIDENT':
                    this.createIncident(event.data, this.dialogRef);
                    break;
                default:
                    break;
            }
        });
    }

    private createIncident(data: any, dialogRef: any) {
        dialogRef.componentInstance.loading = true;
        dialogRef.componentInstance.loadingSubject.next(true);
        this.incidentService.post('investigation', '', { action: 'create-incident' }, data)
            .then((res: Incident) => {
                this.store.dispatch(incidentActions.incidentCreateSuccess({ incident: res, makeActive: data.status, userPreferences: this.userPreferences }));
                dialogRef.close();
                this.router.navigate(['/incidents', res.sys_id]);
            })
            .catch((err) => {
                this.store.dispatch(incidentActions.incidentCreateError({ error: err.message }));
                dialogRef.componentInstance.loading = false;
                dialogRef.componentInstance.loadingSubject.next(false);
                dialogRef.componentInstance.error = err.message;
            });
    }

    downloadAsCSV() {
        const date = new Date().toLocaleDateString().replace(/\//g, '_');
        const dateRange = this.search.form.value.dateRange.replace(/\s/g, '_');
        const format = this.incidentsTable.formatForCSVDownload();
        const csv = new Angular5Csv(format.data, `incidents-${date}-${dateRange}`, format.options);
    }

    openQueryBuilder() {
        const data: QueryData = {
            rules: [{ field: '', operator: '=', value: '' }],
            formatWithColon: true,
            page: 'incident'
        };
        let sessionRules: any = sessionStorage.getItem(`${data.page}-query`);
        sessionRules ? sessionRules = JSON.parse(sessionRules) : sessionRules = [];
        (sessionRules[0] && sessionRules[0].rules && sessionRules[0].rules.length) ?
            data.ruleGroups = sessionRules :
            data.ruleGroups = [{ condition: 'AND', rules: data.rules }];
        const dialogRef = this.matDialog.open(QueryBuilderComponent, {
            disableClose: false,
            panelClass: 'ctl-panel-class',
            data
        });
        dialogRef.componentInstance.fields = this.incidentsHeadersSubject.value;
        dialogRef.componentInstance.inputData = data;

        dialogRef.componentInstance.queryBody.pipe(
            takeUntil(this.destroyed$)
        ).subscribe(event => {
            if (event.name === 'submit') {
                this.formBuilderComponent.form.controls.query.setValue(event.data);
                this.searchFilterActionHandler({ name: 'SEARCH', data: this.formBuilderComponent.form.value });
                dialogRef.close();
            } else if (event.name === 'cancel') {
                dialogRef.close();
            }
        });
    }

    isClosed(row: Incident): boolean {
        return row.state === 'Closed';
    }

    urgency(cell: any): number {
        if (cell.u_queue === 'Tier 1') {
            return 1;
        } else if (cell.u_queue === 'Tier 2') {
            return 2;
        } else {
            return 3;
        }
    }

    onHelpClick() {
        // Opens up the Incident KB dialog with how to article
        const dialogRef = this.dialog.open(HelpDialogModalComponent, { data: { articleTitle: 'How to Search Incidents...' } });
    }

    onToggleClick(value: string) {
        this.urlService.setToggleValue(value);
    }

    onAddWorklog(incident: Incident): void {
        this.incidentsService.addWorklog(incident);
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
