import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../../guards/auth-gaurd.service';
import {ListManagementComponent} from './list-management/list-management.component';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';

const routes: Routes = [  {
  path: 'list',
  canActivate: [AuthGuardService],
  data: {
     requiredPermission: ['portal-access.enable-admin-lists-tab'],
    any: false
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuardService],
      component: ListManagementComponent
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListManagementRoutingModule { }
