<h3 class="mat-title">{{worklog.title}}</h3>
<div fxFill markdown [data]="worklog.message"></div>
<br>
<div fxLayout="row wrap" fxLayoutAlign="start center">
  <div *ngFor="let chartContainer of worklog.chartsData; let i = index;" fxFlex="1 0 300px">
    <div>
      <h3 class="chart-title">{{chartContainer.title}}</h3>
      <h5>{{chartContainer.description}}</h5>
      <div style="height: 300px;" [id]="chartContainer.title + i"></div>
    </div>
  </div>
</div>
