<section>
  <app-portal-loader [loadingStatus]="!filterReady"></app-portal-loader>
  <div class="layout-padding">
    <button
      mat-stroked-button
      type="button"
      routerLink="/admin"
      class="admin_sections--back"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to Admin Page
    </button>
    <mat-card fxFill>
      <mat-card-title>Filters</mat-card-title>
      <mat-card-content>
        <app-form-builder
          *ngIf="userFilterDetails.length"
          [fieldDetails]="userFilterDetails"
          (actionEvents)="onCompanyChanged($event)"
          #usersForm
        >
        </app-form-builder>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>User Accounts</mat-card-title>
      <mat-card-actions>
        <button
          *ifUserCan="'account.create'"
          mat-stroked-button
          color="primary"
          (click)="onAddUser()"
        >
          <mat-icon>add</mat-icon>
          <span>Create User</span>
        </button>
      </mat-card-actions>
    </mat-card>
    <br />
    <mat-card>
      <app-table
        [data]="usersDataStream"
        [headerStream]="usersHeaders"
        [pageSize]="25"
        [showFilter]="true"
        [loading]="loading"
        #usersTable
        (events)="onClick($event)"
      >
        <mat-form-field fxFlex *ngIf="usersTable.showFilter">
          <mat-label>Filter</mat-label>
          <input
            matInput
            (keyup)="usersTable.applyFilter($event.target.value)"
            placeholder="Filter through results. Ex: Phishing Traffic Detected"
          />
        </mat-form-field>
      </app-table>
    </mat-card>
  </div>
</section>
