import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { CompaniesService } from '@modules/companies/services/companies.service';
import { Company, CompanyTableHeader } from '@modules/companies/interfaces/companies';
import { AddCompanyComponent } from '@modules/companies/components/add-company/add-company.component';

import { COMPANY_TABLE_HEADERS } from '@modules/companies/constants/companies-constants';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  private destroyer$ = new Subject();

  loading = false;
  errorMessage: string = null;
  filterCtrl: FormControl = new FormControl('');
  companiesDataSource: MatTableDataSource<Company> = new MatTableDataSource([]);
  displayedColumns: string[] = [];
  tableColumns: CompanyTableHeader[] = COMPANY_TABLE_HEADERS;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private companiesService: CompaniesService,
    private dialog: MatDialog,
    private router: Router
  ) {
    // set the columns to display
    this.displayedColumns = this.tableColumns.map((column: CompanyTableHeader) => column.key);
  }

  ngOnInit(): void {
    // fetching companies
    this.fetchCompanies();

    // apply the filter
    this.filterCtrl.valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(value => {
      this.companiesDataSource.filter = value?.trim().toLowerCase() ?? '';

      if (this.companiesDataSource.paginator) {
        this.companiesDataSource.paginator.firstPage();
      }
    });
  }

  fetchCompanies(): void {
    this.loading = true;
    this.errorMessage = null;
    this.companiesService.fetchCompanies()
      .pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false))
      .subscribe({
        next: (companies: Company[]) => {
          this.companiesDataSource.data = companies;
          setTimeout(() => {
            this.companiesDataSource.paginator = this.paginator;
            this.companiesDataSource.sort = this.sort;
          });
        },
        error: (error) => {
          console.log(error)
          this.errorMessage = error.message;
        }
      });
  }

  clearFilter(): void {
    this.filterCtrl.reset();
  }

  addCompany(): void {
    this.dialog.open(AddCompanyComponent, {
      disableClose: true,
      width: '70vw',
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'New Company',
        companyDetails: null,
      }
    }).afterClosed().pipe(takeUntil(this.destroyer$)).subscribe(data => {
      if (data) {
        // add the data to the list
        this.fetchCompanies();
      }
    });
  }

  goToDetails(company: Company): void {
    this.router.navigate(['/admin/company', company.id]);
  }

}
