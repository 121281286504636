import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { SweetSharedModule } from 'src/app/sweet-shared/sweet-shared.module';
import { FlexModule } from '@angular/flex-layout';
import { EventsPageComponent } from './components/events-page/events-page.component';
import { EventDetailPageComponent } from './components/event-detail-page/event-detail-page.component';
import { EventsRoutingModule } from './events-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import * as fromEvent from './store/event.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EventEffects } from './store/event.effects';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CellActionsComponent } from './components/cell-actions/cell-actions.component';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { A11yModule } from '@angular/cdk/a11y';
import { MatSortModule } from '@angular/material/sort';
import { EventModule } from '@modules/events/events.module';


@NgModule({
  declarations: [EventsPageComponent, EventDetailPageComponent, CellActionsComponent],
  imports: [
    CommonModule,
    SweetSharedModule,
    FlexModule,
    MatInputModule,
    MatCardModule,
    EventsRoutingModule,
    StoreModule.forFeature(fromEvent.eventsFeatureKey, fromEvent.reducer),
    EffectsModule.forFeature([EventEffects]),
    MatProgressBarModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    DragDropModule,
    ScrollingModule,
    CdkTableModule,
    CdkTreeModule,
    A11yModule,
    EventModule
  ]
})
export class EventsModule {
}
