import {AdminOption} from '../../model';
import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  // Admin page options list.
  adminOptions: AdminOption[] = [
    {
      icon: 'business',
      url: '/admin/company',
      permission: ['portal-access.enable-admin-company-tab', 'company.list'],
      friendly: 'company',
      name: 'admin.company'
    },
    {
      icon: 'supervisor_account',
      url: '/admin/users',
      friendly: 'Accounts',
      permission: 'portal-access.enable-admin-accounts-tab',
      description: '',
      name: 'admin.accounts'
    },
    {
      icon: 'supervisor_account',
      url: '/admin/external-users',
      friendly: 'External accounts',
      permission: 'portal-access.enable-admin-ext-accounts-tab',
      description: '',
      name: 'admin.external-accounts',
      notProdReady: false
    },
  ];

  constructor() {
  }

}
