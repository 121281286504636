import {AuthService} from '../../../auth/services/auth.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {State} from '@app/reducers';
import {FormEvent} from '../../store/profile.model';
import {PermissionService} from '@sweet-shared/services/permission.service';
import {filter, takeUntil} from 'rxjs/operators';
import * as companyActions from '../../../admin/store/company.actions';
import * as profileActions from '../../store/profile.actions';
import {Company} from '../../../admin/store/company.model';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {AccountsService} from '../../../admin/services/accounts.service';
import * as _ from 'lodash';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as userActions from '../../../admin/store/user/user.actions';
import {User} from '../../../admin/store/user/user.model';
import {AppService} from '@app/app.service';
import {SnackbarService} from '@shared-services/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {FieldInterface, FormBuilderComponent} from '@sweet-shared/components/form-builder/form-builder.component';
import * as moment from 'moment';
import {ClipboardService, IClipboardResponse} from 'ngx-clipboard';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import {Auth } from 'aws-amplify';
import {EmailVerificationCodeDialogComponent} from '@sweet-shared/email-verification/email-verification-code-dialog/email-verification-code-dialog.component';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger(
      'fadeIn',
      [
        transition('false <=> true', animate(1000, keyframes([
          style({opacity: 0}),
          style({opacity: 1})
        ])))
      ]
    )
  ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  // Subscriptions
  currentUsersCompany: any;
  subscriptions: Subscription[] = [];
  userProfile: User = null;
  editMode = false;
  destroyer$: ReplaySubject<any> = new ReplaySubject<any>();

  userCompanies: Array<Company>;
  userProducts: any;
  userPermissions: Array<string>;
  defaultPermissions: any[] = [];
  userForm: FormGroup;
  isEditing = false;
  userInfo: User = null;
  loading = false;
  apiKeys: any;
  apiKeyLoader = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  panelOpenState = false;
  apiKeyCopied = '';


  routes: any[] = [
    {value: 'company', friendly: 'Company'},
    {value: 'list', friendly: 'List'},
    {value: 'search', friendly: 'Search'},
    {value: 'auth', friendly: 'Auth'},
    {value: '*', friendly: '*'}

  ];


  constructor(
    private store: Store<State>,
    private permissionService: PermissionService,
    private accountsService: AccountsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private appService: AppService<any>,
    private clipboardService: ClipboardService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.loading = true;

    this.authService.getAuthenticatedUser().pipe(takeUntil(this.destroyer$)).subscribe(user => {
        this.userProfile = user;
      }
    );
    // get users permissions and sort alphabetically
    this.userPermissions = this.permissionService.permissions.sort();

    // get users companies from store
    this.store.dispatch(companyActions.loadCompanies());
    // get user company info
    this.store.dispatch(profileActions.loadCurrentUserCompany({currentUserCompanyId: this.userProfile.profile}));

    // tslint:disable-next-line:max-line-length
    this.store.select(state => state.profile.currentUserCompany).pipe(takeUntil(this.destroyer$)).subscribe(value => this.currentUsersCompany = value);

    this.subscriptions.push(
      this.store.select(state => state.companies.companies).subscribe(value => this.userCompanies = value)
    );

    this.accountsService.permission();
    this.accountsService.getPermision().pipe(
      filter(data => !!data),
      takeUntil(this.destroyer$)
    ).subscribe(permission => {
      const permissionList = [];
      _.forOwn(permission, (value, key) => {
        permissionList.push(value);
      });
      this.defaultPermissions = permissionList;

    });

    this.store.dispatch(userActions.loadUser({username: this.userProfile.username}));
    this.store.select(state => state.users.currentUser)
      .pipe(
        filter(values => !!values),
        takeUntil(this.destroyer$)
      ).subscribe(val => {
      this.userInfo = val;
      setTimeout(() => this.formValue(), 200);

    });
    this.loadApiKey();
  }

  loadApiKey() {
    this.appService.get('apiKey', `apikey`, null)
      .then((val) => {
        this.apiKeys = val;
      })
      .catch(error => {
        this.snackbarService.open(error.message);
      });
  }

  formValue() {
    try {
      if (this.userInfo.Attributes.given_name) {
        this.userForm = this.fb.group({
          given_name: new FormControl({value: this.userInfo.Attributes.given_name, disabled: true}, Validators.required),
          family_name: new FormControl({value: this.userInfo.Attributes.family_name, disabled: true}, Validators.required),
          email: new FormControl({value: this.userInfo.Attributes.email, disabled: true}, Validators.required),
          gender: [[this.userInfo.Attributes.gender], Validators.required],
          phone_number: new FormControl({value: this.userProfile.phone_number, disabled: true}, Validators.required),
          username: new FormControl({value: this.userInfo.Username, disabled: true}, Validators.required),
          profile: new FormControl({value: this.userInfo.Attributes.profile, disabled: true}, Validators.required),
          sub: new FormControl({value: this.userInfo.sub, disabled: true}, Validators.required),
          allowed_permissions: [this.userInfo.Attributes.allowed_permissions, {disabled: true}, Validators.required],
          blocked_permissions: [this.userInfo.Attributes.blocked_permissions, {disabled: true}, Validators.required],
          access_attributes: new FormControl({value: this.userInfo.Attributes.access_attributes, disabled: true}, Validators.required)
        });
        this.userForm.controls.gender.disable();
      }
    } catch (error) {
      console.log('Waiting for data to load...');
    }
    this.loading = false;


  }


  editForm() {
    this.isEditing = true;
    this.userForm.controls.given_name.enable();
    this.userForm.controls.family_name.enable();
  }

  cancel() {
    this.userForm.disable();
    this.isEditing = false;
  }

  saveForm() {
    this.loading = true;
    this.appService.put('user', `${this.userInfo.Username}`, null, this.userForm.getRawValue())
      .then((res) => {
        this.snackbarService.open('Your update was successful');
        this.isEditing = false;
        this.userForm.disable();
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.store.dispatch(userActions.editUserError({error: error.message}));
        this.snackbarService.open(error.message);
      });
  }


  userHasPermission(category: string, action: string): boolean {
    const permissionName = `${category?.toLowerCase()}.${action?.toLowerCase()}`;
    return this.userPermissions.includes(permissionName);
  }

  eventHandler(event: FormEvent) {
    switch (event.type) {
      case 'CANCEL':
        // cancel the edit, all we need to do is to make the form to be not editable
        this.editMode = false;
        break;
      case 'SUBMIT':
        break;
    }
  }

  generateApiKey() {
    const generateApiKey: FieldInterface[] = [
      {
        name: 'routeAllowed',
        type: 'text',
        component: 'input-select',
        placeholder: 'Routes',
        label: 'Routes',
        flex: '100%',
        selectMultiple: true,
        defaultValue: [],
        // validators: [Validators.required],
        options: this.routes,
        signalOnChanged: true,
      },
      {
        label: 'Date and Time',
        component: 'input-date-time',
        name: 'dateTime',
        placeholder: 'Date and Time',
        defaultValue: null,
        flex: '',
        autocomplete: 'off',
        validators: [Validators.required],
        hide: false,
        signalOnChanged: true,
      },
    ];

    const dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '600px',
      minHeight: '100px',
      disableClose: true
    });
    dialogRef.componentInstance.fieldDetails = generateApiKey;
    dialogRef.componentInstance.loading = false;
    dialogRef.componentInstance.title = 'Generate API Key';

    dialogRef.componentInstance.actionButtons = {
      flex: 'end center',
      buttons: [
        {
          label: 'CANCEL',
          iconName: null,
          name: 'CANCEL',
          color: 'warn',
          type: 'basic'
        },
        {
          label: 'CREATE',
          iconName: null,
          name: 'CREATE',
          color: 'primary',
          type: 'stroked'
        }
      ]
    };
    dialogRef.componentInstance.loading = this.apiKeyLoader;
    dialogRef.componentInstance.actionEvents.pipe(takeUntil(this.destroyer$))
      .subscribe((event: any) => {
        if (event.name === 'CREATE') {
          this.apiKeyLoader = true;
          const from = moment(event.data.dateTime[0]).format('YYYY-MM-DD');
          const to = moment(event.data.dateTime[1]).format('YYYY-MM-DD');

          const dateB = moment(from);
          const dateC = moment(to);

          const days =  dateC.diff(dateB, 'days');
          const routesAllowed = event.data.routeAllowed;
          const dataToSubmit = {
            routes_allowed: routesAllowed,
            days
          };
          this.appService.post('apiKey', `apikey`, null, dataToSubmit)
            .then((res) => {
              dialogRef.close();
              this.loadApiKey();
              this.snackbarService.open('Api Key Created!');
            })
            .catch((error) => {
              dialogRef.close();
              this.snackbarService.open('Something went wrong. Please contact your administrator.');
            });
        }
        if (event.name === 'CANCEL') {
          dialogRef.close();
        }
      });


  }

  clipboardCopy(event) {
    this.clipboardService.copyResponse$.pipe(takeUntil(this.destroyer$)).
    subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this.snackbarService.open('Api Key Copied!');
        this.apiKeyCopied = 'accent';
      } else {
        this.snackbarService.open('Error Copying Api Key. Please Try Again.');
      }
    });
  }

  delete(event){
    this.loading = true;
    this.appService.delete('apiKey', `apikey/${event.token_id}`, null, null)
      .then((res) => {
        this.snackbarService.open('API Key Deleted!');
        this.loadApiKey();
        this.loading = false;


      })
      .catch(_ => {
        this.snackbarService.open('Something went wrong. Please contact your administrator.');
        this.loading = false;

      });
  }

  changePassword(): void {
    this.dialog.open(ChangePasswordComponent, {
      minWidth: '600px',
      minHeight: '100px',
      disableClose: true,
      data: {
        username: this.userProfile.username
      }
    });
  }

  getVerificationCode() {
    return this.dialog.open(EmailVerificationCodeDialogComponent, {
      minWidth: '300px',
      panelClass: 'no-padding-dialog',
      disableClose: true
    }).afterClosed();
  }

  submitVerificationCode(user) {
    console.log('USER', user);
    this.getVerificationCode().subscribe({
      next: (res) => {
        Auth.verifyUserAttributeSubmit(user, 'email', res)
          .then(response => {
            // attribute updated
            setTimeout(() =>
              this.snackbarService.open('Email Activation Completed', response),
              5000);
            this.authService.logout();
          })
          .catch(error => {
            this.snackbarService.open(error);
          });
      },
      error: (error) => {
        this.snackbarService.open(error.message);
      }
    });
  }

  verifyEmail(): void {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.verifyUserAttribute(user, 'email')
          .then(resp => this.submitVerificationCode(user))
          .catch(err => {
            this.snackbarService.open(err);
          });
      })
      .catch(error => {
        this.snackbarService.open(error);
      });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }

}
