import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./pages/auth/auth.module";
import { SweetSharedModule } from "./sweet-shared/sweet-shared.module";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducers";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./app.effects";
import { EventParamsEffects } from "./shared-stores/event-params/event-params.effects";
import { ProfileModule } from "./pages/profile/profile.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IncidentsModule } from "./pages/incidents/incidents.module";
import { KnowledgeBaseModule } from "./pages/knowledge-base/knowledge-base.module";
import { AdminModule } from "./pages/admin/admin.module";
import { UserPreferenceModule } from "./pages/user-preference/user-preference.module";
import { EventsModule } from "./pages/events/events.module";
import { ProductsEffects } from "./shared-stores/products/products.effects";
import { DashboardEffects } from "./pages/dashboards/store/dashboard.effects";
import { ClipboardModule } from "ngx-clipboard";
import { DashboardsModule } from "./pages/dashboards/dashboards.module";
import { HttpClientModule } from "@angular/common/http";
import { ReportingModule } from "./pages/reporting/reporting.module";
import { DevicesModule } from "./pages/devices/devices.module";
import { LogStreamModule } from "./pages/log-stream/log-stream.module";
import { SecurityPostureModule } from "./pages/security-posture/security-posture.module";
import { NgxLoadingModule } from "ngx-loading";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { ListManagementModule } from "./pages/list-management/list-management.module";
import { NgxMatNativeDateModule } from "@angular-material-components/datetime-picker";
import { MatNativeDateModule } from "@angular/material/core";
import { MultiSelectTypeComponent } from "./pages/dashboards/dashboard-detail-page/components/multi-select-type/multi-select-type.component";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { ThreatHuntingModule } from './pages/threat-hunting/threat-hunting.module';
import { KbService } from './pages/knowledge-base/kb.service';
import * as fromPortalCache from './shared-stores/portal-cache/reducers/portal-cache.reducer';
import { PortalCacheEffects } from './shared-stores/portal-cache/effects/portal-cache.effects';
import { EnvironmentService } from './environment/environment.service';
import { UserModule } from "@modules/user/user.module";
import { CompaniesModule } from "@modules/companies/companies.module";
import { CompaniesRoutingModule } from "@modules/companies/companies-routing.module";

@NgModule({
  declarations: [AppComponent, MultiSelectTypeComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AmplifyAngularModule,
    SweetSharedModule,
    AuthModule,
    DashboardsModule,
    HttpClientModule,
    ProfileModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AppEffects,
      EventParamsEffects,
      ProductsEffects,
      DashboardEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }) : [],
    BrowserAnimationsModule,
    AdminModule,

    IncidentsModule,
    EventsModule,
    ReportingModule,
    UserPreferenceModule,
    KnowledgeBaseModule,
    ClipboardModule,
    DevicesModule,
    LogStreamModule,
    NgxMatNativeDateModule,
    SecurityPostureModule,
    ListManagementModule,
    NgxLoadingModule.forRoot({
      primaryColour: '#0075C9',
      secondaryColour: '#0075C9',
      tertiaryColour: '#0075C9',
      backdropBackgroundColour: 'rgba(192,192,192,0.3)',
    }),
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
    }),
    FormlyMaterialModule,
    ThreatHuntingModule,
    // APP ROUTING MODULE NEEDS TO BE THE LAST MODULE IN THIS LIST
    AppRoutingModule,
    MatSelectModule,
    StoreModule.forFeature(fromPortalCache.storeFeatureKey, fromPortalCache.reducer),
    EffectsModule.forFeature([PortalCacheEffects]),
  ],
  providers: [AmplifyService, MatNativeDateModule, KbService],
  bootstrap: [AppComponent],
})
export class AppModule { }
