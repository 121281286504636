import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AthenaFilterAndStatement,
  AthenaFilterOrStatement,
  AthenaFilterStatement,
  QueryWidgetModel, WidgetModel
} from '../../../../../sweet-shared/models/widget.model';
import {QueryBuilderConfig, Rule, RuleSet} from 'angular2-query-builder';
import * as eventParamsActions from '../../../../../shared-stores/event-params/event-params.actions';
import {filter, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '../../../../../reducers';
import {MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../../../../../app.service';

@Component({
  selector: 'app-query-widget',
  templateUrl: './query-widget.component.html',
  styleUrls: ['./query-widget.component.scss']
})
export class QueryWidgetComponent implements OnInit {
  @Input() widget: QueryWidgetModel;
  indexName: string;
  error: string = null;
  indexDetails: any = null;
  formReady = false;
  queryBuilderModel: RuleSet = {
    condition: 'and',
    rules: []
  };
  queryAthenaStatement: AthenaFilterStatement;
  queryBuilderConfig: QueryBuilderConfig = {
    fields: {}
  };
  operators: any = {
    '=': 'string',
    '!=': 'string',
    '<': 'string',
    '<=': 'string',
    '>': 'string',
    '>=': 'string',
    contains: 'string',
    like: 'string',
    in: 'array',
    incidr: 'array',
    'is null': null,
    'is not null': null,
    between: 'array'
  }

  constructor(
    private store: Store<State>,
    private dialogRef: MatDialogRef<QueryWidgetComponent>,
    private widgetAppService: AppService<WidgetModel>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(eventParamsActions.loadEventFields());
    this.widgetAppService.get('index', '', null)
      .then(res => {
        this.indexName = this.widget.index_name || 'default';
        this.indexDetails = res.find(index => index.id === this.indexName);
        this.indexDetails.fields.forEach(field => {
          this.queryBuilderConfig.fields[field.value] = {
            name: field.friendly,
            value: field.value,
            type: 'string',
            operators: Object.keys(this.operators),
          }
        });
        setTimeout(() => this.formReady = true);
      })
      .catch(err => {
        this.formReady = true;
        this.error = err.message;
      })
  }

  setQueryAthenaStatement(queryWidgetEvent: RuleSet) {
    this.queryAthenaStatement = this.formatQueryForAthena(queryWidgetEvent);
  }

  formatQueryForAthena(queryModel: RuleSet, beginningStatement = {}): AthenaFilterStatement {
    beginningStatement[queryModel.condition] = queryModel.rules.map(rule => {
      if (rule.hasOwnProperty('condition') && rule.hasOwnProperty('rules')) {
        return this.formatQueryForAthena(rule as RuleSet);
      } else {
        const statement: Partial<AthenaFilterAndStatement | AthenaFilterOrStatement> | any = {
          field: (rule as Rule).field,
          comparison: (rule as Rule).operator
        };
        if (this.operators[(rule as Rule).operator] === 'string') {
          statement.value = (rule as Rule).value;
        } else if (this.operators[(rule as Rule).operator] === 'array') {
          if ((rule as Rule).operator === 'between') {
            const [minValue, maxValue] = (rule as Rule).value.split(',');
            statement.min = minValue;
            statement.max = maxValue;
          } else {
            statement.values = (rule as Rule).value.split(',');
          }
        }
        return statement;
      }
    });
    return beginningStatement;
  }

  saveWidgetQuery() {
    this.dialogRef.close({name: 'QUERY_WIDGET_SET', value: this.queryAthenaStatement});
  }

}
