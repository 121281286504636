import {AuthService} from './pages/auth/services/auth.service';
import {Component, HostListener, OnInit} from '@angular/core';
import {LayoutConfig} from './sweet-shared/models/layout-config';
import {Store} from '@ngrx/store';
import {State} from './reducers';
import * as profileAction from 'src/app/pages/profile/store/profile.actions';
import {Router} from '@angular/router';
import {ThemeSwitcherService} from './sweet-shared/services/theme-switcher.service';
import {Observable} from 'rxjs';
import {User} from './pages/admin/store/user/user.model';
import {Location} from '@angular/common';
import { addUser, setCurrentUser } from './pages/admin/store/user/user.actions';
import { MetricsService } from './sweet-shared/modules/metrics/services/metrics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ng-portal';
  isAuthenticated = false;
  user: User = null;
  isDarkMode: Observable<boolean>;

  // Layout configuration
  layoutConfig: LayoutConfig = {
    showFooter: true,
    customFooter: false,
    customHeader: false,
    showSidenav: false,
    loadingStatus: false,
    sideNavOpened: JSON.parse(localStorage.getItem('sideNavOpened') || 'true')
  };

  constructor(
    // inject the store
    private store: Store<State>,
    // inject router
    private router: Router,
    // service for switching between dark/light mode
    private themeService: ThemeSwitcherService,
    private authService: AuthService,
    private location: Location,
    public metricsService: MetricsService
  ) {

  }

  ngOnInit(): void {
    // check if user is logging in via SSO
    this.checkForSSOLogin();
    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      this.layoutConfig.showSidenav = isAuthenticated;
    });
    
    this.authService.getAuthenticatedUser().subscribe(user => {
      this.user = user
      this.store.dispatch(setCurrentUser({user: this.user}));

    });
    this.isDarkMode = this.themeService.isDarkTheme;

    
  }

  checkForSSOLogin() {
    // If on admin/external accounts route, exit function
    if (this.location.path(true).includes('admin/external-users')) {
      return;
    }
    if (this.location.path(true).includes('mylevel3-env1')) {
      this.authService.ssoLogin('mylevel3-env1');
    }
    if (this.location.path(true).includes('mylevel3-env2')) {
      this.authService.ssoLogin('mylevel3-env2');
    }
    if (this.location.path(true).includes('mylevel3-env3')) {
      this.authService.ssoLogin('mylevel3-env3');
    }
    if (this.location.path(true).includes('mylevel3-env4')) {
      this.authService.ssoLogin('mylevel3-env4');
    }
    // Oopsie fix here, control center currently has the wrong link info.  We are fixing it here.
    if (this.location.path(true).includes('lumen-control-center')) {
      this.authService.ssoLogin('mylevel3');
    }
  }
}
