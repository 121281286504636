<section class="datetime-picker">
    <mat-form-field appearance="{{ appearance }}">
        <mat-label>
            {{ label }}
        </mat-label>
        <mat-select [formControl]="dateTimeSelectedCtrl" [compareWith]="datetimeService.dateRangeComparison">
            <mat-option *ngFor="let option of options" [value]="option.value">{{ option.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="dateTimeSelectedCtrl?.value?.type as dateType">
        <ng-container *ngIf="specialDateValues.includes(dateType)">
            <ng-container *ngIf="dateType === customDate">
                <div class="custom-relative">
                    <mat-form-field>
                        <mat-label>Custom date Range</mat-label>

                        <mat-date-range-input [formGroup]="customDateRangeForm" [rangePicker]="picker">
                            <input matStartDate placeholder="Start Date" formControlName="start">
                            <input matEndDate placeholder="End Date" formControlName="end">
                        </mat-date-range-input>

                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <!-- Invalid Date Messages -->
                        <mat-error *ngIf="customDateRangeForm.controls.start.hasError('matStartDateInvalid')">Invalid
                            start
                            date</mat-error>
                        <mat-error *ngIf="customDateRangeForm.controls.end.hasError('matEndDateInvalid')">Invalid end
                            date
                        </mat-error>

                        <!-- Required Date Messages -->
                        <mat-error *ngIf="customDateRangeForm.controls.start.errors?.required">From Date Required
                        </mat-error>
                        <mat-error *ngIf="customDateRangeForm.controls.end.errors?.required">To Date Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="dateType === relativeDate">
                <form [formGroup]="relativeDateRangeForm" class="custom-relative">
                    <div class="start-date">
                        <mat-form-field>
                            <mat-label>Start</mat-label>
                            <input matInput placeholder="Start" formControlName="start">
                        </mat-form-field>
                        <p *ngIf="!!relativeDateRangeForm.valid && mayaStart; else invalidStart">{{ mayaStart | date :
                            'medium' }}</p>
                        <ng-template #invalidStart>
                            <mat-error *ngIf="!!relativeDateRangeForm.dirty">Invalid maya expression</mat-error>
                        </ng-template>
                    </div>

                    <div class="end-time">
                        <mat-form-field>
                            <mat-label>End</mat-label>
                            <input matInput placeholder="Start" formControlName="end">
                        </mat-form-field>
                        <p *ngIf="!!relativeDateRangeForm.valid && mayaEnd; else invalidEnd">{{ mayaEnd | date :
                            'medium'}}</p>
                        <ng-template #invalidEnd>
                            <mat-error *ngIf="!!relativeDateRangeForm.dirty">Invalid maya expression</mat-error>
                        </ng-template>
                    </div>
                </form>
            </ng-container>
        </ng-container>
    </ng-container>


</section>