import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThreatHuntingRoutingModule } from './threat-hunting-routing.module';
import { ThreatHuntingDetailsComponent } from './components/threat-hunting-details/threat-hunting-details.component';
import { SweetSharedModule } from 'src/app/sweet-shared/sweet-shared.module';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {MatChipsModule} from '@angular/material/chips';
import {MatTableModule} from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GraphComponent } from './components/graph/graph.component';
import { PivotTableComponent } from './components/pivot-table/pivot-table.component';
import { FlexmonsterPivotModule } from 'ng-flexmonster';



@NgModule({
  declarations: [
    ThreatHuntingDetailsComponent,
    GraphComponent,
    PivotTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetSharedModule,
    ThreatHuntingRoutingModule,
    MatProgressBarModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    FlexModule,
    MatInputModule,
    MatCardModule,
    MatButtonToggleModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatChipsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    FlexmonsterPivotModule,
  ],
})
export class ThreatHuntingModule { }
