<div class="layout-padding">
    <mat-card class="mat-elevation-z8">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-card-title>Available Threat Analysis</mat-card-title>
        </div>
        <mat-card-content>
            <mat-button-toggle-group [value]="selectedState" (change)="onSelectThreatAnalysisTypes($event.value)">
                <mat-button-toggle *ngFor="let item of threatAnalysisTypes" [value]="item.friendly"
                    [disabled]="!filterThreatAnalysisTypes(item.name)">{{item.friendly}}</mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-content>
    </mat-card>
    <br>
    <div fxLayout="row wrap" fxFill *ngIf="threatAnalysisSelected.length > 0" fxLayoutGap="5px">
        <mat-card class="mat-elevation-z8" fxFlex>
            <mat-card-title fxLayoutAlign="center center">Settings</mat-card-title>
            <mat-card-content fxLayout="column">
                <form [formGroup]="settingsForm">
                    <div class="container" fxLayout="row wrap" fxFill>
                        <mat-form-field appearance="outline" fxFlex fxFlexOffset='10px'>
                            <mat-label>{{field.label}}</mat-label>
                            <input [autocomplete]="field.autocomplete" [selectMode]="'range'" matInput [required]='field.required'
                                [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [placeholder]="field.placeholder" formControlName="date_range">
                            <owl-date-time #dt1></owl-date-time>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex fxFlexOffset='10px'>
                            <mat-label>Data Limit</mat-label>
                            <input matInput formControlName="limit">
                        </mat-form-field>                   
                    </div>
                </form>
                <div fxLayoutAlign="end end">
                    <button mat-stroked-button color="primary" [disabled]="!settingsForm.valid || loading" (click)="loadData()">Submit</button>
                </div>   
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z8" fxFlex>
            <mat-card-title class="container" fxLayoutAlign="center center">Summary of &nbsp;{{selectedState}}</mat-card-title>
            <mat-card-content fxLayout="column">
                    <p>{{threatArticle}}</p>
                    <div fxLayoutAlign="end end">
                        <button mat-stroked-button color="primary" (click)="showThreatSummary()">Full Article</button>
                    </div> 
            </mat-card-content>
        </mat-card>
    </div>
    <br>
    <div fxLayoutAlign="start start" *ngIf="threatAnalysisSelected.length > 0 && showResult">
        <button mat-stroked-button color="primary" (click)="reset()">Reset Analysis</button>
    </div>
    <br>
    <mat-card class="mat-elevation-z8 result-card" fxFlexFill *ngIf="threatAnalysisSelected.length > 0 && showResult">
        <mat-card-title fxLayoutAlign="center center">Results of <span> &nbsp;{{selectedState}}</span></mat-card-title>
        <mat-card-content fxLayout="column">
            <div fxLayoutAlign="center center" fxFlex *ngIf="loading">
                <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
            </div>
            <div *ngIf="!loading">
                <mat-button-toggle-group [value]="selectedView" (change)="onSelectView($event.value)">
                    <mat-button-toggle color="primary" value="table">
                        <mat-icon>table_rows</mat-icon>
                        Table View
                    </mat-button-toggle>
                    <mat-button-toggle color="primary" value="pivot" class="ribbon">
                        <mat-icon>pivot_table_chart</mat-icon>
                        Pivot View
                        <span>beta</span>
                    </mat-button-toggle>
                    <mat-button-toggle color="primary" value="graph" class="ribbon">
                        <mat-icon>timeline</mat-icon>
                        Graph View
                        <span>beta</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>           
            </div>
            <app-pivot-table 
            *ngIf="showPivot"
            [pivotData]="pivotData"
            [pivotHeaders]="pivotHeaders"
            >
            </app-pivot-table>
            <app-graph 
            *ngIf="showGraph"
            [pivotData]="pivotData"
            [pivotHeaders]="pivotHeaders"
            >
            </app-graph>
            <div *ngIf="showTable && !loading"> 
                <div fxLayoutAlign="end">
                    <button mat-stroked-button color="primary" (click)="downloadAsCSV()">
                        <mat-icon>download</mat-icon>
                        Export to CSV
                    </button>
                </div>
                <app-table
                    [headerStream]="threatHuntHeader"
                    [data]="threatHuntData"
                    [pageSize]="25"
                    [showFilter]="true"
                    [loading]="false"
                    [greyOut]="false"
                    [highlight]="highlight.bind(this)"
                    (events)="onTableRowClick($event)"               
                    #threatHuntTable
            ></app-table>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <app-bottom-page-border *ngIf="threatAnalysisSelected.length > 0 && showResult"></app-bottom-page-border>
</div>

