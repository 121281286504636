import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { WidgetModel } from '@sweet-shared/models/widget.model';

export const SELECT_ALL = '_Select_All';
export const SELECT_ANY = '_Any_';

@Injectable({
  providedIn: 'root'
})

/**
 * manage the input variables for widgets
 *
 * it's main objective is to efficiently manage widget input data and prevent unncessary calls to backend
 */
export class InputVariableService {
  private inputVariablesSubject = new BehaviorSubject({});
  private internalInputVariables = {};
  private initialFetch = false;
  public shouldFetch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private inputVariableReadySubject = new BehaviorSubject(false);
  dropDownTypes = ["dropdown", "string_dropdown"];
  public refetch$ = new Subject();

  get inputVariables(): Observable<any> {
    return this.inputVariablesSubject.asObservable();
  }

  get inputVariableReady(): Observable<boolean> {
    return this.inputVariableReadySubject.asObservable();
  }

  constructor() { }

  unsetInitialFetch(): void {
    this.initialFetch = false;
  }

  initializeInputVariables(widgets: any[]): void {
    this.internalInputVariables = widgets.reduce((prev, next: WidgetModel) => {
      if (this.dropDownTypes.includes(next.type)) {
        const defaultValue = next?.default_value ?? SELECT_ALL;
        prev[next.params.inputName] = next.params.isMultiSelect ? [defaultValue] : defaultValue;
      }
      return prev;
    }, {
      company: null,
      dateFrom: null,
      dateTo: null
    });
    this.inputVariablesSubject.next(this.internalInputVariables);
    this.inputVariableReadySubject.next(true);
  }

  setSingleInputVariables(name: string, value: any): void {
    this.internalInputVariables[name] = value;

    if (this.canFetch()) {
      if (!this.initialFetch) {
        this.inputVariablesSubject.next(this.internalInputVariables);
      }
      this.initialFetch = true;
    }
  }

  emitInputVariables(value?): void {
    value = value ? value : this.inputVariablesSubject.value;
    this.internalInputVariables = value;
    this.inputVariablesSubject.next(value);
  }

  private canFetch(): boolean {
    const inputs = Object.values(this.inputVariablesSubject.value);
    return !inputs.includes(null);
  }

  public getInitialFetch(): boolean {
    return this.initialFetch;
  }
}
