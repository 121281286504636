import { MatSort, Sort } from '@angular/material/sort';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, pluck, scan, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, interval, ReplaySubject, Subject } from 'rxjs';

import { State } from '@app/reducers';
import { AppService } from '@app/app.service';
import { UrlService } from '@shared-services/url.service';
import { DateService } from '@shared-services/date.service';
import { Company } from '@app/pages/admin/store/company.model';
import { SnackbarService } from '@shared-services/snackbar.service';
import { AuthService } from '@app/pages/auth/services/auth.service';
import * as eventActions from '@app/pages/events/store/event.actions';
import { CompaniesService } from '@shared-services/companies.service';
import * as companyActions from '@app/pages/admin/store/company.actions';
import { PermissionService } from '@sweet-shared/services/permission.service';
import * as incidentActions from '@app/pages/incidents/store/incident.actions';
import { HeaderSortingService } from '@shared-services/header-sorting.service';
import { EventField } from '@app/shared-stores/event-params/event-params.model';
import { EventRequest, QueryParams } from '@app/pages/events/store/event.model';
import { IncidentsService } from '@app/pages/incidents/services/incidents.service';
import { DashboardService } from '@app/pages/dashboards/services/dashboard.service';
import * as eventParamsActions from '@app/shared-stores/event-params/event-params.actions';
import { TableComponent, TableHeader } from '@sweet-shared/components/table/table.component';
import * as userPreferenceActions from '@app/pages/user-preference/store/user-preference.actions';
import { QueryData } from '@app/pages/incidents/components/incidents-page/incidents-page.component';
import { RedirectModalComponent } from '@sweet-shared/components/redirect-modal/redirect-modal.component';
import { QueryBuilderComponent, RuleGroup } from '@sweet-shared/components/query-builder/query-builder.component';
import { HelpDialogModalComponent } from '@sweet-shared/components/help-dialog-modal/help-dialog-modal.component';
import { WidgetFormComponent } from '@sweet-shared/components/widgets/widget-forms/widget-form/widget-form.component';
import { FieldInterface, ActionButtonInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';
import { RefreshService } from '@sweet-shared/services/refresh.service';


@Component({
  selector: 'app-analysis-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent implements OnInit, OnDestroy {
  // Private destroyer used to kill all the subscriptions once the page is destroyed.
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  private actionEvent$: Subject<any> = new Subject();
  hide: BehaviorSubject<{ flag: boolean, typ: string }> = new BehaviorSubject({ flag: true, typ: '' });

  // Each user has a default date range that they set as their preferences. We need to access that
  // in other for us to fill the search component.
  defaultDateRange: string = null;

  // As all page, this will be the loader controller for each and every action(s) we do in this page.
  // By default, it will be set to false and will only be triggered when there is an action.
  loading = false;

  // Due to the fact we have super user(s) in the system, and also that searches are restricted to
  // particular user, we need to let the user choose the company in which he/she would like to
  // perform a search from. The list will be empty for a start and then will be populated when available.
  allCompanies: Company[] = [];

  // Date range is also something we need for the user to choose from. It will be passed down to the filter
  // component to populate the dropdown. Again it will be set to an empty list until populated.
  dataRanges: any[] = [];

  // Form Details
  searchFieldDetails: FieldInterface[] = null;
  @Output() paginationChange = new EventEmitter();


  // Search action buttons
  searchActionButtons: ActionButtonInterface = {
    flex: '',
    buttons: [
      {
        iconName: 'search',
        name: 'SEARCH',
        label: 'Search',
        color: 'primary',
        type: 'stroked'
      },
      {
        iconName: 'build',
        name: 'QUERY',
        label: 'Query Builder',
        color: 'primary',
        type: 'stroked',
        class: 'query-builder'
      }
    ],
  };

  // List of all the fields available
  eventFields: EventField[] = null;

  // Because the header is subject to change when the user decide to add/remove columns he/she does not need
  // we need to signal the table to update its headers. For that to be possible, we will use a BehaviorSubject
  eventsHeadersSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

  // Data Stream to use to populate the table is also subject to change without the need of reloading the table
  // for that to be possible we will be using a behaviorSubject.
  eventsDataStream: BehaviorSubject<any[]> = new BehaviorSubject([]);

  // Signal to use when the component is performing a search of not.
  searching: boolean;

  // loader subject
  loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  // since we need a reference to the search query we will need to store it in case the
  // user would like to go to the detail page
  queryStringParams: any = null;
  queryRuleGroups: RuleGroup[];
  queryRuleGroups$ = new BehaviorSubject<{ ruleGroups: RuleGroup[], data: string }>(null);

  // for modal window
  dialogRef: any;

  eventPageUrl: string = null;

  userPreferences: any;

  authUser: any;

  // to store ids of events that have already been sent to macaw during session
  alreadySentToMacaw: string[] = [];

  // Reference to formBuilder to pass to query dialog
  @ViewChild('searchEventsForm') searchEventsForm: FormBuilderComponent;
  @ViewChild('eventsTable') eventsTable: TableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource([]);

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };
  pathValues: EventEmitter<any> = new EventEmitter();
  previousIndex: number;
  private currentRoute;
  private previousRoute;

  counter$ = new Subject();
  counter = 0;
  refresh$ = new Subject();

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(
    private store: Store<State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private appService: AppService<any>,
    private incidentsService: IncidentsService,
    private permissionService: PermissionService,
    private snackbarService: SnackbarService,
    private companiesService: CompaniesService,
    private dateService: DateService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    public dialog: MatDialog,
    public renderer: Renderer2,
    public urlService: UrlService,
    public refreshSrv: RefreshService
  ) { }

  ngOnInit() {
    // next search or refresh countdown
    this.counter$.pipe(
      switchMap((minutes: number) => interval(1000).pipe(
        map(seconds => ({ minutes, seconds }))
      )),
      takeUntil(this.destroyed$),
    ).subscribe(pkg => {
      this.counter = pkg.minutes - pkg.seconds;
    })

    // handle logic to refresh search
    this.refresh$.
      pipe(
        switchMap((minutes: number) => {
          const t = minutes * 1000;
          this.counter$.next(minutes);
          return interval(t);
        }),
      ).subscribe(d => {
        const pkg = {
          "name": "SEARCH",
          data: { ...this.searchEventsForm?.form?.value }
        }

        this.searchEventsForm.actionEvents.emit(pkg);
      })


    this.eventPageUrl = this.activatedRoute.snapshot['_routerState'].url;

    // listen for a refresh signal and refresh
    this.refreshSrv?.refreshInterval$.pipe(
      filter((minutes: any) => !!minutes),
    ).subscribe(v => this.refresh$.next(v))

    // Setting up all listener(s) and variable needed for the page
    this.showHideDateTime();
    this.gettingSearchDefaultValues();

    this.authService.getAuthenticatedUser().pipe(filter(val => !!val), take(1))
      .subscribe(user => {
        this.authUser = user;
      });
    // subscribing to the result
    this.store.select(state => state.events.events).pipe(
      takeUntil(this.destroyed$))
      .subscribe((events: any[]) => {
        this.dataSource.data = events;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.urlService.registerEvents(events);

        if (!!events) {
          const rr = this.refreshSrv.refreshRate$.getValue();

          this.refresh$.next(this.refreshSrv.refreshRate$.getValue())
        }
      }
      );

    // Subscribing to the loading while searching for result.
    this.store.select(state => state.events.loading).pipe(
      takeUntil(this.destroyed$)).subscribe(loading => {
        this.searching = loading;
        this.loaderSubject.next(loading);
      }
      );

    this.queryRuleGroups$.pipe(
      filter(data => !!data)
    ).subscribe(val => {
      // set rule groups
      this.queryRuleGroups = val.ruleGroups;
      // save to local storage, so that when user uses copied url, query will be set in query-builder
      sessionStorage.setItem(`event-query`, JSON.stringify(val.ruleGroups));
      // set value in the form
      if (this.searchEventsForm) {
        this.searchEventsForm.form.controls.query.setValue(val.data);
        // add query to the route
        let dateFrom;
        let dateTo;
        if (this.searchEventsForm.form.controls.dateRange.value === 'custom') {
          [dateFrom, dateTo] = this.searchEventsForm.form.controls.dateTime.value;
        } else {
          ({ dateFrom, dateTo } = this.dateService.dataRange(this.searchEventsForm.form.controls.dateRange.value, this.dataRanges));
        }
        this.mergeWithQueryParams({
          qrg: JSON.stringify(this.queryRuleGroups),
          dateFrom,
          dateTo,
          dateRange: this.searchEventsForm.form.controls.dateRange.value,
          companyFilter: this.searchEventsForm.form.controls.companyFilter.value
        });
      }
    });

    // attached to the router params
    this.activatedRoute.queryParamMap.pipe(
      filter(data => !!data),
      take(1)
    ).subscribe((qsp: any) => {
      this.queryStringParams = qsp.params;
    });
    this.hide.subscribe(pkg => {
      if (!!this.searchFieldDetails) {
        this.searchFieldDetails = this.searchFieldDetails.map(s => {
          if (s.name === 'dateTime') {
            return { ...s, hide: pkg.flag };
          }
          return s;
        });
      }
    }
    );
    this.alreadySentToMacaw = JSON.parse(sessionStorage.getItem('sent_to_macaw')) || [];

    // Set route

    this.currentRoute = this.urlService.setCurrentUrl(this.router.url);
    this.previousRoute = this.urlService.getPreviousUrl();
    this.urlService.registerEventsHeader(this.eventsHeadersSubject);
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDragDrop<string[]>) {
    if (event) {
      const cols = this.eventsHeadersSubject.value;
      moveItemInArray(cols, event.previousIndex, event.currentIndex);
      this.eventsHeadersSubject.next(cols);
    }

  }

  isSticky(header: any): boolean {
    return header.friendly === 'Actions';
  }

  onContextMenu(event: MouseEvent, fieldName: string, value: string) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { fieldName, value };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  includeValue() {
    const { fieldName, value } = this.contextMenu.menuData;
    let queryBuilderValue = JSON.parse(sessionStorage.getItem('event-query') || 'null');
    const groupOption = {
      condition: 'AND',
      rules: [
        {
          field: fieldName,
          operator: '=',
          value
        }
      ]
    };
    if (!queryBuilderValue) {
      queryBuilderValue = [groupOption];
    } else {
      queryBuilderValue.push(groupOption);
    }

    this.queryRuleGroups$.next({ ruleGroups: queryBuilderValue, data: '' });
    this.pathValues.next({ query: QueryBuilderComponent.queryStringBuilder(queryBuilderValue, false) });
  }

  excludeValue() {
    const { fieldName, value } = this.contextMenu.menuData;
    let queryBuilderValue = JSON.parse(sessionStorage.getItem('event-query') || 'null');
    const groupOption = {
      condition: 'NOT',
      rules: [
        {
          field: fieldName,
          operator: '=',
          value
        }
      ]
    };
    if (!queryBuilderValue) {
      queryBuilderValue = [groupOption];
    } else {
      queryBuilderValue.push(groupOption);
    }

    this.queryRuleGroups$.next({ ruleGroups: queryBuilderValue, data: '' });
    this.pathValues.next({ query: QueryBuilderComponent.queryStringBuilder(queryBuilderValue, false) });
  }

  computeDisplayedCols(headers: any[]): string[] {
    const unsortedColumnHeaders = headers.map(header => header.name);
    const sortItems = new HeaderSortingService();
    const sorted = sortItems.sortList(unsortedColumnHeaders, 'events');
    return sorted;
  }

  // Helper function to update query params
  mergeWithQueryParams(queryParams: { [key: string]: string }) {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams
    });
  }

  showHideDateTime() {
    this.actionEvent$.subscribe(event => {
      if (event.data && event.data.field === 'dateRange') {
        this.hide.next({ flag: event.data.value !== 'custom', typ: event.data.value });
      }
    });
  }

  searchFilterActionHandler(event) {
    let eventRequest: EventRequest;
    this.actionEvent$.next(event);
    // For now there is only a single event name coming from the form which is the one
    // with name 'SEARCH'. So we will be filtering only for that in case we have another
    // event name
    switch (event.name) {
      case 'SEARCH':
        // Extract the event params
        const dRange = {
          dateFrom: event.data.dateRange.from,
          dateTo: event.data.dateRange.to
        };
        const queryParams: QueryParams = {
          size: 500,
          companyFilter: event.data.companyFilter,
          ...dRange
        };

        eventRequest = {
          query: this.queryRuleGroups || [],
          ...dRange
        };
        this.store.dispatch(eventActions.fetchEvents({ queryParams, eventRequest }));
        break;
      case 'VALUE_CHANGED':
        // just in case we need to listen to change in more than 1 cols we will be checking which col is changing
        if (event.data.field === 'selectedCols') {
          const eventsHeaders: TableHeader[] = this.eventFields.filter(evtField => event.data.value.includes(evtField.name));
          if (this.permissionService.hasPermission('analysis.run-search')) {
            // analysis.run-search will have to change when the appropriate permission is created.
            eventsHeaders.push({
              friendly: 'Actions',
              name: 'actions'
            });
          }
          this.eventsHeadersSubject.next(eventsHeaders);
        } else if (event.data.field === 'companyFilter') {
          this.mergeWithQueryParams({ companyFilter: event.data.value });
        } else if (event.data.field === 'dateRange') {
          const pkg = event?.data?.value;

          this.mergeWithQueryParams({ dateFrom: pkg.from, dateTo: pkg.to });
        } else if (event.data.field === 'dateTime') {
          if (event.data?.value?.length) {
            const [dateFrom, dateTo] = event.data.value;
            this.mergeWithQueryParams({ dateFrom, dateTo });
          }
        }
        break;
      case 'QUERY':
        this.openQueryBuilder();
        break;
      case 'CREATE_WIDGET':
        this.createWidgetDialog();
        break;
      default:
        break;
    }
  }

  filteredHeaders(headers): any[] {
    return headers.filter(header => header.friendly !== 'Actions');
  }

  cellActionsHandler(data: { action: string, data: any }): void {
    if (data.action === 'CREATE_INCIDENT') {
      this.onCreateIncident(data.data);
    }
  }

  createWidgetDialog() {
    this.dialogRef = this.matDialog.open(WidgetFormComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class'
    });
    this.dialogRef.componentInstance.loading = false;
    this.dialogRef.componentInstance.includeDashboardOptions = true;
    this.dialogRef.componentInstance.query = this.searchEventsForm.form.controls.query.value;
    this.dialogRef.componentInstance.categoryField = this.eventFields.map((c: any) => ({
      value: {
        friendly: c.friendly,
        name: c.name,
        type: c.type,
      },
      friendly: c.friendly,
    }));
    this.dialogRef.componentInstance.actions = [
      {
        value: 'count',
        friendly: 'Count',
        type: ['String', 'TimeStamp', 'FloatingPoint', 'IPv6 address', 'Long', 'IPv4 Address', 'MAC Address', 'Integer']
      },
      { value: 'avg', friendly: 'Average', type: ['FloatingPoint', 'Long', 'Integer'] },
      { value: 'min', friendly: 'Minimum', type: ['FloatingPoint', 'Long', 'Integer', 'TimeStamp'] },
      { value: 'max', friendly: 'Maximum', type: ['FloatingPoint', 'Long', 'Integer', 'TimeStamp'] },
      { value: 'sum', friendly: 'Sum', type: ['FloatingPoint'] }
    ];
    this.dialogRef.componentInstance.widgetDateRange = this.searchEventsForm.form.value.dateRange;
    this.dialogRef.componentInstance.eventDateRange = this.dataRanges;
    this.dialogRef.componentInstance.createWidgetEvent.pipe(takeUntil(this.destroyed$))
      .subscribe(event => {
        if (event.name === 'submit') {
          this.determineWidgetType(event.data);
        } else if (event.name === 'cancel') {
          this.dialogRef.close();
        }
      });
  }

  determineWidgetType(data) {
    if (data.widgetType === 'line' || data.widgetType === 'bar') {
      this.createCommonWidget(data);
    }
    if (data.widgetType === 'pie') {
      this.createPieWidget(data);
    }
    if (data.widgetType === 'single_metric') {
      this.createSingleMetricWidget(data);
    }
  }

  createCommonWidget(data) {
    const widgetData = this.dashboardService.commonWidgetPayload(
      data, data.dashboard, this.authUser, this.userPreferences.dateRange, this.dataRanges
    );
    this.createWidget(widgetData);
  }

  createSingleMetricWidget(data) {
    const widgetData = this.dashboardService.singleMetricPayload(data, data.dashboard, this.authUser);
    this.createWidget(widgetData);
  }

  createPieWidget(data) {
    const pieWidgetData = this.dashboardService.piePayload(data, data.dashboard, this.authUser, this.userPreferences.dateRange, this.dataRanges);
    this.createWidget(pieWidgetData);
  }

  createWidget(widgetData) {
    this.dialogRef.componentInstance.loading = true;
    this.appService
      .post('widgets', '', null, widgetData)
      .then((widget) => {
        this.dialogRef.close();
        this.dialogRef = this.matDialog.open(RedirectModalComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
          maxHeight: '90vh',
          panelClass: 'ctl-panel-class'
        });
        this.dialogRef.componentInstance.title = 'Widget Successfully Created';
        this.dialogRef.componentInstance.message = `Your widget has been successfully created.  To view your new widget click 'Go to Dashboard'.`;
        this.dialogRef.componentInstance.buttonText = 'Go to Dashboard';
        this.dialogRef.componentInstance.path = `dashboard/${widgetData.dashboard_id}`;
      })
      .catch((err) => {
        this.dialogRef.componentInstance.loading = false;
        this.snackbarService.open(err.message);
      });
  }

  private gettingSearchDefaultValues() {
    this.store.dispatch(userPreferenceActions.loadUserPreferences());
    this.store.dispatch(eventParamsActions.loadEventFields());
    this.store.dispatch(companyActions.loadCompanies());
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const defaultCompanyFilter = user.attributes.profile;
        const queryParams$ = this.activatedRoute.queryParamMap.pipe(
          filter(data => !!data),
          take(1)
        );
        const eventParams$ = this.store.select(state => state.eventParams).pipe(
          filter(val => !val.loading),
          takeUntil(this.destroyed$)
        );
        const userPreferences$ = this.store.select(state => state.userPreferences.userPreferences).pipe(
          filter(val => Object.keys(val).length > 0),
          takeUntil(this.destroyed$)
        );
        const companies$ = this.store.select(state => state.companies.companies).pipe(
          filter(c => !!c),
          takeUntil(this.destroyed$)
        );
        combineLatest([queryParams$, eventParams$, userPreferences$, companies$]).pipe(
          takeUntil(this.destroyed$)
        ).subscribe(combined => {
          // Extract all observable values and assign
          const [queryParams, evtParams, userPreferences, companies] = combined;
          this.eventFields = evtParams.eventParams.fields;
          this.userPreferences = userPreferences;
          this.allCompanies = companies;
          this.dataRanges = evtParams.eventParams.eventDateRanges;
          const params = queryParams['params'];
          if (params.qrg) {
            this.queryRuleGroups$.next({ ruleGroups: JSON.parse(params.qrg), data: '' });
          }
          // Build headers for table
          const eventsHeaders: TableHeader[] = this.eventFields.filter(evtField => userPreferences.searchFields.includes(evtField.name));
          if (this.permissionService.hasPermission('analysis.run-search')) {
            // analysis.run-search will have to change when the appropriate permission is created.
            eventsHeaders.push({
              friendly: 'Actions',
              name: 'actions'
            });
          }
          this.eventsHeadersSubject.next(eventsHeaders);
          let queryDefaultValue = null;
          if (params.qrg) {
            queryDefaultValue = QueryBuilderComponent.queryStringBuilder(JSON.parse(params.qrg), false);
          }
          // If the queryParams has data re: these fields, default value will be set to provided data
          this.searchFieldDetails = [
            {
              label: 'Date Range',
              component: 'input-datepicker',
              name: 'dateRange',
              placeholder: 'Date Range',
              flex: '',
              signalOnChanged: true,
              defaultValue: params?.dateRange || userPreferences.dateRange,
              validators: [Validators.required],
              options: this.dataRanges
            },
            {
              label: 'Company Filter',
              component: 'input-select',
              name: 'companyFilter',
              placeholder: 'Company Filter',
              flex: '',
              defaultValue: params?.companyFilter || defaultCompanyFilter,
              validators: [Validators.required],
              showSearch: true,
              showSearchFormControl: new FormControl(),
              options: this.companiesService.companyNameAndFriendly(companies),
              signalOnChanged: true
            },
            {
              label: 'Columns Selected',
              component: 'input-select',
              name: 'selectedCols',
              placeholder: 'Selected Columns',
              flex: '',
              showSearch: true,
              showSearchFormControl: new FormControl(),
              selectMultiple: true,
              defaultValue: userPreferences.searchFields,
              signalOnChanged: true,
              validators: [Validators.required],
              options: evtParams.eventParams.fields.map((c: EventField) => {
                return { value: c.name, friendly: c.friendly };
              })
            },
            {
              // label: 'Event Search. Ex: INC1141* or INC1141689',
              label: '',
              component: 'input-textarea',
              name: 'query',
              placeholder: 'Event Search. Ex: INC1141* or INC1141689',
              defaultValue: queryDefaultValue,
              flex: '100%',
              validators: [],
              disabled: true
            }
          ];
          setTimeout(() => this.searchEventsForm.form.controls.query.disable(), 1000);
        });
      }
      ).catch((err) => {
        this.router.navigate(['/']);
      });

  }

  onTableRowClick(event) {
    if (event.type === 'VIEW_CLICK') {
      const qsp = {
        ...this.queryStringParams,
        eventId: event.data.JCEF_evtUId,
        isoDate: event.data.ISODATE
      };
      if (this.permissionService.hasPermission('analysis.run-search')) {
        // analysis.run-search will have to change when the appropriate permission is created.
        this.router.navigate(['events', 'details'], { queryParams: qsp }).then(() => { });
      } else {
        this.snackbarService.open(`You do not have the required permission(analysis.run-search)`, 'Ok');
      }
      return;
    }

    if (event.type === 'MESSAGE_CLICK') {
      this.store.dispatch(eventActions.investigateEvent(event.data));

    }
  }

  openQueryBuilder() {
    const data: QueryData = {
      rules: [{ field: '', operator: '=', value: '' }],
      formatWithColon: false,
      page: 'event'
    };
    let sessionRules: any = sessionStorage.getItem(`${data.page}-query`);
    sessionRules = sessionRules ? JSON.parse(sessionRules) : sessionRules = [];
    (sessionRules[0] && sessionRules[0].rules && sessionRules[0].rules.length) ? data.ruleGroups = sessionRules : data.ruleGroups = [{ condition: 'AND', rules: data.rules }];
    const dialogRef = this.matDialog.open(QueryBuilderComponent, {
      disableClose: false,
      panelClass: 'ctl-panel-class',
      data
    });
    dialogRef.componentInstance.fields = this.eventFields;
    dialogRef.componentInstance.inputData = data;

    dialogRef.componentInstance.queryBody.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(event => {
      if (event.name === 'submit') {
        this.queryRuleGroups$.next(event);
        dialogRef.close();
      } else if (event.name === 'cancel') {
        dialogRef.close();
      } else if (event.name === 'RESET_QUERY') {
        this.queryRuleGroups$.next({ ruleGroups: [], data: '' });
      }
    });
  }

  onCreateIncident(row: any) {
    this.store.dispatch(incidentActions.loadIncidentAttributes());
    this.store.dispatch(companyActions.loadCompanies());
    const companies$ = this.store.select(state => state.companies.companies).pipe(
      take(1),
    );
    const incidentAttributes$ = this.store.select(state => state.incidents.incidentAttributes).pipe(
      filter(ia => !!ia && !ia.incidentAttributesLoading && Object.entries(ia).length > 0),
      take(1)
    );
    combineLatest(companies$, incidentAttributes$).pipe(
      take(1)
    ).subscribe(both => {
      const [companies, priorities] = both;
      // Shape company and priorities for form
      const companyOptions = companies.map(company => {
        return { value: company.id, friendly: company.name };
      });
      const priorityOptions = priorities.priority.map(attr => {
        return {
          value: attr,
          friendly: attr
        };
      });
      // Get shared create incident config from service
      const formConfig = this.incidentsService.getCreateIncidentForm(companyOptions, priorityOptions);
      let dialogRef: MatDialogRef<FormBuilderComponent> = this.matDialog.open(FormBuilderComponent, {
        disableClose: true
      });
      // Find index of company field in formConfig
      const companyFieldIndex = formConfig.incidentFormDetails.findIndex(field => {
        return field.name === 'customerId';
      });
      // Limit company options to  only company of event
      const newCompanyField: FieldInterface = {
        label: 'Company',
        component: 'input-select',
        name: 'customerId',
        placeholder: 'Company',
        flex: '100%',
        selectMultiple: false,
        defaultValue: row.CUSTOMERID,
        validators: [Validators.required],
        options: [companyOptions.find(company => {
          return company.value === row.CUSTOMERID;
        })],
        disabled: true
      };
      // Replace field from service with new field
      formConfig.incidentFormDetails.splice(companyFieldIndex, 1, newCompanyField);

      dialogRef.componentInstance.actionButtons = formConfig.formButtons;
      dialogRef.componentInstance.fieldDetails = formConfig.incidentFormDetails;
      dialogRef.componentInstance.title = formConfig.title;
      dialogRef.componentInstance.loading = false;
      dialogRef.componentInstance.actionEvents.subscribe(event => {
        switch (event.name) {
          case 'CANCEL':
            dialogRef.close();
            dialogRef = null;
            break;
          case 'CREATE_INCIDENT':
            this.createIncident(event.data, row, dialogRef);
            break;
          default:
            break;
        }
      });
    });

  }

  private createIncident(data: any, row: any, dialogRef: any) {
    const tableMarkdown = this.incidentsService.makeMdTableFromRow(row, this.eventsHeadersSubject.value);
    data.message += tableMarkdown;
    data.customerId = row.CUSTOMERID;
    this.incidentsService.createIncident(dialogRef, data, this.userPreferences);
  }

  async onSendToMacaw(row) {
    // Get already sent ids
    const alreadySent = JSON.parse(sessionStorage.getItem('sent_to_macaw')) || [];
    // add id of newly sent
    alreadySent.push(row.JCEF_evtUId);
    // put in session storage
    sessionStorage.setItem('sent_to_macaw', JSON.stringify(alreadySent));
    // update instance variable for template to disable button
    this.alreadySentToMacaw = alreadySent;
    try {
      await this.appService.post('investigation', `${row.JCEF_evtUId}/investigate`, null, row);
      this.snackbarService.open('Event sent to automated analysis');
    } catch (err) {
      if (err.status === '400') {
        this.snackbarService.open('Event has already been submitted');
      } else {
        this.snackbarService.open('Failed to process event, please try again later');
        // take out previously added id
        const takeOutFailed = alreadySent.filter(id => id !== row.JCEF_evtUId);
        // correct session storage
        sessionStorage.setItem('sent_to_macaw', JSON.stringify(takeOutFailed));
        // update instance variable to enable button
        this.alreadySentToMacaw = takeOutFailed;
      }
    }
  }

  onHelpClick() {
    // Opens up the Incident KB dialog with how to article
    const dialogRef = this.dialog.open(HelpDialogModalComponent, { data: { articleTitle: 'Events Section Overview...' } });
  }

  downloadAsCSV() {
    const date = new Date().toLocaleDateString();
    const dateRange = this.searchEventsForm.form.value.dateRange;
    const format = this.eventsTable.formatForCSVDownload();
    const csv = new Angular5Csv(format.data, `events-${date}-${dateRange}`, format.options);
  }

  onAddWorklog(row): void {
    const activeId = this.userPreferences.activeInvestigation;
    if (activeId) {
      this.store.dispatch(incidentActions.getIncident({ incidentId: activeId }));
      this.store.select(state => state.incidents)
        .pipe(
          filter(val => !!val && !!val.selectedIncident),
          pluck('selectedIncident'),
          take(1)
        )
        .subscribe(selectedIncident => {
          if (row.CUSTOMERID !== selectedIncident.company) {
            this.snackbarService.open('Unable to add worklog. Event and incident companies do not match.');
            return false;
          }
          const tableMarkdown = '\n\nEvent Unique ID: ' + row.JCEF_evtUId + this.incidentsService.makeMdTableFromRow(row, this.eventsHeadersSubject.value);
          this.incidentsService.addWorklog(selectedIncident, tableMarkdown);
        });
    }
  }

  handlePaginationChange(changes: any) {
    this.paginationChange.emit({
      type: 'paginationChange',
      payload: changes
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
