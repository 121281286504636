import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FilterAction, IndexField, SearchPayload, SearchResponse } from '@modules/events/interfaces/events';
import { EventsService } from '@modules/events/services/events.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import { UrlService } from '@shared-services/url.service';
import { PermissionService } from '@sweet-shared/services/permission.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CreateIncidentComponent } from '../create-incident/create-incident.component';

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss']
})
export class EventPageComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroyer$ = new Subject();
  searching = false;
  error: string = null;
  macawId: string[] = [];
  dateRange: any = null;

  // Table data
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selectedColumns: IndexField[] = [];
  sortColumns: any[] = [];
  availableColumns: any[] = [];
  displayedColumns: string[] = [];
  contextMenuPosition = { x: '0px', y: '0px' };
  alreadySent: string[] = [];
  filters: any = null;

  // Pagination and Sorting
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  // Column manager controller
  columnManager = new EventEmitter();
  previousUrl: string = null;

  constructor(
    private eventService: EventsService,
    private permissionService: PermissionService,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private urlService: UrlService
  ) { }

  ngOnInit(): void {
    this.alreadySent = JSON.parse(sessionStorage.getItem('sent_to_macaw')) || [];

    // subscribe to the response
    this.eventService.eventResults$.pipe(takeUntil(this.destroyer$)).subscribe(res => {
      this.dataSource.data = res;
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.eventService.sendEvent({ name: 'SEARCHING', data: false });
      });
    });

    // Error
    this.eventService.error$.pipe(takeUntil(this.destroyer$)).subscribe(err => {
      this.error = err;
      this.eventService.sendEvent({ name: 'SEARCHING', data: false });
    });

    // searching
    this.eventService.searching$.pipe(takeUntil(this.destroyer$)).subscribe(val => this.searching = val);

    // event filter from cache if any
    this.eventService.eventFilters$.pipe(takeUntil(this.destroyer$)).subscribe(val => {
      if (val) {
        this.router.navigate([], {
          queryParamsHandling: 'merge',
          queryParams: val
        }).then(() => {
          this.urlService.setCurrentUrl(this.router.url);
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  performSearch(payload: SearchPayload): void {
    this.eventService.setSearching(true);
    this.eventService.sendEvent({ name: 'SEARCHING', data: true });
    this.filters = payload;
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: payload
    }).then(() => {
      this.urlService.setCurrentUrl(this.router.url);
    });
    this.eventService.search(payload).pipe(takeUntil(this.destroyer$)).subscribe({
      next: (res: SearchResponse) => this.pullResponse(res.id, payload.companyFilter),
      error: (err) => {
        this.error = err.message;
        this.eventService.setSearching(false);
        this.eventService.sendEvent({ name: 'SEARCHING', data: false });
      }
    })
  }

  canSendToMacaw(element): boolean {
    return !this.alreadySent.includes(element.jcef_evtuid);
  }

  actionHandler(evt: FilterAction): void {
    const { name, data } = evt;
    if (name === 'SEARCH') {
      this.error = null;
      this.performSearch(data);
    } else if (name === 'UPDATE_FIELD') {
      this.selectedColumns = data.headers.map(key => this.availableColumns.find(item => item.value === key));
      this.sortColumns = data?.sort_by ?? [];
    } else if (name === 'COLUMN') {
      if (this.selectedColumns.length === 0) {
        this.selectedColumns = data.map(key => this.availableColumns.find(item => item.value === key));
      }
    } else if (name === 'AVAILABLE_COLUMNS') {
      // update availbale cols
      this.availableColumns = data;
    }

    this.displayedColumns = ['view', ...this.selectedColumns.map(col => col.value.toLowerCase())];
  }

  pullResponse(queryId: string, companyFilter: string): void {
    this.eventService.searchResults(queryId, companyFilter)
      .pipe(takeUntil(this.destroyer$)).subscribe({
        next: (res) => {
          if (res && res.url) {
            this.eventService.fetchDataFromS3(res.url);
          }
        },
        error: (err) => {
          this.error = err.message;
        }
      })
  }

  onContextMenu(event: MouseEvent, item: any, columnId: string) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': { columnId, item } };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onIncludeValue(value): void {
    const { columnId, item } = value;
    let queryBuilderValue = JSON.parse(sessionStorage.getItem('event-query') || 'null');
    const groupOption = {
      condition: 'AND',
      rules: [
        {
          field: columnId,
          operator: '=',
          value: item
        }
      ]
    };
    if (!queryBuilderValue) {
      queryBuilderValue = [groupOption];
    } else {
      queryBuilderValue.push(groupOption);
    }
    sessionStorage.setItem('event-query', JSON.stringify(queryBuilderValue));
    this.eventService.sendEvent({ name: 'UPDATED_QUERY_FILTER', data: queryBuilderValue });
  }

  onExcludeValue(value): void {
    const { columnId, item } = value;
    let queryBuilderValue = JSON.parse(sessionStorage.getItem('event-query') || 'null');
    const groupOption = {
      condition: 'NOT',
      rules: [
        {
          field: columnId,
          operator: '=',
          value: item
        }
      ]
    };
    if (!queryBuilderValue) {
      queryBuilderValue = [groupOption];
    } else {
      queryBuilderValue.push(groupOption);
    }
    sessionStorage.setItem('event-query', JSON.stringify(queryBuilderValue));
    this.eventService.sendEvent({ name: 'UPDATED_QUERY_FILTER', data: queryBuilderValue });
  }

  inMacawFly(element): boolean {
    return this.macawId.includes(element.jcef_evtuid);
  }

  sendToMacaw(element): void {
    this.macawId.push(element.jcef_evtuid);
    this.eventService.sentToMacaw(element).pipe(takeUntil(this.destroyer$), finalize(() => this.macawId = this.macawId.filter(id => id !== element.jcef_evtuid))).subscribe({
      next: (res) => {
        this.alreadySent.push(element.jcef_evtuid);
        sessionStorage.setItem('sent_to_macaw', JSON.stringify(this.alreadySent));
        this.snackbarService.open('Event sent to automated analysis');
      },
      error: (err) => {
        if (err.status === '400') {
          this.snackbarService.open('Event has already been submitted');
        } else {
          this.snackbarService.open('Failed to process event, please try again later');
        }
      }
    });

  }

  eventDetails(element): void {
    const qsp = {
      ...this.filters,
      eventId: element.jcef_evtuid,
      isoDate: element.isodate,
    };

    if (this.permissionService.hasPermission('analysis.run-search')) {
      this.router.navigate(['events', 'details'], { queryParams: qsp });
    } else {
      this.snackbarService.open(`You do not have the required permission(analysis.run-search)`, 'Ok');
    }
  }

  showColumnManagement(): void {
    this.columnManager.emit();
  }

  createIncident(element): void {
    this.dialog.open(CreateIncidentComponent, {
      disableClose: true,
      width: '80%',
      data: {
        company: this.filters.companyFilter,
        element
      }
    }).afterClosed().subscribe(val => {
      if (val) {
        this.router.navigate(['/incidents/incident', val.sys_id]);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
