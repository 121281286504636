<mat-card class="card_padding">
  <div class="container">
    <mat-card-title>
      {{title}}
    </mat-card-title>

    <mat-card-content>
      {{message}}
    </mat-card-content>

    <div fxLayout="row" fxLayoutAlign="end center" class="action-buttons">
      <button mat-flat-button color="warn" (click)='onConfirm()'>Yes</button>
      <button mat-stroked-button [mat-dialog-close]='true' (click)="onDismiss()">No</button>
    </div>
  </div>
</mat-card>
