import { AuthService } from '../../../auth/services/auth.service';
import {UserPreference} from '../../../user-preference/store/user-preference.model';
import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {Incident} from '../../store/incident.model';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from '../../../../reducers';
import * as fromIncident from '../../store/incident.actions';
import * as fromCompany from '../../../admin/store/company.actions';
import * as fromUser from '../../../admin/store/user/user.actions';
import * as fromUserPreference from '../../../user-preference/store/user-preference.actions';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {takeUntil, take, filter} from 'rxjs/operators';
import {Location} from '@angular/common';
import {Auth} from 'aws-amplify';
import {EventType} from 'src/app/sweet-shared/components/incident-display/incident-display.component';
import {Company} from '../../../admin/store/company.model';
import {User} from '../../../admin/store/user/user.model';
import {UsersService} from '../../../../shared-services/users.service';
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit, OnDestroy {
  id: string;
  incidentAssigning = false;
  loaded = false;
  incidentSubject: BehaviorSubject<Incident> = new BehaviorSubject(null);
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  incident: Incident = null;
  currentUser: string = null;
  incidentDataSource: BehaviorSubject<any[]> = new BehaviorSubject([]);
  loading = false;
  loadingWorklogs = false;
  incidentAssignLoading = false;
  incidentAssignError: string = null;
  destroyer$: ReplaySubject<any> = new ReplaySubject(null);

  classificationOptions: any[] = [];
  tierOptions: any[] = [];
  priorityOptions: any[] = [];
  stateOptions: any[] = [];
  closureStateOptions: any[] = [];
  companies: any[] = [];
  users: any[] = [];
  userPreferences: UserPreference = null;

  updating = false;
  updatingError: string = null;
  showIncidentDisplayDetailsButton = false;

  incidentWorklogs: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<State>,
    private location: Location,
    private authService: AuthService,
    private usersService: UsersService,
  ) {
    store.select(state => state.incidents.selectedIncident)
      .pipe(takeUntil(this.destroyer$)).subscribe((inc) => {
      if (inc) {
          this.incidentSubject.next(inc);
          this.incident = inc;

          this.incidentDataSource.next([inc]);
          this.fetchWorkOrder(inc.sys_id);

          this.authService.getAuthenticatedUser().pipe(
            takeUntil(this.destroyer$),
            take(1)
          ).subscribe(loggedInUser => {
            const companyFilters = [loggedInUser.profile];
            if (loggedInUser.profile !== inc.company) {
              companyFilters.push(inc.company);
            }
            this.store.dispatch(fromUser.loadUsers({companyFilter: companyFilters}));
          });
        }
      }
    );
    store.select(state => state.userPreferences.userPreferences)
      .pipe(takeUntil(this.destroyer$)).subscribe(userPrefs => this.userPreferences = userPrefs);

    store.select(state => state.incidents.loadingSelectedIncident)
      .pipe(takeUntil(this.destroyer$)).subscribe(loading => {
        this.loading = loading;
        this.loadingSubject.next(this.updating || this.loading);
      }
    );

    store.select(state => state.incidents.worklogsLoading)
      .pipe(takeUntil(this.destroyer$)).subscribe(val => this.loadingWorklogs = val);

    store.select(state => state.incidents.incidentAssigning)
      .pipe(takeUntil(this.destroyer$)).subscribe(val => this.incidentAssignLoading = val);

    store.select(state => state.incidents.incidentAssigningError)
      .pipe(takeUntil(this.destroyer$)).subscribe(val => this.incidentAssignError = val);

    Auth.currentUserInfo().then(userInfo => {
      if (userInfo && userInfo.hasOwnProperty('username')) {
        this.currentUser = userInfo.username;
        this.loaded = true;
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(paramMap => {
      if (paramMap.id) {
        this.id = paramMap.id;
        this.store.dispatch(fromIncident.getIncident({incidentId: paramMap.id}));
        this.setupPage();
      }
    });
  }

  private setupPage() {
    // Pull all information and details from the store needed for this page to render properly.
    this.store.dispatch(fromIncident.loadIncidentAttributes());
    this.store.select(state => state.incidents.incidentAttributes).pipe(takeUntil(this.destroyer$)).subscribe(res => {
      if (res && res.hasOwnProperty('classification')) {
        this.classificationOptions = res.classification.map((c) => {
          return {
            friendly: c,
            value: c
          };
        });
      }

      if (res && res.hasOwnProperty('state')) {
        this.stateOptions = res.state.map((c) => {
          return {
            friendly: c,
            value: c
          };
        });
      }

      if (res && res.hasOwnProperty('closureState')) {
        this.closureStateOptions = res.closureState.map((c) => {
          return {
            friendly: c,
            value: c
          };
        });
      }

      if (res && res.hasOwnProperty('priority')) {
        this.priorityOptions = res.priority.map((c) => {
          return {
            friendly: c,
            value: c
          };
        });
      }

      if (res && res.hasOwnProperty('tier')) {
        this.tierOptions = res.tier.map((c) => {
          return {
            friendly: c,
            value: c
          };
        });
      }
    });

    this.store.dispatch(fromCompany.loadCompanies());
    this.store.select(state => state.companies.companies).pipe(
      filter(val => !!val),
      takeUntil(this.destroyer$)
    ).subscribe((res: Company[]) => {
      this.companies = res.map((c, index) => {
        return {
          friendly: c.name,
          value: c.id
        };
      });
    });

    this.authService.getAuthenticatedUser().pipe(
      take(1),
      takeUntil(this.destroyer$)
    ).subscribe(u => {
      this.store.dispatch(fromUser.loadUsers({companyFilter: [u.profile]}));
      this.store.select(state => state.users.users).pipe(
        filter(val => !!val),
        takeUntil(this.destroyer$)
      ).subscribe((res: User[]) => {
        this.users = this.usersService.groupUsersByCompany(res);
      });
    });

    this.store.dispatch(fromUserPreference.loadUserPreferences());

    this.store.select(state => state.userPreferences.updating)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(res => {
          this.updating = res;
          this.loadingSubject.next(res);
        }
      );

    this.store.select(state => state.incidents.incidentAssigning).pipe(takeUntil(this.destroyer$))
      .subscribe(res => {
          this.incidentAssigning = res;
        }
      );

    // Incident update
    this.store.select(state => state.incidents.updating)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(updating => {
          this.updating = updating;
          this.loadingSubject.next(updating);
        }
      );

    this.store.select(state => state.incidents.updateError)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(error => this.updatingError = error);

    this.store.select(state => state.incidents.incidents).pipe(
      takeUntil(this.destroyer$)
    ).subscribe(incidents => {
      let incident = null;
      if (incidents && incidents.length > 0) {
        incident = incidents.find(inc => this.id && inc.sys_id === this.id);
      }

      if (incident) {
        this.incident = incident;
      }
    });

  }

  computeIncidentGrade(incident: Incident): string {
    if (incident.priority.includes('1')) {
      return environment.gradeColors.grade1;
    } else if (incident.priority.includes('2')) {
      return environment.gradeColors.grade2;
    } else if (incident.priority.includes('3')) {
      return environment.gradeColors.grade3;
    } else if (incident.priority.includes('4')) {
      return environment.gradeColors.grade4;
    } else if (incident.priority.includes('5')) {
      return environment.gradeColors.grade5;
    } else {
      return '';
    }
  }

  fetchWorkOrder(incidentId: string) {
    // Here we only get the worklogs if there is nothing worklogs present
    if (this.incident && this.incident.worklogs) {
      return;
    }
    this.store.dispatch(fromIncident.loadWorklog({incidentId}));
  }

  makeActive(incident: Incident, data: any, activate: boolean) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const params = {
          incidentId: incident.sys_id,
          value: {
            ...data,
            u_unlisted_affected_user: user.username
          },
          userPreferences: activate ? {
            ...this.userPreferences,
            activeInvestigation: incident.sys_id,
            owner_id: user.attributes.sub
          } : null
        };
        this.store.dispatch(fromIncident.updateIncident({...params}));
      })
      .catch(error => {
        // Here the user is not authenticated.
        // TODO - Vico please decide what to do here.
      });
  }

  dismiss(incident: Incident) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const userPrefs = {...this.userPreferences, activeInvestigation: '-', owner_id: user.attributes.sub};
        this.store.dispatch(fromUserPreference.dismissUserPreferences({userPreferences: userPrefs, notify: true}));
      })
      .catch(error => {
        // Here the user is not authenticated.
        // TODO - Vico please decide what to do here.
      });
  }

  incidentActionHandler(event: EventType) {
    switch (event.type) {
      case 'DISMISS':
        this.dismiss(event.incident);
        break;
      case 'ASSIGN_SELF_AND_MAKE_ACTIVE':
        // this.assignAndMakeActive(event.incident);
        this.makeActive(event.incident, event.data, true);
        break;
      case 'UPDATE':
        this.store.dispatch(fromIncident.updateIncident({incidentId: event.incident.sys_id, value: event.data}));
        break;
      default:
        // action not supported.
        break;
    }
  }

  formatWorklogValue(value: string): string {
    if (!value) {
      return;
    }
    // remove underscores
    let newValue = value.replace(/_/g, ' ');
    // gets first segment of markdown, so that its not all in the header
    const thereIsAColon = newValue.indexOf(':') > -1;
    if (thereIsAColon) {
      newValue = `${newValue.slice(0, newValue.indexOf(':'))}...`;
    } else if (!thereIsAColon && (newValue.length > 20)) {
      newValue = `${newValue.slice(0, 20)}...`;
    }
    return newValue;
  }

  ngOnDestroy() {
    this.store.dispatch(fromIncident.clearSelectedIncident());
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }

}
