import { Component, Input, OnInit } from '@angular/core';
import { EnvironmentService } from '@app/environment/environment.service';

@Component({
  selector: 'app-pivot-table',
  templateUrl: './pivot-table.component.html',
  styleUrls: ['./pivot-table.component.scss']
})
export class PivotTableComponent implements OnInit {

  @Input() pivotData: any[] = [];
  @Input() pivotHeaders: any[] = [];
  report: any;
  key: string;

  constructor(
    private envService: EnvironmentService
  ) { 
  }

  ngOnInit(): void {
    this.key = this.envService.flexmonsterLicense;
    this.report = {
      dataSource: {
        data: this.getData(),
        mapping: {
          "Date": {
            "type": "year/month/day"
          }
        }
      },
      slice: {
        rows: [{
            uniqueName: "JCEF_srcIP",
            caption: "Source IP"
          }
        ],
        columns: [
        {
          uniqueName: "Date"
        },
        {
          uniqueName: "JCEF_dstIP",
          caption: "Destination IP"
        },
        {
          uniqueName: "[Measures]"
        }
      ],
        measures: [{
          uniqueName: "JCEF_evtBytes",
          caption: "Event Bytes",
          aggregation: "sum"
        }]
      }
    }
    
  }

  customizeToolbar(toolbar: Flexmonster.Toolbar) {
    toolbar.showShareReportTab = false;
    let tabs = toolbar.getTabs(); 
    toolbar.getTabs = () => {
        // remove the Connect tab using its id
        tabs = tabs.filter(tab => tab.id != "fm-tab-connect");
        return tabs; 
    }
  }

  getData() {
    const headers = this.pivotHeaders.map(items => items.name)  
    let reduce_data = []; 
    this.pivotData.forEach(data => {
      const allKeys = Object.keys(data);
      const result = allKeys.reduce((next, key) => {
        if (headers.includes(key)) {
          return { ...next, [key]: data[key] };
        } else {
          return next;
        }
      }, {});
      reduce_data.push(result)
    });
    // Format date and time for better grouping
    const output = reduce_data.map(a => {
      let [Date, Time] = a.ISODATE.split(" ");
      return { ...a, Date, Time }
    })
    return output;
  }
  

}
