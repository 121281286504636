import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as _moment from 'moment';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';


import { State } from '@app/reducers';
import { Profile } from '@app/pages/profile/store/profile.model';
import { SnackbarService } from '@shared-services/snackbar.service';
import { EventParams } from '@app/shared-stores/event-params/event-params.model';
import { UserPreference } from '@app/pages/user-preference/store/user-preference.model';
import * as eventParamsActions from '@app/shared-stores/event-params/event-params.actions';
import * as preferenceActions from '@app/pages/user-preference/store/user-preference.actions';
import {
  ActionButtonInterface,
  FieldInterface, FormBuilderComponent
} from '@sweet-shared/components/form-builder/form-builder.component';
import { AuthService } from '@app/pages/auth/services/auth.service';
import { countryCodeOptions } from '@app/pages/user-preference/countryCodes';
import { DatetimeService } from '@services/datetime.service';


@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss']
})

export class UserPreferenceComponent implements OnInit, OnDestroy {
  destroyer$: Subject<void> = new Subject<void>();
  userPreference: UserPreference = null;
  userProfile: Profile = null;
  loading = true;
  updating = false;
  error: string = null;
  mfaEnabled = false;
  mfaLoading = true;
  enableMFAInput = false;
  newDate = new Date();
  preferredFieldForm: FormGroup;
  changePasswordLoading = false;
  changePasswordForm: FormGroup;
  dialogRef: any = null;
  tempEvent: any;
  searchText = '';
  loading$: Observable<boolean>;


  // Event Fields
  eventParams: EventParams = null;

  // MSG CONSTANTS
  MESSAGES = {
    updateUserPreference: {
      success: 'Update Successful!',
      error: 'Could not update. Please try again later.'
    },
    toggleMFA: {
      success: {
        on: 'MFA Enabled',
        off: 'MFA Disabled'
      },
      error: 'Could not update MFA Status. Please try again later.'
    },
    submitPasswordChange: {
      success: 'Successfully Changed Password!',
      error: 'Could not update password. Please try again later.'
    }
  };

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private snackBarService: SnackbarService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public datetimeService: DatetimeService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.store.select(state => state.userPreferences.loading)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(val => this.loading = val);

    this.store.select(state => state.userPreferences.updating)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(val => this.updating = val);

    this.store.select(state => state.userPreferences.errorLoading)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(val => this.error = val);

    this.store.select(state => state.userPreferences.userPreferences)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(val => {
        this.userPreference = val;
        this.preferredFieldForm = this.generatePreferredFieldForm(val);
      });

    this.store.select(state => state.profile.currentUserProfile)
      .subscribe(value => {
        this.userProfile = value;
        this.store.dispatch(preferenceActions.loadUserPreferences());
      });

    this.store.dispatch(eventParamsActions.loadEventFields());
    this.loading$ = this.store.select(state => state.eventParams.loading);
    this.store.select(state => state.eventParams.eventParams)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(val => this.eventParams = val);

    // get the MFA status
    this.getMfaStatus(null);

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required, Validators.minLength(1)]],
      newPassword: ['', [Validators.required, Validators.minLength(14)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(14)]]
    });

  }

  dateRangeComparison(option, value): boolean {
    return option.from === value.from && option.to === value.to && option.type === value.type;
  }

  updateUserPreference() {
    if (this.preferredFieldForm.valid && this.preferredFieldForm.touched) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          const userPreferences: UserPreference = {
            ...this.userPreference,
            ...this.preferredFieldForm.value
          };

          // make sure we have something for activeInvestigations
          if (!userPreferences.hasOwnProperty('activateInvestigation')) {
            userPreferences.activeInvestigation = '-';
          }
          // update cache for event dateRange
          sessionStorage.setItem('event-dateRange', JSON.stringify(userPreferences.dateRange));
          this.store.dispatch(preferenceActions.updateUserPreferences({ userPreferences, notify: true }));
        })
        .catch(error => {
          this.snackBarService.open(this.MESSAGES.updateUserPreference.error);
        });
    }
  }

  private getMfaStatus(error: string) {
    this.mfaLoading = true;
    this.error = error;
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.getPreferredMFA(user)
          .then(preferredMFA => {
            this.mfaEnabled = preferredMFA === 'SMS_MFA';
            this.mfaLoading = false;
          })
          .catch(err => {
            this.mfaLoading = false;
            this.error = 'Could not retrieve MFA Status. Please try again later.';
            this.snackBarService.open('Could not update MFA Status. Please try again later.');
          });
      })
      .catch(err => {
        this.mfaLoading = false;
        this.error = 'Could not retrieve MFA Status. Please try again later.';
        this.snackBarService.open('Could not update MFA Status. Please try again later.');
      });
  }

  ngOnDestroy() {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  toggleMFAStatus(status) {
    if (status.checked) {
      this.toggleMFA('SMS');
    } else {
      this.toggleMFA('NOMFA');
    }
  }

  private toggleMFA(mfaMethod: 'TOTP' | 'SMS' | 'NOMFA') {
    this.mfaLoading = true;
    this.error = null;
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.setPreferredMFA(user, mfaMethod)
          .then(mfaSetRes => {
            if (mfaMethod !== 'NOMFA' && mfaSetRes === 'SUCCESS') {
              this.snackBarService.open(this.MESSAGES.toggleMFA.success.on);
            } else {
              this.snackBarService.open(this.MESSAGES.toggleMFA.success.off);
            }
            this.getMfaStatus(null);
            this.mfaLoading = false;
          })
          .catch(err => {
            this.mfaLoading = false;
            this.getMfaStatus(this.MESSAGES.toggleMFA.error);
            this.snackBarService.open(this.MESSAGES.toggleMFA.error);
          });
      })
      .catch(err => {
        this.mfaLoading = false;
        this.getMfaStatus(this.MESSAGES.toggleMFA.error);
        this.snackBarService.open(this.MESSAGES.toggleMFA.error);
      });
  }

  private generatePreferredFieldForm(preferredField: UserPreference) {
    return this.fb.group({
      searchFields: [preferredField.searchFields, []],
      searchRefreshInterval: [preferredField.searchRefreshInterval, []],
      dateRange: [preferredField.dateRange, []],
      dateFormat: [preferredField.dateFormat, []]
    });
  }

  showDateFormat(format) {
    const date = new Date();
    return _moment(date).format(format);
  }

  submitPasswordChange(changePasswordForm) {
    this.changePasswordLoading = true;
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, changePasswordForm.value.currentPassword, changePasswordForm.value.confirmPassword);
      })
      .then(() => {
        this.changePasswordLoading = false;
        this.snackBarService.open(this.MESSAGES.submitPasswordChange.success);
        this.changePasswordForm.reset();
      })
      .catch(err => {
        this.changePasswordLoading = false;
        this.snackBarService.open(this.MESSAGES.submitPasswordChange.error);
      });
  }

  enableMfa() {
    const buttons: ActionButtonInterface = {
      flex: 'end center',
      buttons: [
        {
          label: 'Cancel',
          iconName: null,
          name: 'CANCEL',
          color: 'warn',
          type: 'basic'
        },
        {
          label: 'Submit',
          iconName: null,
          name: 'CREATE',
          color: 'primary',
          type: 'stroked'
        }
      ]
    };
    const mfaEnablingField: FieldInterface[] = [
      {
        name: 'CountryCode',
        type: 'tel',
        component: 'input-select',
        placeholder: 'Country Code',
        label: 'Country Code',
        flex: '100%',
        defaultValue: null,
        validators: [Validators.required],
        signalOnChanged: true,
        options: countryCodeOptions.map(c => {
          return {
            value: c.value,
            friendly: `${c.name}  ${c.value}`
          };
        })
      },
      {
        name: 'phoneNumber',
        type: 'tel',
        component: 'input-text',
        placeholder: 'Phone Number',
        label: 'Phone Number',
        flex: '100%',
        defaultValue: null,
        validators: [Validators.required],
        signalOnChanged: true
      },
    ];
    this.dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '30%',
      minHeight: '100px',
      disableClose: true
    });
    this.dialogRef.componentInstance.actionButtons = buttons;
    this.dialogRef.componentInstance.fieldDetails = mfaEnablingField;
    this.dialogRef.componentInstance.title = 'Enter a Phone Number';
    this.dialogRef.componentInstance.actionEvents.subscribe(event => {
      if (event.name === 'CREATE') {
        this.tempEvent = event;
      }
      switch (event.name) {
        case 'CREATE':
          this.authService.getMfaNumber(`${event.data.CountryCode}${event.data.phoneNumber}`);
          this.dialogRef.close();
          this.verificationForm();
          break;
        case 'CANCEL':
          this.dialogRef.close();
          this.dialogRef = null;
          break;
      }
    });
  }

  verificationForm() {
    const buttons: ActionButtonInterface = {
      flex: 'end center',
      buttons: [
        {
          label: 'Cancel',
          iconName: null,
          name: 'CANCEL',
          color: 'warn',
          type: 'basic'
        },
        {
          label: 'Resend Confirmation Code',
          iconName: null,
          name: 'RESEND',
          color: '',
          type: 'basic'
        },
        {
          label: 'Confirm Number',
          iconName: null,
          name: 'CONFIRM',
          color: 'primary',
          type: 'stroked'
        },
      ]
    };
    const verificationForm: FieldInterface[] = [
      {
        name: 'verificationNumber',
        type: 'text',
        component: 'input-text',
        placeholder: 'Verification Number',
        label: 'Verification Number',
        flex: '100%',
        defaultValue: null,
        signalOnChanged: true
      }
    ];
    this.dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '30%',
      minHeight: '100px',
      disableClose: true
    });
    this.dialogRef.componentInstance.fieldDetails = verificationForm;
    this.dialogRef.componentInstance.actionButtons = buttons;
    this.dialogRef.componentInstance.title = 'Enter Your Verification Code';
    this.dialogRef.componentInstance.actionEvents.subscribe(event => {

      switch (event.name) {
        case 'CONFIRM':
          // this.verifyAttribute(event.data.verificationNumber)
          this.authService.verifyAttribute(event.data.verificationNumber);
          this.dialogRef.close();
          break;
        case 'RESEND':
          this.authService.getMfaNumber(`${this.tempEvent.data.CountryCode}${this.tempEvent.data.phoneNumber}`);
          break;
        case 'CANCEL':
          this.dialogRef.close();
          this.dialogRef = null;
          break;
      }
    });

  }

  // assign input search value to a global variable. this is used for the pipe component
  onTextInput(value) {
    this.searchText = value;
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

}
