import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../guards/auth-gaurd.service';
import {PermissionsGuardService} from '../../guards/permissions-guard.service';
import { ThreatHuntingDetailsComponent } from './components/threat-hunting-details/threat-hunting-details.component';


const routes: Routes = [
  {
    path: 'threat-hunting',
    canActivate: [AuthGuardService, PermissionsGuardService],
    data: {
      requiredPermission: ['portal-access.enable-analysis-section'],
      any: false
    },
    children: [
      {
        path: '',
        canActivate: [PermissionsGuardService],
        component: ThreatHuntingDetailsComponent,
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThreatHuntingRoutingModule { }
