import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AfterContentInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { QueryBuilderConfig, RuleSet } from 'angular2-query-builder';

import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { startsWith, endsWith, map, find } from 'lodash';

import { AppService } from '@app/app.service';
import { DateService } from '@shared-services/date.service';
import { RelativeDate } from '@sweet-shared/models/relative-date-range.model';
import { AthenaFilterAndStatement, AthenaFilterOrStatement, AthenaFilterStatement, AthenaGroupStatementModel, AthenaSelectStatementModel, QueryWidgetModel, WidgetModel } from '@sweet-shared/models/widget.model';

export interface WidgetSettingFormData {
  title: string;
  isNew: boolean;
  settingWidgets: WidgetModel[];
  queryWidgets: QueryWidgetModel[];
  defaultModels: any;
}

export interface ActionData {
  name: string;
  data: any;
}

export interface GroupSelectionInterface {
  fieldType: string;
  field: string;
  unit?: string;
  format?: string;
  seconds?: number;
}

@Component({
  selector: 'app-setting-widget-form',
  templateUrl: './setting-widget-form.component.html',
  styleUrls: ['./setting-widget-form.component.scss']
})

export class SettingWidgetFormComponent implements OnInit, AfterContentInit {
  // emitter
  @Output() crupdate: EventEmitter<ActionData> = new EventEmitter();
  @Input() loading = false;
  generalInfoType = '';
  optionQueryData: any;
  optionDataForm: any;


  relativeDateRanges: RelativeDate[] = [];

  // Index choices getter/loader
  private debug = false;
  private selectedUserIndex: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private availableFields: string[] = [];
  private availableTypes: any[] = [
    { label: 'Bar', value: 'bar' },
    { label: 'Dropdown', value: 'dropdown' },
    { label: 'Line', value: 'line' },
    { label: 'Map', value: 'map' },
    { label: 'Multi Bar', value: 'multi_bar' },
    { label: 'Multi Line', value: 'multi_line' },
    { label: 'Pie', value: 'pie' },
    { label: 'Query', value: 'query_builder' },
    { label: 'Single Metric', value: 'single_metric' },
    { label: 'Solid Gauge', value: 'solid_gauge' },
    { label: 'Table', value: 'table' }
  ];

  athenaStatement: any = {
    standard: [
      {
        select_statement: [],
        where_statement: {
          time: {
            field: 'isodate',
            start: null,
            end: null
          },
          filter: null,
        },
        group_by_statement: [],
      }
    ],
    order_by: []
  };
  allIndexes: any[] = [];
  indexesOptions: any[] = [];
  operators: any = {
    '=': 'string',
    '!=': 'string',
    '<': 'string',
    '<=': 'string',
    '>': 'string',
    '>=': 'string',
    contains: 'string',
    like: 'string',
    in: 'array',
    incidr: 'array',
    'is null': null,
    'is not null': null,
    between: 'array'
  };

  // Error message controller
  errorMessage: string = null;

  minDate = new Date();
  maxDate = new Date();



  // Stepper form controllers
  // General Tab
  generalInformationModel: any = this.data.isNew ? {} : this.data.defaultModels.generalInformationModel;
  generalInformationForm = new FormGroup({});
  generalInformationFields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      defaultValue: null,
      templateOptions: {
        label: 'Title',
        placeholder: 'Widget Title',
        required: true,
      },
    },
    {
      key: 'description',
      type: 'textarea',
      defaultValue: null,
      templateOptions: {
        label: 'Widget Description',
        placeholder: 'Widget Description',
        required: true,
        input: 'textarea'
      },
    },
    {
      key: 'source',
      type: 'select',
      defaultValue: 'log',
      templateOptions: {
        label: 'Widget Source',
        placeholder: 'Widget source',
        required: true,
        options: [
          { label: 'Incidents', value: 'incidents' },
          { label: 'Log', value: 'log' },
          { label: 'Static', value: 'static' },
        ],
        change: (model, newValue) => {
          this.generalInformationFields.map((val) => {
            if (newValue?.value === 'log') {
              this.availableTypes = this.availableTypes.filter((elt: any) => elt?.value !== 'string_dropdown')
            } else {
              const sDropDown = { label: 'Static Dropdown', value: 'string_dropdown' };
              const newList = this.availableTypes.filter((elt: any) => elt?.value !== 'string_dropdown')
              this.availableTypes = [...newList, sDropDown].sort((a: any, b: any) => {
                const labelA = a?.label?.toLowerCase();
                const labelB = b?.label?.toLowerCase();

                if (labelA < labelB) {
                  return -1
                }

                if (labelA > labelB) {
                  return 1;
                }

                return 0;
              })
            }



            if (newValue.value === 'static') {

              if (val.key === 'type') {
                val.templateOptions.options = [{ label: 'Static Dropdown', value: 'string_dropdown' }];
              }
            } else if (newValue.value === 'incidents') {

              if (val.key === 'type') {
                val.templateOptions.options = [{ label: 'Map', value: 'map' }];
              }
            } else {
              if (val.key === 'type') {
                val.templateOptions.options = this.availableTypes;
              }
            }
          });
        },
      }
    },
    {
      key: 'type',
      type: 'select',
      defaultValue: this.data.isNew ? 'dropdown' : this.data.defaultModels?.generalInformationModel?.type,
      templateOptions: {
        label: 'Widget Type',
        placeholder: 'Widget Type',
        required: true,
        options: this.availableTypes,
        change: (_, newValue) => this.setSelectFieldLimit(newValue.value),

      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.setSelectFieldLimit(field.defaultValue);
        }
      }
    },
    {
      key: 'treat_empty_values_as',
      type: 'select',
      defaultValue: null,
      templateOptions: {
        label: 'Treat Empty Values As',
        placeholder: 'Treat Empty Values As',
        required: true,
        options: [
          { value: 'nulls', label: 'Nulls' },
          { value: 'zeros', label: 'Zeros' }
        ],
      },
      hideExpression: model => {
        return !['bar', 'multi_bar', 'line', 'multi_line'].includes(model.type);
      }
    },
    {
      key: 'index',
      type: 'select',
      defaultValue: this.data.isNew ? 'default' : this.data.defaultModels.generalInformationModel.index,
      templateOptions: {
        label: 'Data Index',
        placeholder: 'Widget Data Index',
        required: true,
        options: [],
        change: (_, newValue) => this.setFieldOptions(newValue.value)
      },
      hooks: {
        onInit: field => {
          this.setFieldOptions(field.model.index);
        }
      },
      hideExpression: (model: any, _) => {
        return model.source !== 'log' || model.type === 'map';
      }
    }
  ];

  // Date Range Tab
  // datetime picker
  dateRange: { start: number, end: number } = { start: null, end: null };
  dateRangeInformationForm: FormGroup = new FormGroup({});


  dateRangeInformationOptions = [
    { label: 'Relative', value: 'relative' },
    { label: 'Static', value: 'static' }
  ];

  dateRangeInformationModel: any = {};
  dateRangeInformationFields: FormlyFieldConfig[] = [
    {
      key: 'start',
      templateOptions: {
        label: 'Start'
      },
      fieldGroup: [
        {
          key: 'type',
          type: 'select',
          defaultValue: 'static',
          templateOptions: {
            label: 'Type',
            placeholder: 'Type',
            options: [
              { label: 'Relative', value: 'relative' },
              { label: 'Static', value: 'static' }
            ]
          }
        },
        {
          key: 'value',
          type: 'select',
          defaultValue: null,
          templateOptions: {
            required: true,
            label: 'Value',
            placeholder: 'Value',
            options: this.dateService.relativeDates
          },
          hideExpression: (model: any, _) => {
            return !model.type || model.type !== 'relative';
          },
        },
        {
          key: 'dateFrom',
          type: 'datetime',
          defaultValue: this.data.defaultModels?.dateRanges?.from || null,
          templateOptions: {
            label: 'Date From',
            placeholder: 'The date from',
            required: true,
            type: 'date'
          },
          hideExpression: (model: any, _) => {
            return !model.type || model.type !== 'static';
          },
        }
      ]
    },
    {
      key: 'end',
      templateOptions: {
        label: 'End',
        pickerOptions: {}
      },
      fieldGroup: [
        {
          key: 'type',
          type: 'select',
          defaultValue: 'static',
          templateOptions: {
            label: 'Type',
            placeholder: 'Type',
            options: [
              { label: 'Relative', value: 'relative' },
              { label: 'Static', value: 'static' }
            ]
          }
        },
        {
          key: 'value',
          type: 'select',
          defaultValue: null,
          templateOptions: {
            required: true,
            label: 'Value',
            placeholder: 'Value',
            options: this.dateService.relativeDates
          },
          hideExpression: (model: any, _) => {
            return !model.type || model.type !== 'relative';
          }
        },
        {
          key: 'dateFrom',
          type: 'datetime',
          defaultValue: this.data.defaultModels?.dateRanges?.to || 'now',
          templateOptions: {
            label: 'Date',
            placeholder: 'The date',
            required: true,
            type: 'date'
          },
          hideExpression: (model: any, _) => {
            return !model.type || model.type !== 'static';
          },
        }
      ]
    }
  ];

  // Field(s) Tab
  // dropdown setting widget option.
  dropdownWidgetQueryModel: any = { fields: [] };
  selectedFields: any[] = [];
  dropdownWidgetQueryForm = new FormGroup({});
  dropdownWidgetQueryFields: FormlyFieldConfig[] = [
    {
      key: 'fields',
      type: 'groupBy',
      templateOptions: {
        addText: 'Add',
        maxItems: 1,
        showRemoveBtn: true
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'column_field',
            defaultValue: null,
            type: 'select',
            templateOptions: {
              label: 'Widget Field',
              placeholder: 'Widget Field',
              required: true,
              options: []
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                this.selectedUserIndex.pipe(
                  filter(val => !!val)
                ).subscribe(val => {
                  field.templateOptions.options = val.fields.map(item => {
                    return { value: item.value, label: item.friendly };
                  });
                });
              }
            }
          },
          {
            key: 'alias',
            type: 'input',
            defaultValue: null,
            templateOptions: {
              label: 'Field Alias',
              placeholder: 'Field Field',
              required: true
            }
          },
          {
            key: 'function',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: 'Field Function',
              required: true,
              placeholder: 'Field Function',
              options: [
                { value: '-', label: 'None' },
                { value: 'avg', label: 'Average' },
                { value: 'count', label: 'Count' },
                { value: 'date_trunc', label: 'Date Trunc' },
                { value: 'min', label: 'Minimum' },
                { value: 'max', label: 'Maximum' },
                { value: 'sum', label: 'Sum' }
              ]
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                //const functionOptions = [...field.templateOptions.options];
                const options = field.templateOptions.options;
                let functionOptions;

                if (options instanceof Observable) {
                  functionOptions = [(options as BehaviorSubject<any>).getValue()]
                }
                else {
                  functionOptions = [...options]
                }

                const type = this.generalInformationForm.get('type');
                type.valueChanges.subscribe(val => {
                  if (field.parent.key !== '0') {
                    return;
                  }
                  if (['pie', 'solid_gauge'].includes(val)) {
                    field.templateOptions.options = functionOptions.filter(option => option.value !== '_');

                  } else if (['bar', 'multi_bar', 'line', 'multi_line'].includes(val)) {
                    field.templateOptions.options = functionOptions.filter(v => v.value === 'date_trunc');
                  } else {
                    field.templateOptions.options = functionOptions;
                  }
                });
              }
            },
            hideExpression: (model: any, _, field) => {
              return field.parent.key === '0' && ['pie', 'solid_gauge'].includes(this.generalInformationModel.type);
            }
          },
          {
            key: 'quantifier',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: 'Quantifier',
              placeholder: 'Quantifier',
              required: true,
              options: [
                { value: '-', label: 'None' },
                { value: 'distinct', label: 'Distinct' }
              ]
            },
            hideExpression: (model: any, _, field) => {
              return model && (field.parent.key === '0' && ['pie', 'bar', 'line', 'solid_gauge'].includes(this.generalInformationModel.type))
                || (!['count', 'avg', 'sum'].includes(model?.function ?? ''));
            }
          },
          {
            key: 'data_type',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: 'Data Type',
              placeholder: 'Data Type',
              required: true,
              options: [
                { value: 'double', label: 'Double' },
                { value: 'integer', label: 'Integer' }
              ]
            },
            hideExpression: (model: any, _, field) => {
              return model && (field.parent.key === '0' && ['pie', 'bar', 'line', 'solid_gauge'].includes(this.generalInformationModel.type))
                || (!['sum'].includes(model?.function ?? ''));
            }
          },
          {
            key: 'unit',
            type: 'select',
            defaultValue: null,
            templateOptions: {
              label: 'Unit',
              placeholder: 'Unit',
              required: true,
              options: [
                { label: 'Second', value: 'second' },
                { label: 'Minute', value: 'minute' },
                { label: 'Hour', value: 'hour' },
                { label: 'Day', value: 'day' },
                { label: 'Month', value: 'month' },
                { label: 'Year', value: 'year' }
              ]
            },
            hideExpression: (model: any, _) => {
              return model && (!model.function || !['date_trunc'].includes(model?.function ?? ''));
            },
          },
        ]
      }
    }
  ];

  // Filters Tab
  // filter information
  showFilterQueryBuilder = true;
  filterInformationModel: any = null;
  filterInformationForm = new FormGroup({});
  filterInformationAvailableFields: QueryBuilderConfig = {
    fields: {}
  };

  // Grouping Tab
  // grouping information
  showGroupingInformation = true;
  groupByStatement: any[] = [];
  groupingInformationForm = new FormGroup({});
  groupingInformationModel: any = this.data.isNew ?
    { fields: [] } :
    { fields: this.generateGroupModelFromAthenaGroupStatement(this.data.defaultModels.groupingInformationModel) };
  groupingInformationAvailableFields: FormlyFieldConfig[] = [
    {
      key: 'fields',
      type: 'groupBy',
      templateOptions: {
        addText: 'Add',
        showRemoveBtn: true
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'fieldType',
            type: 'select',
            defaultValue: null,
            templateOptions: {
              label: 'Field Type',
              placeholder: 'Field Type',
              required: true,
              options: [
                { label: 'Field', value: 'column_field' },
                { label: 'String Field', value: 'string_field' },
                { label: 'Date Floor', value: 'date_floor' },
                { label: 'Date Format', value: 'date_format' },
                { label: 'Date Trunc', value: 'date_trunc' }
              ],
            }
          },
          {
            key: 'field',
            type: 'select',
            defaultValue: null,
            templateOptions: {
              label: 'Field',
              placeholder: 'Field',
              required: true,
              options: []
            },
            hideExpression: (model: any, _) => {
              return !model.fieldType || !['column_field', 'date_trunc', 'date_format', 'date_floor'].includes(model.fieldType);
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.templateOptions.options = this.availableFields.map((field: any) => {
                  return { label: field.friendly, value: field.value };
                });
              }
            }
          },
          {
            key: 'field',
            type: 'input',
            defaultValue: null,
            templateOptions: {
              label: 'Field String',
              placeholder: 'Field String',
              required: true
            },
            hideExpression: (model: any, _) => {
              return !model.fieldType || !['string_field'].includes(model.fieldType);
            },
          },
          {
            key: 'unit',
            type: 'select',
            defaultValue: null,
            templateOptions: {
              label: 'Unit',
              placeholder: 'Unit',
              required: true,
              options: [
                { label: 'Second', value: 'second' },
                { label: 'Minute', value: 'minute' },
                { label: 'Hour', value: 'hour' },
                { label: 'Day', value: 'day' },
                { label: 'Month', value: 'month' },
                { label: 'Year', value: 'year' }
              ]
            },
            hideExpression: (model: any, _) => {
              return !model.fieldType || !['date_trunc'].includes(model.fieldType);
            },
          },
          {
            key: 'format',
            type: 'input',
            defaultValue: null,
            templateOptions: {
              label: 'Date Format',
              placeholder: 'Date Format',
              required: true
            },
            hideExpression: (model: any, _) => {
              return !model.fieldType || !['date_format'].includes(model.fieldType);
            },
          },
          {
            key: 'seconds',
            type: 'input',
            defaultValue: null,
            templateOptions: {
              label: 'Seconds',
              placeholder: 'Seconds',
              required: true,
              type: 'number',
              min: 0
            },
            hideExpression: (model: any, _) => {
              return !model.fieldType || !['date_floor'].includes(model.fieldType);
            },
          }
        ]
      }
    }
  ];

  // Order By Tab
  // grouping information
  orderByStatement: any[] = [];
  orderByInformationForm: FormGroup = new FormGroup({});
  orderByInformationModel: any = this.data.isNew ? { fields: [] } : { fields: this.data.defaultModels.orderByInformationModel };
  orderByInformationFields: FormlyFieldConfig[] = [
    {
      key: 'fields',
      type: 'groupBy',
      templateOptions: {
        addText: 'Add',
        showRemoveBtn: true
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'alias',
            type: 'select',
            defaultValue: null,
            templateOptions: {
              label: 'Alias',
              placeholder: 'Alias',
              required: true,
              options: [],
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.templateOptions.options = this.dropdownWidgetQueryModel.fields.map((field: any) => {
                  return { label: field.alias, value: field.alias };
                });
              }
            },
            hideExpression: (model: any, _) => {
              return this.generalInformationModel.type === 'string_dropdown';
            },
          },
          {
            key: 'sort',
            type: 'select',
            defaultValue: null,
            templateOptions: {
              label: 'Order',
              placeholder: 'Order',
              required: true,
              options: [
                { label: 'Ascending', value: 'asc' },
                { label: 'Descending', value: 'desc' }
              ]
            }
          },
        ]
      }
    }
  ];

  // Results Tab
  // Stepper form controllers
  resultInformationModel: any = this.data.isNew ? {} : this.data.defaultModels.resultInformationModel;
  resultInformationForm = new FormGroup({});
  resultInformationFields: FormlyFieldConfig[] = [
    {
      key: 'variableName',
      type: 'input',
      defaultValue: null,
      templateOptions: {
        label: 'Output Variable',
        required: true
      },
      validators: {
        inputName: {
          expression: (field) => {
            return this.data.settingWidgets.map((settingWidget) => {
              return settingWidget.params.inputName;
            }).indexOf(field.value);
          },
          message: (error, field: FormlyFieldConfig) =>
            `"${field.formControl.value}" is already in use!`,
        },
      },
      hideExpression: (model: any, _) => {
        return this.generalInformationModel && this.generalInformationModel.type !== 'dropdown'
          && this.generalInformationModel.type !== 'query_builder' && this.generalInformationModel.type !== 'string_dropdown';
      }
    },
    {
      key: 'limit',
      type: 'checkbox',
      defaultValue: ['pie', 'solid_gauge'].includes(this.generalInformationModel.type),
      templateOptions: {
        label: 'Limit Results'
      },
      hideExpression: (model: any, _) => {
        return this.generalInformationModel.type === 'query_builder' ||
          this.generalInformationModel.type === 'string_dropdown' || this.generalInformationModel.type === 'map';
      }
    },
    {
      key: 'isMulti',
      type: 'checkbox',
      defaultValue: false,
      templateOptions: {
        label: 'Multi Select',
      },
      hideExpression: (model: any, _) => {
        return !['dropdown', 'string_dropdown'].includes(this.generalInformationModel.type);
      }
    },
    {
      key: 'limitValue',
      type: 'input',
      defaultValue: ['pie', 'solid_gauge'].includes(this.generalInformationModel.type) ? 10 : 1,
      templateOptions: {
        label: 'Limit',
        required: true,
        type: 'number',
        min: 1,
        change: (_, newValue) => {
          if (this.resultInformationModel.limit) {
            this.athenaStatement.limit = newValue.target.value;
          } else {
            if (this.athenaStatement.hasOwnProperty('limit')) {
              delete this.athenaStatement.limit;
            }
          }
        }
      },
      hideExpression: (model: any, _) => {
        return !model.limit;
      }
    }
  ];

  showQueryVariables = false;
  queryVariablesForm = new FormGroup({});
  queryVariablesModel: any = this.data.isNew ? {} : this.data.defaultModels.queryVariablesModel;
  queryVariablesFormFields: FormlyFieldConfig[] = [
    {
      key: 'queryVariables',
      type: 'select',
      templateOptions: {
        label: 'Query Widgets',
        placeholder: 'Select a Query Widget',
        options: this.data.queryWidgets.map(item => {
          return { label: item.title, value: `${item.params.variable_name}` };
        }),
        required: this.showQueryVariables,
      },
      validation: {
        messages: {
          FILTER_STATEMENT_REQUIRED: (error, field) => {
            return `Filter conditions are required when using query widget`;
          }
        }
      },
      defaultValue: this.data?.defaultModels?.queryVariablesModel?.queryVariables || null,
    }
  ];

  // input variable information
  showInputVariables = false;
  inputVariablesForm = new FormGroup({});
  inputVariablesModel: any = {};
  inputVariablesFormFields: FormlyFieldConfig[] = [
    {
      key: 'inputVariables',
      type: 'groupBy',
      templateOptions: {
        addText: 'Add Input Variable',
        showRemoveBtn: true,
        maxItems: this.data?.settingWidgets?.length || 0
      },
      hooks: {
        onInit: field => {
          if (!this.data.isNew) {
            this.inputVariablesModel = JSON.parse(JSON.stringify({ inputVariables: this.data.defaultModels.inputVariablesModel }));
          }
        }
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'input_variable_name',
            type: 'select',
            defaultValue: null,
            validation: {
              messages: {
                'Missing input variable': (error, field) => {
                  return `Selected input variable must be present as a value in query above within double curly brackets, e.g. {{ ${field.formControl?.value || 'My Input Variable'} }}`;
                }
              }
            },
            templateOptions: {
              label: 'Input Variable',
              placeholder: 'Input Variable',
              required: true,
              options: [],
              change: field => {
                // check if there are any input variables selected
                if (this.inputVariablesModel?.inputVariables?.length) {
                  // convert to string to check for regex match of '{{ [input name] }}'
                  const json = JSON.stringify(this.filterInformationModel);
                  const selectedInput = field.formControl.value;
                  const escapedValue = selectedInput.replace('$', '\\$');
                  const regex = new RegExp(`{{\\s*${escapedValue}\\s*}}`, 'g');
                  // If no matches, set error for user to know what to do
                  if (!regex.test(json)) {
                    field.formControl.setErrors({ 'Missing input variable': selectedInput });
                  }
                }
              }
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                if (this.data.settingWidgets.length) {

                  field.templateOptions.options = this.data.settingWidgets.map((item: any) => {
                    if (item.type === 'string_dropdown') {
                      return { label: `${item.title} (${item.params.inputName})`, value: item.params.inputName };
                    } else {
                      return { label: `${item.title} (${item.params.inputName})`, value: item.params.inputName };
                    }
                  });
                }
              }
            }
          },
          {
            key: 'default_value',
            type: 'input',
            defaultValue: null,
            templateOptions: {
              label: 'Default Value',
              placeholder: 'Default Value',
              required: false,
            }
          },
        ]
      }
    }
  ];

  // stepper for dropdown widget only
  optionInformationModel: any = this.data.isNew ? {} : this.data.defaultModels.optionInformationModel;
  optionInformationForm = new FormGroup({});
  optionInformationFields: FormlyFieldConfig[] = [];


  static whereTimeStatementBuilder(value, dateRange, athenaStatement) {
    if (value.dateType === 'relative') {
      SettingWidgetFormComponent.processRelativeDateRange(value.relativeDateFrom, true, dateRange, athenaStatement);
    }
    if (value.dateType === 'relative') {
      SettingWidgetFormComponent.processRelativeDateRange(value.relativeDateTo, false, dateRange, athenaStatement);
    }

    // check for static daterange
    if (value.dateType === 'static') {
      dateRange.start = new Date(value.dateFrom).getTime();
      if (athenaStatement.standard?.length) {
        athenaStatement.standard[0].where_statement.time = {
          ...athenaStatement.standard[0].where_statement.time,
          start: Math.round((new Date(value.dateFrom).getTime() / 1000)).toString()
        };
      }
    }
    if (value.dateType === 'static') {
      dateRange.end = new Date(value.dateTo).getTime();
      if (athenaStatement.standard?.length) {
        athenaStatement.standard[0].where_statement.time = {
          ...athenaStatement.standard[0].where_statement.time,
          end: Math.round((new Date(value.dateTo).getTime() / 1000)).toString()
        };
      }
    }
  }


  static processRelativeDateRange(value: any, start: boolean, dateRange, athenaStatement): void {
    // if (value && value.value) {
    if (value) {
      const num = /\d+/g;
      const minutes = /\bminutes\b/;
      const year = /\byear\b/;
      const days = /\bdays\b/;
      const hours = /\bhours\b/;
      // const finalValue = value.value.split(' ');
      const finalValue = value.split(' ');

      finalValue.map((item) => {
        if (startsWith(item, 'last')) {
          // const regxAmount = value.value.match(num);
          const regxAmount = value.match(num);

          // let regxUnit = value.value.match(minutes) || value.value.match(year);
          let regxUnit = value.match(minutes) || value.match(year);

          if (regxUnit[0].endsWith('s')) {
            regxUnit = regxUnit[0].slice(0, -1);
          } else {
            regxUnit = regxUnit[0];
          }
          start ? athenaStatement.standard[0].where_statement.time = {
            ...athenaStatement.standard[0].where_statement.time,
            start: moment().subtract(regxAmount, regxUnit).unix().toString()
          } : athenaStatement.standard[0].where_statement.time = {
            ...athenaStatement.standard[0].where_statement.time,
            end: moment().subtract(regxAmount, regxUnit).unix().toString()
          };
          start ? dateRange.start = moment().subtract(regxAmount, regxUnit).unix() : dateRange.end = moment().subtract(regxAmount, regxUnit).unix();
        } else if (endsWith(item, 'ago')) {
          const regxAmount1 = value.match(num);

          // const regxAmount1 = value.value.match(num);
          // let regxUnit1 = value.value.match(days) || value.value.match(hours);
          let regxUnit1 = value.match(days) || value.match(hours);

          if (regxUnit1[0].endsWith('s')) {
            regxUnit1 = regxUnit1[0].slice(0, -1);
          } else {
            regxUnit1 = regxUnit1[0];
          }
          start ? athenaStatement.standard[0].where_statement.time = {
            ...athenaStatement.standard[0].where_statement.time,
            start: moment().subtract(regxAmount1, regxUnit1).unix().toString()
          } : athenaStatement.standard[0].where_statement.time = {
            ...athenaStatement.standard[0].where_statement.time,
            end: moment().subtract(regxAmount1, regxUnit1).unix().toString()
          };
          start ? dateRange.start = moment().subtract(regxAmount1, regxUnit1).unix() : dateRange.end = moment().subtract(regxAmount1, regxUnit1).unix();
        } else if (item === 'now') {
          start ? athenaStatement.standard[0].where_statement.time = {
            ...athenaStatement.standard[0].where_statement.time,
            start: moment().unix().toString()
          } : athenaStatement.standard[0].where_statement.time = {
            ...athenaStatement.standard[0].where_statement.time,
            end: moment().unix().toString()
          };
          start ? dateRange.start = moment().unix() : dateRange.end = moment().unix();
        }
      });
    }

  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WidgetSettingFormData,
    private dateService: DateService,
    private apiService: AppService<any>,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.dateRangeInformationForm = this.formBuilder.group({
      dateType: '',
      dateFrom: [this.data.defaultModels?.dateRanges?.from || null],
      dateTo: [this.data.defaultModels?.dateRanges?.to || null],
      relativeDateFrom: [this.data.defaultModels?.dateRanges?.from || null],
      relativeDateTo: [this.data.defaultModels?.dateRanges?.to || null]
    });
    this.dateRangeInformationForm.get('dateFrom').valueChanges.subscribe(value => {
      this.maxDate = value;
    })

    if (!this.data.isNew) {
      this.dropdownWidgetQueryModel = { fields: this.generateSelectFormFieldsFromAthenaStatement(this.data.defaultModels.selectFormModel) };
      this.showInputVariables = this.data.defaultModels.showInputVariables;
      this.showQueryVariables = this.data.defaultModels.showQueryVariables;
    }
    this.relativeDateRanges = this.dateService.relativeDates;
    this.orderByInformationForm.valueChanges.subscribe((value) => {
      this.formatOrderBy(value);
    });

    this.dropdownWidgetQueryForm.valueChanges.subscribe((value) => {
      this.formatSelectedField(value);
    });

    this.groupingInformationForm.valueChanges.subscribe((value) => {
      this.formatGroupBy(value);
    });

    this.dateRangeInformationForm.valueChanges.subscribe((value) => {
      SettingWidgetFormComponent.whereTimeStatementBuilder(value, this.dateRange, this.athenaStatement);
    });
  }

  private setSelectFieldLimit(value: any): void {
    this.generalInfoType = '';
    this.generalInfoType = value;
    // Call function that discerns what to show on field 3 as fiend function
    if (this.debug) {
    }
    if (this.data.isNew) {
      switch (value) {
        case 'dropdown':
        case 'single_metric':
          this.dropdownWidgetQueryFields[0].templateOptions.maxItems = 1;
          this.dropdownWidgetQueryFields[0].templateOptions.minItems = 1;
          this.dropdownWidgetQueryFields[0].templateOptions.showRemoveBtn = false;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}]
          };
          break;
        case 'pie':
        case 'solid_gauge':
          this.dropdownWidgetQueryFields[0].templateOptions.maxItems = 2;
          this.dropdownWidgetQueryFields[0].templateOptions.minItems = 2;
          this.dropdownWidgetQueryFields[0].templateOptions.showRemoveBtn = false;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}, {}]
          };
          break;
        case 'line':
        case 'bar':
          this.groupingInformationAvailableFields[0].templateOptions.minItems = 1;
          this.dropdownWidgetQueryFields[0].templateOptions.minItems = 2;
          this.groupingInformationModel = {
            ...this.groupingInformationModel,
            fields: [{}]
          };
          this.dropdownWidgetQueryFields[0].templateOptions.showRemoveBtn = false;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}, {}]
          };
          break;
        case 'multi_line':
        case 'multi_bar':
          this.groupingInformationAvailableFields[0].templateOptions.minItems = 2;
          this.dropdownWidgetQueryFields[0].templateOptions.minItems = 3;
          this.groupingInformationModel = {
            ...this.groupingInformationModel,
            fields: [{}]
          };
          this.dropdownWidgetQueryFields[0].templateOptions.showRemoveBtn = false;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}, {}, {}]
          };
          break;
        case 'table':
          this.dropdownWidgetQueryFields[0].templateOptions.maxItems = 20;
          this.groupingInformationAvailableFields[0].templateOptions.minItems = 0;
          this.dropdownWidgetQueryFields[1].templateOptions.minItems = 1;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}, {}]
          };
          break;
        case 'string_dropdown':
          this.orderByInformationFields[0].templateOptions.minItems = 1;
          this.orderByInformationFields[0].templateOptions.maxItems = 1;
          this.orderByInformationFields[0].templateOptions.showRemoveBtn = false;
          this.orderByInformationModel = {
            ...this.orderByInformationModel,
            fields: [{}]
          };
          break;
        case 'query_builder':
        default:
          this.dropdownWidgetQueryFields[0].templateOptions.maxItems = null;
          this.dropdownWidgetQueryFields[0].templateOptions.minItems = 1;
          this.dropdownWidgetQueryFields[0].templateOptions.showRemoveBtn = false;
          this.dropdownWidgetQueryModel = {
            ...this.dropdownWidgetQueryModel,
            fields: [{}]
          };
          break;
      }
    }
  }

  ngAfterContentInit(): void {
    // Fetch all the indexes
    this.fetchIndexes();
    this.resetQueryFilter();
  }

  resetQueryFilter(): void {
    if (this.debug) {
    }
    this.filterInformationModel = {
      condition: 'and',
      rules: []
    };
    // If editing set the values for the query builder
    if (!this.data.isNew) {
      this.filterInformationModel = this.generateQueryBuilderModelFromAthenaWhereFilter(this.data.defaultModels.filterInformationModel);

      const wSource = this.data?.defaultModels?.generalInformationModel?.source
      if (wSource === 'log') {
        this.availableTypes = this.availableTypes.filter((elt: any) => elt?.value !== 'string_dropdown')
      }
    }
    this.showFilterQueryBuilder = false;
    setTimeout(() => this.showFilterQueryBuilder = true);
  }

  resetGroupBySection(availableFields: string[]): void {
    if (this.debug) {
    }
    this.availableFields = availableFields;
    this.groupingInformationModel = {
      fields: this.groupingInformationModel.fields.filter((field: any) => map(availableFields, 'value').includes(field.field))
    };
    this.showGroupingInformation = false;
    setTimeout(() => this.showGroupingInformation = true, 500);
  }

  // function called for the event option step
  optionFormDataEvent(event) {
    if (event.name === 'optionFormValue') {
      this.optionDataForm = event.data;
    } else if (event.name === 'queryModel') {
      this.optionQueryData = event.data;
    }
  }

  createWidget(): void {
    const typeofWidget = this.generalInformationModel.type;

    let widgetData: any = {};
    const validation = this.generalInformationForm.invalid || this.groupingInformationForm.invalid ||
      this.resultInformationForm.invalid || this.dateRangeInformationForm.invalid ||
      this.dropdownWidgetQueryForm.invalid || this.orderByInformationForm.invalid ||
      this.inputVariablesForm.invalid;
    if (typeofWidget === 'map') {
      widgetData = {
        title: this.generalInformationModel.title,
        description: this.generalInformationModel.description,
        source: this.generalInformationModel.source,
        type: this.generalInformationModel.type,
        params: {}
      };
    } else if (typeofWidget !== 'string_dropdown' && typeofWidget !== 'map') {
      widgetData = {
        title: this.generalInformationModel.title,
        description: this.generalInformationModel.description,
        source: this.generalInformationModel.source,
        type: this.generalInformationModel.type,
        params: {
          athenaStatement: this.athenaStatement
        }
      };
    } else {
      widgetData = {
        title: this.generalInformationModel.title,
        description: this.generalInformationModel.description,
        source: this.generalInformationModel.source,
        type: this.generalInformationModel.type,
        params: {
          ...this.optionDataForm || [],

          sortBy: this.orderByInformationModel.fields[0].sort
        }
      };
    }


    if (['bar', 'multi_bar', 'line', 'multi_line'].includes(widgetData.type)) {
      widgetData.treat_empty_values_as = this.generalInformationModel.treat_empty_values_as;
    }
    // If input variables have been selected, its model will have keys, so should be included in payload
    if (Object.keys(this.inputVariablesModel).length || Object.keys(this.queryVariablesModel).length) {
      widgetData.input_variables = [];
      if (this.inputVariablesModel.inputVariables?.length) {
        widgetData.input_variables = [...this.inputVariablesModel.inputVariables];
      }
      if (this.queryVariablesModel.queryVariables?.length) {
        const queryVar = { input_variable_name: this.queryVariablesModel.queryVariables, default_value: this.athenaStatement.standard[0].where_statement.filter };
        widgetData.input_variables = [...widgetData.input_variables, queryVar];
        widgetData.params.athenaStatement.standard[0].where_statement.filter = `{{${this.queryVariablesModel.queryVariables}}}`;
      }
    }

    // Inject the index name
    if (widgetData.type !== 'map' && this.generalInformationModel && this.generalInformationModel.index) {
      widgetData.index_name = this.generalInformationModel.index;
    }
    if (this.generalInformationModel.type === 'dropdown' || this.generalInformationModel.type === 'string_dropdown') {
      widgetData.params.inputName = this.resultInformationModel.variableName;
      widgetData.params.isMultiSelect = this.resultInformationModel.isMulti;
    }
    if (this.generalInformationModel.type === 'query_builder') {
      delete widgetData.params.athenaStatement;
      widgetData.params.variable_name = this.resultInformationModel.variableName;
      widgetData.params.default_value = [];
    }
    if (this.debug) {
    }
    this.crupdate.emit({ name: this.data.isNew ? 'CREATE' : 'UPDATE', data: widgetData });
  }

  formatQueryForAthena(queryModel: any): void {
    const athenaFilter = {};
    athenaFilter[queryModel.condition] = queryModel.rules.reduce((prev: any, next: any) => {
      if (!next.hasOwnProperty('condition')) {
        const field: any = {
          field: next.field,
          comparison: next.operator
        };
        if (this.operators[next.operator] === 'string') {
          field.value = next.value;
        } else if (this.operators[next.operator] === 'array') {
          // If a input variable is being used in the query
          if (/{{\s*.*\s*}}/.test(next.value)) {
            field.values = next.value;
          } else if (next.operator === 'between') {
            const [minValue, maxValue] = next.value.split(',');
            field.min = minValue;
            field.max = maxValue;
          } else {
            field.values = next.value.split(',');
          }
        }
        prev.push(field);
      } else {
        const temp = {};
        temp[next.condition] = next.rules.reduce((p: any, n: any) => {
          const field: any = {
            field: n.field,
            comparison: n.operator
          };
          if (this.operators[n.operator] === 'string') {
            field.value = n.value;
          } else if (this.operators[n.operator] === 'array') {
            if (n.operator === 'between') {
              const [minValue, maxValue] = n.value.split(',');
              field.min = minValue;
              field.max = maxValue;
            } else {
              field.values = n.value.split(',');
            }
          }
          p.push(field);
          return p;
        }, []);
        prev.push(temp);
      }
      return prev;
    }, []);
    if (athenaFilter[queryModel.condition].length > 0) {
      this.athenaStatement.standard[0].where_statement.filter = athenaFilter;
    } else {
      if (this.athenaStatement.standard[0].where_statement.hasOwnProperty('filter')) {
        delete this.athenaStatement.standard[0].where_statement.filter;
      }
    }
  }

  // Takes in the the athena filter statement and returns a model that works with query-builder component
  generateQueryBuilderModelFromAthenaWhereFilter(athenaFilter: AthenaFilterStatement | AthenaFilterAndStatement | AthenaFilterOrStatement): RuleSet {
    const model = { condition: 'and', rules: [] };
    for (const key in athenaFilter) {
      if (key === 'and' || key === 'or') {
        model.condition = key;
        athenaFilter[key].forEach(rule => {
          if (rule.hasOwnProperty('and') || rule.hasOwnProperty('or')) {
            // Must keep calling itself if still has nested 'and's or 'or's
            model.rules.push(this.generateQueryBuilderModelFromAthenaWhereFilter(rule));
          } else {
            const value = rule.comparison === 'in' ? rule.values : rule.value;
            model.rules.push({ field: rule.field, operator: rule.comparison, value });
          }
        });
      }
    }
    return model;
  }

  validateQueryVariable(event): void {
    if (!this.queryVariablesFormFields[0].formControl) {
      return;
    }
    if (event.rules.length === 0) {
      this.queryVariablesFormFields[0].formControl.setErrors({ FILTER_STATEMENT_REQUIRED: true });
    } else {
      this.queryVariablesFormFields[0].formControl.setErrors(null);
    }
  }

  // To check validity of input varibles form, while manipulating query builder form
  validateInputVariables(event): void {
    // If no selected input variables, eject
    if (!Object.keys(this.inputVariablesModel).length || !event?.rules) {
      return;
    }
    const json = JSON.stringify(event.rules);
    // Grab all the names of the selected input variables
    const varNames = this.inputVariablesModel.inputVariables.map(variable => {
      return variable.input_variable_name;
    });
    if (varNames?.length) {
      // For each input variable, check if it is present within the query rules
      varNames.forEach(variable => {
        const escapedString = variable.replace('$', '\\$');
        const regex = new RegExp(`{{\\s*${escapedString}\\s*}}`, 'g');
        if (regex.test(json)) {
          // If present, find the form control with selected variable and remove error
          (this.inputVariablesFormFields[0].formControl as any).controls.forEach(control => {
            const field = control.controls.input_variable_name;
            if (field && field.errors && field.errors['Missing input variable'] && field.errors['Missing input variable'] === variable) {
              (control.controls.input_variable_name as FormControl).setErrors(null);
            }
          });
        }
      });
    }
  }

  private formatGroupBy(formatGroupBy: any): void {
    this.groupByStatement = formatGroupBy.fields.reduce((prev: any, next: any) => {
      if (next.fieldType === 'column_field') {
        prev.push({
          group_by_field: {
            column_field: next.field
          }
        });
      } else if (next.fieldType === 'string_field') {
        prev.push({
          group_by_field: {
            string_field: next.field
          }
        });
      } else if (next.fieldType === 'date_floor') {
        prev.push({
          group_by_field: {
            date_floor: {
              column_field: next.field,
              seconds: next.seconds
            }
          }
        });
      } else if (next.fieldType === 'date_format') {
        prev.push({
          group_by_field: {
            date_format: {
              column_field: next.field,
              format: next.format
            }
          }
        });
      } else if (next.fieldType === 'date_trunc') {
        prev.push({
          group_by_field: {
            date_trunc: {
              column_field: next.field,
              unit: next.unit
            }
          }
        });
      }
      return prev;
    }, []);
    if (this.groupByStatement.length > 0) {
      this.athenaStatement.standard[0].group_by_statement = this.groupByStatement;
    } else {
      if (this.athenaStatement.standard[0].hasOwnProperty('group_by_statement')) {
        delete this.athenaStatement.standard[0].group_by_statement;
      }
    }
  }

  generateGroupModelFromAthenaGroupStatement(groupStatements: AthenaGroupStatementModel[]): GroupSelectionInterface[] {
    return groupStatements.map(group => {
      const fieldType = Object.keys(group.group_by_field)[0];
      if (fieldType === 'column_field' || fieldType === 'string_field') {
        return {
          fieldType,
          field: group.group_by_field[fieldType]
        };
      } else if (fieldType === 'date_trunc') {
        return {
          fieldType,
          field: group.group_by_field.date_trunc.column_field,
          unit: group.group_by_field.date_trunc.unit
        };
      } else if (fieldType === 'date_floor') {
        return {
          fieldType,
          field: group.group_by_field.date_floor.column_field,
          seconds: group.group_by_field.date_floor.seconds
        };
      } else if (fieldType === 'date_format') {
        return {
          fieldType,
          field: group.group_by_field.date_format.column_field,
          format: group.group_by_field.date_format.format
        };
      }
    });
  }

  private formatOrderBy(orderBy: any): void {
    this.orderByStatement = orderBy.fields;
    if (this.orderByStatement.length > 0) {
      this.athenaStatement.order_by = this.orderByStatement;
    } else {
      if (this.athenaStatement.hasOwnProperty('order_by')) {
        delete this.athenaStatement.order_by;
      }
    }
  }

  // Takes in an athenaStatement and returns form model for dropdown query form
  generateSelectFormFieldsFromAthenaStatement(selectStatement: AthenaSelectStatementModel[]): { column_field: string, alias: string, function: string, quantifier?: string, unit?: string }[] {
    return selectStatement?.map(selection => {
      const functionName = Object.keys(selection.select_field)[0] === 'column_field' ? '-' : Object.keys(selection.select_field)[0];
      const columnField = functionName === '-' ? selection.select_field.column_field : selection.select_field[functionName].column_field;
      const selectField: any = {
        column_field: columnField,
        alias: selection.alias,
        function: functionName,
        unit: null
      };
      if (['avg', 'count', 'sum'].includes(functionName)) {
        selectField.quantifier = selection.select_field[functionName].quantifier && selection.select_field[functionName].quantifier !== '' ? selection.select_field[functionName].quantifier : '-';
        selectField.data_type = selection.select_field[functionName].data_type ? selection.select_field[functionName].data_type : [];
      }


      if (functionName === 'date_trunc') {
        selectField.unit = selection.select_field[functionName]?.unit;
      }

      return selectField;
    });
  }

  private formatSelectedField(selectedFields: any): void {
    const fields = selectedFields.fields;

    this.athenaStatement.standard[0].select_statement = fields.map((field: any) => {
      let athenaFormattedField: any = {};
      if (field.function && field.function !== '-') {
        athenaFormattedField.alias = field.alias;
        const selectedField: { [key: string]: any } = {};
        selectedField[field.function] = {
          column_field: field.column_field,
        };
        if (['avg', 'count', 'sum'].includes(field.function)) {
          selectedField[field.function].quantifier = field.quantifier && field.quantifier !== '-' ? field.quantifier : '';
          selectedField[field.function].data_type = field.data_type;
        }
        // sometime the user might pick date trunc then in that case we need to add the unit
        if (field.function === 'date_trunc') {
          selectedField[field.function] = {
            ...selectedField[field.function],
            unit: field.unit
          };
        }
        athenaFormattedField.select_field = selectedField;
      } else {
        athenaFormattedField = {
          alias: field.alias,
          select_field: {
            column_field: field.column_field
          }
        };
      }
      return athenaFormattedField;
    });
    if (this.debug) {
    }
  }

  private fetchIndexes(): void {
    this.apiService.get('index', '', null)
      .then((indexes: any[]) => {
        this.allIndexes = indexes;
        this.indexesOptions = indexes.map(index => {
          return { label: index.friendly, value: index.id };
        });

        // update the index choices
        this.setIndexOptions();
        this.setFieldOptions(this.generalInformationFields[4].defaultValue);
      })
      .catch((error: any) => {
        this.errorMessage = error;
      }
      );
  }

  private setIndexOptions(): void {
    this.generalInformationFields = this.generalInformationFields.map(field => {
      if (field.key === 'index') {
        field.templateOptions.options = this.indexesOptions;
      }
      return field;
    });
  }

  private updateSelectedFields(indexFields: string[]): void {
    if (this.debug) {
      console.log('Update selected fields', this.dropdownWidgetQueryModel);
    }

    if (this.dropdownWidgetQueryModel.fields) {
      this.dropdownWidgetQueryModel.fields = this.dropdownWidgetQueryModel.fields.filter(field => {
        if (Object.keys(field).length === 0) {
          return field;
        }
        return indexFields.includes(field.column_field) && field.column_field;
      });
      if (this.debug) {
        console.log('The new fields are', this.dropdownWidgetQueryModel);
      }
    }


  }

  private updateFilterFields(fields: any[]): void {
    if (this.debug) {
      console.log('Updating filter fields based on ', fields);
    }
    this.resetQueryFilter();
    this.filterInformationAvailableFields.fields = fields.reduce((prev, next) => {
      prev[next.value] = { name: next.friendly, value: next.value, type: 'string', operators: Object.keys(this.operators) };
      return prev;
    }, {});
  }

  private setFieldOptions(selectedIndexId: string): void {
    if (this.debug) {
      console.log('Setting the fields available based on index ID ', selectedIndexId);
    }
    const selectedIndex = find(this.allIndexes, (index: any) => index.id === selectedIndexId);
    if (selectedIndex) {
      this.selectedUserIndex.next(selectedIndex);
      this.updateFilterFields(selectedIndex.fields);

      this.groupingInformationAvailableFields[0].fieldArray.fieldGroup = this.groupingInformationAvailableFields[0].fieldArray.fieldGroup.map((item: any) => {
        if (item.key === 'field' && item.type === 'select') {
          item.templateOptions.options = selectedIndex.fields.map((field: any) => {
            return { label: field, value: field };
          });
        }
        return item;
      });

      this.resetGroupBySection(selectedIndex.fields);
      // update the selected field by removing all the fields that are not part of the index the user selected.
      this.updateSelectedFields(selectedIndex.fields.map(f => f.value));
    }

  }

  includeInputs(event: MatCheckboxChange) {
    this.showInputVariables = event.checked;
  }

  includeQueryInputs(event: MatCheckboxChange) {
    this.showQueryVariables = event.checked;
    if (!event.checked) {
      this.queryVariablesModel = { queryVariables: null };
    }
  }

  isFilterStepValid(): boolean {
    if (this.showQueryVariables) {
      return this.queryVariablesModel?.queryVariables && this.filterInformationForm.valid && this.athenaStatement.standard[0].where_statement.filter;
    }
    return this.filterInformationForm.valid;
  }

  setAthenaQuery(event: MatSelectChange) {
    this.athenaStatement.standard[0].where_statement.filter = `$${event.source.value}`;
  }
}
