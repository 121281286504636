import {AfterViewInit, Component, OnDestroy, EventEmitter, Output} from '@angular/core';
import {AppService} from '../../../../app.service';
import {KbService} from '../../kb.service';

@Component({
    selector: 'app-knowledge-base-page',
    templateUrl: './knowledge-base-page.component.html',
    styleUrls: ['./knowledge-base-page.component.scss'],
})
export class KnowledgeBasePageComponent implements AfterViewInit, OnDestroy {

    @Output() actionEvents: EventEmitter<any> = new EventEmitter();
    searchQuery = '';
    hitList = null;
    hitListLen = 0;
    hitListKeywords = [];
    templatesLoaded = true;
    mainArticle = null;

    constructor(private knowledgeBaseService: AppService<any>, private kbService: KbService) {

    }

    ngAfterViewInit(): void {
    }

    onSendInitQuery(keyWordSearch?) {
        // Clear main article
        this.destroyMainArticle();

        // Check to see if a query has been submitted, if not, kick back and do nothing
        if (keyWordSearch === undefined && this.searchQuery === '') {
            // Clear results and reset page
            this.hitListKeywords = null;
            this.hitList = null;
            return;
        } else if (keyWordSearch !== undefined) {
            // Clear array
            this.hitListKeywords = [];
            // Search using predefined keywords
            this.hitList = this.kbService.generateListOfArticles(keyWordSearch);
            // Modified list of keywords to show user
            this.hitListKeywords.push(keyWordSearch);
            this.hitListLen = this.hitList.length;
        } else if (this.searchQuery !== '') {
            // Search using custom keywords
            this.hitList = this.kbService.generateListOfArticles(this.searchQuery);
            // Modified list of keywords to show user
            this.hitListKeywords = this.kbService.normalizeSearchQuery(this.searchQuery);
            this.hitListLen = this.hitList.length;
        } else {
            return;
        }
    }

    loadMainArticle(articleTitle) {
        this.mainArticle = this.kbService.findSingleArticle(articleTitle);
    }

    destroyMainArticle(): void {
        this.mainArticle = null;
    }

    onClose(val) {
        this.actionEvents.emit({name: val});
    }

    ngOnDestroy() {
    }
}
