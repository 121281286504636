<div class='forgot-password'>
  <mat-card *ngIf='resetPasswordData === null' fxFlex.xs="95">
    <mat-progress-bar mode="indeterminate" *ngIf='loading'></mat-progress-bar>
    <section class='content' *ngIf='!successMessage; else onSuccessMessage'>
      <mat-card-title>Account recovery</mat-card-title>
      <mat-card-subtitle>Enter the username/email associated with the account you are trying to recover.</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]='usernameForm' fxLayout='column' (submit)='onForgotPassword()'>
          <p class='error' *ngIf='error'>{{error}}</p>
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Username</mat-label>
            <input matInput formControlName='username'>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color='primary' (click)="onForgotPassword()" [disabled]='usernameForm.invalid || loading'>Next</button>
      </mat-card-actions>
    </section>

    <ng-template #onSuccessMessage>
      <section class='content'>
        <mat-card-title>Account recovery</mat-card-title>
        <mat-card-content>
          <p>{{successMessage}}</p>
          <a routerLink='/'>Procceed to login page</a>
        </mat-card-content>

      </section>
    </ng-template>
  </mat-card>
</div>

