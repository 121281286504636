<div fxLayout='column'>
  <mat-card style="height: 485px">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>{{widget.title}} <span *ngIf="!(this.tableComponent.data | async)?.length" class="no-data-message">
          (No Results Found)
          <mat-icon [inline]="true" matTooltip="Is your query valid? Current Query: {{widget.params.query}}" [matTooltipPosition]="'above'">help</mat-icon>
        </span>
        </mat-card-title>
        <mat-card-subtitle>{{widget.description}}</mat-card-subtitle>
      </mat-card-title-group>
      <div class='header' fxLayout='row' fxLayoutAlign='end center' fxFlex>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="widget_menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)='fetchWidgetData(this._filters)'>
            <mat-icon>refresh</mat-icon>
            <span>Refresh</span>
          </button>
          <button mat-menu-item (click)="editWidget()">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)='deleteDialog()'>
            <mat-icon>delete_forever</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
    </mat-card-header>

    <div>
      <app-table #tableComponent
                 [headerStream]="tableHeader"
      ></app-table>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'height.px': height * 0.4 }" *ngIf="loading; else errorTemplate">
          <div class="indicator-container">
            <ngx-loading *ngIf="loading; else errorTemplate" [show]="true"></ngx-loading>
          </div>
        </div>
        <ng-template #errorTemplate>
          <p  [ngStyle]="{'height.px': height * 0.9 }" class='error' *ngIf='error'>There has been an error. Please click <a class="refresh" (click)="fetchWidgetData()">here</a> to refresh, or try again later</p>
        </ng-template>
        <div [id]='widget.widget_id' [ngStyle]="{'height.px': height * 0.1 }" ></div>
      </mat-card-content>
    </div>

  </mat-card>
</div>
