import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import xor from 'lodash/xor';
import { filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { InputVariableService } from 'src/app/sweet-shared/services/input-variable.service';
import { AppService } from '@app/app.service';
import { WidgetModel } from '@sweet-shared/models/widget.model';
import { DashboardService } from '@app/pages/dashboards/services/dashboard.service';

@Component({
    selector: 'app-setting-widget',
    templateUrl: './setting-widget.component.html',
    styleUrls: ['./setting-widget.component.scss']
})
export class SettingWidgetComponent implements OnInit, OnDestroy {
    private destroyer$ = new Subject();
    private apiCall = null;

    public static readonly DEFAULT_VALUES_KEY = 'DEFAULT_WIDGET_VALUES';
    private relevantFields: { [key: string]: string } = {
    company: null,
    dateFrom: null,
    dateTo: null
};


@Input() widget: WidgetModel = null;
@Input() inputVariableService: InputVariableService;

@Output() loading: EventEmitter<any> = new EventEmitter<any>();
@Output() widgetAction: EventEmitter<any> = new EventEmitter();

@Input() settingFilterAction: EventEmitter<any> = new EventEmitter<any>();

dropdownOptions: any[] = [];
dropdownControl: FormControl;

error: string = null;
fields: FormlyFieldConfig[] = [];
form = new FormGroup({});
model: any = {};
options: FormlyFormOptions = {};

static getDefaultObject() {
    return JSON.parse(window.sessionStorage.getItem(SettingWidgetComponent.DEFAULT_VALUES_KEY) || '{}');
}

static setDefaultObjectValue(key: string, value: any) {
    const current = SettingWidgetComponent.getDefaultObject();
    const updated = {
        ...current,
        [key]: value
    };
    window.sessionStorage.setItem(SettingWidgetComponent.DEFAULT_VALUES_KEY, JSON.stringify(updated));
}

static getDefaultObjectValue(key: string): string[] | string {
    return SettingWidgetComponent.getDefaultObject()[key];
}

constructor(
    private widgetDataService: AppService<any[]>,
    private http: HttpClient,
    private dashboardService: DashboardService,
) { }

ngOnInit(): void {
    this.dropdownControl = new FormControl(this.getDefaultValue());
    this.inputVariableService.inputVariables.pipe(
        takeUntil(this.destroyer$),
        map(f => {
            let shouldFetch = false;
            const payload = Object.keys(this.relevantFields).reduce((prev: any, next: string) => {
                if (prev[next] !== f[next]) {
                    shouldFetch = true;
                    prev[next] = f[next];
                }
                return prev;
            }, this.relevantFields);
            return { payload, shouldFetch };
        }),
        filter(pkg => pkg.shouldFetch)
    ).subscribe(pkg => {
        this.relevantFields = pkg.payload;
        this.getData(pkg.payload);
    });

    this.dropdownControl
        .valueChanges
        .pipe(startWith(this.getDefaultValue()), pairwise())
        .subscribe(([prev, next]) => {
            const valueSelected = xor(next, prev);
            if (prev.length < next.length) {
                // New value selected
                // let check if it is either _Any_ or % that has just been selected and handle it properly.
                if (valueSelected[0] === '%') {
                    // user just picked select all
                    const allValues = this.dropdownOptions.filter(v => v.value !== '_Any_').map(v => v.value);
                    this.dropdownControl.setValue(allValues);
                    this.inputVariableService.setSingleInputVariables(this.widget.params.inputName, allValues);
                } else if (valueSelected[0] === '_Any_') {
                    // we should deselect everything but _Any_
                    this.dropdownControl.setValue(['_Any_']);
                    this.inputVariableService.setSingleInputVariables(this.widget.params.inputName, ['_Any_']);
                } else {
                    // some other value have been added, we need to either remove _Any_ and/or add %
                    if (next.includes('_Any_')) {
                        // remove it
                        const value = next.filter(v => v !== '_Any_');
                        this.dropdownControl.setValue(value);
                        this.inputVariableService.setSingleInputVariables(this.widget.params.inputName, value);
                    }
                }
            } else {
                // here if all is in the list remove it
                if (next.includes('%')) {
                    const value = next.filter(v => v !== '%');
                    this.dropdownControl.setValue(value);
                    this.inputVariableService.setSingleInputVariables(this.widget.params.inputName, value);
                                }
                            }

                        });
                }

            private getDefaultValue() {
                    if (this.widget.params.isMultiSelect) {
                        return [this.widget.default_value ?? '_Any_'];
                    }
                    return this.widget.default_value ?? '';
            }

        private getData(filters: any): void {
                this.loading.emit(true);
                this.apiCall = this.widgetDataService.put('widgets', `${this.widget.dashboard_id}/${this.widget.widget_id}/get-data`, {}, filters, true);
                this.apiCall
                    .then((response: any) => {
                        if (response.status === 202) {
                            return this.getData(filters);
                        } else {
                            this.http.get(response.data.url).subscribe((data: any[]) => {
                                const widgetData = data;
                                this.dropdownOptions = this.widget.params.isMultiSelect ? [{ label: 'Select All', value: '%' }, ...widgetData] : widgetData;

                                // persist the data for the dropdown in the local storage to be used later on
                                localStorage.setItem(this.widget.params.inputName, JSON.stringify(widgetData));
                                this.loading.emit(false);
                                this.apiCall = null;
                            });
                        }
                    })
                    .catch((error: any) => {
                        this.error = error.response;
                        this.loading.emit(false);
                        this.apiCall = null;
                    });
                if (this.widget.type === 'string_dropdown') {
                this.loading.emit(false);
            }
        }

            deleteWidget() {
                this.dashboardService.deleteWidget(this.widget, this.widgetAction);
            }

            noNullFields(obj: any, fields: string[]): boolean {
                if (!!!obj || !!!fields) {
                    return false;
                }
                return Object.keys(fields).reduce((acc, key) => {
                    return !!obj[key] || acc;
                }, false);
            }

            ngOnDestroy() {
                this.destroyer$.next(true);
                this.destroyer$.complete();
                if (this.apiCall) {
                    this.widgetDataService.cancel(this.apiCall);
                    this.apiCall = null;
                }
            }
        }
