import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import sortBy from 'lodash/sortBy';
import { Subject } from 'rxjs';
import * as am4core from '@amcharts/amcharts4/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4charts from '@amcharts/amcharts4/charts';

import { AppService } from '@app/app.service';
import { InputVariableService } from '@sweet-shared/services/input-variable.service';
import { WidgetInfoComponent } from '@sweet-shared/components/widgets/widget-info/widget-info.component';

@Component({
  selector: 'app-multi-bar',
  templateUrl: './multi-bar.component.html',
  styleUrls: ['./multi-bar.component.scss']
})
export class MultiBarComponent implements OnInit, OnDestroy {
  private apiCall = null;
  private destroyer$ = new Subject();
  private _height;
  loading = false;

  @Input() widgetDetails: any = null;
  @Input() set height(h) {
    this._height = h;
  }
  get height() {
    return this._height;
  }

  @Output() widgetAction = new EventEmitter();

  inputVariables: { [key: string]: any } = null;
  shouldFetch = false;
  errorMessage: string = null;

  // chart element
  chart: any = null;

  constructor(
    private inputVariablesService: InputVariableService,
    private widgetService: AppService<any>,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Set the input variables
    this.setWidgetInputVariables();
    this.setInputVariableSubscription();
  }

  private setInputVariableSubscription(): void {
    this.inputVariablesService.inputVariables.subscribe(inputVariables => {
      Object.keys(inputVariables).forEach(name => {
        if (this.inputVariables.hasOwnProperty(name)) {
          if (this.inputVariables[name] === null || this.inputVariables[name] !== inputVariables[name]) {
            this.inputVariables[name] = inputVariables[name];
            this.shouldFetch = true;
          }
        }
      });

      if (this.shouldFetch) {
        if (!Object.values(this.inputVariables).includes(null)) {
          this.fetchWidgetData();
        } else {
          this.shouldFetch = false;
        }
      }
    });
  }

  private setWidgetInputVariables(): void {
    const widgetInputVariables = this.widgetDetails?.input_variables ?? [];
    this.inputVariables = widgetInputVariables.reduce((acc, next) => {
      acc[next.input_variable_name] = next.default_value;
      return acc;
    }, { company: null, dateFrom: null, dateTo: null });
  }

  private fetchWidgetData(): void {
    let response = null;
    this.errorMessage = null;
    this.loading = true;
    this.apiCall = this.widgetService.put(`widgets`, `${this.widgetDetails.dashboard_id}/${this.widgetDetails.widget_id}/get-data`, null, this.inputVariables, true);
    this.apiCall.then(res => response = res)
      .catch(err => this.errorMessage = err)
      .finally(() => {
        if (!this.errorMessage) {
          // check the response and see if we get a 200 or 200 and handle he 202 properly
          if (response.status === 200) {
            const dataUrl = response.data.url;
            this.getWidgetDataResult(dataUrl);
          } else if (response.status === 202) {
            // The query is still running in the backend, let reping it
            return this.fetchWidgetData();
          }
        }
        this.shouldFetch = false;
        this.loading = false;
      }
      );
  }

  private getWidgetDataResult(url: string) {
    this.httpClient.get(url).subscribe((res: any[]) => {
      const valueXAxis = this.widgetDetails.params.athenaStatement?.standard[0]?.select_statement[0]?.alias ?? [];
      if (!this.chart) {
        this.chart = am4core.create(this.widgetDetails.widget_id, am4charts.XYChart);
        // value x axis

        // since it is a brand new chart, let set the legend
        this.chart.legend = new am4charts.Legend();

        // the legend has its own container, let move it there
        const legendContainer = am4core.create(`${this.widgetDetails.widget_id}-legend`, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        this.chart.legend.parent = legendContainer;

        // build the value axis
        this.buildDateAxis();
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        // set the scrollbar
        this.chart.scrollbarX = new am4core.Scrollbar();
      }

      const snapTo = this.chart?.cursor?.snapToSeries || [];
      if (res.length > 0) {
        const row = res[0];
        Object.keys(row).forEach((key: string) => {
          if (this.chart.series.values.find(item => item.dataFields.valueY === key)) {
            return;
          }
          if (key !== valueXAxis) {
            snapTo.push(this.createBarSeries(key, key, valueXAxis, true));
          }
        });
      }
      this.chart.data = res = sortBy(res, [(o) => o[`${valueXAxis}`]]);
    });
  }

  buildDateAxis(): void {
    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.groupData = true;
    dateAxis.groupCount = 100;
    dateAxis.renderer.minLabelPosition = 0.05;
    dateAxis.renderer.maxLabelPosition = 0.95;
    return dateAxis;
  }

  private createBarSeries(field: string, name: string, xValueField: string, hiddenInLegend: boolean): void {
    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = xValueField;

    series.name = name;
    series.tooltipText = `{name}: [bold]{valueY}[/]\n${xValueField}: [bold]{dateX}`;

    series.strokeWidth = 2;
    if (hiddenInLegend) {
      series.hiddenInLegend = false;
    }
    series.tooltip.pointerOrientation = 'vertical';
    series.width = 4;
    series.groupFields.valueY = 'max';
    series.stacked = true;
    series.dataItems.template.locations.categoryX = 0.5;

    // Set the cursor
    this.chart.cursor = new am4charts.XYCursor();
    return series;
  }

  refresh(): void {
    this.fetchWidgetData();
  }

  info(): void {
    this.dialog.open(WidgetInfoComponent, {
      width: '40vw',
      maxWidth: '95vw',
      data: this.widgetDetails
    });
  }

  ngOnDestroy(): void {
    this.chart?.dispose();
    this.destroyer$.next(false);
    this.destroyer$.complete();

    if (this.apiCall) {
      this.widgetService.cancel(this.apiCall);
    }
  }

}
