<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="layout-padding dashboard__detail--page" *ngIf="selectedDashboard" fxLayout='column' fxFlex>
  <div fxLayout.gt-sm='row' fxLayout.lt-md="column" fxLayoutAlign='space-between start'
    fxLayoutAlign.lt-md="start stretch">
    <button mat-stroked-button type="button" routerLink="/dashboard" class="dashboard_list--back">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Dashboard List
    </button>
    <h2 class="text-center"><strong>Dashboard Details - {{selectedDashboard.title}}</strong></h2>
    <div fxLayout.gt-sm='row' fxLayout.lt-md="column" fxLayoutAlign='space-between start'
      fxLayoutAlign.lt-md="space-between stretch">
      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>Dashboard Actions
      </button>
      <mat-menu #menu="matMenu">
        <button *ifUserCan="['widget.create-from-template', 'widget.create-custom']; any: true" mat-menu-item
          [disabled]="!formReady" matTooltip="Create a Widget" (click)="addSettingWidget()">
          <mat-icon>widgets</mat-icon>
          New Widget / Filter
        </button>
        <ng-container *appNotProdReady="true">
          <button *ifUserCan="'dashboard.create-custom'" (click)="onDuplicate()" mat-menu-item type="button">
            <mat-icon>content_copy</mat-icon>
            Duplicate Dashboard
          </button>
        </ng-container>
        <ng-container *appNotProdReady="false">
          <button *ifUserCan="['report.create-from-template', 'report.create-custom']; any: true" mat-menu-item
            (click)="reportDialog()">
            <mat-icon>report</mat-icon>
            Create Report
          </button>
        </ng-container>
        <ng-container *appNotProdReady="false">
          <button *ifUserCan="'report.read'" mat-menu-item type="button" (click)="viewReport()">
            <mat-icon>assignment</mat-icon>
            View Reports
          </button>
        </ng-container>
        <button *ngFor="let queryWidget of queryWidgets" mat-menu-item type="button"
          (click)="openQueryWidgetBuilder(queryWidget.params.variable_name)">
          <mat-icon>search</mat-icon>
          Set {{queryWidget.title}}
        </button>
      </mat-menu>
    </div>
  </div>
  <app-portal-loader [loadingStatus]="isUpdating"></app-portal-loader>

  <mat-accordion>
    <mat-expansion-panel expanded="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <app-portal-loader [loadingStatus]="!formReady || dashboardFilterLoading"></app-portal-loader>

      <mat-expansion-panel-header class="dashboard-filter-panel">
        <mat-panel-title>
          <div fxLayout="column">
            <p>Dashboard Widget Global Parameters</p>
            <p class="dashboard-filter-panel--description">
              {{selectedDashboard.description}}
            </p>
          </div>

        </mat-panel-title>
        <mat-divider></mat-divider>
      </mat-expansion-panel-header>
      <mat-card *ifUserCan="'widget.execute'">
        <div class='setting-widget-container'>
          <div *ngFor='let widget of dropdownWidgets'>
            <app-setting-widget [widget]='widget' [inputVariableService]='inputVariableService'
              (widgetAction)='widgetAction($event)'></app-setting-widget>
          </div>
        </div>

        <!-- DateRange and Company -->
        <div class='filter-container' *ngIf='inputVariableService.inputVariableReady | async'>
          <div class='filter'>
            <app-datetime-picker [appearance]="outline" [defaultValue]="defaultSelectedDate" [options]="eventDateRange" (onDateSelected)="handleDateSelected($event)"></app-datetime-picker>
          </div>
          <mat-form-field>
            <mat-label>Company</mat-label>
            <mat-select [formControl]='companyControl'>
              <mat-option>
                <ngx-mat-select-search #sText [formControl]="searchFormControl" [placeholderLabel]="'Search Company'"
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of companyOptions | inputSearch: sText.value" [value]="option.value">
                {{option.friendly}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='filter-btn'>
          <button mat-raised-button color="primary" (click)='applyFilter()'>Apply</button>
        </div>
      </mat-card>


    </mat-expansion-panel>
  </mat-accordion>


  <div class="lock-row" fxLayout="row" fxLayoutAlign="space-between end" *ngIf="selectedDashboard.widgets?.length">
    <div>
      <button *ngFor="let queryWidget of queryWidgets" mat-stroked-button
        (click)="openQueryWidgetBuilder(queryWidget.params.variable_name)">Set {{queryWidget.title}}
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <button mat-icon-button *ngIf="gridsterLocked | async; else: gridsterUnLocked" (click)="unlockGridster()"
      matTooltip="Unlock to rearrange widgets" [matTooltipPosition]="'left'">
      <mat-icon>lock</mat-icon>
    </button>
    <ng-template #gridsterUnLocked>
      <button mat-icon-button (click)="lockGridster()" matTooltip="Lock to save widget positions"
        [matTooltipPosition]="'left'" matTooltipShowDelay="1000">
        <mat-icon>lock_open</mat-icon>
      </button>
    </ng-template>
  </div>

  <ng-container>
    <div *ngIf="!loading || gridsterItems.length > 0" [ngStyle]="{'height.px': gridsterContainerHeight}"
      class="gridster_container">
      <gridster [options]="gridsterOptions" fxLayout='row wrap' fxLayoutAlign='start start' #gridster>
        <gridster-item [item]="item.position" *ngFor="let item of gridsterItems; trackBy:widgetTrackBy"
          [ngSwitch]='item.widget.type'>

          <div *ngSwitchCase="'table'" style="overflow: scroll; height: 450px">
            <app-table-widget [widgetDetails]='item.widget' [inputVariableService]='inputVariableService'
              (widgetAction)='widgetAction($event)'></app-table-widget>
          </div>

          <!-- Multi bar -->
          <div *ngSwitchCase="'multi_bar'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'multi_bar'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- Multi line -->
          <div *ngSwitchCase="'multi_line'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'multi_line'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- Map -->
          <div *ngSwitchCase="'map'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'map'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- Map -->
          <div *ngSwitchCase="'pie'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'pie'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- Single Metric -->
          <div *ngSwitchCase="'single_metric'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'single_metric'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- Solid Gauge -->
          <div *ngSwitchCase="'solid_gauge'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'solid_gauge'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- Fuel Gauge -->
          <div *ngSwitchCase="'fuel_gauge'">
            <app-widget-wrapper [widgetDetails]="item.widget" [height]="item.height" [widgetType]="'fuel_gauge'"
              (widgetAction)='widgetAction($event)'></app-widget-wrapper>
          </div>

          <!-- This will remain for the table component. We will decide as a team how to deal with this. -->
          <div *ngSwitchDefault>
            <app-widget-header [widget]='item.widget' [height]='item.height' [parentDashboard]="selectedDashboard"
              (widgetAction)='widgetAction($event)' [filterAction]="filterAction" [newWidgetAction]="newWidgetAction"
              [eventDateRange]="eventDateRange"></app-widget-header>
          </div>

        </gridster-item>
      </gridster>
    </div>
  </ng-container>

  <ng-container>
    <div *ngIf="!loading && gridsterItems.length === 0" fxLayout="row" class="dashboard_prompt-empty"
      fxLayoutAlign="center center">
      <mat-card>
        <mat-card-header>
          <mat-card-title>This dashboard is empty. Please add a widget.</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayoutAlign="center center">
          <mat-card-actions>
            <button mat-stroked-button color="primary" (click)='addSettingWidget()'>
              <mat-icon>add</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</div>