import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ControlConfig} from '../../models/control-config.model';

export interface FilterAction {
  name: 'SEARCH' | 'DOWNLOAD_RESULT';
  data: object;
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() configOptions: ControlConfig[];

  @Output() submitSearchClickEvent: EventEmitter<any> = new EventEmitter();

  searchForm: FormGroup;
  searchBody: object;

  // actions emitter
  actionsHandler: EventEmitter<FilterAction> = new EventEmitter();


  constructor(
    private fb: FormBuilder
    ) {
  }

  ngOnInit() {
    this.searchForm = this.generateSearchForm(this.searchBody);
  }

  triggerAction(name, data: object) {
    if (!name) {
      return;
    }
    this.actionsHandler.emit({name, data});
  }

  generateSearchForm(search: any) {
    const config = this.generateControlsConfig(this.configOptions);
    return this.fb.group(config);
  }

  generateControlsConfig(options) {
    return options.reduce((accummulator, currentValue) => {
      accummulator[currentValue.controlName] = [currentValue.defaultValue ? currentValue.defaultValue : '', currentValue.validators ? currentValue.validators : []];
      return accummulator;
    }, {});
  }

  onSubmit() {
    this.submitSearchClickEvent.emit(this.searchForm.value);
  }

}
