import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import Graph from "graphology";
import { Sigma } from "sigma";
import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";
import NodeProgramBorder from "./node.border";
import random  from 'graphology-layout/random';
import forceLayout from 'graphology-layout-force';
import noverlap from 'graphology-layout-noverlap';

export interface Node {
  ip: string;
  type: string;
  color: string;
  image?: string;
  size?: number;
}

export interface Edge {
  source: string;
  destination: string;
  size?: number;
  label?: string; //protocol
  type: string;
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("container") container: ElementRef | null = null;
  @Input() pivotData: any[] = [];
  @Input() pivotHeaders: any[] = [];
  graph: Graph = new Graph();
  sigma?: Sigma;
  formatted_data: any[] = [];
  Nodes: Set<Node> = new Set<Node>();
  Edges: Set<Edge> = new Set<Edge>();

  constructor() { }

  ngOnInit(): void {
    this.formatData();
    this.buildGraph();
  }

  formatData() {
    const headers = this.pivotHeaders.map(items => items.name)  
    this.pivotData.forEach(data => {
      const allKeys = Object.keys(data);
      const result = allKeys.reduce((next, key) => {
        if (headers.includes(key)) {
          return { ...next, [key]: data[key] };
        } else {
          return next;
        }
      }, {});
      this.formatted_data.push(result);
    });
  }

  buildGraph() {
    const RED = "#FA4F40";
    const BLUE = "#727EE0";
    let tmpNodeSet: Set<String> = new Set<String>();
    let tmpEdgeSet: Set<String> = new Set<String>();
    this.formatted_data.forEach(evt => {
      if(evt.JCEF_srcIP && !tmpNodeSet.has(evt.JCEF_srcIP)){
        this.Nodes.add({ip: evt.JCEF_srcIP, type: "Source", color: RED})  
        tmpNodeSet.add(evt.JCEF_srcIP)      
      }
      if(evt.JCEF_dstIP && !tmpNodeSet.has(evt.JCEF_dstIP)){
        this.Nodes.add({ip: evt.JCEF_dstIP, type: "Destination", color: BLUE})
        tmpNodeSet.add(evt.JCEF_dstIP)
      }
      if(evt.JCEF_srcIP && evt.JCEF_dstIP && !tmpEdgeSet.has(evt.JCEF_srcIP + '-' + evt.JCEF_dstIP)){
        this.Edges.add({source: evt.JCEF_srcIP, destination: evt.JCEF_dstIP, type: 'line', label: evt.JCEF_evtProto})
        tmpEdgeSet.add(evt.JCEF_srcIP + '-' + evt.JCEF_dstIP)
      }
    })
    this.Nodes.forEach(node => {
      this.graph.addNode(node.ip, { size: 15, label: node.ip, type: "image", color: node.color });
    })
    this.Edges.forEach(edge => {
      this.graph.addEdge(edge.source, edge.destination, { type: edge.type, label: edge.label, size: 5 });
    })

    this.graph.nodes().forEach((node, i) => {
      const angle = (i * 2 * Math.PI) / this.graph.order;
      this.graph.setNodeAttribute(node, "x", 100 * Math.cos(angle));
      this.graph.setNodeAttribute(node, "y", 100 * Math.sin(angle));
    });

    noverlap.assign(this.graph, {
      maxIterations: 100,
      settings: {
        margin: 10,
        ratio: 2
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.container) {
      this.container.nativeElement.style.height = '500px';
      this.sigma = new Sigma(this.graph, this.container.nativeElement, {
        // We don't have to declare edgeProgramClasses here, because we only use the default ones ("line" and "arrow")
        nodeProgramClasses: {
          image: getNodeProgramImage(),
          border: NodeProgramBorder,
        },
        renderEdgeLabels: true,
      })
    }    
  }

  ngOnDestroy(): void {
    if (this.sigma) {
      this.sigma.kill();
    }
  }
}
