<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field fxFill *ngIf="(this.companies$ | async) && !data.edit">
        <mat-label>Company</mat-label>
        <mat-select formControlName="profile" required>
          <mat-option
            *ngFor="let company of data.companies$ | async"
            [value]="company.id"
            >{{ company.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="data.edit" class="example-full-width" fxFlexFill>
        <mat-label>Company</mat-label>
        <input
          formControlName="profile"
          matInput
          name="profile"
          type="text"
          required
        />
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>Username</mat-label>
        <input
          formControlName="username"
          matInput
          name="username"
          type="text"
          required
        />
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>First Name</mat-label>
        <input
          formControlName="given_name"
          matInput
          name="given_name"
          type="text"
          required
        />
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>Last Name</mat-label>
        <input
          formControlName="family_name"
          matInput
          name="family_name"
          type="text"
          required
        />
      </mat-form-field>

      <mat-form-field class="example-full-width" fxFlexFill>
        <mat-label>Account Email</mat-label>
        <input
          formControlName="email"
          matInput
          name="email"
          type="email"
          required
        />
      </mat-form-field>

      <mat-form-field fxFill>
        <mat-label>Access Attributes</mat-label>
        <mat-select
          [compareWith]="compareFn"
          formControlName="access_attributes"
          multiple
        >
          <mat-option
            *ngFor="let attribute of attributes"
            [value]="attribute"
            >{{ attribute }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFill>
        <mat-label>Roles</mat-label>
        <mat-select formControlName="gender" multiple required>
          <mat-option *ngFor="let role of roles$ | async" [value]="role.name">{{
            role.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ifUserCan="'account.edit-permissions'">
        <h2>Granular Permissions</h2>
        <mat-card-subtitle
          >Below you'll be able to modify user permissions on a granular
          level</mat-card-subtitle
        >
        <app-permission-list
          [user]="data.loggedUser"
          *ngIf="showPermissions"
          [userRoles]="userRoles$"
          (permissionChanged)="updatePermissions($event)"
        ></app-permission-list>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex>
        <button mat-button color="warn" type="button" (click)="onCancel()">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
        <button
          *ngIf="!data.edit"
          mat-stroked-button
          color="primary"
          type="submit"
          [disabled]="addUserForm.invalid || loading"
        >
          <mat-icon>done</mat-icon>
          Submit
        </button>
        <button
          *ngIf="data.edit"
          mat-stroked-button
          color="primary"
          type="submit"
          [disabled]="addUserForm.invalid || loading"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
