<mat-card>
  <mat-card-title>{{widget.title}}</mat-card-title>
  <mat-card-content>
    <section *ngIf='formReady && !error; else loading'>
      <query-builder [(ngModel)]='queryBuilderModel' [config]="queryBuilderConfig" (ngModelChange)="setQueryAthenaStatement($event)"></query-builder>
    </section>

    <ng-template #loading>
      <section class='loading'>
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
      </section>
    </ng-template>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-dialog-close mat-button color="warn">Cancel</button>
    <button mat-stroked-button color="primary" (click)="saveWidgetQuery()">Save</button>
  </mat-card-actions>
</mat-card>
