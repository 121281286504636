import { FormControl, Validators } from '@angular/forms';
import { FieldInterface, FormBuilderComponent } from './../../../../sweet-shared/components/form-builder/form-builder.component';
import { AuthService } from './../../../auth/services/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import * as userActions from '../../store/user/user.actions';
import * as companiesActions from '../../store/company.actions';
import { filter, map, takeUntil, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, from, ReplaySubject, Subject } from 'rxjs';
import { UserStatusEnum, User } from '../../store/user/user.model';
import { Router } from '@angular/router';
import { TableComponent } from '../../../../sweet-shared/components/table/table.component';
import { AppService } from '../../../../app.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountsService } from '../../services/accounts.service';
import { PermissionService } from '../../../../sweet-shared/services/permission.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { SnackbarService } from '../../../../shared-services/snackbar.service';
import { CompaniesService } from '../../../../shared-services/companies.service';
import { ModalComponent } from '../../../../sweet-shared/components/modal/modal.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  loggedInUser: any = null;
  usersDataStream: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  usersData: any[] = [];
  usersHeaders: BehaviorSubject<any[]> = new BehaviorSubject([]);
  loading = false;
  filterReady = false;
  userFilterDetails: FieldInterface[] = [];
  systemRoles = [];
  // List of allowed companies the user has access to
  companies: any[] = [];
  companies$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  // All the possible AWS user statuses
  userStatusEnum = UserStatusEnum;
  userStatuses = Object.values(UserStatusEnum);
  @ViewChild('usersTable') usersTable: TableComponent;
  @ViewChild('usersForm') usersForm: FormBuilderComponent;

  // contains some of the granular permissions for dashboards
  accountsActions = {
    deletePermission: 'account.delete',
    editPermission: 'account.edit'
  };

  constructor(
    private store: Store<State>,
    private router: Router,
    private authService: AuthService,
    private appService: AppService<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private accountsService: AccountsService,
    private snackbarService: SnackbarService,
    private permissionService: PermissionService,
    private companiesService: CompaniesService
  ) { }

  ngOnInit() {
    this.pageSetup();
  }

  private pageSetup() {
    this.store.dispatch(companiesActions.loadCompanies());
    this.authService.getAuthenticatedUser().pipe(
      take(1),
      takeUntil(this.destroyer$)
    ).subscribe(user => {
      this.loggedInUser = user;

      this.store.select(state => state.companies.companies).pipe(
        takeUntil(this.destroyer$),
        filter(val => !!val)
      ).subscribe(companies => {

        // the list of compani(es)  user has access to
        this.companies = this.companiesService.companyNameAndFriendly(companies);
        this.companies$.next(companies);

        from(this.appService.get('permissionRoles', '', null))
          .pipe(
            map(res => res.map((role: any) => {
              return {
                value: role.name,
                friendly: role.name,
                permissions: role.permissions

              };
            })),
            takeUntil(this.destroyer$)
          )
          .subscribe(permissionRoles => {

            this.systemRoles = permissionRoles;
            this.userFilterDetails = [
              {
                component: 'input-select',
                label: 'Company Filter',
                name: 'companyFilter',
                flex: '',
                placeholder: 'Company Filter',
                defaultValue: [user.profile],
                validators: [Validators.required],
                required: true,
                selectMultiple: true,
                signalOnChanged: true,
                options: this.companies,
                showSearch: true,
                showSearchFormControl: new FormControl(),
                showSelectAll: true
              },
              {
                component: 'input-select',
                label: 'Roles Filter',
                name: 'rolesFilter',
                flex: '',
                placeholder: 'Roles Filter',
                defaultValue: [],
                validators: [],
                selectMultiple: true,
                signalOnChanged: true,
                options: this.systemRoles
              },
              {
                component: 'input-select',
                label: 'User Status',
                name: 'statusFilter',
                flex: '',
                placeholder: 'Status Filter',
                defaultValue: [],
                validators: [],
                selectMultiple: true,
                signalOnChanged: true,
                options: this.userStatuses.map(c => {
                  return {
                    value: c,
                    friendly: c
                  };
                })
              }
            ];
            this.filterReady = true;
            this.store.dispatch(userActions.loadUsers({ companyFilter: [user.profile] }));
            const usersSub = this.store.select(state => state.users.users).pipe(
              filter(val => !!val),
              takeUntil(this.destroyer$),
              map(items => {
                return items.map(item => {
                  // to 'flatten' user objects and make nested properties
                  // able to have columns in the table
                  const newItem = {
                    ...item,
                    ...item.Attributes
                  };
                  return newItem;
                });
              })
            );
            usersSub.subscribe((val: any) => {
              this.usersDataStream.next(val);
              this.usersData = val;
              this.loading = false;
              const headers = [
                {
                  name: 'family_name',
                  friendly: 'Last Name'
                },
                {
                  name: 'given_name',
                  friendly: 'First Name'
                },
                {
                  name: 'Username',
                  friendly: 'Username'
                },
                {
                  name: 'profile',
                  friendly: 'Company'
                },
                {
                  name: 'email',
                  friendly: 'Email'
                },
                {
                  name: 'UserStatus',
                  friendly: 'Status'
                },
                {
                  name: 'Enabled',
                  friendly: 'Enabled'
                },
                {
                  name: 'gender',
                  friendly: 'Role'
                },
                {
                  name: 'UserLastModifiedDate',
                  friendly: 'Last Modified',
                  type: 'TimeStamp'
                },
                {
                  name: 'UserCreateDate',
                  friendly: 'Created',
                  type: 'TimeStamp'
                },

              ];
              this.usersHeaders.next(headers);
              if (this.usersForm) {
                const formSub = this.usersForm.form.valueChanges.pipe(
                  takeUntil(this.destroyer$)
                );
                combineLatest(usersSub, formSub)
                  .subscribe((usersAndFormValues) => {
                    const [users, formValues] = usersAndFormValues;
                    this.filterUsers(users, formValues);
                  });
              }
            }
            );
          });
      });
    });
    // to show portal-loader
    this.store.select(state => state.users.loadingUsers).pipe(takeUntil(this.destroyer$))
      .subscribe(isLoading => {
        this.loading = isLoading;
      });
  }

  onCompanyChanged(event: any) {
    this.usersTable.data.next(this.usersData);
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'companyFilter') {
      if (event.data.value.length > 0) {
        this.store.dispatch(userActions.loadUsers({ companyFilter: event.data.value }));
      }
    }
  }

  filterUsers(users, formValues) {
    if (this.usersForm) {
      const { rolesFilter, statusFilter } = formValues;
      let newData = this.usersDataStream.value.slice();
      if (rolesFilter.length) {
        newData = newData.filter((item: any) => {
          const roles: string[] = item.gender ? item.gender.split(',') : [];
          return rolesFilter.some(permission => {
            return roles.includes(permission);
          });
        });

      }
      if (statusFilter.length) {
        newData = newData.filter(item => {
          return statusFilter.includes(item.UserStatus);
        });
      }
      this.usersTable.data.next(newData);
    }
  }

  onClick(eventData) {
    const requiredPermission = 'account.read';
    if (this.permissionService.hasPermission(requiredPermission)) {
      this.router.navigate([`/admin/users/${eventData.data.Username}`]).then(() => { });
    } else {
      const dialogRef = this.dialog.open(ModalComponent);
      dialogRef.componentInstance.message = `
            <p>You are missing the required permission('${requiredPermission}') to view account details.</p>
            <p>Please contact your admin for assistance.</p>
        `;
      dialogRef.componentInstance.title = `Missing Required Permission`;
    }
  }

  resetUserPassword(userData) {
    this.appService.post('resetUser', `${userData.Username}/reset`, null, null)
      .then((res) => this.openSnackBar('A reset code was sent', 'Ok'))
      .catch(error => {
        this.openSnackBar(error, 'ok');
      });

  }

  accountActions(actionName, userData) {
    const userSub = this.store.select(state => state.users.users)
      .pipe(
        map(value => {
          return value.find(user => user.sub === userData.sub);
        }),
        take(1)
      )
      .subscribe(newData => {
        this.store.dispatch(userActions.editUser({ user: newData, path: actionName }));
      });
  }

  enableUser(userData) {
    this.accountActions('enable', userData);
  }

  disableUser(userData) {
    this.accountActions('disable', userData);
  }

  deleteUser(userData) {
    this.accountsService.deleteUser(userData);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  onAddUser() {
    this.dialog.open(AddUserComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        title: 'Add User',
        edit: false,
        loggedUser: null,
        companies$: this.companies$
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        //add it to the store
        if (this.loggedInUser.profile === data.Attributes.profile) {
          this.store.dispatch(userActions.addUser({ user: data }));
        }
      }
    });

    // .afterClosed().subscribe(data => {
    //   if (data) {
    //     this.appService.post('user', '', null, data)
    //       .then((userResponse) => {
    //         if (this.loggedInUser.profile === userResponse.Attributes.profile) {
    //           this.store.dispatch(userActions.addUser({ user: userResponse }));
    //         }
    //         this.snackbarService.open('Your user was created', 'Ok');
    //       })
    //       .catch((error) => {
    //         this.snackbarService.open(error.response.data.message);
    //       });
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

}
