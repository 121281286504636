import {environment} from '../../../../environments/environment.prod';
import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import Amplify, {Auth} from 'aws-amplify';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from '../../admin/store/user/user.model';
import {Store} from '@ngrx/store';
import {State} from '@app/reducers';

import * as UserPreferenceAction from '../../../pages/user-preference/store/user-preference.actions';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private confirmUserCognitoObject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private authenticating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private recoveryData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private cognitoObject: any = null;
  private mfaRequired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private resetMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<State>,

  ) {
    this.authPrecheck();
  }

  isMfaRequired() {
    return this.mfaRequired;
  }

  getRecoveryData() {
    return this.recoveryData;
  }

  getAuthenticatedUser() {
    return this.user;
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isAuthenticating() {
    return this.authenticating;
  }

  getAuthError() {
    return this.error;
  }

  getResetMessage() {
    return this.resetMessage;
  }

  getConfirmUserCognitoObject() {
    return this.confirmUserCognitoObject;
  }

  login(username: string, password: string) {
    this.authenticating.next(true);
    this.error.next(null);
    Auth.signIn(username, password)
      .then((cognitoUser: any) => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // get the required parameters
          this.cognitoObject = cognitoUser;
          this.authenticating.next(false);
          this.setConfirmUserCognitoObject(cognitoUser);
          this.router.navigate(['/auth/change-password'], {});
        } else if (cognitoUser.challengeName === 'SMS_MFA' || cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.mfaRequired.next(true);
          this.cognitoObject = cognitoUser;
          this.authenticating.next(false);
          this.router.navigate(['/auth/mfa-verifier'], {});
        } else {
          this.setAuthenticatedUser({...cognitoUser.attributes, username: cognitoUser.username});
        }
      })
      .catch((error: any) => {
          if (error.code === 'PasswordResetRequiredException') {
            this.resetMessage.next('Please enter the second code that was sent');
            this.accountRecovery(username);
          } else {
            this.setAuthenticatedUserError(error.message);
          }
        }
      );

  }

  logout() {
    Auth.signOut();
    this.setAuthenticatedUser(null);
    localStorage.clear();
    this.store.dispatch(UserPreferenceAction.clearUserPreference);
    this.router.navigate(['/auth/login']);

  }

  async completeNewPassword(user, newPassword, requiredAttributes) {
    this.authenticating.next(true);
    try {
      await Auth.completeNewPassword(user, newPassword, requiredAttributes);
      this.authenticating.next(false);
      await this.authPrecheck();
      this.router.navigate(['/profile']);
    } catch (e) {
      this.error.next(e);
      this.authenticating.next(false);
    }
  }

  accountRecovery(username) {
    this.authenticating.next(true);
    Auth.forgotPassword(username)
      .then(
        (cognitoUser: any) => {
          const message = `A reset code has been sent via ${cognitoUser.CodeDeliveryDetails.AttributeName} to ${cognitoUser.CodeDeliveryDetails.Destination}`;
          this.recoveryData.next({message, username});
          this.authenticating.next(false);
        }
      )
      .catch(
        (error: any) => {
          if (error.code === 'UserNotFoundException') {
            this.error.next('There is no account associated with the given username');
          } else if (error.code === 'InvalidParameterException') {
            this.error.next(`Password of this account cannot be recovered online. Please contact your administrator.`);
          } else {
            this.error.next(error.message);
          }
          this.authenticating.next(false);
        }
      );
  }

  resetPassword(username: string, code: string, password: string) {
    Auth.forgotPasswordSubmit(username, code, password)
      .then(
        (response: any) => {
          this.authenticating.next(false);
          this.recoveryData.next(null);
          this.login(username, password);
        }
      )
      .catch(
        (error: any) => {
          this.authenticating.next(false);
          this.error.next(error.message);
        }
      );
  }

  configureAmplify(region) {
      Amplify.configure(environment.amplifyConfig[region]);
  }

  confirmSignIn(code) {
    Auth.confirmSignIn(this.cognitoObject, code, this.cognitoObject.challengeName)
      .then(
        (res) => {
          const user = {
            sub: res.signInUserSession.idToken.payload.sub,
            email_verified: res.signInUserSession.idToken.payload.email_verified,
            email: res.signInUserSession.idToken.payload.email,
            phone_number: res.signInUserSession.idToken.payload.phone_number,
            phone_number_verified: res.signInUserSession.idToken.payload.phone_number_verified,
            gender: res.signInUserSession.idToken.payload.gender,
            family_name: res.signInUserSession.idToken.payload.family_name,
            given_name: res.signInUserSession.idToken.payload.given_name,
            profile: res.signInUserSession.idToken.payload.profile,
            username: res.username
          };
          this.cognitoObject = null;
          this.error.next(null);
          this.mfaRequired.next(false);
          this.setAuthenticatedUser(user);
        }
      )
      .catch(
        error => {
          this.setAuthenticatedUserError(error.message);
        }
      );
  }

  // confirming new password needs this specific cognito user object
  private setConfirmUserCognitoObject(confirmingUser) {
    this.confirmUserCognitoObject.next(confirmingUser);
  }

  private setAuthenticatedUser(attrs) {
    this.user.next(attrs);
    if (!!attrs) {
      this.authenticated.next(true);
      this.authenticating.next(false);
    } else {
      this.authenticated.next(false);
      this.authenticating.next(false);
      this.router.navigate(['/auth/login'], {});
    }
  }

  private setAuthenticatedUserError(error) {
    this.error.next(error);
    this.authenticating.next(false);
    this.authenticated.next(false);
  }

  authPrecheck() {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser: any) => this.setAuthenticatedUser({...cognitoUser.attributes, username: cognitoUser.username}))
      .catch((error: any) => {
        this.setAuthenticatedUserError(error.message);
        localStorage.clear();
      });
  }

  getMfaNumber(phoneNumber) {
    Auth.currentAuthenticatedUser()
      .then((user) =>
        Auth.updateUserAttributes(user, {
          phone_number: phoneNumber
        }).then(() =>
          Auth.verifyUserAttribute(user, 'phone_number').then((r) => {
            }
            // this.openSnackBar('A confirmation code was sent', 'ok')
          ).catch(error => {
            this.openSnackBar(error, 'ok');
          }))
      );
  }

  verifyAttribute(code) {
    Auth.currentAuthenticatedUser()
      .then((user) =>
        Auth.verifyUserAttributeSubmit(user, 'phone_number', code)
          .then(() =>
            Auth.setPreferredMFA(user, 'SMS')
              .then(() => {
                  this.openSnackBar('Your phone number was added', 'ok');
                  location.reload();
                }
              )
          ).catch(error => {
          this.openSnackBar(error.message, 'ok');
        })
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  ssoLogin(customProvider) {
    Auth.federatedSignIn({customProvider}).then((res) => {
    }).catch((error) => console.log(error, 'error'));
  }

}

