<mat-card class="main-mat-card">
    <mat-card-title>What's New?</mat-card-title>
    <!-- Update content below including the 3 most recent release notes-->
    <!-- Copy the release notes in changelog.md into a markdown to html converter to make your life easy :)  Update text as needed-->
    <mat-card-content>
        <h2>Release: 4.37.4 (2022-04-19)</h2>
        <h3>Fixes</h3>
        <ul>
            <li><strong>Fix UI issue in the report creation wizard</strong></li>
            <li><strong>Updated Report Creation to support is-active on and off</strong></li>
            <li><strong>Updated set widget input vars to handle case of no input variables</strong></li>
            <li><strong>Version number added back in to UI</strong></li>
            <li><strong>In external Accounts, updated loader and UI style</strong></li>
            <li><strong>Updated formatting of timestamp field for tables when downloaded from a single widget</strong></li>
            <li><strong>Added countdown and updated refresh times to Incidents section</strong></li>
            <li><strong>Updated company module</strong></li>
        </ul>

        <h2>Release: 4.37.3 (2022-04-04)</h2>
        <h3>Fixes</h3>
        <ul>
            <li><strong>Fix UI issue in the report creation wizard</strong></li>
        </ul>

        <h2>Release: 4.37.1 (2022-03-31)</h2>
        <h3>Fixes</h3>
        <ul>
            <li><strong>Fix issue with deleting a table widget from a dashboard</strong></li>
            <li><strong>Updated user verified UI logic</strong></li>
        </ul>
        <h3>Features</h3>
        <ul>
            <li><strong>Added dropdown support for ANY through out portal</strong></li>
            <li><strong>Added ability for Users to add multiple worklogs to an incident</strong></li>
            <li><strong>Added persistent navigation to Incident and Event tables</strong></li>
            <li><strong>Implemented new service on switching environment files</strong></li>
            <li><strong>Updated routes / logic to support APAC and EMEA region login</strong></li>
        </ul>
        <h3>Chores</h3>
        <ul>
            <li><strong>Update Production API Routes</strong></li>
        </ul>

        <h2>Release: 4.37.0 (2022-03-16)</h2>
        <h3>Fixes</h3>
        <ul>
            <li><strong>Update the way Date Objs are passed to the backend</strong></li>
            <li><strong>Update the style of the Company view in Admin section</strong></li>
            <li><strong>Fix bug with widget edit and delete</strong></li>
            <li><strong>Fix bug with widget creation not showing dropdown for Counts in Fields tab</strong></li>
            <li><strong>Update company in-line searching in Log Stream section</strong></li>
            <li><strong>Update the ability to see company edit as Lumen admin only in Admin section</strong></li>
            <li><strong>Adjust default column view in Events section</strong></li>
            <li><strong>Fix issue with widget authentication</strong></li>
            <li><strong>Update reporting to sync with backend updates</strong></li>
        </ul>
        <h3>Chores</h3>
        <ul>
            <li><strong>Update environment file for Production and QA</strong></li>
        </ul>
    </mat-card-content>
    <!-- End of content to update-->
    <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="onNoClick()">Got It!</button>
    </mat-card-actions>
</mat-card>
