<ng-container *ngIf="true">
  <div>
      <h1>{{worklog.title}}</h1>

        <!-- Headers for threat profile-->
        <div fxLayout="row" fxLayoutAlign="space-between-start">

            <!-- Data in worklog is ordered in the array from the backend-->
            <!-- Threat Notes Card-->
            <mat-card fxFlex="35" class="threatCard">
              <mat-card-title>{{worklog.chartsData[0].title}}</mat-card-title>
              <mat-card-content style="overflow: auto; max-height: 100%; height: 475px;" markdown> {{worklog.chartsData[0].message}}</mat-card-content>
            </mat-card>
            <div class="dividerColumn"></div>

            <div fxLayout="column" fxFlex="20">
              <!-- Risk Level Card-->
              <mat-card fxFlex="35" class="threatCard" style="text-align: center">
                <mat-card-title>{{worklog.chartsData[1].title}}</mat-card-title>
                <br>
                <br>
                <mat-card-content fxFill markdown style="color:{{worklog.chartsData[1].risk_color}}; text-align: center; font-size: 3vw; font-weight: bolder;" [data]="worklog.chartsData[1].risk_level"></mat-card-content>
              </mat-card>
              <div class="dividerColumn"></div>

              <!-- Score Card-->
              <mat-card fxFlex="65" class="threatCard" style="text-align: center">
                <mat-card-title>{{worklog.chartsData[2].title}}</mat-card-title>
                <br>
                <br>
                <mat-card-content style="color:{{worklog.chartsData[2].risk_color}}; text-align: center; font-size: 3vw; font-weight: normal;">{{worklog.chartsData[2].risk_score}}</mat-card-content>
                <div style="height: 300px; justify-content: space-evenly" [id]="worklog.chartsData[2].title"></div>
              </mat-card>
            </div>
            <div class="dividerColumn"></div>

            <!-- Threat Chart Card-->
            <mat-card fxFlex="45" class="threatCard">
              <mat-card-title class="chart-title">{{worklog.chartsData[3].title}}</mat-card-title>
              <mat-card-subtitle>{{worklog.chartsData[3].description}}</mat-card-subtitle>
              <mat-card-content style="height: 450px; max-height: available" [id]="worklog.chartsData[3].title"></mat-card-content>
            </mat-card>
        </div>

        <!-- Additional Information title and Body (Mark Down)-->
        <mat-card class="threatCard">
          <mat-card-title>{{worklog.chartsData[4].title}}</mat-card-title>
          <mat-card-content fxFill markdown [data]="worklog.chartsData[4].message"></mat-card-content>
        </mat-card>
  </div>
</ng-container>
