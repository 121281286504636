import {filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UserPrefDatePipe} from '../../pipes/userPrefDate.pipe';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {HeaderSortingService} from '@shared-services/header-sorting.service';


export interface TableHeader {
  name: string;
  friendly: string;
  description?: string;
  type?: string;
}

export interface TableEvent {
  type: string;
  data: any;
}

export interface CheckboxOptions {
  show: boolean;
  headerName: string;
  execute: (row: any, event: MatCheckboxChange) => any;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, AfterViewInit {
  // Here we will be using event emitter to make sure we update the data as they come in.
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: TableHeader[] = [];
  displayedColumnsWithoutAction: TableHeader[] = [];
  displayedRows: string[] = [];
  filterVal: string = null;
  previousIndex: number;

  @Input() data: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input() headerStream: BehaviorSubject<TableHeader[]> = new BehaviorSubject<TableHeader[]>([]);
  @Input() headers: TableHeader[] = [];
  @Input() loading = false;
  @Input() paginatorConfig: any;
  @Input() pageSize: number;
  @Input() showFilter = true;
  @Output() events: EventEmitter<TableEvent> = new EventEmitter<TableEvent>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() minWidth?: null;
  @Input() cellDecorator: (el: any, key: string) => string;
  @Input() actionsTemplate: TemplateRef<HTMLElement>;
  @Input() newRow: (row: any) => boolean;
  @Input() greyOut: (row: any) => boolean;
  @Input() slm3View?: boolean = false;
  @Input() highlight: (row: any) => boolean;
  @Input() headerPaginator = false;

  // By default table will be scrollable. If that's not wanted, this input has to be passed down with a false vale. Ex: List Mgmt
  @Input() scrollable = true;

  // Urgency will take an object, with a header property and a function to execute
  // The header is there so that the function is not executed on every column of every row
  // instead only being executed on the column that matches in name to the given header property
  @Input() urgency: {
    header: string,
    run: (cell: any) => number
  };
  @Input() checkbox: CheckboxOptions;
  @Input() doubleScrollbar = true;

  // emit pagination change
  @Output() paginationChange = new EventEmitter();

  @ViewChild('scrollTop') scrollTop: ElementRef;
  @ViewChild('scrollBottom') scrollBottom: ElementRef;


  emitClickEvent(type: string, row: any) {
    this.events.emit({ type, data: row });
  }

  isDate(val: any) {
    return new Date(val).getTime() > 0;
  }

  applyFilter(filterValue: string) {
    this.filterVal = filterValue;
    this.dataSource.filter = filterValue?.trim()?.toLowerCase();
  }

  constructor() {}

  ngOnInit() {
    // Subscribe to the data stream and set the dataSource properly as it changes
    this.data.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.filterVal ? this.filterVal : '');
    });
    // subscribe to the changed of the headings
    this.headerStream.pipe(filter(d => !!d)).subscribe(headings => {
      this.headers = headings;
      this.setTableHeadings(headings);
    });
  }

  private setTableHeadings(headers: TableHeader[]) {
    this.displayedColumns = headers && headers.length > 0 ? headers.map(value => {
      return {
        name: value.name,
        friendly: value.friendly,
        type: value.type
      };
    }) : [];

    // Replace headers with sorted header array
    // Will need to pass in a string flag value for different pages
    this.displayedColumns = this.sortColumnHeaders();

    // Here might be a good place to add a check for stored column headers and pass that list
    // In when we implement the persistent column arrangement

    this.displayedRows = this.displayedColumns.map(value => value.name);
    if (this.slm3View) {
      this.displayedRows.unshift('details');
    }
    this.displayedColumnsWithoutAction = this.displayedColumns.filter(c => c.name !== 'actions');
  }

  formatForCSVDownload() {
    const options = {
      useBom: true,
      // Friendly headers for CSV
      headers: this.displayedColumnsWithoutAction.map(header => header.friendly),
      nullToEmptyString: true,
    };
    const data = this.data.value.map(incident => {
      const obj = {};
      // To use user preferred date format
      const pipe = new UserPrefDatePipe('en-us');
      this.displayedColumnsWithoutAction.forEach(header => {
        if (header.type === 'TimeStamp') {
          obj[header.name] = pipe.transform(incident[header.name]);
        } else {
          obj[header.name] = incident[header.name];
        }
      });
      return obj;
    });
    return { data, options };
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event) {
      moveItemInArray(this.displayedRows, event.previousIndex, event.currentIndex);
    }
  }

  sortColumnHeaders(pageRequest?: string) {
    // If no pageRequest, set to default sorting
    let pageValue = 'default';
    if (pageRequest) {
      pageValue = pageRequest;
    } else {
      // Return unsorted list
      return this.displayedColumns;
    }
    const sortItems = new HeaderSortingService();
    return sortItems.sortList(this.displayedColumns, pageValue);
  }

  ngAfterViewInit() {
    // Show the bottom and top scroll bars on the table
    this.scrollBottom.nativeElement.onscroll = e => {
      this.scrollTop.nativeElement.scrollLeft = (e.target as HTMLElement).scrollLeft;
    };
    this.scrollTop.nativeElement.onscroll = e => {
      this.scrollBottom.nativeElement.scrollLeft = (e.target as HTMLElement).scrollLeft;
    };
  }

  handlePaginationChange(changes: any) {
    this.paginationChange.emit({
      type: 'paginationChange',
      payload: changes
    });
  }
}
