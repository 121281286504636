<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div class="layout-padding">
  <mat-card fxFill>
    <mat-card-title>Filters</mat-card-title>
    <div class="search">
      <mat-form-field fxFill>
        <mat-label>Company</mat-label>
        <mat-select (selectionChange)="onSelectChange($event)">
          <mat-option>
            <ngx-mat-select-search [formControl]="searchCompany" noEntriesFoundLabel="'No results found'"
                                   placeholderLabel="Search Company" [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
            <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
          </mat-option>
          <mat-option *ngFor="let company of companies | inputSearch:searchCompany?.value"
                      [value]="company">{{company.friendly}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-stroked-button (click)="searchFilterActionHandler(selectedCompany)">Search</button>
    </div>
  </mat-card>

  <br>
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title>Devices</mat-card-title>
      <button mat-icon-button [matMenuTriggerFor]="menu" *appNotProdReady="true">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="!collaspe" class="buttons" (click)="collaspe = !collaspe">
            <mat-icon>fullscreen_exit</mat-icon>
            <span>Exit Fullscreen</span>
          </button>
          <button mat-menu-item *ngIf="collaspe" class="buttons" (click)="collaspe = !collaspe">
            <mat-icon>fullscreen</mat-icon>
            <span>Fullscreen</span>
          </button>
          <button mat-menu-item class="buttons" (click)="createDevice()">
            <mat-icon>add_box</mat-icon>
            <span>Create Device</span>
          </button>
        </mat-menu>

      </div>
    </div>

    <div [ngClass]="collaspe ? 'table' : 'none'">
      <app-table
        [data]="deviceList"
        [headerStream]="deviceHeader"
        (events)="onRowClick($event)"
        #deviceTable>
        <mat-form-field fxFlex *ngIf='deviceTable.showFilter'>
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="deviceTable.applyFilter($event.target.value)"
                 placeholder="Filter through results. Ex: CTL Docker LCA">
        </mat-form-field>
      </app-table>
    </div>
  </mat-card>
</div>
