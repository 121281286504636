<div class="reset-password-page" fxLayout='row' fxFlexFill fxLayoutAlign='center center'>
  <mat-card fxFlex.xs="95">
    <mat-card-title>Password Reset</mat-card-title>
    <mat-card-subtitle>{{message}}</mat-card-subtitle>
    <mat-card-content>
      <form [formGroup]='form' fxLayout='column' (submit)='onPasswordReset()'>
        <p class='error-msg' *ngIf='error' style="border: 1px solid red">{{error}}</p>
        <p *ngIf="resetMessage">{{resetMessage}}</p>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Reset password Code</mat-label>
          <input matInput formControlName='code'>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>New Password</mat-label>
          <input matInput type='password' formControlName='password'>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Confirm New Password</mat-label>
          <input matInput type='password' formControlName='confirmPassword'>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button color='primary' (click)="resendCode()">Resend Code</button>
      <button mat-raised-button color='primary' (click)='onPasswordReset()' [disabled]='form.invalid || loading'>Submit</button>
    </mat-card-actions>
  </mat-card>
</div>
