import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { State } from '@app/reducers';
import { AppService } from '@app/app.service';
import { User } from '@app/pages/admin/store/user/user.model';
import { Company } from '@app/pages/admin/store/company.model';
import { AuthService } from '@app/pages/auth/services/auth.service';
import { SnackbarService } from '@shared-services/snackbar.service';
import { CompaniesService } from '@shared-services/companies.service';
import * as companyActions from '@app/pages/admin/store/company.actions';
import { TableComponent, TableHeader } from '@sweet-shared/components/table/table.component';
import { ExternalAccountService } from '@app/pages/admin/components/external-account.service';
import { AddExternalUserComponent } from '@app/pages/admin/components/add-external-user/add-external-user.component';
import { FieldInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';

@Component({
  selector: 'app-external-accounts',
  templateUrl: './external-accounts.component.html',
  styleUrls: ['./external-accounts.component.scss']
})
export class ExternalAccountsComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  pageTitle: string = 'External Account';
  showPermissions = true;
  systemRoles: any[] = [];
  companiesSelectOptions: any[] = [];
  loadingCompanies: boolean;
  error = null;

  externalAccountsLoading$: Observable<boolean>;

  loading = false;
  externalListData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  externalListHeaderData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  usersDataStream: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  params: any;
  expand = false;
  dataFromRowClick: any;
  userFilterDetails: FieldInterface[] = [];


  // Tracks current value of the company filter
  selectedFilteredCompany: string;
  companies$: Observable<Company[] | { label: string, value: string }[]>;
  companiesLoading$: Observable<boolean>;
  @ViewChild('externalTable') externalTable: TableComponent;
  @ViewChild('externalUsersForm') externalUsersForm: FormBuilderComponent;
  loggedInUser: any = null;
  companies: any[] = [];
  usersData: any[] = [];

  private headerBuilder(): TableHeader[] {
    return [
      {
        friendly: 'Last Name',
        name: 'family_name',
        description: ''
      },
      {
        friendly: 'First Name',
        name: 'given_name',
        description: ''
      },
      {
        friendly: 'Realm',
        name: 'realm',
        description: ''
      },
      {
        friendly: 'Profile',
        name: 'profile',
        description: ''
      }
    ];

  }

  constructor(
    private appService: AppService<any>,
    private authService: AuthService,
    public dialog: MatDialog,
    private externalAccountService: ExternalAccountService,
    private router: Router,
    private snackbarService: SnackbarService,
    private store: Store<State>,
    private companiesService: CompaniesService
  ) { }

  ngOnInit() {
    // get companies for options to filter table
    this.store.dispatch(companyActions.loadCompanies());
    this.store.select(state => state.companies.companies).pipe(takeUntil(this.destroyer$)).subscribe(companies => this.setCompaniesSelectOption(companies));
    this.store.select(state => state.companies.loadingCompanies).pipe(takeUntil(this.destroyer$)).subscribe(loadingCompany => this.loadingCompanies = loadingCompany);
    this.getSystemRoles();
    this.externalAccountsLoading$ = this.externalAccountService.loadingExternalUser;
    this.externalListHeaderData.next(this.headerBuilder());

    this.authService.getAuthenticatedUser().pipe(take(1)).subscribe(user => {
      this.loggedInUser = user;
      this.params = { companyFilter: user.profile };
      this.externalAccountService.externalUsers.pipe(takeUntil(this.destroyer$)).subscribe((users: any[]) => this.externalListData.next(users));
      this.externalAccountService.getExternalAccountError().pipe(filter(d => !!d), takeUntil(this.destroyer$)).subscribe(error => this.snackbarService.open(error));
      this.store.select(state => state.companies.companies).pipe(takeUntil(this.destroyer$), filter(val => !!val)).subscribe(companies => this.setFilterSession(companies, user));
    });
  }

  private setFilterSession(companies: any[], user: any): void {
    this.companies = this.companiesService.companyNameAndFriendly(companies);
    this.selectedFilteredCompany = user.profile;
    this.userFilterDetails = [{
      component: 'input-select',
      label: 'Company Filter',
      name: 'companyFilter',
      flex: '',
      placeholder: 'Company Filter',
      defaultValue: user.profile,
      validators: [Validators.required],
      required: true,
      selectMultiple: false,
      signalOnChanged: true,
      options: this.companies,
      showSearch: true,
      showSearchFormControl: new FormControl(),
      showSelectAll: true
    }
    ];
  }

  private setCompaniesSelectOption(companies: any[]): void {
    if (!!companies) {
      this.companiesSelectOptions = companies.map(company => {
        return { label: company.name, value: company.id };
      });
    }
  }

  private getSystemRoles(): void {
    this.appService.get('permissionRoles', '', null)
      .then((roles) => this.systemRoles = roles.map((role: any) => { return { value: role.name, friendly: role.name, permissions: role.permissions } }))
      .catch((error: any) => this.error = error);
  }

  filterUsers(users, formValues) {
    if (this.externalUsersForm) {
      const { companyFilter } = formValues;

      let newData = this.usersDataStream.value.slice();
      if (companyFilter.length) {
        newData = newData.filter((item: any) => {
          const roles: string[] = item.companyFilter ? item.companyFilter.split(',') : [];
          return companyFilter.some(permission => {
            return roles.includes(permission);
          });
        });
      }
      this.externalTable.data.next(newData);
    }
  }

  onCompanyChanged(event: any) {
    this.externalTable.data.next(this.usersData);
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'companyFilter') {
      if (event.data.value.length > 0) {
        // On filter change, update company ID for Create New External User
        this.selectedFilteredCompany = event.data.value;
        this.externalAccountService.externalUserAccount({ companyFilter: event.data.value });
        this.externalAccountService.getExternalUserAccount().pipe(takeUntil(this.destroyer$)).subscribe((value => {
          if (value) {
            this.externalListData.next(value);
          } else {
            this.snackbarService.open('No external accounts associated with company');
          }
        }));
      }
    }
  }

  onRowClick(event: any): void {
    this.expand = true;
    if (event.type === 'ROW_CLICK') {
      this.dataFromRowClick = event.data;
      this.router.navigate([`/admin/external-users/${event.data.domain}/${event.data.realm}/${event.data.user_id}`]).then();
    }
  }

  addExternalAccount() {
    this.dialog.open(AddExternalUserComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'ctl-panel-class',
      data: {
        showPermissions: true,
        loggedUser: null,
        title: 'Add External User',
        edit: true
      }
    })

    // .afterClosed().subscribe(data => {
    //   if (data) {
    //     const payload = {
    //       ...data,
    //       gender: data.gender.toString()
    //     };
    //     this.user.createExternalUserAccount(payload).then(_ => {
    //       this.snackbarService.open('New External User Added!');
    //       this.loading = false;
    //       this.reloadPage();
    //     }).catch(error => {
    //       this.loading = false;
    //       this.snackbarService.open('Something Went Wrong.');
    //     });
    //   }
    // });
  }

  reloadPage() {
    const currentPage = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentPage]);
    });
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
