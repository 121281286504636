import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputSearch'
})
export class InputSearchPipe implements PipeTransform {

  transform(items: any[], searchText: any): any {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = this._normalizeValue(searchText)?.trim();
    // return items.filter(val => {return this._normalizeValue(val.friendly).includes(searchText); });
    return items?.filter(val =>  this._normalizeValue(val?.friendly || val?.label)?.includes(searchText));
  }

  private _normalizeValue(value: string): string {
    return value?.toLowerCase().replace(/\s/g, '');
  }
}
