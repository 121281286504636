import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListManagementRoutingModule } from './list-management-routing.module';
import { ListManagementComponent } from './list-management/list-management.component';
import {SweetSharedModule} from "../../sweet-shared/sweet-shared.module";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {FlexModule} from "@angular/flex-layout";
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from "@angular/material/divider";
import {MatSelectModule} from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';



@NgModule({
  declarations: [ListManagementComponent],
    imports: [
        CommonModule,
        ListManagementRoutingModule,
        SweetSharedModule,
        MatCardModule,
        SweetSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatDividerModule,
        MatSelectModule,
        NgxMatSelectSearchModule

    ]
})
export class ListManagementModule { }
