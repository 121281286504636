<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<section class='change-password'>
  <h2 mat-dialog-title>Change Password</h2>
  <mat-dialog-content class='mat-typography'>
    <form [formGroup]='form'>
      <p class='error' *ngIf='error'>{{error}}</p>
      <mat-form-field appearance="outline">
        <mat-label>Current Password</mat-label>
        <input matInput placeholder="Current Password" formControlName='currentPassword' type='password'>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input matInput placeholder="Current Password" formControlName='newPassword' type='password'>
      </mat-form-field>
      <section class='password-validators'>
        <p *ngFor='let v of passwordValidationRules' [ngClass]="{'completed': v.valid, 'invalid': !v.valid}">{{v.errorMessage}}</p>
      </section>
      <mat-form-field appearance="outline">
        <mat-label>Confirm New Password</mat-label>
        <input matInput placeholder="Current Password" formControlName='confirmPassword' type='password'>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <div mat-dialog-actions class='action-btns'>
    <button mat-button (click)="onNoClick()" color='warm' [disabled]='loading'>Cancel</button>
    <button mat-button cdkFocusInitial color='primary' [disabled]='form.invalid || loading' (click)='submit()'>Change</button>
  </div>
</section>
