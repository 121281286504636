import { C } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { KbService } from 'src/app/pages/knowledge-base/kb.service';
import { HelpDialogModalComponent } from 'src/app/sweet-shared/components/help-dialog-modal/help-dialog-modal.component';
import {TableComponent} from '../../../../sweet-shared/components/table/table.component';
import { ThreatHuntingService } from '../../services/threat-hunting.service';
import { ThreatHunt } from '../../store/threat-hunting.model';
@Component({
  selector: 'app-threat-hunting-details',
  templateUrl: './threat-hunting-details.component.html',
  styleUrls: ['./threat-hunting-details.component.scss']
})

export class ThreatHuntingDetailsComponent implements OnInit {
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  logs: string[];
  threatAnalysis: string[];
  threatAnalysisSelected: string[];
  selectedState: string;
  selectedView
  field: any;
  threatHuntData: BehaviorSubject<ThreatHunt[]> = new BehaviorSubject([]);
  threatHuntHeader: BehaviorSubject<string[]> = new BehaviorSubject([]);
  settingsForm: FormGroup; 
  showResult: boolean;
  showPivot: boolean;
  showTable: boolean;
  showGraph: boolean;
  threatArticle: string;

  @ViewChild('threatHuntTable') threatHuntTable: TableComponent;
  loading: boolean;

  //outlier variables
  outlierRange: number

   //Data for pivot table
   pivotData: any[];
   pivotHeaders: any[];
 

  constructor(
    private threatHuntingService: ThreatHuntingService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private kbService: KbService
  ) { }

  ngOnInit(): void { 
    this.settingsForm = this.fb.group({
      date_range: ["",[Validators.required]],
      limit: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(0), Validators.max(1000)]],
    });
    this.threatHuntingService.getLoading().pipe(
      startWith(true),
      takeUntil(this.destroyer$)
    ).subscribe(isLoading => {
        this.loading = isLoading;
      });
    this.threatHuntingService.fetchThreatAnalysisTypes();

    this.threatHuntingService.getThreatAnalysisTypes()
      .pipe(filter(data => !!data), takeUntil(this.destroyer$))
      .subscribe(analysis => this.threatAnalysis = analysis);
    this.field = {
      label: 'Date Range',
      placeholder: 'range',
      name: 'Date Time',
      required: false
    }
    this.showResult = false;
    this.showPivot = false;
    this.showGraph = false;
    this.showTable = false;
    this.initialize();
  }

  //utility function
  initialize() {
    //setup data for shared table
    this.threatAnalysisSelected = [];
    this.threatHuntingService.getThreatHuntHeaders()
      .pipe(filter(data => !!data), takeUntil(this.destroyer$))
      .subscribe(headers => {
        this.threatHuntHeader.next(headers)
        this.pivotHeaders = headers
      });

    this.threatHuntingService.getThreatHuntData()
      .pipe(filter(data => !!data), takeUntil(this.destroyer$))
      .subscribe(data => {
        const evtBytes = data.map(item => Number(item['JCEF_evtBytes']));
        this.outlierRange = this.outlier(evtBytes);
        this.threatHuntData.next(data);
        this.pivotData = data
        this.onSelectView("table");      
      });
  }

  threatAnalysisTypes = [
    {
      friendly: 'Data Exfiltration',
      name: 'data_exfiltration'
    }
  ]

  filterThreatAnalysisTypes(name) {
    return this.threatAnalysis?.includes(name)? true : false;
  }

  onSelectThreatAnalysisTypes(name){
    this.threatAnalysisSelected = name;
    this.selectedState = name;
    this.showResult = false;
    this.threatArticle = this.kbService.findSingleArticle('Data Exfiltration...').summary;
  }

  onSelectView(value) {
    this.selectedView = value;
    switch(value){
      case "table": {
        this.showTable = true;
        this.showPivot = false;
        this.showGraph = false;
        break;
      }
      case "pivot": {
        this.showTable = false;
        this.showPivot = true;
        this.showGraph = false;
        break;
      }
      case "graph": {
        this.showTable = false;
        this.showPivot = false;
        this.showGraph = true;
        break;
      }
      default: {
        this.showTable = true;
        this.showPivot = false;
        this.showGraph = false;
      }

    }

  }

  loadData(){
    const from = this.settingsForm.value.date_range[0].toISOString();
    const to = this.settingsForm.value.date_range[1].toISOString();
    const limit = this.settingsForm.value.limit;
    this.showResult = true;
    this.threatHuntingService.fetchThreatHuntData(from, to, limit);
    this.threatHuntingService.fetchThreatHuntHeaders();
  }

  downloadAsCSV() {
    const date = new Date().toLocaleDateString();
    const dateRange = this.settingsForm.value.date_range.map(d => d.toISOString());
    const format = this.threatHuntTable.formatForCSVDownload();
    const csv = new Angular5Csv(format.data, `threat-hunt-${date}-${dateRange}`, format.options);
  }

  // finding outliers
  outlier(arr) {
    const mean = arr.reduce((s, n) => s + n) / arr.length;
    const variance = arr.reduce((s, n) => s + (n - mean) ** 2, 0) / (arr.length - 1);
    const sd = Math.sqrt(variance);
    return mean + 2 * sd;
  }

  highlight(row: ThreatHunt){
    return row['JCEF_evtBytes'] > this.outlierRange;
  }

  showThreatSummary() {
    // Opens up the TH KB dialog with  article
    const dialogRef = this.dialog.open(HelpDialogModalComponent, {data: {articleTitle: 'Data Exfiltration...'} });
  }


  reset() {
    this.threatAnalysisSelected = [];
    this.selectedState = undefined;
    this.showResult = false;
    this.settingsForm.reset();
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
