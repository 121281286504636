import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { EventsService } from '@modules/events/services/events.service';

@Component({
  selector: 'app-create-incident',
  templateUrl: './create-incident.component.html',
  styleUrls: ['./create-incident.component.scss']
})
export class CreateIncidentComponent implements OnInit {
  private destroyer$ = new Subject();
  form: FormGroup;

  priorities: string[] = [];
  error: string = null;
  loading = true;

  constructor(
    private fb: FormBuilder,
    private eventService: EventsService,
    private dialogRef: MatDialogRef<CreateIncidentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: string, element: any }
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      message: ['', [Validators.required]],
      customerId: [{ value: this.data.company, disabled: true }, [Validators.required]],
      priority: ['', [Validators.required]],
      status: [false, [Validators.required]]
    });

    this.eventService.fetchIncidentAttributes().pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
      next: (res) => {
        this.priorities = res.priority;
      },
      error: (err) => {
        this.error = err.message;
      }
    });
  }

  dismiss(): void {
    this.dialogRef.close(null);
  }

  private buildEvent(element): string {
    const obj = {
      headers: '| ',
      spacer: '| ',
      values: '| '
    };
    const keys = Object.keys(element);
    keys.forEach((key) => {
      obj.headers += (key + ' | ');
      obj.spacer += '--------| ';
      obj.values += (element[key] || '') + ' | ';
    });
    return '\n\n' + obj.headers + '\n' + obj.spacer + '\n' + obj.values + '\n\n';
  }

  createIncident(): void {
    this.loading = true;
    let payload = this.form.getRawValue();
    payload = {
      ...payload,
      message: `${payload.message}\n\n${this.buildEvent(this.data.element)}`
    }
    this.eventService.createIncident(payload).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
      next: (res) => {
        this.dialogRef.close(res);
      },
      error: (err) => {
        this.error = err.message;
      }
    });
  }

}
