export interface Worklog {
  sys_id: string;
  sys_created_on: string;
  name: string;
  element_id: string;
  sys_tags: string;
  value: string;
  sys_created_by: string;
  element: string;
  __status: string;
  [key: string]: any;
}

export interface IncidentWorklog {
  type: string;
  title: string;
  username: string;
  createdAt: string;
  message: string;
  chartsData?: WorklogChart[];
}

export interface WorklogChart {
  title: string;
  description: string;
  type: string;
  axes: {
    xAxisProperty: string;
    xAxisLabel: string;
    yAxisLabel: string;
  };
  data: any[];
}

export interface IncidentUpdateInterface {
  company: string;
  classification: string;
  u_unlisted_affected_user: string;
  u_queue: string;
  incident_state: string;
  priority: string;
}


export interface Incident {
  id?: string;
  active: boolean;
  activity_due: string;
  approval: string;
  assigned_to: string;
  assignment_group: string;
  category: string;
  close_code: string;
  close_notes: string;
  closed_at: string;
  closed_by: string;
  company: string;
  contact_type: string;
  description: string;
  due_date: string;
  incident_state: string;
  made_sla: boolean;
  number: string;
  opened_at: string;
  opened_by: string;
  priority: string;
  reopen_count: string;
  resolved_at: string;
  resolved_by: string;
  short_description: string;
  sla_due: string;
  state: IncidentState;
  subcategory: string;
  sys_created_by: string;
  sys_created_on: string;
  sys_id: string;
  sys_tags: string;
  sys_updated_by: string;
  sys_updated_on: string;
  time_worked: string;
  u_affected_user: string;
  u_affected_user_from: string;
  u_contact_time?: string;
  u_external_company: string;
  u_nimsoft_probe: string;
  u_nimsoft_source: string;
  u_queue: string;
  u_soa_date: string;
  u_unlisted_affected_user: string;
  u_unlisted_caller: string;
  u_vendor_location: string;
  worklogs?: Worklog[];
}

export interface IncidentAttribute {
  [key: string]: string[];
}

export enum IncidentState {
  NEW = 'New',
  ACTIVE = 'Active',
  PENDING_CUSTOMER_INFO = 'Pending Customer Info',
  PENDING_FOR_CHANGE = 'Pending for Change',
  CLOSED = 'Closed'
}
