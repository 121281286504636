<section class="products">
  <form [formGroup]="productForm" *ngIf="productForm">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div
          class="mat-tree-node"
          [ngStyle]="{ 'padding-left': node.level * 20 + 40 + 'px' }"
        >
          {{ node.friendly }}
          <mat-form-field
            *ngIf="node.attributes.length && attributes[node.attributes[0]]"
            [ngStyle]="{ width: '300px' }"
          >
            <ng-container [ngSwitch]="attributes[node.attributes[0]].type">
              <input
                *ngSwitchCase="'String'"
                matInput
                [placeholder]="attributes[node.attributes[0]].friendly"
                [formControlName]="node.name + '_input'"
              />
              <input
                *ngSwitchCase="'Int'"
                matInput
                type="number"
                min="0"
                step="1"
                [placeholder]="attributes[node.attributes[0]].friendly"
                [formControlName]="node.name + '_input'"
              />
              <input
                *ngSwitchCase="'Float'"
                matInput
                type="number"
                min="0"
                [placeholder]="attributes[node.attributes[0]].friendly"
                [formControlName]="node.name + '_input'"
              />
            </ng-container>
          </mat-form-field>
        </div>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div
          [ngStyle]="{ 'margin-left': node.level * 40 + 'px' }"
          *ngIf="checkedMap[node.name]"
        >
          <div class="mat-tree-node">
            <button
              type="button"
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.name"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
            </button>
            {{ node.friendly }}
            <mat-form-field
              *ngIf="node.attributes.length"
              class="amount"
              [ngStyle]="{ width: '300px' }"
            >
              <ng-container [ngSwitch]="attributes[node.attributes[0]].type">
                <input
                  *ngSwitchCase="'String'"
                  matInput
                  type="text"
                  [placeholder]="attributes[node.attributes[0]].friendly"
                  [formControlName]="node.name + '_input'"
                />
                <input
                  *ngSwitchCase="'Int'"
                  matInput
                  type="number"
                  min="0"
                  step="1"
                  [placeholder]="attributes[node.attributes[0]].friendly"
                  [formControlName]="node.name + '_input'"
                />
                <input
                  *ngSwitchCase="'Float'"
                  matInput
                  type="number"
                  min="0"
                  [placeholder]="attributes[node.attributes[0]].friendly"
                  [formControlName]="node.name + '_input'"
                />
              </ng-container>
            </mat-form-field>
          </div>
          <div [class.tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </form>
</section>
