import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { ExternalAccount } from '../external-account.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-external-account-edit',
  templateUrl: './external-account-edit.component.html',
  styleUrls: ['./external-account-edit.component.scss']
})
export class ExternalAccountEditComponent implements OnInit {
  @Input() loading: boolean;
  @Input() userData: ExternalAccount;
  @Input() systemPermissions$: Observable<any[]>;
  @Input() userHasPermission: Function;
  @Output() editEvent: EventEmitter<any> = new EventEmitter();
  showPermissions = true;

  userForm: FormGroup;
  roles: any;
  permissions: any;


  constructor(private fb: FormBuilder, private accountEditService: AppService<any>) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      user_id: [{ value: this.userData.user_id, disabled: true }, [Validators.required]],
      username: [{ value: this.userData.username, disabled: true }, [Validators.required]],
      profile: [{ value: this.userData.profile, disabled: true }, [Validators.required]],
      given_name: [this.userData.given_name, [Validators.required]],
      family_name: [this.userData.family_name, [Validators.required]],
      realm: [this.userData.realm, [Validators.required]],
      gender: [this.userData.gender, [Validators.required]],
      blocked_permissions: [this.userData.blocked_permissions, []],
      allowed_permissions: [this.userData.allowed_permissions, []],
      access_attributes: [this.userData.access_attributes, []]
    });

    this.permissions = _.sortBy(this.permissions, [(o) => o.friendly]);
    this.accountEditService.get('roles', '', null)
      .then((res) => this.roles = res.map((c: any) => ({
        value: c.name,
        friendly: c.name
      })))
      .catch();
  }

  onPermissionToggle(evt: any, friendlyName: string, actionName: string): void {
    const permissionName = `${friendlyName?.toLowerCase()}.${actionName?.toLowerCase()}`;
    let allowedPermissions = this.userForm.get('allowed_permissions').value;
    let blockedPermissions = this.userForm.get('blocked_permissions').value;

    if (evt.checked) {
      // add it to the allowed permissions
      if (!allowedPermissions.includes(permissionName)) {
        allowedPermissions.push(permissionName);
      }
      blockedPermissions = blockedPermissions.filter((p: string) => p !== permissionName);
    } else {
      if (!blockedPermissions.includes(permissionName)) {
        blockedPermissions.push(permissionName);
      }
      allowedPermissions = allowedPermissions.filter((p: string) => p !== permissionName);
    }
    this.userForm.patchValue({ allowed_permissions: allowedPermissions, blocked_permissions: blockedPermissions });
  }

  onPermissionsChanged(evt): void {
    this.userForm.patchValue({ allowed_permissions: evt.allowedPermissions, blocked_permissions: evt.blockedPermissions })
  }

  onSubmit(value: string) {
    const userValue = {};
    userValue['user_id'] = this.userData.user_id;
    userValue['username'] = this.userData.username;
    userValue['profile'] = this.userData.profile;
    if (value === 'save') {
      this.loading = true
      this.editEvent.emit({ name: value, data: Object.assign(this.userForm.value, userValue) });
    } else if (value === 'cancel') {
      this.editEvent.emit({ name: value });
    }
  }

}
