import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import sortBy from 'lodash/sortBy';
import { Store } from '@ngrx/store';
import {catchError, filter, map, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Observer, of, ReplaySubject, Subscription, timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import { State } from '@app/reducers';
import { SnackbarService } from '@shared-services/snackbar.service';
import { ModalComponent } from '@sweet-shared/components/modal/modal.component';
import { PermissionService } from '@sweet-shared/services/permission.service';
import * as dashboardActions from '@app/pages/dashboards/store/dashboard.actions';
import { DashboardService } from '@app/pages/dashboards/services/dashboard.service';
import { Dashboard, DashboardActions } from '@app/pages/dashboards/store/dashboard.model';
import { CheckboxOptions, TableHeader } from '@sweet-shared/components/table/table.component';
import { ReportsWarningComponent } from '@app/pages/dashboards/reports-warning/reports-warning.component';
import { ActionButtonInterface, FieldInterface, FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardTemplate: any[] = [];
  dashboards: BehaviorSubject<Dashboard[]> = new BehaviorSubject([]);
  dashboardHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
  reportHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
  error: string = null;
  loading = false;
  dialogRef: any = null;
  action: any = {
    name: 'more_vert'
  };

  // contains some of the granular permissions for dashboards
  dashboardTableActions: DashboardActions = {
    readPermission: 'dashboard.read',
    deletePermission: 'dashboard.delete',
    editPermission: 'dashboard.edit',
    createCustomPermission: 'dashboard.create-custom',
    createReport: ['report.create-custom', 'report.create-from-template'],
    viewReport: 'report.read',
  };

  checkboxOptions: CheckboxOptions = {
    show: true,
    headerName: 'title',
    execute: this.onBoxChecked.bind(this)
  };
  checkedDashboards: { id: string; name: string; }[] = [];

  onBoxChecked(row: Dashboard, event: MatCheckboxChange) {
    if (event.checked) {
      this.checkedDashboards.push({ id: row.dashboard_id, name: row.title });
    } else {
      this.checkedDashboards = this.checkedDashboards.filter(dash => dash.id !== row.dashboard_id);
    }
  }

  constructor(
    private dialog: MatDialog,
    private store: Store<State>,
    private router: Router,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private permissionService: PermissionService,
    private dashboardAppService: AppService<Dashboard>
  ) {
  }

  ngOnInit() {
    this.setupPage();
    this.dashboardHeader.next(this.headerBuilder());
    this.reportHeader.next(this.reportHeaderBuilder());
  }

  private setupPage() {
    this.store.dispatch(dashboardActions.getAllDashboardTemplate());
    this.store.dispatch(dashboardActions.loadDashboard());
    this.store.select(state => state.dashboard.dashboards)
      .pipe(filter(val => !!val), untilDestroyed(this))
      .subscribe(dashboards => this.dashboards.next(dashboards.map((c): Dashboard => {
        return {
          ...c,
          actions: this.dashboardTableActions
        };
      })));
    this.store.select(state => state.dashboard.loading).pipe(
      untilDestroyed(this)
    ).subscribe(loading => {
      this.loading = loading;
      if (!loading) {
        if (this.dialogRef) {
          // done loading should close it now
          this.dialogRef.close();
          this.dialogRef = null;
        }
      }
    });
    this.store.select(state => state.dashboard.error).pipe(
     untilDestroyed(this)
    ).subscribe(error => this.error = error);
  }


  onRowClick(row: any) {


    if (row.type === 'ROW_CLICK') {
      this.onDashboardRowClick(row);
    } else if (row.type === 'DELETE') {
      // deleting the dashboard
      if (row?.data?.reports?.length) {
        const dialogRef: MatDialogRef<ReportsWarningComponent> = this.dialog.open(ReportsWarningComponent, {
          width: '800px',
          disableClose: true,
          autoFocus: false,
          maxHeight: '90vh',
          panelClass: 'ctl-panel-class'
        });
        dialogRef.componentInstance.reports = row.data.reports;
        dialogRef.componentInstance.events.subscribe(event => {
          if (event === 'CANCEL') {
            dialogRef.close();
          } else if (event === 'PROCEED') {
            dialogRef.close();
            this.deleteDialog(row);
          } else if (event === 'DUPLICATE') {
            dialogRef.close();
            this.dashboardService.onDuplicate(row.data);
          }
        });
      } else {
        this.deleteDialog(row);
      }
    } else if (row.type === 'EDIT') {
      this.editDashboard(row.data);
    } else if (row.type === 'CREATE_REPORT') {
      this.reportDialog(row.data);
    }
    else if (row.type === 'VIEW_REPORT') {
      this.router.navigate(['/reporting'], { queryParams: { filter: row.data.title }, skipLocationChange: false });
    }
  }

  onDashboardRowClick(row) {
    if (this.permissionService.hasPermission(this.dashboardTableActions.readPermission)) {
      this.router.navigate([`/dashboard/${row.data.dashboard_id}`]).then(() => { });
    } else {
      const dialogRef = this.dialog.open(ModalComponent);
      dialogRef.componentInstance.message = `
            <p>You are missing the required permission('${this.dashboardTableActions.readPermission}') to view account details.</p>
            <p>Please contact your admin for assistance.</p>
        `;
      dialogRef.componentInstance.title = `Missing Required Permission`;
    }
  }

  deleteDialog(row) {
    const subs = [];
    const observer = (obsr: Observer<number>) => {
      let i = 0;
      setInterval(() => {
        obsr.next(i++);
      }, 1000);
    };

    this.dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true
    });

    this.dialogRef.componentInstance.actionButtons = {
      flex: 'end center',
      buttons: [
        {
          label: 'No',
          iconName: null,
          name: 'No',
          color: 'warn',
          type: 'basic'
        },
        {
          label: 'Yes',
          iconName: null,
          name: 'Yes',
          color: 'primary',
          type: 'stroked'
        }
      ]
    };
    this.dialogRef.componentInstance.title = 'Are you sure you would like to delete the dashboard.';
    this.dialogRef.componentInstance.loading = false;
    this.dialogRef.componentInstance.actionEvents.subscribe((event: any) => {
      switch (event.name) {
        case 'Yes':
          this.deleteDashboard(row.data, this.dialogRef);
          break;
        case 'No':
          this.dialogRef.close();
          this.dialogRef = null;
          break;
      }
    });
  }

  // used to create a report
  reportDialog(dashboardData) {
    this.dashboardService.createReportDialog(dashboardData);
  }

  editDashboard(dashboardData: any) {
    const buttons: ActionButtonInterface = {
      flex: 'end center',
      buttons: [
        {
          label: 'Cancel',
          iconName: null,
          name: 'CANCEL',
          color: 'warn',
          type: 'basic',
        },
        {
          label: 'Save',
          iconName: null,
          name: 'SAVE',
          color: 'primary',
          type: 'stroked',
          pristineDisabled: true
        },
      ],
    };
    const newDashboardName: FieldInterface[] = [
      {
        name: 'title',
        type: 'text',
        component: 'input-text',
        placeholder: 'Dashboard Name',
        label: 'Dashboard Name',
        flex: '100%',
        defaultValue: dashboardData.name,
        validators: [Validators.required],
      },
      {
        name: 'description',
        type: 'text',
        component: 'input-textarea',
        placeholder: 'Description',
        label: 'Description',
        flex: '100%',
        defaultValue: dashboardData.description,
      }
    ];
    this.dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '400px',
      minHeight: '100px',
      disableClose: true
    });
    this.dialogRef.componentInstance.title = 'Edit Dashboard';
    this.dialogRef.componentInstance.actionButtons = buttons;
    this.dialogRef.componentInstance.fieldDetails = newDashboardName;
    this.dialogRef.componentInstance.actionEvents.subscribe((event: any) => {

      switch (event.name) {
        case 'SAVE':
          this.saveDashboardName(dashboardData, event.data);
          break;
        case 'CANCEL':
          this.dialogRef.close();
          this.dialogRef = null;
          break;
      }
    });
  }

  // To detect if there have been no changes made when editing dashboard
  // preventing unnecessary put requests
  noChangesMade(obj1: any, obj2: any) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  }

  saveDashboardName(dashboardData: any, event: any) {
    this.dialogRef.componentInstance.loading = true;
    if (this.noChangesMade(event, dashboardData)) {
      this.snackbarService.open('No changes have been detected');
      this.dialogRef.componentInstance.loading = false;
    } else {
      const editedDashboard = {
        category: dashboardData.category,
        createdAt: dashboardData.createdAt,
        createdBy: dashboardData.createdBy,
        dashboard_id: dashboardData.dashboard_id,
        owner_id: dashboardData.owner_id,
        updatedAt: dashboardData.updatedAt,
        updatedBy: dashboardData.updatedBy,
        title: event.title,
        description: event.description,
      };
      this.dashboardAppService.put('dashboards', `${dashboardData.dashboard_id}`, null, editedDashboard)
        .then((_) => {
          this.dialogRef.close();
          this.store.dispatch(dashboardActions.editDashboard({ dashboard: editedDashboard }));
          this.snackbarService.open('Your Dashboard was updated');
        }
        )
        .catch((err) => {
          this.dialogRef.componentInstance.loading = false;
          this.snackbarService.open(err.message);
        });
    }
  }

  deleteDashboard(dashboard: any, dialogRef: any) {
    dialogRef.componentInstance.loading = true;
    this.dashboardAppService.delete('dashboards', `${dashboard.dashboard_id}`, null, null)
      .then((_) => {
        this.store.dispatch(dashboardActions.deleteDashboardSuccess({ dashboardId: dashboard.dashboard_id }));
        dialogRef.close();
      }).catch((error: any) => {
        dialogRef.componentInstance.loading = false;
        this.snackbarService.open(error.message);
      }
      );
  }

  onDuplicate(dashboard: Dashboard): void {
    this.dashboardService.onDuplicate(dashboard);
  }

  private reportHeaderBuilder(): TableHeader[] {
    const reportHeaders = [
      {
        friendly: 'Title',
        name: 'title',
        description: ''
      },
      {
        friendly: 'Dashboard Name',
        name: 'dashboardName',
        description: ''
      },
      {
        friendly: 'Dashboard ID',
        name: 'dashboardId',
        description: ''
      },
      {
        friendly: 'Report ID',
        name: 'reportId',
        description: ''
      },
      {
        friendly: 'Date Created',
        name: 'dateCreated',
        description: ''
      },
      {
        friendly: 'Status',
        name: 'status',
        description: ''
      },
    ];
    return reportHeaders;

  }

  private headerBuilder(): TableHeader[] {
    const dashboardHeaders = [
      {
        friendly: 'Dashboard Title',
        name: 'title',
        description: ''
      },
      {
        friendly: 'Default',
        name: 'default',
        description: ''
      },
      {
        friendly: 'Shared',
        name: 'shared',
        description: ''
      },
      {
        friendly: 'Created At',
        name: 'createdAt',
        description: '',
        type: 'TimeStamp'

      },
      {
        friendly: 'Updated At',
        name: 'updatedAt',
        description: '',
        type: 'TimeStamp'
      },
    ];
    if (this.permissionService.hasPermission((Object.values(this.dashboardTableActions) as any).flat(), true)) {
      dashboardHeaders.push({
        friendly: 'Actions',
        name: 'actions',
        description: ''
      });
    }
    return dashboardHeaders;
  }

  private createTemplate(dashboard: any, dialogRef: any) {
    const tempDashboard: any = [];
    dashboard.map((option: any) => tempDashboard.push(option.value));
    dialogRef.componentInstance.loading = true;
    this.store.dispatch(dashboardActions.dashboardCreate({ dashboards: tempDashboard }));
    this.store.select(state => state.dashboard.dialogClose).pipe(untilDestroyed(this))
      .subscribe(val => {
        if (val) {
          dialogRef.close();
        }
      });
    this.store.select(state => state.dashboard.error).pipe(
      filter(val => !!val),
      untilDestroyed(this)
    ).subscribe(error =>
      this.snackbarService.open(error));

  }


  createDashboardDialog() {
    const dialogRef = this.dialog.open(DashboardCreateComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: 'ctl-panel-class'
    });
    dialogRef.componentInstance.loading = false;
    dialogRef.componentInstance.dashboardCreateEvents.pipe(untilDestroyed(this)).
      subscribe(event => {
        if (event.name === 'submit') {
          this.createTemplate(event.data, dialogRef);
        } else if (event.name === 'cancel') {
          dialogRef.close();
        }
      });
  }

  onMultiDeleteDashboard() {
    if (!this.checkedDashboards.length) {
      return false;
    }
    const dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true,
    });

    dialogRef.componentInstance.actionButtons = {
      flex: 'end center',
      buttons: [
        {
          label: 'No',
          iconName: null,
          name: 'No',
          color: 'warn',
          type: 'basic',
        },
        {
          label: 'Yes',
          iconName: null,
          name: 'Yes',
          color: 'primary',
          type: 'stroked',
        },
      ],
    };
    dialogRef.componentInstance.title = `Are you sure you want to delete the selected dashboard(s) and all associated reports and report histories?`;
    dialogRef.componentInstance.loading = false;

    dialogRef.componentInstance.actionEvents.subscribe((evt) => {
      switch (evt.name) {
        case 'Yes':
          dialogRef.componentInstance.loading = true;
          const requestsArray = this.checkedDashboards.map(dash => {
            return this.dashboardAppService.delete('dashboards', `${dash.id}`, null, null)
              .then(() => {
                this.store.dispatch(dashboardActions.deleteDashboardSuccess({ dashboardId: dash.id }));
              });
          });
          // Emits the last values of an array of observables or promises and completes
          forkJoin(requestsArray).pipe(
            // when all requests succeed
            map(res => {
              this.snackbarService.open('Selected dashboards have been deleted');
              this.checkedDashboards = [];
            }),
            // if any fail
            catchError(err => {
              return of(this.snackbarService.open('Not able to delete dashboards at this time.  Please try again later.'));
            }),
            // either way turn loader off and close confirmation dialog
            tap(() => {
              this.loading = false;
              dialogRef.close();
            }),
          ).subscribe();
          break;
        case 'No':
          dialogRef.close();
          break;
      }
    });
  }
}

@UntilDestroy()
@Component({
  selector: 'app-dashboard-create.component',
  templateUrl: './dashboard-create.component.html',
  styleUrls: ['./dashboard-create.component.scss']
})
export class DashboardCreateComponent implements OnInit {
  @Input() loading = false;
  dashboardForms: FormGroup[] = [];
  dashboardTemplate: any[] = [];
  dashboard: any[] = [];
  valid = true;
  subscription = new Subscription();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();
  @Output() dashboardCreateEvents: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DashboardCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private formBuilder: FormBuilder
  ) {
  }


  ngOnInit() {
    this.store.select(state => state.dashboard.templateTags)
      .pipe(untilDestroyed(this))
      .subscribe((res: any[]) => {
        // getting the template and passing it to the dialog list
        this.dashboardTemplate = res.map((c: any) => {
          return {
            groupName: c.tag,
            option: sortBy(c.templates, [(o: any) => o.friendly]).map((t: any) => {
              return {
                value: t.raw,
                friendly: t.friendly
              };
            })
          };
        });
      });
    this.addDashboard();
    this.valid = this.validate(this.dashboardForms);

  }

  private findTemplate(templateValue): { value: string, friendly: string } {
    const allDashboardTemplates = this.dashboardTemplate.reduce((prev, next) => {
      prev = prev.concat(next.option);
      return prev;
    }, []);
    return allDashboardTemplates.find(template => template.value === templateValue);
  }

  addDashboard() {
    const formGroup = this.formBuilder.group({
      template_name: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', []],
    });
    // When value of selected dashboard template changes
    formGroup.get('template_name').valueChanges.pipe(untilDestroyed(this)).subscribe(templateValue => {
      // Update the title to the same
      const template = this.findTemplate(templateValue);
      formGroup.get('title').setValue(template ? template.friendly : '');
    });
    this.dashboardForms = [...this.dashboardForms, formGroup];
    // call validation to capture validity of the added form
    this.valid = this.validate(this.dashboardForms);
    // update subscriptions
    this.updateSubscriptions();
  }

  // ngOnDestroy() {
  //   this.destroyed$.next(true);
  //   this.destroyed$.complete();
  // }


  // used to delete a dashboard form
  delete(value: any) {
    this.dashboardForms.splice(value, 1);
    this.valid = this.validate(this.dashboardForms);
    this.updateSubscriptions();
  }

  onSubmit(value: string) {
    this.dashboardCreateEvents.emit({ name: value, data: this.dashboardForms });
  }

  validate(list: FormGroup[]): boolean {
    return list.reduce((acc: boolean, cur) => {
      acc = acc && cur.valid;
      return acc;
    }, true);
  }

  updateSubscriptions() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
    this.dashboardForms.forEach(f => {
      this.subscription.add(f.valueChanges.subscribe(_ => {
        this.valid = this.validate(this.dashboardForms);
      }));
    });
  }
}
