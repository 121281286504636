import {Action, createAction, props} from '@ngrx/store';
import {Dashboard} from './dashboard.model';

// get all the dashboard template and tags
export const getAllDashboardTemplate = createAction(
  '[Dashboard/API] Get All Dashboard Template',
);
export const getAllDashboardTemplateSuccess = createAction(
  '[Dashboard/API] Get All Dashboard Template Success',
  props<{ templateTags: any[] }>()
);
export const getAllDashboardTemplateError = createAction(
  '[Dashboard/API]  Get All Dashboard Template Error',
  props<{ error: string }>()
);

// load user dashboard
export const loadDashboard = createAction(
  '[Dashboard/API] Load Dashboard',
);
export const loadDashboardSuccess = createAction(
  '[Dashboard/API] Load Dashboard Success',
  props<{ dashboards: any[] }>()
);
export const loadDashboardFailure = createAction(
  '[Dashboard/API] Load Dashboard Failure',
  props<{ error: string }>()
);

// create a dashboard
export const dashboardCreate = createAction(
  '[Dashboard/API] Create Dashboard',
  props<{ dashboards: Dashboard[] }>()
);
export const dashboardCreateSuccess = createAction(
  '[Dashboard/API] Create Dashboard Success',
  props<{ dashboards: Dashboard[] }>()
);
export const dashboardCreateFailure = createAction(
  '[Dashboard/API] Create Dashboard Failure',
  props<{ error: string }>()
);

// view a single dashbooard
export const  getDashboard = createAction(
  '[Dashboard/API] Get Dashboard ',
  props<{ dashboardId: string }>()
);
export const  getDashboardSuccess = createAction(
  '[Dashboard/API] Get Dashboard Success',
  props<{ selectedDashboard: any }>()
);
export const  getDashboardFailure = createAction(
  '[Dashboard/API] Get Dashboard Failure',
  props<{ error: string }>()
);

// edit a dashboard
export const editDashboard = createAction(
  '[Dashboard/API] Edit Dashboard',
  props<{dashboard: Dashboard}>()
);
export const editDashboardSuccess = createAction(
  '[Dashboard/API] Edit Dashboard Success',
  props<{dashboard: Dashboard}>()
);
export const editDashboardFailure = createAction(
  '[Dashboard/API] Edit Dashboard Failure',
  props<{error: string}>()
);

// deleting dashboard
export const deleteDashboardSuccess = createAction(
  '[Dashboard/API] Delete Dashboard Success',
  props<{dashboardId: string}>()
);


// update date range
export const updateDateRange = createAction(
  '[Dashboard/API] Update Date Range',
  props<{range: any}>()
); 