<mat-card>
  <mat-card-title>Contact Support</mat-card-title>
  <mat-card-content>
    <h3>Are you having trouble with our platform?</h3>
    <h3>Do you have some feedback you would like to share with our team?</h3>
    <h3>Please feel free to contact us with the email below!</h3>
    <br>
    <span>
    <a href="mailto:{{data.email}}">{{data.email}}</a>
    <button ngxClipboard (cbOnSuccess)="copied($event)" [color]="linkCopied" [cbContent]="data.email" mat-icon-button aria-label="Copy Support Desk Email">
      <mat-icon>file_copy</mat-icon>
    </button>
    </span>


  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="primary" (click)="onNoClick()">Got It!</button>
  </mat-card-actions>
</mat-card>
