import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { State } from '@app/reducers';
import { AppService } from '@app/app.service';
import { User } from '@app/pages/admin/store/user/user.model';
import * as userActions from '@app/pages/admin/store/user/user.actions';
import { FormBuilderComponent } from '@sweet-shared/components/form-builder/form-builder.component';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private messageReset: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private messageResentCode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private permissionData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userRoleData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private appService: AppService<any>,

  ) { }

  getResetMessage() {
    return this.messageReset;
  }

  getResentConfirmationCode() {
    return this.messageResentCode;
  }

  resentConfirmationCodeError() {
    return this.error;
  }

  resetError() {
    return this.error;
  }

  getPermision() {
    return this.permissionData;
  }

  getUserRole() {
    return this.userRoleData;
  }

  accountsError() {
    return this.error;
  }

  deleteUser(userData: User, routePath?: string): void {
    // sets store value of openDialog to true
    this.store.dispatch(userActions.openDialog());
    const dialogRef = this.dialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true,
    });
    // success or error will reset `dialogOpen` to false and close it
    this.store.select(state => state.users.dialogOpen).pipe(
      filter(val => val === false),
      take(1)
    ).subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.actionButtons = {
      flex: 'end center',
      buttons: [
        {
          label: 'No',
          iconName: null,
          name: 'No',
          color: 'warn',
          type: 'basic',
        },
        {
          label: 'Yes',
          iconName: null,
          name: 'Yes',
          color: 'primary',
          type: 'stroked',
        },
      ],
    };

    dialogRef.componentInstance.title = `Are you sure you would like to delete the account for ${userData.Username}.`;

    dialogRef.componentInstance.actionEvents.subscribe((evt) => {
      switch (evt.name) {
        case 'Yes':
          dialogRef.componentInstance.loading = true;
          this.store.dispatch(userActions.deleteUser({ user: userData, routePath }));
          break;
        case 'No':
          dialogRef.close();
          break;
      }
    });
  }

  resetUserPassword(userData: any) {
    this.appService.put('user', `${userData}/reset-password`, null, null).then((res) => this.messageReset.next('A reset code was sent'))
      .catch(error => {
        this.error.next(error.message);
      });
    }


  resendConfirmationCode(username: string): void {
    this.appService.put('user', `${username}/reset-password`, null, null)
      .then((res) => this.messageResentCode.next('A reset code was sent'))
      .catch(error => {
        this.error.next(error.message);
      });
  }

  permission() {
    this.appService.get('granularPermissions', '', null)
      .then((values) => {
        this.permissionData.next(values);
      })
      .catch(error => {
        this.error.next(error.message);
      });
  }
  userRole() {
    this.appService.get('permissionRoles', '', null)
      .then((res) => {
        return this.userRoleData.next(res);
      })
      .catch((error) => this.error.next(error.message));
  }
}
