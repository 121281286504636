import {UserPreference} from './../../user-preference/store/user-preference.model';
import {IncidentAttribute, IncidentUpdateInterface, IncidentWorklog} from './incident.model';
import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {AppService} from 'src/app/app.service';
import {Incident, Worklog} from './incident.model';
import {switchMap, map, concatMap, tap, withLatestFrom, take, flatMap, filter} from 'rxjs/operators';
import * as incidentActions from './incident.actions';
import * as userPreferenceActions from '../../user-preference/store/user-preference.actions';
import {selectAllIncidentAttributes, selectAllIncidents} from './incident.selector';
import {State} from '../../../reducers';
import {of} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {Auth} from 'aws-amplify';
import {SnackbarService} from '../../../shared-services/snackbar.service';


@Injectable()
export class IncidentEffects {


  constructor(
    private actions$: Actions,
    private incidentService: AppService<Incident>,
    private store: Store<State>,
    private worklogService: AppService<Worklog>,
    private incidentAttributesService: AppService<string[]>,
    private snackbarService: SnackbarService
  ) {
  }


  @Effect({dispatch: true})
  fetchIncident$ = this.actions$.pipe(
    ofType(incidentActions.searchIncidents),
    switchMap((action: { searchParams: object }) => {
      return this.incidentService.get('investigation', '', action.searchParams)
        .then(
          (res: { source: Incident[], total: number }) => {
            return incidentActions.loadIncidentsSuccess({incidents: res.source, total: res.total});
          }
        )
        .catch(
          (error) => {
            return incidentActions.loadIncidentsError({error: error.response.message});
          }
        );
    })
  );

  @Effect({dispatch: true})
  clearIncidents$ = this.actions$.pipe(
    ofType(incidentActions.clearIncidents),
    map(() => incidentActions.loadIncidentsSuccess({incidents: [], total: 0}))
  );

  @Effect({dispatch: true})
  getIncident$ = this.actions$.pipe(
    ofType(incidentActions.getIncident),
    withLatestFrom(this.store.pipe(select(selectAllIncidents))),
    switchMap(([action, allIncidents]) => {
      const incident = allIncidents.find(inc => inc.sys_id === action.incidentId);

      if (incident) {
        return of(incidentActions.getIncidentSuccess({incident}));
      } else {
        return this.incidentService.get('investigation', `${action.incidentId}`, null)
          .then((inc: Incident) => incidentActions.getIncidentSuccess({incident: inc}))
          .catch((err) => incidentActions.getIncidentError({error: err.response.message}));
      }
    })
  );

  // gradeColors: {
  //   grade1: '#B50D12 5px solid', Highest
  //   grade2: '#C94218 5px solid', high
  //   grade3: '#E6A243 5px solid', Moderate
  //   grade4: '#009054 5px solid', Low
  //   grade5: '#0080DC 5px solid'  Planning
  // }

  // Todo This block of code is used to show mock data on worklogs.  Can be removed when Backend is setup.
  // @Effect({dispatch: true})
  // fetchWorklogs$ = this.actions$.pipe(
  //   ofType(incidentActions.loadWorklog),
  //   switchMap((action: { incidentId: string }) => {
  //     return this.worklogService.get('investigation', `${action.incidentId}/worklog`, null)
  //       .then((worklogs: Worklog[]) => {
  //         const testWorklogs: any = [
  //           // ...worklogs,
  //           {
  //             type: 'threat_profile',
  //             title: 'Threat Profile',
  //             username: 'Automated Analyst',
  //             createdAt: '2021-04-06 04:21:00',
  //             message: 'New Threat Profile generated...',
  //             chartsData: [
  //               {
  //                 title: 'Notes',
  //                 type: 'markdown',
  //                 message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet mauris commodo quis imperdiet massa.\n\n' + '- Varius sit amet mattis vulputate enim. Ut tristique et egestas quis ipsum.\n' +
  //                           '- Morbi blandit cursus risus at ultrices mi tempus imperdiet.\n' +
  //                           '- Non diam phasellus vestibulum lorem sed risus. Nisl rhoncus mattis rhoncus urna neque.\n' +
  //                           '- Maecenas accumsan lacus vel facilisis volutpat est.\n\n' +
  //                           'Et malesuada fames ac turpis egestas maecenas pharetra convallis. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet.\n\n' +
  //                           '| Consectetur |\n' +
  //                           '| ------ |\n' +
  //                           '| 127.0.0.1:8000 |\n' +
  //                           '| 127.0.0.2:8000 |\n' +
  //                           '| 127.0.0.3:8000 |\n' +
  //                           '| 127.0.0.4:8000 |\n' +
  //                           '| 127.0.0.5:8000 |\n' +
  //                           '| 127.0.0.6:8000 |\n\n' +
  //                           'Consectetur purus ut faucibus pulvinar elementum integer enim neque volutpat. Eget aliquet nibh praesent tristique magna sit amet.\n\n' + '\n' +
  //                           '- Morbi blandit cursus risus at ultrices mi tempus imperdiet.\n' +
  //                           '- Non diam phasellus vestibulum lorem sed risus. Nisl rhoncus mattis rhoncus urna neque.\n' +
  //                           '- Maecenas accumsan lacus vel facilisis volutpat est.\n\n'
  //               },
  //               {
  //                 title: 'Risk Level',
  //                 type: 'risk_level',
  //                 risk_level: 'HIGH',
  //                 risk_color: '#C94218',
  //               },
  //               {
  //                 title: 'Score',
  //                 type: 'risk_score',
  //                 risk_score: 88,
  //                 risk_color: '#C94218'
  //               },
  //               {
  //                 title: 'Observed Activity',
  //                 type: 'multi_line',
  //                 description: 'Last 30 Days',
  //                 axes: {
  //                   xAxisProperty: 'date',
  //                   xAxisLabel: 'Date',
  //                   yAxisLabel: 'Bytes'
  //                 },
  //                 data: [
  //                   {date: '2021-04-08T14:00:00Z', Warning: 6, High: 8, Notice: 0},
  //                   {date: '2021-04-08T15:00:00Z', Warning: 14, High: 9, Notice: 5},
  //                   {date: '2021-04-08T16:00:00Z', Warning: 12, High: 15, Notice: 7},
  //                   {date: '2021-04-08T17:00:00Z', Warning: 10, High: 8, Notice: 7},
  //                   {date: '2021-04-08T18:00:00Z', Warning: 8, High: 9, Notice: 8},
  //                   {date: '2021-04-08T19:00:00Z', Warning: 17, High: 10, Notice: 9}
  //                 ]
  //               },
  //               {
  //                 title: 'Additional Information',
  //                 type: 'markdown',
  //                 message: 'Incididunt amet anim do non nostrud aliquip elit, incididunt officia non sint ipsum incididunt aute deserunt, incididunt consectetur sed occaecat laborum fugiat mollit.\n\n| Attribute | Values |\n| ------ | ------ |\n| Families | satori |\n| CVEs | CVE-2017-17215;CVE-2014-8361 |\n | Reference URLs | https://blog.lumen.com/the-resilient-satori-botnet |\n | First Seen | 2021-01-02 00:01:20 |\n | Last Seen | 2021-01-25 13:22:00 |\n | Port | 8083 |\n | Country | RU |\n',
  //               }
  //             ],
  //           },
  //           {
  //             type: 'threat_history',
  //             title: 'Established connection with C2 server',
  //             username: 'Automated Analyst',
  //             createdAt: '2021-03-10T04:26:16',
  //             message: 'A new investigation has been created based on a detected established connection from a host on network (192.51.100.5) with a C2 server (203.0.113.88) on the C2 port (8083)\n\n| First Seen | Last Seen | Packets | Bytes | TCP Flags |\n| ------ | ------ | ------ | ------ | ------ |\n| 01/21/2021 1:30 AM | 01/21/2021 1:50 AM | 8273 | 89 MB | SYN/ACK/PSH |\n| 01/21/2021 12:10 AM | 01/21/2021 12:32 AM | 829 | 5 MB | SYN/ACK/PSH |\n',
  //             chartsData: [
  //               {
  //                 title: 'Estimated Bytes Exchanged',
  //                 description: 'During length of detected connections',
  //                 axes: {
  //                   xAxisProperty: 'date',
  //                   xAxisLabel: 'Date',
  //                   yAxisLabel: 'Bytes'
  //                 },
  //                 data: [
  //                   {date: '2021-04-08T14:00:00Z', Warning: 6, High: 8, Notice: 0},
  //                   {date: '2021-04-08T15:00:00Z', Warning: 14, High: 9, Notice: 5},
  //                   {date: '2021-04-08T16:00:00Z', Warning: 12, High: 15, Notice: 7},
  //                   {date: '2021-04-08T17:00:00Z', Warning: 10, High: 8, Notice: 7},
  //                   {date: '2021-04-08T18:00:00Z', Warning: 8, High: 9, Notice: 8},
  //                   {date: '2021-04-08T19:00:00Z', Warning: 17, High: 10, Notice: 9}
  //                 ]
  //               },
  //               {
  //                 title: 'Estimated Packets Exchanged',
  //                 description: 'During length of detected connections',
  //                 axes: {
  //                   xAxisProperty: 'date',
  //                   xAxisLabel: 'Date',
  //                   yAxisLabel: 'Packets'
  //                 },
  //                 data: [
  //                   {date: '2021-04-08T14:00:00Z', Warning: 6, High: 8, Notice: 0},
  //                   {date: '2021-04-08T15:00:00Z', Warning: 14, High: 9, Notice: 5},
  //                   {date: '2021-04-08T16:00:00Z', Warning: 12, High: 15, Notice: 7},
  //                   {date: '2021-04-08T17:00:00Z', Warning: 10, High: 8, Notice: 7},
  //                   {date: '2021-04-08T18:00:00Z', Warning: 8, High: 9, Notice: 8},
  //                   {date: '2021-04-08T19:00:00Z', Warning: 17, High: 10, Notice: 9}
  //                 ]
  //               }
  //             ]
  //           },
  //           {
  //             createdAt: '2021-03-10T04:26:16',
  //             type: 'standard',
  //             title: 'Established connection with C2 server explicitly allowed',
  //             username: 'Automated Analyst',
  //             message: 'A firewall log indicated that the connection was allowed\n\n| Timestamp | Device Vendor | Device Product | Source Address | Source Port | Destination Address | Destination Category | Category Allowed |\n| ------ | ------ | ------ | ------ | ------ | ------ | ------ | ------ |\n| 01/21/2021 12:10 AM | Cisco | ASA | 192.51.100.5 | 17293 | 203.0.113.88 | 8083 | ALLOW |\n'
  //           }
  //         ];
  //
  //         return incidentActions.loadWorklogSuccess({incidentId: action.incidentId, worklogs: testWorklogs});
  //       })
  //       .catch((error) => incidentActions.loadWorklogError({error: error.message}));
  //   })
  // );

  @Effect({dispatch: true})
  fetchWorklogs$ = this.actions$.pipe(
    ofType(incidentActions.loadWorklog),
    switchMap((action: { incidentId: string }) => {
      return this.worklogService.get('investigation', `${action.incidentId}/worklog`, null)
        .then((worklogs: Worklog[]) => incidentActions.loadWorklogSuccess({incidentId: action.incidentId, worklogs}))
        .catch((error) => incidentActions.loadWorklogError({error: error.message}));
    })
  );

  @Effect({dispatch: true})
  incidentAttributes$ = this.actions$.pipe(
    ofType(incidentActions.loadIncidentAttributes),
    withLatestFrom(this.store.pipe(select(selectAllIncidentAttributes))),
    switchMap(([action, allIncidentAttributes]) => {
      if (Object.keys(allIncidentAttributes).length === 0) {
        return this.incidentAttributesService.get('incidentAttributes', '', null)
          .then((res: IncidentAttribute) => incidentActions.incidentAttributesSuccess({attributes: res}))
          .catch((error: any) => incidentActions.incidentAttributesError({error: error.response.message}));
      } else {
        return of(incidentActions.incidentAttributesSuccess({attributes: allIncidentAttributes}));
      }
    }),
  );


  @Effect({dispatch: true})
  incidentUpdate$ = this.actions$.pipe(
    ofType(incidentActions.updateIncident),
    switchMap((action: { incidentId: string, value: IncidentUpdateInterface, userPreferences?: UserPreference }) => {
      return this.incidentService.put('investigation', action.incidentId, {action: 'update'}, {value: action.value})
        .then((res: { id: string, status: string }) => incidentActions.updateIncidentStatus({
          statusId: res.id,
          userPreferences: action.userPreferences
        }))
        .catch((error: any) => incidentActions.updateIncidentError({error: error.message}));
    })
  );

  @Effect({dispatch: true})
  incidentCreate$ = this.actions$.pipe(
    ofType(incidentActions.incidentCreate),
    switchMap((action: { incident: any }) => {
      return this.incidentService.post('incidents', '', null, action.incident)
        .then((res: Incident) => {
          return incidentActions.incidentCreateSuccess({incident: res});
        })
        .catch((err) => incidentActions.incidentCreateError({error: err.message}));
    })
  );


  @Effect({dispatch: true})
  incidentCreateSuccess$ = this.actions$.pipe(
    ofType(incidentActions.incidentCreateSuccess),
    filter(action => action.makeActive === true),
    // @ts-ignore
    switchMap((action: { incident: any, makeActive: boolean, userPreferences: UserPreference }) => {
      // TODO - when userName is available from state, won't need to call this
      return Auth.currentAuthenticatedUser()
        .then(user => {
          const params = {
            incidentId: action.incident.sys_id,
            value: {
              category: action.incident.category,
              company: action.incident.company,
              u_queue: action.incident.u_queue,
              incident_state: action.incident.incident_state,
              priority: action.incident.priority,
              classification: action.incident.classification,
              u_unlisted_affected_user: user.username,
            },
            userPreferences: action.makeActive ? {
              ...action.userPreferences,
              activeInvestigation: action.incident.sys_id,
              owner_id: user.attributes.sub
            } : null
          };
          return incidentActions.updateIncident({...params});
        })
        .catch(error => {
          // Here the user is not authenticated.
          this.snackbarService.open(error.message);
        });
    })
  );

  @Effect({dispatch: true})
  incidentUpdateStatus$ = this.actions$.pipe(
    ofType(incidentActions.updateIncidentStatus),
    switchMap((action: { statusId: string, userPreferences?: UserPreference }) => {
      return this.incidentService.get('investigation', `${action.statusId}/update-status`, null).then(
        (res: any) => {
          if (res && res.message && res.message === 'Not Yet Completed') {
            return incidentActions.updateIncidentStatus({statusId: action.statusId});
          } else {
            // check to see if there is a username then we will update the user preferences
            if (action.userPreferences) {
              // We need to update the user preference
              return userPreferenceActions.updateUserPreferences({userPreferences: action.userPreferences, notify: true, incident: res});
            } else {
              return incidentActions.updateIncidentSuccess({incident: res});
            }
          }
        }
      ).catch(
        (error: any) => incidentActions.updateIncidentError({error: error.message})
      );
    })
  );

}
