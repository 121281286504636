import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface SessionMetric {
  id?: number;
  route: string;
  startTime: number;
  endTime: number;
  nextRoute: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  private _session$: BehaviorSubject<SessionMetric[]> = new BehaviorSubject([]);
  private metricsBuffer: SessionMetric | undefined = undefined;
  public session$ = this._session$.asObservable();

  constructor(public router: Router) {
    
    // listen for navigation start events and update metric
    this.router.events.pipe(filter((evt: RouterEvent) => evt instanceof NavigationStart))
    .subscribe((rEvent: RouterEvent) => {
      const nextSession = this.makeBuffer(this.metricsBuffer, rEvent);
      const prevMetric = [...this._session$.getValue(), nextSession];
      this._session$.next(prevMetric);
      this.metricsBuffer = this.makeBuffer(undefined, rEvent)
    })
  }

  private makeBuffer(buffer: SessionMetric, routerEvent: RouterEvent): SessionMetric {
    const curTime = Date.now();

    if (!!!buffer) {
      return {startTime: curTime, endTime: curTime, route: routerEvent?.url, nextRoute: null}
    }

    return {...buffer, nextRoute: routerEvent?.url, endTime: Date.now()}
  }

  getSessionDuration(buffer: SessionMetric): number {
      const st = buffer?.startTime || 0;
      const et = buffer?.endTime || 0;
      return new Date(et - st).getSeconds();
  }


}
