import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormEvent, Profile} from '../../store/profile.model';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {
  // Input for the data coming in from the user. It will default to null in case it is not provided.
  // The intend is to have this form re-use while creating a new user and/or when a user login for the first time
  // to setup his/her profile
  @Input() profile: Profile = null;

  // Output streamer to communicate with the outside.
  @Output() actionHandler: EventEmitter<FormEvent> = new EventEmitter<FormEvent>();

  // Form control.
  form: FormGroup;

  // Error matcher
  matcher = new MyErrorStateMatcher();

  constructor(
    // Injecting the form builder.
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    // initialize the form
    this.initializeForm(this.profile);
  }

  initializeForm(data: Profile) {
    // This function initialize the form group with the data provided.
    this.form = this.fb.group({
      email: [data && data.email ? data.email : '', [Validators.required, Validators.email]],
      family_name: [data && data.family_name ? data.family_name : '', [Validators.required, Validators.minLength(3)]],
      given_name: [data && data.given_name ? data.given_name : '', [Validators.required, Validators.minLength(3)]],
      profile: [data && data.profile ? data.profile : '', [Validators.required, Validators.minLength(3)]],
      gender: [data && data.gender ? data.gender : '', [Validators.required, Validators.minLength(1)]]
    });
  }

  cancelEvent(): void {
    // This is trigger when the cancel button on the form is clicked.
    this.actionHandler.emit({type: 'CANCEL'});
  }

  submitEvent(): void {
    // this is trigger when the form is submitted.
    // in order for us to submit the form, we need to make sure that the form is valid.
    if (this.form.valid) {
      this.actionHandler.emit({type: 'SUBMIT', data: this.form.value});
    } else {
      // TODO - Vico what do you want to do here. Should we use the toast here
    }
  }

}
