<div *ngIf="loading">Loading user preferences</div>
<div class="layout-padding" fxLayout.gt-xs="row wrap" fxLayout.xs="column">
  <mat-card fxFlex fxFlex.gt-lg="25">
    <mat-card-title>MFA</mat-card-title>
    <mat-card-subtitle>Adjust your MFA settings</mat-card-subtitle>
    <mat-card-content fxLayout="column">
      <div class="user-preference--error" *ngIf="error">{{ error }}</div>
      <div>
        <div *ngIf="!mfaEnabled">
          <button mat-raised-button color="primary" (click)="enableMfa()">
            MFA Registration
          </button>
        </div>
        <div *ngIf="mfaEnabled">
          <div *ngIf="!enableMFAInput">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="mfaEnabled"
              (change)="toggleMFAStatus($event)"
              labelPosition="before"
              [disabled]="mfaLoading"
              >MFA Enabled
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions></mat-card-actions>
  </mat-card>

  <mat-card fxFlex fxFlex.gt-lg="25">
    <mat-card-title>Password</mat-card-title>
    <mat-card-subtitle>Change your password</mat-card-subtitle>
    <mat-card-content>
      <form
        [formGroup]="changePasswordForm"
        (submit)="submitPasswordChange(changePasswordForm)"
        fxLayout="column"
      >
        <mat-form-field>
          <input
            type="password"
            matInput
            formControlName="currentPassword"
            placeholder="Current Password"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            type="password"
            matInput
            formControlName="newPassword"
            placeholder="New Password"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            type="password"
            matInput
            formControlName="confirmPassword"
            placeholder="Confirm Password"
          />
        </mat-form-field>

        <button
          mat-raised-button
          [disabled]="changePasswordForm.invalid"
          color="primary"
        >
          <mat-icon>save</mat-icon>
          Update
        </button>
      </form>
    </mat-card-content>
    <mat-card-actions> </mat-card-actions>
  </mat-card>

  <mat-card fxFlex fxFlex.sm="100" fxFlex.gt-lg="25">
    <mat-card-title>Account Preferences</mat-card-title>
    <mat-card-subtitle
      >Actions applicable to the user preferences.</mat-card-subtitle
    >
    <mat-card-content>
      <div class="mfa" *ngIf="eventParams">
        <form
          [formGroup]="preferredFieldForm"
          *ngIf="!(loading$ | async)"
          (submit)="updateUserPreference()"
          fxLayout="column"
        >
          <mat-form-field>
            <mat-label>Default Search Columns</mat-label>
            <mat-select
              formControlName="searchFields"
              multiple
              (selectionChange)="change($event)"
            >
              <app-auto-complete
                (searchText)="onTextInput($event)"
              ></app-auto-complete>
              <mat-option
                *ngFor="
                  let field of eventParams.fields | inputSearch: searchText
                "
                [value]="field.name"
              >
                {{ field.friendly }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Default Date Format</mat-label>
            <mat-select formControlName="dateFormat">
              <mat-option *ngFor="let d of eventParams.dateFormats" [value]="d">
                {{ newDate | date: d }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Default Search Refresh Interval</mat-label>
            <mat-select formControlName="searchRefreshInterval">
              <mat-option
                *ngFor="let searchInterval of eventParams.searchIntervals"
                [value]="searchInterval.name"
              >
                {{ searchInterval.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Default Search Range</mat-label>
            <mat-select
              formControlName="dateRange"
              [compareWith]="datetimeService.dateRangeComparison"
            >
              <mat-option
                *ngFor="let dateRange of eventParams.dashboardDateRanges"
                [value]="dateRange.value"
              >
                {{ dateRange.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Update button -->
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="
              preferredFieldForm.pristine || preferredFieldForm.invalid
            "
          >
            <mat-icon>save</mat-icon>
            Update
          </button>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
