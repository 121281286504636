import {Component, OnDestroy, OnInit} from '@angular/core';
import {SecurityPostureService} from '../../services/security-posture.service';
import {Observable, ReplaySubject} from 'rxjs';
import {delay, filter, map, pluck, take, takeUntil} from 'rxjs/operators';
import {SnackbarService} from '@shared-services/snackbar.service';
import {Store} from '@ngrx/store';
import {State} from '@app/reducers';
import * as companyActions from '../../../admin/store/company.actions';
import {AuthService} from '../../../auth/services/auth.service';
import {Company} from '../../../admin/store/company.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '@sweet-shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatRadioChange} from '@angular/material/radio';
import * as profileActions from '@app/pages/profile/store/profile.actions';
import {User} from '@app/pages/admin/store/user/user.model';

@Component({
    selector: 'app-security-posture',
    templateUrl: './security-posture.component.html',
    styleUrls: ['./security-posture.component.scss']
})
export class SecurityPostureComponent implements OnInit, OnDestroy {
    userProfile: User = null;
    selectedSecurityPosture = '';
    selectedCompany: string;
    loadingSecurityPosture$: Observable<boolean>;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    companies: Company[];
    matDialogRef: MatDialogRef<ConfirmationDialogComponent>;
    destroyer$: ReplaySubject<any> = new ReplaySubject<any>();

    securityPostureDefinitions = [
        {
            label: 'No Blocking',
            value: 'none',
            description: 'No indicators will be automatically blocked.',
            style: {color: '#0080dc'}
        },
        {
            label: 'Confirmed Threats',
            value: 'severe',
            description: 'Block contact to indicators with Risk Score = 100',
            style: {color: '#009054'}
        },
        {
            label: 'Very High Risk and Confirmed Threats',
            value: 'very high',
            description: 'Block contact to indicators with Risk Score > 80',
            style: {color: '#b96b00'}
        },
        {
            label: 'High Risk, Very High Risk, and Confirmed Threats',
            value: 'high',
            description: 'Block contact to indicators with Risk Score > 60',
            style: {color: '#ee3026'}
        },
    ];

    // variables for company dropdown
    companies$: Observable<Company[] | { label: string, value: string }[]>;
    filterForm = new FormGroup({});
    filterModel = {companyId: 'company name'};
    filterFields: FormlyFieldConfig[] = [
        {
            key: 'companyId',
            type: 'search-select',
            templateOptions: {
                label: 'Company',
                options: [],
                search: true,
                searchLabel: 'Search Companies',
            },
            hooks: {
                onInit: (field: FormlyFieldConfig) => {
                    // set options
                    field.templateOptions.options = this.companies$;
                    // get authed user to set users company as default value
                    this.authService.getAuthenticatedUser()
                        .pipe(take(1), pluck('profile'))
                        .subscribe(userCompany => {
                            this.securityPostureService.fetchSecurityPosture(userCompany);
                            field.formControl.setValue(userCompany);
                            this.selectedCompany = userCompany;
                        });

                    // watch and react to form changes
                    field.formControl.valueChanges.subscribe(val => {
                        this.securityPostureService.fetchSecurityPosture(val);
                        this.selectedCompany = val;
                    });
                },
            },
        }
    ];
    currentUsersCompany: any;

    // set up for form to update security posture
    securityPostureForm = new FormGroup({
        securityLevel: new FormControl(this.selectedSecurityPosture, Validators.required)
    });

    constructor(
        private securityPostureService: SecurityPostureService,
        private store: Store<State>,
        private authService: AuthService,
        private snackbarService: SnackbarService,
        private matDialog: MatDialog
    ) {
    }

    ngOnInit(): void {

        // Load user profile
        this.authService.getAuthenticatedUser().pipe(takeUntil(this.destroyer$)).subscribe(user => {
                this.userProfile = user;
            }
        );

        // Get user company info
        this.store.dispatch(profileActions.loadCurrentUserCompany({currentUserCompanyId: this.userProfile.profile}));

        // load companies for company field options
        this.store.dispatch(companyActions.loadCompanies());
        this.companies$ = this.store.select(state => state.companies.companies)
            .pipe(
                filter(data => !!data),
                // formly select needs options in following shape
                map(companies => companies.map(company => {
                    return {label: company.name, value: company.id};
                })),
                takeUntil(this.destroyed$)
            );

        // Update security posture whenever changed
        this.securityPostureService.getSecurityPosture().pipe(
            filter(data => !!data)
        ).subscribe(posture => {
            this.selectedSecurityPosture = posture.value;
            this.securityPostureForm.controls.securityLevel.setValue(posture.value);
        });

        // Handle security posture error
        this.securityPostureService.getSecurityPostureError()
            .pipe(
                filter(data => !!data),
                takeUntil(this.destroyed$)
            )
            .subscribe(err => {
                this.snackbarService.open(err);
            });

        // Handle security posture success
        this.securityPostureService.getSecurityPostureSuccess()
            .pipe(
                filter(data => !!data),
                takeUntil(this.destroyed$)
            )
            .subscribe(successMessage => {
                this.snackbarService.open(successMessage);
            });

        // listen for loading status
        this.loadingSecurityPosture$ = this.securityPostureService.getSecurityPostureLoading().pipe(delay(0));
        // Users default company
        this.store.select(state => state.profile.currentUserCompany)
            .pipe(takeUntil(this.destroyer$))
            .subscribe(value => this.currentUsersCompany = value);
    }

    radioChange(event: MatRadioChange) {
        this.securityPostureForm.controls.securityLevel.setValue(event.value);
    }

    onUpdateClick() {
        this.matDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            autoFocus: false,
            maxHeight: '90vh',
            panelClass: 'ctl-panel-class'
        });
        const friendlySelected = this.securityPostureDefinitions.find(def => def.value === this.securityPostureForm.get('securityLevel').value).label;
        this.matDialogRef.componentInstance.title = 'Security Posture';
        this.matDialogRef.componentInstance.message = `Are you sure you want to update the Security Posture to '${friendlySelected}'?`;
        this.matDialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.updateSecurityPosture();
            }
        });
    }

    updateSecurityPosture() {
        // Captures case where user is not a lumen/ctl and does not trigger company filter observables
        let companyToUpdate = '';
        if (this.selectedCompany == null) {
            // Non-lumen user
            companyToUpdate = this.currentUsersCompany.name;
        } else {
            // Lumen user
            companyToUpdate = this.selectedCompany;
        }

        this.securityPostureService.updateSecurityPosture({
            company_id: companyToUpdate,
            preference_name: 'security_posture_text',
            type: 'string',
            value: this.securityPostureForm.get('securityLevel').value
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

}
