<div class="layout-padding">
  <app-portal-loader [loadingStatus]="!searchFieldDetails"></app-portal-loader>
  <section class="result-search">
    <app-event-filter></app-event-filter>
  </section>
  <mat-card *ifUserCan="'analysis.run-search'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title>Search</mat-card-title>
      <div (click)="onHelpClick()" aria-label="Incident Search Help">
        <button mat-icon-button>
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content class="row-layout">
      <app-form-builder
        [loading]="searching"
        [loadingSubject]="loaderSubject"
        *ngIf="searchFieldDetails"
        [fieldDetails]="searchFieldDetails"
        [actionButtons]="searchActionButtons"
        (actionEvents)="searchFilterActionHandler($event)"
        [pathValues]="pathValues"
        #searchEventsForm
      >
      </app-form-builder>
      <div class="refresh-timer" *ngIf="counter > 0">
        <h5>Time to refresh: {{ counter }}</h5>
      </div>
    </mat-card-content>
  </mat-card>
  <br />

  <mat-card>
    <button
      mat-button
      color="primary"
      (click)="downloadAsCSV()"
      *ngIf="eventsDataStream.value.length"
    >
      <mat-icon>get_app</mat-icon>
      Download Results
    </button>
    <mat-card-content *ifUserCan="'analysis.run-search'">
      <div class="table-container">
        <mat-table
          matSort
          [dataSource]="dataSource"
          cdkDropListGroup
          cdkDropList
          cdkDropListOrientation="horizontal"
          [cdkDropListData]="computeDisplayedCols(eventsHeadersSubject | async)"
          (cdkDropListDropped)="dropListDropped($event)"
          #eventsTable
        >
          <ng-container matColumnDef="view" sticky="true" stickyEnd="false">
            <mat-header-cell *matHeaderCellDef>View</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                (click)="onTableRowClick({ type: 'VIEW_CLICK', data: element })"
              >
                <mat-icon>visibility</mat-icon>
              </button>
              <ng-container *ngIf="element.JCEF_evtType === 'Clue'">
                <button
                  #sendBtn
                  [disabled]="false"
                  mat-icon-button
                  (click)="
                    onTableRowClick({ type: 'MESSAGE_CLICK', data: element });
                    sendBtn.disabled = true
                  "
                >
                  <mat-icon>send</mat-icon>
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container
            [matColumnDef]="header.name"
            *ngFor="
              let header of filteredHeaders(eventsHeadersSubject | async);
              let i = index
            "
          >
            <mat-header-cell
              *matHeaderCellDef
              [mat-sort-header]="header.name"
              cdkDropListLockAxis="x"
              cdkDrag
              (matSortChange)="sortData($event)"
            >
              {{ header.friendly }}
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              (contextmenu)="
                onContextMenu($event, header.friendly, element[header.name])
              "
            >
              {{ element[header.name] }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions" sticky="true" stickyEnd="true">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Actions
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <app-cell-actions
                [item]="element"
                (actionHandler)="cellActionsHandler($event)"
              ></app-cell-actions>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="
              computeDisplayedCols(eventsHeadersSubject | async)
            "
          ></mat-header-row
          >>
          <mat-row
            *matRowDef="
              let row;
              columns: computeDisplayedCols(eventsHeadersSubject | async)
            "
          ></mat-row
          >>
        </mat-table>
        <mat-paginator
          [pageIndex]="incidentsService.paginatorConfig?.pageIndex"
          [pageSize]="incidentsService.paginatorConfig?.pageSize"
          [length]="incidentsService.paginatorConfig?.length"
          (page)="handlePaginationChange($event)"
          [pageSizeOptions]="[5, 10, 25, 50, 100, 250, 500]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </mat-card-content>
    <div
      style="visibility: hidden; position: fixed"
      [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y"
      [matMenuTriggerFor]="contextMenu"
    ></div>
    <mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item (click)="includeValue()">Include</button>
        <button mat-menu-item (click)="excludeValue()">Exclude</button>
      </ng-template>
    </mat-menu>
  </mat-card>
</div>
