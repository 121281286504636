import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { CompanyIndex, EventParameters, SearchPayload, SearchResponse, SortField } from '../interfaces/events';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _event: Subject<any> = new Subject();
  private _eventResults: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private _eventFilters: BehaviorSubject<any> = new BehaviorSubject(null);
  private _searching: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _error: BehaviorSubject<string> = new BehaviorSubject(null);

  eventResults$: Observable<any[]> = this._eventResults.asObservable();
  eventFilters$: Observable<any> = this._eventFilters.asObservable();
  searching$: Observable<boolean> = this._searching.asObservable();
  error$: Observable<string> = this._error.asObservable();

  event$ = this._event.asObservable();

  constructor(
    private httpClient: AppService<any>,
    private client: HttpClient
  ) { }

  setEventFilters(filters: any): void {
    this._eventFilters.next(filters);
  }

  setEventResults(results: any[]): void {
    this._eventResults.next(results);
  }

  setSearching(searching: boolean): void {
    this._searching.next(searching);
  }

  sendEvent(payload: any): void {
    this._event.next(payload);
  }

  fetchEventParams(): Observable<EventParameters> {
    return from(this.httpClient.get('eventParams', '', null));
  }

  fetchIndexes(companyId: string): Observable<CompanyIndex[]> {
    return from(this.httpClient.get('companyIndexes', 'indexes-by-company', { companyFilter: companyId }));
  }

  // TODO - Move this to the userService when it is ready.
  fetchUserPreference(): Observable<any> {
    return from(this.httpClient.get('userPreferences', '', null));
  }

  fecthUserFieldSet(indexId: string): Observable<any> {
    return from(this.httpClient.get('companyIndexes', `field-sets/${indexId}`, null));
  }

  updateUserFieldSet(indexId: string, fields: string[]): Observable<any> {
    let payload = {
      index_id: indexId,
      headers: fields
    };

    return from(this.httpClient.post('companyIndexes', 'field-sets', null, payload));
  }

  search(payload: SearchPayload): Observable<SearchResponse> {
    return from(this.httpClient.post('search', '', null, payload));
  }

  searchResults(queryId: string, companyFilter: string): Observable<{ url: string }> {
    return from(this.httpClient.get('search', `${queryId}/results`, { companyFilter }))
  }

  fetchDataFromS3(url: string): void {
    this.client.get(url).subscribe({
      next: (res: any) => {
        this._eventResults.next(res.results);
        this._searching.next(false);
      },
      error: (err) => {
        this._error.next(err.message);
        this._searching.next(false);
      }
    })
  }

  sentToMacaw(item: any): Observable<any> {
    return from(this.httpClient.post('macaw', `investigate`, null, item));
  }

  fetchIncidentAttributes(): Observable<any> {
    return from(this.httpClient.get('incidentAttributes', '', null));
  }

  createIncident(payload: any): Observable<any> {
    return from(this.httpClient.post('investigation', '', { action: 'create-incident' }, payload));
  }
}
