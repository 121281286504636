<div class='forgot-password' fxLayout='row' fxLayoutAlign='center center'>
  <mat-card fxFlex.xs="95">
    <mat-progress-bar color="primary" mode="indeterminate" *ngIf='loading'></mat-progress-bar>
    <mat-card-title>MFA Code</mat-card-title>
    <mat-card-subtitle>Enter the MFA code sent to you.</mat-card-subtitle>
    <mat-card-content>
      <form [formGroup]='form' fxLayout='column' (submit)='onMFACodeSubmit()'>
        <p class='error' *ngIf='error'>{{error}}</p>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>MFA Code</mat-label>
          <input matInput formControlName='code'>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color='primary' (click)="onMFACodeSubmit()" [disabled]='form.invalid || loading'>Submit</button>
    </mat-card-actions>
  </mat-card>
</div>

