<app-portal-loader [loadingStatus]="loading || !user"></app-portal-loader>

<div
  fxLayout="row"
  fxLayout.lt-md="column"
  class="layout-padding"
  fxLayoutAlign="space-between start"
  fxLayoutAlign.lt-md="start stretch"
>
  <button
    mat-stroked-button
    type="button"
    routerLink="/admin/external-users"
    class="account_list--back"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back to Accounts
  </button>
  <mat-card
    *ngIf="!loading && user"
    fxLayout="column"
    fxFlex.gt-sm="70"
    fxFlex.lt-md="100"
  >
    <mat-card-header fxLayout="row">
      <div mat-card-avatar>
        <app-user-display
          [profile]="{
            given_name: user.given_name,
            email: user.user_id
          }"
        ></app-user-display>
      </div>
      <div>
        <mat-card-title class="profile_user-name"
          >{{ user.given_name }} {{ user.family_name }}</mat-card-title
        >
        <mat-card-subtitle>{{ user.user_id }}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Company</h3>
        <mat-list-item>
          <p>{{ user.profile | titlecase }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Role</h3>
        <mat-list-item>
          <mat-chip-list disabled>
            <mat-chip *ngFor="let role of user.gender">{{ role }}</mat-chip>
          </mat-chip-list>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Domain</h3>
        <mat-list-item>
          <p>{{ user.domain }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Realm</h3>
        <mat-list-item>
          <p>{{ user.realm }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <section
        class="permission-container"
        *ifUserCan="'account.edit-permissions'"
      >
        <h2>Granular Permissions</h2>
        <mat-card-subtitle
          >Below you'll be able to modify user permissions on a granular
          level</mat-card-subtitle
        >
        <app-permission-list
          [user]="user"
          editable="false"
          *ngIf="user"
        ></app-permission-list>
      </section>
    </mat-card-content>
    <mat-card-actions
      *ifUserCan="['account.edit', 'account.delete']; any: true"
      fxLayout="row wrap"
      fxLayoutAlign="space-around"
    >
      <ng-container *ifUserCan="'account.edit'">
        <button mat-stroked-button (click)="onEditUser(externalDetailData)">
          Edit
        </button>
        <button mat-stroked-button *ngIf="false">Global Logout</button>
      </ng-container>
      <ng-container *ifUserCan="'account.delete'">
        <button mat-stroked-button (click)="onDeleteUser()">Delete</button>
      </ng-container>
    </mat-card-actions>
  </mat-card>
  <div></div>
</div>
