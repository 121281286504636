<div class="title">
  {{ title }}
</div>

<div class="options">
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="widget_menu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button
      *ngFor="let option of getActiveMenus()"
      mat-menu-item
      (click)="menuEvent.emit(option.action)"
    >
      <mat-icon> {{ option?.icon }} </mat-icon>
      <span> {{ option?.label }} </span>
    </button>
  </mat-menu>
</div>
