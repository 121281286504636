import {filter, take} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {ThemeSwitcherService} from '../../services/theme-switcher.service';
import {Observable} from 'rxjs';
import {AuthService} from '../../../pages/auth/services/auth.service';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';
import {AppEmitterService} from '../../../app-emitter.service';
import {MatDialog} from '@angular/material/dialog';
import {SupportDialogComponent} from '../support-dialog/support-dialog.component';
import {AppService} from '../../../app.service';
import {Incident} from '../../../pages/incidents/store/incident.model';
import {Router} from '@angular/router';
import * as companyActions from '../../../pages/admin/store/company.actions';
import {environment} from '../../../../environments/environment';
import {User} from '../../../pages/admin/store/user/user.model';
import {logoutAction} from '../../../reducers/actions';
import {WhatsNewDialogComponent} from '../whats-new-dialog/whats-new-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isDarkTheme: Observable<boolean>;
  @Input() isAuthenticated = false;
  @Input() user: User = null;
  isDark = true;
  checked: boolean;
  hasNoActiveInvestigations = true;
  activeInvestigationId = '-';
  versionNumber: string;
  companiesMap: { [key: string]: string };

  constructor(
    private themeService: ThemeSwitcherService,
    private authService: AuthService,
    private store: Store<State>,
    private appEmitterService: AppEmitterService,
    public dialog: MatDialog,
    private appService: AppService<Incident>,
    private router: Router
  ) {
  }

  ngOnInit() {

    // Listens for user authentication before calling all companies.
    this.authService.isAuthenticated().subscribe((value) => {
      if (value) {
        this.store.dispatch(companyActions.loadCompanies());
        this.store.select(state => state.companies.companiesMap).pipe(
          filter(data => !!data),
          take(1)
        ).subscribe(val => {
          this.companiesMap = val;
        });
      }
    });

    // Checks to see if dark mode is activated
    this.isDarkTheme = this.themeService.isDarkTheme;
    this.isDarkTheme.subscribe((val) => {
      this.isDark = val;
    });

    this.store.select(state => state.userPreferences.userPreferences.activeInvestigation)
      .pipe(
        filter(v => !!v)
      )
      .subscribe(res => this.activeInvestigationId = res);

    this.versionNumber = environment.version;


  }

  goToAssignedIncidents() {
    this.router.navigateByUrl('/incidents', {state: {userName: this.user.username}}).then(() => {
      const emitData = {
        name: 'SHOW_ASSIGNED',
        data: {
          userName: this.user.username,
          userCompany: this.user.profile,
          nonClosedInvestigationOptions: ['New', 'Active', 'Pending Customer Info', 'Pending for Change']
        }
      };
      this.appEmitterService.emit(emitData);
    });
  }

  toggleTheme() {
    this.checked = !this.checked;
    this.themeService.setDarkTheme(this.checked);
    return this.checked;
  }

  toggleNav() {
    this.appEmitterService.emit({
      name: 'TOGGLE_SIDENAV',
      data: null
    });
  }

  // Regular logout function. Takes in boolean. Param of true activates the Global Logout feature
  logout(global: boolean) {
    this.themeService.setDarkTheme(false);
    this.store.dispatch(logoutAction());
    this.authService.logout();
  }

  // Opens up the contact support dialog with email information linked to GitLab Service Desk
  openDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent, {
      data: {
        email: 'incoming+ctl-mss-development-web-ng-portal-15429979-issue-@incoming.gitlab.com'
      }
    });
  }

  // Opens up the what's new dialog with updated change logs
  openDialogReleaseNotes(): void {
    const dialogRef = this.dialog.open(WhatsNewDialogComponent, {});
  }
}
