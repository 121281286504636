<div class="layout-padding" fxLayout="column" fxLayout.gt-lg="row" fxLayoutGap="8px">
  <div fxFlex.gt-lg="50" fxFlex="100" [ngClass]="expand? 'full-list-table': 'partial-list-table'">
    <div>
      <app-portal-loader [loadingStatus]="loading"></app-portal-loader>
      <mat-card style="width: 100%">

        <mat-card-title>List Management</mat-card-title>
        <div class="search">
          <mat-form-field>
            <mat-label>Company: </mat-label>
            <mat-label>{{params.companyFilter}}</mat-label>
            <mat-select (selectionChange)="onSelectChange($event)">
              <mat-option>
                <ngx-mat-select-search [formControl]="searchCompany" noEntriesFoundLabel="'No results found'" placeholderLabel="Search Company"  [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
                <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
              </mat-option>
              <mat-option *ngFor="let company of companies | inputSearch:searchCompany?.value" [value]="company">{{company.friendly}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-stroked-button (click)="searchFilterActionHandler()">Search</button>

        </div>
        <mat-form-field fxFlexFill>
          <input matInput (keyup)="listTable.applyFilter($event.target.value)"
                 autocomplete="off"
                 placeholder="Filter through results. Ex: Allow ">
        </mat-form-field>

        <app-table
          [data]="listManagementList"
          [headerStream]="listHeader"
          [scrollable]="false"
          [pageSize]="10"
          [doubleScrollbar]="false"
          (events)="onRowClick($event)"
          #listTable>
        </app-table>
      </mat-card>
    </div>
  </div>

  <div *ngIf="!expand" fxFlex.gt-lg="50" fxFlex="100">
    <div [ngClass]="!expand? 'detail-full-list-table': 'detail-partial-list-table'">
      <app-portal-loader [loadingStatus]="loadingDetailList"></app-portal-loader>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <mat-card-title fxFlex>{{selectedList.name}}</mat-card-title>
          <button *ifUserCan="['list-mgmt.add-item', 'list-mgmt.clear-list', 'list-mgmt.view-items']; any: true" mat-icon-button [matMenuTriggerFor]="listMenu" aria-label="Profile Menu" style="color: #404142">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #listMenu="matMenu">
            <button *ifUserCan="ListManagementTableActions.createItemListPermission"
                    mat-menu-item (click)="openAddListItemDialog('', listTable.headers, 'ADD_LIST_ITEM' )">
              <mat-icon class="material-icons-round">add</mat-icon>
              <span>Add List Item</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)=clearAllListItems(selectedList) *ifUserCan="'list-mgmt.clear-list'">
              <mat-icon color="warn">delete_sweep</mat-icon>
              <span>Clear List</span>
            </button>
            <button mat-menu-item
                    (click)=refreshAllListItems(selectedList)
                    *ifUserCan="ListManagementTableActions.viewItemListPermission">
              <mat-icon color="warn">refresh</mat-icon>
              <span>Refresh List</span>
            </button>
          </mat-menu>
        </div>
        <mat-form-field fxFlexFill>
          <input matInput (keyup)="listDetailTable.applyFilter($event.target.value)"
                 autocomplete="off"
                 placeholder="Filter through results. Ex: facebook.com">
        </mat-form-field>

        <app-table
          [data]="finalDetailDataList"
          [headerStream]="detailListHeader"
          [scrollable]="false"
          [pageSize]="10"
          [actionsTemplate]="listDetailTableActions"
          [doubleScrollbar]="false"
          #listDetailTable>
        </app-table>

        <ng-template #listDetailTableActions let-element>
          <div>
            <ng-container *ifUserCan="'list-mgmt.edit-item'">

              <button mat-menu-item
                    (click)="openAddListItemDialog(element, listDetailTable.headers, 'EDIT_LIST_DATA')" >
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="detailRowListEvent('DELETE_LIST_DATA', element,
            listDetailTable.headers)"  *ifUserCan="ListManagementTableActions.deleteItemListPermission">
            <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            </ng-container>
          </div>
        </ng-template>

      </mat-card>

    </div>
  </div>

</div>
