<section>
  <h3 mat-dialog-title>Report Input Variables</h3>
  <div class='content'>
    <form [formGroup]='form' (submit)="submit()">
      <mat-form-field appearance="outline" *ngFor='let variableName of variables'>
        <mat-label>{{variableName}}</mat-label>
        <input matInput [formControlName]="variableName">
      </mat-form-field>
      <div class='actions'>
        <button mat-button (click)="cancel()" type='button'>Cancel</button>
        <button mat-raised-button color='primary' type='submit' [disabled]='form.invalid'>Submit</button>
      </div>
    </form>
  </div>
</section>

