import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '@app/reducers';

import {DevicesService} from '../services/devices.service';
import {AuthService} from '../../auth/services/auth.service';
import {Company} from '../../admin/store/company.model';
import {PermissionService} from '@app/sweet-shared/services/permission.service';
import { DeviceCreateComponent } from '../device-create/device-create.component';
import { SnackbarService } from 'src/app/shared-services/snackbar.service';
import {CompaniesService} from '@shared-services/companies.service';

export interface Interfaces {
  amon: string;
  ext: string;
  gw: string;
  if_id: number;
  ifname: string;
  ip: string;
  mgmt: string;
  monitor: string;
  netmask: number;
  vlan: string;
}
export interface Devices {
  createdAt: string;
  createdBy: string;
  customerId: string;
  deviceCustomerId: number;
  deviceId: number;
  deviceType: string;
  friendlyName: string;
  healthInformation: string;
  id: string;
  interfaces: Interfaces[];
  lat: string;
  lon: string;
  name: string;
  physicalAddress: string;
  service: string;
  updatedAt: string;
  updatedBy: string;
  upstreamData: string;
  version: string;
}

@Component({
  selector: 'app-device',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
  // contains some of the granular permissions for dashboards
  deviceTableActions: any = {
    readPermission: 'devices.view',
  };
  loading = false;
  collaspe = false;
  filterVal: string = null;
  deviceList: BehaviorSubject<Devices[]> = new BehaviorSubject([]);
  deviceHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  companies$: Observable<Company[] | {label: string, value: string}[]>;
  filterModel = {companyId: 'company name'};
  userProfile: any;
  createDeviceLoader: boolean;
  selectedCompany: any;

  searchCompany = new FormControl('');
  companies: any[] = [];

  constructor(
    private devicesService: DevicesService,
    private store: Store<State>,
    private permissionService: PermissionService,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private companiesService: CompaniesService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getAllDevice();
    this.getAllCompany();
  }

  getAllCompany() {
    this.store.select(state => state.companies.companies).pipe(
      filter(c => !!c),
      takeUntil(this.destroyer$)
    ).subscribe(companies => {
      console.log('companies$', companies)
      this.companies = this.companiesService.companyNameAndFriendly(companies);
    });
  }

  getAllDevice(): void {
    this.authService.getAuthenticatedUser().pipe(take(1)).subscribe(user => {
      this.userProfile = {
        companyFilter: user.profile,
      };
      this.getDeviceList(user.profile);
     });
    this.buildDeviceHeader();
  }


  getDeviceList(userProfile): void {
    this.devicesService.deviceList(userProfile);
    this.devicesService.getListDevice().pipe(
      filter(data => !!data),
      takeUntil(this.destroyer$)
    ).subscribe(res => {
      if (this.userProfile.companyFilter !== 'centurylink') {
        this.deviceList.next(res.filter(device => device.customerId === this.userProfile.companyFilter));
      } else {
        this.deviceList.next(res);
      }
      this.loading = false;
    });
  }

  searchFilterActionHandler(event) {
    this.getDeviceList(event.value);
  }

  buildDeviceHeader() {
    // build table headers
    this.devicesService.headerBuilder();
    this.devicesService.getHeaderList().pipe(
      filter(data => !!data),
      takeUntil(this.destroyer$)
    ).subscribe(header => {
      this.deviceHeader.next(header);
    });
  }

  applyFilter(filterValue: string) {
    this.filterVal = filterValue;
    this.dataSource.filter = filterValue.trim()?.toLowerCase();
  }

  onSelectChange(event) {
    this.selectedCompany = event.value;
  }

  onDeviceRowClick(row) {
    if (this.permissionService.hasPermission(this.deviceTableActions.readPermission)) {
      this.router.navigate([`/devices/${row.data.customerId}/${row.data.id}`]).then(() => {});
    }
  }

  onRowClick(row: any) {
    if (row.type === 'ROW_CLICK') {
      this.onDeviceRowClick(row);
    } else if (row.type === 'DELETE') {
    }
  }

  // Fuction used to create a device
  createDevice() {
    const dialogRef = this.dialog.open(DeviceCreateComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: 'ctl-panel-class'
    });
    if (this.userProfile.companyFilter === 'centurylink') {
      dialogRef.componentInstance.companies = this.companies;
    }
    dialogRef.componentInstance.loading = this.createDeviceLoader;
    dialogRef.componentInstance.actionEvents.pipe(takeUntil(this.destroyer$)).subscribe(event => {
     if (event.name === 'submit') {
       this.devicesService.createDevice(event.data.value);
       this.snackbarService.open('Your device was sucessfull created');
       dialogRef.close();
      }
     else if (event.name === 'cancel') {
      dialogRef.close();
      }
    });
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
