import { Injectable } from '@angular/core';
import {AppService} from '../../../app.service';
import {BehaviorSubject, of, ReplaySubject} from 'rxjs';
import {TableHeader} from '../../../sweet-shared/components/table/table.component';
import { HttpClient } from '@angular/common/http';
import { filter, takeUntil } from 'rxjs/operators';
import {Auth} from 'aws-amplify';
import { ThreatHunt } from '../store/threat-hunting.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ThreatHuntingService {
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  private threatAnalysisTypesData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private threatHuntData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private threatHuntHeadersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private threatHuntService: AppService<ThreatHunt>,
    private http: HttpClient,
  ) { }

  private THREATHUNTINGFIELDS = [ "ISODATE", "JCEF_evtName", "JCEF_evtBytes", "JCEF_evtDur", "JCEF_srcIP", "JCEF_srcPort", "JCEF_dstIP", "JCEF_dstPort", "JCEF_evtProto"]
  private ANALYSIS_TYPES = ["data_exfiltration"]

  getThreatAnalysisTypes() {
    return this.threatAnalysisTypesData;
  }

  getThreatHuntData() {
    return this.threatHuntData;
  }

  getThreatHuntError() {
    return this.error;
  }

  getLoading() {
    return this.loadingSubject;
  }

  getThreatHuntHeaders() {
    return this.threatHuntHeadersSubject;
  }

  fetchThreatAnalysisTypes(){
    of(this.ANALYSIS_TYPES).subscribe(analysis => this.threatAnalysisTypesData.next(analysis))
  }

  async fetchThreatHuntData(from, to, limit) {
    this.loadingSubject.next(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const queryParams = {
        size: limit,
        companyFilter: user.attributes.profile,
      }
      const eventRequest =  {
        query: [],
        dateRange: 'custom',
        dateFrom: from,
        dateTo: to
      }

      const rawEvents = await this.threatHuntService.post('events', '', queryParams, eventRequest); 
      const result = await this.threatHuntService.get('events', `${rawEvents.id}/results`, queryParams);

      this.http.get(result.url).pipe(
        filter(data => !!data),
        takeUntil(this.destroyer$))
      .subscribe((data: any) => {
        const events = data.results.map((item: any) => item._source);
        const sortedEvents = _.sortBy(events, [(o) => Number(o['JCEF_evtBytes'])]).reverse();
        this.threatHuntData.next(sortedEvents);
        this.loadingSubject.next(false);
      });
    }
    catch(e){
      this.error.next(e.message);
    }
    finally{
      this.loadingSubject.next(false);
    }       
  }

  async fetchThreatHuntHeaders() {
    try {
      const paramsFields = await this.threatHuntService.get('eventParams', '', null);
      const threatHuntHeaders: TableHeader[] = paramsFields.fields.filter(field => this.THREATHUNTINGFIELDS.includes(field.name));
      this.threatHuntHeadersSubject.next(threatHuntHeaders);
    }
    catch(e) {
      this.error.next(e.message)
    }
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}


