import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { from, Observable } from 'rxjs';
import { Attribute, Company, CompanyDetails, Product } from '@modules/companies/interfaces/companies';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private httpClient: AppService<any>
  ) { }

  fetchCompanies(): Observable<Company[]> {
    return from(this.httpClient.get('company', '', null));
  }

  fetchProducts(): Observable<Product[]> {
    return from(this.httpClient.get('products', '', null)).pipe(
      map(products => {
        return products.map(product => {
          return {
            ...product,
            parent: product?.parent?.map(parent => parent) ?? [],
            attributes: product.attributes.map(attr => attr)
          };
        });
      })
    );
  }

  fetchCompanyAttributes(): Observable<{ [key: string]: Attribute }> {
    return from(this.httpClient.get('company', 'attributes', null)).pipe(
      map(response => {
        return response.reduce((acc: { [key: string]: Attribute }, next) => {
          acc[next.name] = next;
          return acc;
        }, {});
      })
    )
  }

  fetchCompanyDetails(companyId: string): Observable<CompanyDetails> {
    return from(this.httpClient.get('company', companyId, null));
  }

  updateCompany(companyId: string, payload): Observable<CompanyDetails> {
    return from(this.httpClient.put('company', `${companyId}`, null, payload));
  }

  create(payload): Observable<CompanyDetails> {
    return from(this.httpClient.post('company', '', null, payload));
  }


}
