<div class="layout-padding">
  <app-portal-loader [loadingStatus]="!searchFieldDetails"></app-portal-loader>
  <div>
    <app-incident-display
      *ngIf="!!activeInvestigation"
      [loadingSubject]="updatingUserPreferences"
      [incidentData]="assignedIncidentSubject"
      (eventHandler)="incidentActionHandler($event)"
      [computeIncidentGrade]="computeIncidentGrade"
      [classificationOptions]="classificationOptions"
      [tierOptions]="tierOptions"
      [stateOptions]="stateOptions"
      [priorityOptions]="priorityOptions"
      [closureStateOptions]="closureStateOptions"
      [users]="users"
      [companies]="allCompanies"
      [userPreferences]="userPreferences"
      [showDetailsButton]="showIncidentDisplayDetailsButton"
    >
    </app-incident-display>
    <mat-button-toggle-group name="toggleSearch" aria-label="Font Style">
      <mat-button-toggle
        class="page-toggle-button"
        routerLink="/incidents"
        (click)="onToggleClick('incidents')"
        >Search Incidents</mat-button-toggle
      >
      <mat-button-toggle
        class="page-toggle-button"
        routerLink="events"
        (click)="onToggleClick('events')"
        >Search Events
      </mat-button-toggle>
    </mat-button-toggle-group>
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="this.urlService.getIncidentToggleValue() == 'incidents'">
    <mat-card *ifUserCan="['response.run-search']">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Search</mat-card-title>
        <div (click)="onHelpClick()" aria-label="Incident Search Help">
          <button mat-icon-button>
            <mat-icon>help</mat-icon>
          </button>
        </div>
      </div>
      <mat-card-content>
        <app-form-builder
          *ngIf="searchFieldDetails"
          [fieldDetails]="searchFieldDetails"
          [actionButtons]="searchActionButtons"
          (actionEvents)="searchFilterActionHandler($event)"
          [loading]="searching"
          (dateSelection)="handleDateSelection($event)"
          [loadingSubject]="loaderSubject"
          [updateField]="fieldUpdater"
          #search
        ></app-form-builder>

        <div class="refresh-timer" *ngIf="counter > 0">
          <h5>Time to refresh: {{ counter }}</h5>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-actions>
        <button
          *ifUserCan="'response.create-inv'"
          mat-stroked-button
          color="primary"
          (click)="onCreateIncident()"
        >
          <mat-icon>add</mat-icon>
          Create Incident
        </button>
        <ng-container *ifUserCan="'response.view-inv'">
          <button
            mat-button
            color="primary"
            (click)="downloadAsCSV()"
            *ngIf="incidentsDataStream.value.length"
          >
            <mat-icon>get_app</mat-icon>
            Download Results
          </button>
        </ng-container>
      </mat-card-actions>
      <mat-card-content *ifUserCan="'response.view-inv'">
        <app-table
          [pageSize]="incidentsService.paginatorConfig?.pageSize"
          [paginatorConfig]="incidentsService.paginatorConfig"
          (paginationChange)="handlePaginationChange($event)"
          [cellDecorator]="computeIncidentGrade.bind(this)"
          [newRow]="highlightNewOrUpdatedIncidents.bind(this)"
          [urgency]="{ header: 'u_queue', run: urgency }"
          [headerStream]="incidentsHeadersSubject"
          [data]="incidentsDataStream"
          [showFilter]="!loading && incidentsDataStream.getValue().length > 0"
          [loading]="searching"
          [greyOut]="isClosed"
          [actionsTemplate]="incidentActionsTemplate"
          (events)="onTableRowClick($event)"
          #incidentsTable
          *ngIf="incidentsHeadersSubject.value.length > 0"
        >
          <mat-form-field fxFlex *ngIf="incidentsTable.showFilter">
            <input
              matInput
              (keyup)="incidentsTable.applyFilter($event.target.value)"
              placeholder="Filter through results. Ex: Phishing Traffic Detected"
            />
          </mat-form-field>
          <ng-template #incidentActionsTemplate let-inc>
            <button
              *ifUserCan="'response.add-worklog'"
              mat-menu-item
              (click)="onAddWorklog(inc)"
            >
              <mat-icon>add</mat-icon>
              Add Worklog
            </button>
          </ng-template>
        </app-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
