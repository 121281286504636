import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface WidgetBarEvent {
  type: string;
  payload?: any
}

@Component({
  selector: 'app-widget-bar',
  templateUrl: './widget-bar.component.html',
  styleUrls: ['./widget-bar.component.scss']
})
export class WidgetBarComponent implements OnInit {

  @Input() title: string = '';
  @Input() enableDownload: boolean = false;
  @Input() dataHeadings = [];
  @Output() eventBus: EventEmitter<WidgetBarEvent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
