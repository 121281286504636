// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GridsterConfig } from 'angular-gridster2/lib/gridsterConfig.interface';
import { Auth } from 'aws-amplify';
import { version } from '../../package.json';

const API = {
    'us-east-1': {
        endpoints: [
            {
                name: 'reports',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/reports/'
            },
            {
                name: 'validators',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/validators/'
            },
            {
                name: 'investigation',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/investigation/'
            },
            {
                name: 'userPreferences',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/user-preferences/'
            },
            {
                name: 'incidentAttributes',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/incident-attributes/'
            },
            {
                name: 'company',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/'
            },
            {
                name: 'user',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/users/'
            },
            {
                name: 'resetUser',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/user/'
            },
            {
                name: 'search',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/search/'
            },
            {
                name: 'eventParams',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/event-params/'
            },
            {
                name: 'events',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/search/'
            },
            {
                name: 'products',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/products/'
            },
            {
                name: 'dashboardTemplate',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-templates-get-tags/'
            },
            {
                name: 'dashboards',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboards/'
            },
            {
                name: 'widgets',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/widgets/',
                custom_header: async () => {
                    const authUser = await Auth.currentSession();
                    return { Authorization: authUser.getIdToken().getJwtToken() };
                }
            },
            {
                name: 'dashboards-bulk',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-bulk-create/'
            },
            {
                name: 'dashboard-duplicate',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-duplicate/'
            },
            {
                name: 'permissionRoles',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/auth/role-permissions'
            },
            {
                name: 'roles',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/role/'
            },
            {
                name: 'userPermission',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/auth/permissions/'
            },
            {
                name: 'granularPermissions',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/permissions'
            },
            {
                name: 'devices',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/devices/'
            },
            {
                name: 'security-posture',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/' // {company_id} + 'preferences/security_posture_text'
            },
            {
                name: 'index',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/index'
            },
            {
                name: 'list-management',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/'
            },
            {
                name: 'external-account',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/user-external'
            },
            {
                name: 'appliance',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/appliance/'
            },
            {
                name: 'logstream',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/logstream'
            },
            {
                name: 'apiKey',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/'
            },
            {
                name: 'device-create',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/appliance/compose'
            },
            {
                name: 'authentication',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/auth/'
            },
            {
                name: 'device',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/devices/'
            },
            {
                name: 'macaw',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/macaw/'
            }
        ]
    },
    // https://api.us1.mss.centurylink.com/
    'ap-southeast-1': {
        endpoints: [
            {
                name: 'reports',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/reports/'
            },
            {
                name: 'validators',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/validators/'
            },
            {
                name: 'investigation',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/investigation/'
            },
            {
                name: 'userPreferences',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/user-preferences/'
            },
            {
                name: 'incidentAttributes',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/incident-attributes/'
            },
            {
                name: 'company',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/'
            },
            {
                name: 'user',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/users/'
            },
            {
                name: 'resetUser',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/user/'
            },
            {
                name: 'search',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/search/'
            },
            {
                name: 'eventParams',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/event-params/'
            },
            {
                name: 'events',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/search/'
            },
            {
                name: 'products',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/company/products/'
            },
            {
                name: 'dashboardTemplate',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-templates-get-tags/'
            },
            {
                name: 'dashboards',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboards/'
            },
            {
                name: 'widgets',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/widgets/',
                custom_header: async () => {
                    const authUser = await Auth.currentSession();
                    return { Authorization: authUser.getIdToken().getJwtToken()};
                }
            },
            {
                name: 'dashboards-bulk',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-bulk-create/'
            },
            {
                name: 'dashboard-duplicate',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-duplicate/'
            },
            {
                name: 'permissionRoles',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/auth/role-permissions'
            },
            {
                name: 'roles',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/role/'
            },
            {
                name: 'userPermission',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/auth/permissions/'
            },
            {
                name: 'granularPermissions',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/permissions'
            },
            {
                name: 'devices',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/devices/'
            },
            {
                name: 'logstream',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/logstream'
            },
            {
                name: 'security-posture',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/' // {company_id} + 'preferences/security_posture_text'
            },
            {
                name: 'index',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/index'
            },
            {
                name: 'list-management',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/'
            },
            {
                name: 'external-account',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/user-external'
            },
            {
                name: 'appliance',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/appliance/'
            },
            {
                name: 'apiKey',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/'
            },
            {
                name: 'device-create',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/appliance/compose'
            },
            {
                name: 'authentication',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/auth/'
            },
            {
                name: 'device',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/devices/'
            }
        ]
    },

    'eu-central-1': {
        endpoints: [
            {
                name: 'reports',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/reports/'
            },
            {
                name: 'validators',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/validators/'
            },
            {
                name: 'investigation',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/investigation/'
            },
            {
                name: 'userPreferences',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/user-preferences/'
            },
            {
                name: 'incidentAttributes',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/incident-attributes/'
            },
            {
                name: 'company',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/'
            },
            {
                name: 'user',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/users/'
            },
            {
                name: 'resetUser',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/user/'
            },
            {
                name: 'search',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/search/'
            },
            {
                name: 'eventParams',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/event-params/'
            },
            {
                name: 'events',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/search/'
            },
            {
                name: 'products',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/company/products/'
            },
            {
                name: 'dashboardTemplate',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-templates-get-tags/'
            },
            {
                name: 'dashboards',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboards/'
            },
            {
                name: 'widgets',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/widgets/',
                custom_header: async () => {
                    const authUser = await Auth.currentSession();
                    return { Authorization: authUser.getIdToken().getJwtToken()};
                }
            },
            {
                name: 'dashboards-bulk',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-bulk-create/'
            },
            {
                name: 'dashboard-duplicate',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/dashboard-duplicate/'
            },
            {
                name: 'permissionRoles',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/auth/role-permissions'
            },
            {
                name: 'roles',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/role/'
            },
            {
                name: 'userPermission',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/auth/permissions/'
            },
            {
                name: 'granularPermissions',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/permissions'
            },
            {
                name: 'devices',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/devices/'
            },
            {
                name: 'logstream',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/logstream'
            },
            {
                name: 'security-posture',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/company/' // {company_id} + 'preferences/security_posture_text'
            },
            {
                name: 'index',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/index'
            },
            {
                name: 'list-management',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/'
            },
            {
                name: 'external-account',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/user-external'
            },
            {
                name: 'appliance',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/appliance/'
            },
            {
                name: 'apiKey',
                endpoint: 'https://api.us1.mss.centurylink.com/v1/'
            },
            {
                name: 'device-create',
                endpoint: 'https://api.us1.mss.centurylink.com/v2/appliance/compose'
            },
            {
                name: 'authentication',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/auth/'
            },
            {
                name: 'device',
                endpoint: 'https://api.us1.mss.centurylink.com/v4/devices/'
            }
        ]
    }
};

export const environment = {
    name: 'prod',
    production: true,
    amplifyConfig: {
        'us-east-1': {
            Auth: {
                oauth: {
                    domain: 'login.us1.mss.centurylink.com',
                    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: 'https://pilot.mss.centurylink.com',
                    redirectSignOut: 'https://pilot.mss.centurylink.com/',
                    responseType: 'code',
                },
                region: 'us-east-1',
                userPoolId: 'us-east-1_AamsxihOK',
                userPoolWebClientId: '2lplsk4fmjuflghm38flt47n20'
            },
            API: API['us-east-1']
        }
    },
    regions: [
        { name: 'us-east-1', value: 'US' }
    ],
    ssoOptions: [
        {
            label: 'Lumen Control Center',
            value: 'lumen-control-center',
            icon: 'vpn_lock'
        }
    ],
    gradeColors: {
        grade1: '#B50D12 5px solid',
        grade2: '#C94218 5px solid',
        grade3: '#E6A243 5px solid',
        grade4: '#009054 5px solid',
        grade5: '#0080DC 5px solid'
    },
    landingPage: '/profile',

    // Items in here must match the route path names. This is used to block certain routes while under development if we don't want production to see it
    hiddenFromProduction: [],
    hiddenFromProductionAdminLinks: [],
    regionDomains: {
        'https://pilot.eu1.mss.centurylink.com': {
            region: 'eu-central-1'
        },
        'https://pilot.ap1.mss.centurylink.com': {
            region: 'ap-southeast-1'
        },
        'https://pilot.mss.centurylink.com': {
            region: 'us-east-1'
        }
    },
    version,
    flexmonsterLicense: 'Z7DF-XB410V-2Z4H1Q-3Q4N31'
};

export const dashboardGridsterConfig: GridsterConfig = {
    gridType: 'fit',
    fixedColWidth: 250,
    fixedRowHeight: 250,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    // setGridSize: boolean,
    compactType: 'none',
    mobileBreakpoint: 640,
    maxCols: 6,
    defaultItemCols: 2,
    defaultItemRows: 2,
    minRows: 1,
    maxItemCols: 50, // max item number of cols
    maxItemRows: 4, // max item number of rows
    minItemCols: 1, // min item number of columns
    minItemRows: 1, // min item number of rows
    minItemArea: 1,
    margin: 10,
    maxItemArea: 2500,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    scrollSensitivity: 10,
    scrollSpeed: 10,
    initCallback: undefined,
    destroyCallback: undefined,
    itemChangeCallback: undefined,
    itemResizeCallback: undefined,
    itemInitCallback: undefined,
    itemRemovedCallback: undefined,
    draggable: {
        delayStart: 0,
        enabled: false,
        ignoreContentClass: 'gridster-item-content',
        ignoreContent: false,
        dragHandleClass: 'drag-handler',
        stop: undefined,
        start: undefined
    },
    resizable: {
        delayStart: 0,
        enabled: false,
        handles: {
            s: true,
            e: true,
            n: true,
            w: true,
            se: true,
            ne: true,
            sw: true,
            nw: true
        }
    },
    swap: true,
    pushItems: true,
    disablePushOnDrag: true,
    disablePushOnResize: false,
    // disableAutoPositionOnConflict?: boolean;
    pushDirections: { north: true, east: true, south: true, west: true },
    displayGrid: 'onDrag&Resize',
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
    emptyCellClickCallback: undefined,
    emptyCellContextMenuCallback: undefined,
    emptyCellDropCallback: undefined,
    emptyCellDragCallback: undefined,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
