import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { skip } from 'rxjs/operators';

export interface SelectOption {
  label: string;
  value: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() isMultiple = false;
  @Input() options: SelectOption[] = [];
  @Input() label: string;

  public selectControl: FormControl;
  public disabled: boolean;

  constructor() {
    this.selectControl = new FormControl('');
  }

  ngOnInit(): void {
    this.selectControl.valueChanges.subscribe((data) => {
      if (data && !this.disabled) {
        this.propagateChange(data);
      }
    })
  }


  /**
   * Function registered to propagate a change to the parent
   */
   public propagateChange: any = (value) => {};

   /**
    * Function registered to propagate touched to the parent
    */
   public propagateTouched: any = () => {};

   /**
    * ControlValueAccessor Interface Methods to be implemented
    */
   writeValue(obj: any): void {
     this.selectControl.setValue(obj);
   }
   registerOnChange(fn: any): void {
     this.propagateChange = fn;
   }

   registerOnTouched(fn: any): void {
     this.propagateTouched = fn;
   }

   setDisabledState?(isDisabled: boolean): void {
     this.disabled = isDisabled;
   }
}
