import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Auth } from 'aws-amplify';
import { SnackbarService } from 'src/app/shared-services/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  loading = false;
  form: FormGroup;
  error = null;

  passwordValidationRules: any[] = [
    {
      regex: new RegExp(/[A-Z]/),
      errorMessage: 'Must contains atleast 1 capital letter',
      valid: false
    },
    {
      regex: new RegExp(/[a-z]/),
      errorMessage: 'Must contains atleast 1 lowercase letter',
      valid: false
    },
    {
      regex: new RegExp(/[0-9]/),
      errorMessage: 'Must contains atleast 1 digit',
      valid: false
    },
    {
      regex: new RegExp(/[$&+,:;=?@#|'<>.^*()%!-]/),
      errorMessage: 'Must contains atleast 1 special character',
      valid: false
    },
    {
      minLength: 8,
      errorMessage: 'Must be atleast 8 characters long',
      valid: false
    }
  ];

  constructor(
    private fb: FormBuilder,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {username: string}
  ) {
    this.form = fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });

    this.performPasswordValidation();
  }

  private performPasswordValidation(): void {
    this.form.controls.newPassword.valueChanges.subscribe(password => {
      this.passwordValidationRules = this.passwordValidationRules.map(v => {
        if (v.regex) {
          v.valid = password.match(v.regex);
        } else {
          v.valid = password.length >= v.minLength
        }
        return v;
      })
    })
  }

  submit(): void {
    if (this.form.valid) {
      this.error = null;
      const {currentPassword, newPassword, confirmPassword} = this.form.value;
      if (newPassword !== confirmPassword) {
        this.error = 'Password not matching'
        return;
      }
      this.loading = true;
      Auth.currentAuthenticatedUser()
        .then(user => {
          Auth.changePassword(user, currentPassword, newPassword)
            .then(_ => {
              this.snackbarService.open('Password Updated');
              this.dialogRef.close();
            })
            .catch(error => this.error = error.message)
            .finally(() => this.loading = false)
        })
        .catch(error => this.error = error.message)
        .finally(() => this.loading = false);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
