import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../guards/auth-gaurd.service';
import { AdminComponent } from './components/admin/admin.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { ListsComponent } from './components/lists/lists.component';
import { AppliancesComponent } from './components/appliances/appliances.component';
import { ExternalAccountsComponent } from './components/external-accounts/external-accounts.component';
import { LogstreamsComponent } from './components/logstreams/logstreams.component';
import { SecurityPoliciesComponent } from './components/security-policies/security-policies.component';
import { AccountComponent } from './components/account/account.component';
import { PermissionsGuardService } from '../../guards/permissions-guard.service';
import { ExternalAccountsDetailComponent } from './components/external-accounts-detail/external-accounts-detail.component';
import { CompanyListComponent } from '@modules/companies/components/company-list/company-list.component';
import { CompanyDetailComponent } from '@modules/companies/components/company-detail/company-detail.component';


const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: AdminComponent
      },
      {
        path: 'company',
        component: CompanyListComponent
      },
      {
        path: 'company/:id',
        component: CompanyDetailComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['company.read'],
          any: false
        }
      },
      {
        path: 'users/:id',
        component: AccountComponent,
        canActivate: [PermissionsGuardService],
        data: {
          requiredPermission: ['account.read']
        }
      },
      {
        path: 'users',
        component: AccountsComponent,
      },
      {
        path: 'lists',
        component: ListsComponent
      },
      {
        path: 'appliances',
        component: AppliancesComponent
      },
      {
        path: 'external-users',
        children: [
          {
            path: '',
            component: ExternalAccountsComponent
          },
          {
            path: ':domain/:realm/:id',
            component: ExternalAccountsDetailComponent
          }
        ]
      },
      // {
      //   path: 'external-users',
      //   component: ExternalAccountsComponent
      // },
      {
        path: 'logstreams',
        component: LogstreamsComponent
      },
      {
        path: 'secure-dns',
        component: SecurityPoliciesComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
