import { Injectable } from '@angular/core';
import {Incident, Worklog} from '../store/incident.model';
import * as incidentActions from '../store/incident.actions';
import {AppService} from '../../../app.service';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';
import {Router} from '@angular/router';
import {UserPreference} from '../../user-preference/store/user-preference.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  ActionButtonInterface,
  FieldInterface, FormBuilderComponent
} from '../../../sweet-shared/components/form-builder/form-builder.component';
import {FormControl, Validators} from '@angular/forms';
import {Company} from '../../admin/store/company.model';
import {TableHeader} from '../../../sweet-shared/components/table/table.component';
import {SnackbarService} from '../../../shared-services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {

  public paginatorConfig = {
    pageSize: 25,
    length: 0,
    pageIndex: 0,
    previousPageIndex: 0,
  };


  constructor(
      private incidentService: AppService<Incident>,
      private store: Store<State>,
      private router: Router,
      private matDialog: MatDialog,
      private snackbarService: SnackbarService,
      private worklogService: AppService<Worklog>
  ) { }

  createIncident(dialogRef: MatDialogRef<any>, data: any, userPreferences: UserPreference) {
    dialogRef.componentInstance.loading = true;
    dialogRef.componentInstance.loadingSubject.next(true);
    this.incidentService.post('investigation', '', {action: 'create-incident'}, data)
        .then((res: Incident) => {
          this.store.dispatch(incidentActions.incidentCreateSuccess({incident: res, makeActive: data.status, userPreferences}));
          dialogRef.close();
          this.router.navigate(['/incidents', res.sys_id]);
        })
        .catch((err) => {
          this.store.dispatch(incidentActions.incidentCreateError({error: err.message}));
          dialogRef.componentInstance.loading = false;
          dialogRef.componentInstance.loadingSubject.next(false);
          dialogRef.componentInstance.error = err.message;
        });
  }


  getCreateIncidentForm(companies, priorityOptions) {
    const incidentFormDetails: FieldInterface[] = [
      {
        label: 'Title',
        name: 'title',
        component: 'input-text',
        defaultValue: null,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Title'
      },
      {
        label: 'Message',
        name: 'message',
        component: 'input-textarea',
        defaultValue: null,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Message'
      },
      {
        label: 'Company',
        component: 'input-select',
        name: 'customerId',
        placeholder: 'Company',
        flex: '100%',
        selectMultiple: false,
        defaultValue: null,
        validators: [Validators.required],
        options: companies,
        showSearch: true,
        showSearchFormControl: new FormControl()

      },
      {
        label: 'Priority',
        component: 'input-select',
        name: 'priority',
        placeholder: 'Priority',
        flex: '100%',
        selectMultiple: false,
        defaultValue: null,
        validators: [Validators.required],
        options: priorityOptions
      },
      {
        label: 'Set as activate investigation',
        name: 'status',
        component: 'input-checkbox',
        defaultValue: false,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Investigate Incident'
      },
    ];
    const formButtons: ActionButtonInterface = {
      flex: 'flex-end',
      buttons: [
        {
          iconName: null,
          color: 'warn',
          label: 'Cancel',
          name: 'CANCEL',
          type: 'basic'
        },
        {
          iconName: null,
          color: 'primary',
          label: 'Create Incident',
          name: 'CREATE_INCIDENT',
          type: 'stroked'
        }
      ]
    };
    const title = 'Create Incident';
    return {
      incidentFormDetails,
      formButtons,
      title
    };
  }

  makeMdTableFromRow(row: any, headers: TableHeader[]): string {
    // To start left side of table
    const obj = {
      headers: '| ',
      spacer: '| ',
      values: '| '
    };
    // iterate through users preferred headers
    headers.forEach((column: TableHeader) => {
      // don't add 'actions' column to markdown table
      if (column.name === 'actions') {
        return false;
      }
      obj.headers += (column.name + ' | ');
      obj.spacer += '--------| ';
      obj.values += (row[column.name] || '') + ' | ';
    });
    return '\n\n' + obj.headers + '\n' + obj.spacer + '\n' + obj.values + '\n\n';
  }

  makeMdTableFromArray(events: any[], headers: TableHeader[], rowCount?: number): string {
    let markdownTable = '| ';
    let headerCount = 0;
    let evts = events.slice(0, rowCount);

    headers.forEach((column: TableHeader) => {
      // don't add 'actions' column to markdown table
      if (column.name === 'actions') {
        return;
      }
      markdownTable += (column.friendly + ' | ');
      headerCount++;
    });
    markdownTable += '\n|';
    for (var i = 0; i < headerCount; i++) {
      markdownTable += '--------|';
    }

    markdownTable += '\n|';
    evts.forEach((event) => {
      Object.keys(event).forEach(k => {
        markdownTable += event[k] + ' |';
      })
      markdownTable += '\n|';
    })

    return '\n\n' + markdownTable + '\n\n';
  }

  addMultipleWorklog(incident: Incident, events: any[], eventsHeaders: TableHeader[]){
    const dialogRef = this.matDialog.open( FormBuilderComponent, {
      width: '900px',
      disableClose: true,
      autoFocus: false,
      minHeight: '34vh',
      panelClass: 'ctl-panel-class'
    });

    const worklogFields: FieldInterface[] = [
      {
        label: 'Title',
        name: 'title',
        component: 'input-text',
        defaultValue: null,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Title'
      },
      {
        label: 'Worklog Message',
        name: 'message',
        component: 'input-textarea',
        defaultValue: null,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Message',
        rows: 10
      },
      {
        label: 'Include Search Results',
        name: 'include',
        component: 'input-checkbox',
        defaultValue: null,
        signalOnChanged: true,
        flex: '50%',
        placeholder: ''
      },
      {
        label: 'Number of Results',
        name: 'results',
        component: 'input-text',
        defaultValue: '10',
        hide: false,
        validators: [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(0), Validators.max(200)],
        type: 'text',
        flex: '50%',
        placeholder: 'Results'
      }
    ];
    const formButtons: ActionButtonInterface = {
      flex: 'flex-end',
      buttons: [
        {
          iconName: null,
          color: 'warn',
          label: 'Cancel',
          name: 'CANCEL',
          type: 'basic'
        },
        {
          iconName: null,
          color: 'primary',
          label: 'Add Worklog',
          name: 'ADD_WORKLOG',
          type: 'stroked'
        }
      ]
    };
    dialogRef.componentInstance.title = incident.number;
    dialogRef.componentInstance.fieldDetails = worklogFields;
    dialogRef.componentInstance.actionButtons = formButtons;
    dialogRef.componentInstance.actionEvents.subscribe(event => {
      //if checkbox toggle
      if(event.name === 'VALUE_CHANGED'){
        dialogRef.componentInstance.fieldDetails[3].hide = !dialogRef.componentInstance.fieldDetails[3].hide;
      }
      if (event.name === 'CANCEL') {
        dialogRef.close();
      } else if (event.name === 'ADD_WORKLOG') {
        dialogRef.componentInstance.loading = true;

        let markDown = '';
        if(!dialogRef.componentInstance.fieldDetails[3].hide){
          markDown = this.makeMdTableFromArray(events, eventsHeaders, events.length > event.data.results ? event.data.results : events.length);
        }

        const message = markDown ? event.data.message += markDown : event.data.message;
        const body = {
          message,
          title: event.data.title
        };
        this.incidentService.put('investigation', `${incident.sys_id}`, {action: 'create-log'}, {value: body})
            .then(() => {
              dialogRef.componentInstance.loading = false;
              dialogRef.close();
              this.snackbarService.open(`New worklog added to ${incident.number}`);
              // create-log returns a message that action has been QUEUED, so
              // to get actual updated worklog to update page, make a request for it
              this.refreshWorklogs(incident, 5);
            })
            .catch(() => {
              dialogRef.componentInstance.loading = false;
              this.snackbarService.open(`Unable to add worklog.  Please try again later.`);
            });
      }
    });
  }

  addWorklog(incident: Incident, markDown?: string): void {
    const dialogRef = this.matDialog.open( FormBuilderComponent, {
      width: '900px',
        disableClose: true,
        autoFocus: false,
        minHeight: '34vh',
        panelClass: 'ctl-panel-class'
      });

      const worklogFields: FieldInterface[] = [
      {
        label: 'Title',
        name: 'title',
        component: 'input-text',
        defaultValue: null,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Title'
      },
      {
        label: 'Worklog Message',
        name: 'message',
        component: 'input-textarea',
        defaultValue: null,
        validators: [Validators.required],
        type: 'text',
        flex: '100%',
        placeholder: 'Message',
        rows: 10
      }
    ];
    const formButtons: ActionButtonInterface = {
      flex: 'flex-end',
      buttons: [
        {
          iconName: null,
          color: 'warn',
          label: 'Cancel',
          name: 'CANCEL',
          type: 'basic'
        },
        {
          iconName: null,
          color: 'primary',
          label: 'Add Worklog',
          name: 'ADD_WORKLOG',
          type: 'stroked'
        }
      ]
    };
    dialogRef.componentInstance.title = incident.number;
    dialogRef.componentInstance.fieldDetails = worklogFields;
    dialogRef.componentInstance.actionButtons = formButtons;
    dialogRef.componentInstance.actionEvents.subscribe(event => {
      if (event.name === 'CANCEL') {
        dialogRef.close();
      } else if (event.name === 'ADD_WORKLOG') {
        dialogRef.componentInstance.loading = true;
        const message = markDown ? event.data.message += markDown : event.data.message;
        const body = {
          message,
          title: event.data.title
        };
        this.incidentService.put('investigation', `${incident.sys_id}`, {action: 'create-log'}, {value: body})
            .then(() => {
              dialogRef.componentInstance.loading = false;
              dialogRef.close();
              this.snackbarService.open(`New worklog added to ${incident.number}`);
              // create-log returns a message that action has been QUEUED, so
              // to get actual updated worklog to update page, make a request for it
              this.refreshWorklogs(incident, 5);
            })
            .catch(() => {
              dialogRef.componentInstance.loading = false;
              this.snackbarService.open(`Unable to add worklog.  Please try again later.`);
            });
      }
    });
  }

    refreshWorklogs(incident: Incident, count) {
      // when this function is dispatched there is no guarentee that it is done being created, so there is a counter
      count--;
      this.store.dispatch(incidentActions.loadingWorklogs({loading: true}));
      setTimeout(() => {
        this.worklogService.get('investigation', `${incident.sys_id}/worklog`, null)
            .then((worklogs: Worklog[]) => {
              if (worklogs.length !== incident.worklogs.length) {
                this.store.dispatch(incidentActions.loadingWorklogs({loading: true}));
                return this.store.dispatch(incidentActions.loadWorklogSuccess({incidentId: incident.sys_id, worklogs}));
              } else if (count > 0) {
                this.refreshWorklogs(incident, count);
              }
            })
            .catch((error) => {
              this.store.dispatch(incidentActions.loadingWorklogs({loading: true}));
              return this.store.dispatch(incidentActions.loadWorklogError({error: error.message}));
            });
      }, 1000);
    }
  }
