<mat-toolbar style="background: white; border-bottom: 18px solid #0075c9">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <!--menu icon which show only when screen is <599-->
      <button mat-icon-button fxHide.gt-xs  (click)="toggleNav()" *ngIf="isAuthenticated">
        <mat-icon class="material-icons-round">menu</mat-icon>
      </button>
      <!--    Switches between white and black logo depending on whether dark mode is active or not-->
      <!--    <img *ngIf="isDark" src="https://assets.ctl.io/images/centurylink-logo-black.svg" alt="CenturyLink Logo">-->
      <!--    span is needed so flex layout can work properly in toolbar-->
      <img src="../../../../assets/logos/lumen_logo_x1.png" alt="CenturyLink Logo" fxHide.lt-sm>
    </div>
      <pre *ngIf="isAuthenticated" fxHide.lt-sm>{{companiesMap && companiesMap[user.profile] || ''}}</pre>
    <img src="../../../../assets/logos/lumen_logo_x1.png" alt="Lumen Logo" fxHide.gt-xs>
    <!--    Shows all toolbar actions. Only visible is user logged in-->
    <div fxLayout="row" *ngIf="isAuthenticated">
      <button mat-icon-button class="header_version">{{ versionNumber }}</button>
      <!-- -->
      <!--    Button for What's New?-->
      <button mat-icon-button [matMenuTriggerFor]="whatsNew" aria-label="Profile Menu" style="color: #404142" fxHide.xs>
        <mat-icon class="material-icons-round">new_releases</mat-icon>
      </button>
      <mat-menu #whatsNew="matMenu">
        <button mat-menu-item (click)="openDialogReleaseNotes()">
          <mat-icon class="material-icons-round">auto_stories</mat-icon>
          <span>Release Notes</span>
        </button>
      </mat-menu>
      <!-- -->
      <!-- -->

      <!--      Button to toggle between dark / light mode-->
<!--      <button mat-icon-button (click)="toggleTheme()" style="color: white;" fxHide.xs>-->
<!--        <mat-icon class="material-icons-round" *ngIf="!isDark" style="color: #F5CC93">nights_stay</mat-icon>-->
<!--        <mat-icon class="material-icons-round" *ngIf="isDark" style="color:#F5CC93">wb_sunny</mat-icon>-->
<!--      </button>-->
      <!--      To Do section-->
      <button mat-icon-button style="color: #404142" [matMenuTriggerFor]="toDo" aria-label="ToDo Menu">
        <mat-icon class="material-icons-round" matBadge="1" matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="activeInvestigationId === '-'">check_circle</mat-icon>
      </button>
      <mat-menu #toDo="matMenu">
        <button *ngIf="activeInvestigationId !== '-'" mat-menu-item [routerLink]="['incidents/', activeInvestigationId]">Active Investigation</button>
        <button mat-menu-item (click)="goToAssignedIncidents()">
          See Incidents Assigned to You
        </button>
      </mat-menu>
      <!--      Menu for support and knowledge base-->
      <button mat-icon-button [matMenuTriggerFor]="help" aria-label="Profile Menu" style="color: #404142" fxHide.xs>
        <mat-icon class="material-icons-round">language</mat-icon>
      </button>
      <mat-menu #help="matMenu">
        <a routerLink="/knowledge-base">
          <button mat-menu-item>
            <mat-icon class="material-icons-round">school</mat-icon>
            <span>Knowledge Base</span>
          </button>
        </a>
        <button mat-menu-item (click)="openDialog()">
          <mat-icon class="material-icons-round">contact_support</mat-icon>
          <span>Support</span>
        </button>
      </mat-menu>

      <!-- User Icon (Gravatar if available Button-->
      <button mat-icon-button [matMenuTriggerFor]="profile" aria-label="Profile Menu" style="color: #404142">
        <ngx-avatar
          [name]="user.given_name + ' ' + user.family_name"
          [gravatarId]="user.email"
          [size]="35"
        ></ngx-avatar>
      </button>
      <mat-menu #profile="matMenu">
        <a routerLink="/profile">
          <button mat-menu-item>
            <mat-icon class="material-icons-round">person</mat-icon>
            <span>{{user.username | uppercase}}</span>
          </button>
        </a>
        <a routerLink='/admin'>
          <button mat-menu-item>
            <mat-icon class="material-icons-round">supervisor_account</mat-icon>
            <span>Admin</span>
          </button>
        </a>
        <a routerLink='/knowledge-base'>
          <button mat-menu-item  fxHide.gt-xs>
            <mat-icon class="material-icons-round">school</mat-icon>
            <span>Knowledge Base</span>
          </button>
        </a>
        <button mat-menu-item (click)="openDialog()" fxHide.gt-xs>
          <mat-icon class="material-icons-round">contact_support</mat-icon>
          <span>Support</span>
        </button>
        <a routerLink='user-preferences'>
          <button mat-menu-item>
            <mat-icon class="material-icons-round">settings</mat-icon>
            <span>User Preferences</span>
          </button>
        </a>
<!--        <button mat-menu-item (click)="toggleTheme()" fxHide.gt-xs>-->
<!--          <mat-icon class="material-icons-round" *ngIf="!isDark" style="color: #F5CC93">nights_stay</mat-icon>-->
<!--          <mat-icon class="material-icons-round" *ngIf="isDark" style="color:#F5CC93">wb_sunny</mat-icon>-->
<!--          <span *ngIf="!isDark">Go Dark!</span>-->
<!--          <span *ngIf="isDark">Lighten Up!</span>-->
<!--        </button>-->
        <button mat-menu-item (click)="logout(false)">
          <mat-icon class="material-icons-round">meeting_room</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>

</mat-toolbar>
