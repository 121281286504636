import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { filter, map, skip, switchMap } from 'rxjs/operators';
import { DatetimeService } from 'src/app/services/datetime.service';

export const RELATIVE_DATE = 'relative';
export const CUSTOM_DATE = 'custom';
export const MAYA_DATE = 'maya';
export interface DateTimeOption {
  name: string;
  value: {
    type: 'relative' | 'maya';
    from?: string;
    to?: string;
  }
}

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent implements OnInit, AfterViewInit {
  private _options = [];
  @Input() showValidator = true;
  @Input() defaultValue = '';
  @Input() emitType: 'iso' | 'maya' = 'iso';
  @Input() config = {};
  @Input() label = 'Select Date'
  @Input() appearance = 'outline';

  prevVal = {
    type: '',
    from: '',
    to: ''
  };


  @Input()
  set options(opts: DateTimeOption[]) {
    this._options = opts;
  }

  get options() {
    return this._options;
  }

  @Output() onDateSelected = new EventEmitter<{ valid: boolean, data: any, from?: string; to?: string, iso?: string }>();

  customDate = CUSTOM_DATE;
  relativeDate = RELATIVE_DATE;

  private specialDateValues = [this.customDate, this.relativeDate];

  // control for the datetime selected
  dateTimeSelectedCtrl: FormControl;
  customDateRangeForm: FormGroup;
  relativeDateRangeForm: FormGroup;
  selectedDateTimeValue: string;
  mayaStart: string = null;
  mayaEnd: string = null;
  relativeFormTouched = false;
  shouldValidate = false;
  onSelection = new Subject();

  constructor(
    public datetimeService: DatetimeService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.dateTimeSelectedCtrl = new FormControl(this.defaultValue ?? null);
    this.customDateRangeForm = this.fb.group({
      type: CUSTOM_DATE,
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required])
    });

    this.relativeDateRangeForm = this.fb.group({
      type: MAYA_DATE,
      start: new FormControl('', [Validators.required, Validators.minLength(2)]),
      end: new FormControl('', [Validators.required, Validators.minLength(2)])
    });

    // this.customDateRangeForm.updateValueAndValidity();
    // set the triggers
    this.setTriggers();

    // handle all date selections and apply maya validations
    this.onSelection.pipe(
      switchMap((pkg: any) => {
        return forkJoin([
          this.datetimeService.validateMayTime(pkg.from ?? '').then(data => data.date).catch(error => null),
          this.datetimeService.validateMayTime(pkg.to ?? '').then(data => data.date).catch(error => null)
        ])
      })
    ).subscribe((response: any) => {
      const [start, end] = response;
      // const from = moment(start ?? '').toDate().toISOString();
      // const to = moment(end ?? '').toDate().toISOString();
      const { from, to } = this.toMomentString(start, end);

      const pkg = { valid: false, data: { type: MAYA_DATE, start: from, end: to }, from, to };
      this.mayaEnd = to;
      this.mayaStart = from;
      if (start && end) {
        this.onDateSelected.emit({ ...pkg, valid: true })
      } else {
        this.onDateSelected.emit({ ...pkg, valid: false });
      }

    })
  }

  ngAfterViewInit(): void {
    this.dateTimeSelectedCtrl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  private setTriggers(): void {
    // handle custom date value changes
    this.customDateRangeForm.valueChanges.pipe(
      skip(1),
      filter(_ => this.customDateRangeForm.valid)
    ).subscribe(pkg => {
      const { from, to } = this.toMomentString(pkg?.start, pkg?.end);
      this.onDateSelected.emit({ data: { type: MAYA_DATE, start: from, end: to }, from, to, valid: this.customDateRangeForm.valid })
    })

    this.relativeDateRangeForm.valueChanges.pipe(
      filter(_ => !!this.relativeDateRangeForm.valid),
      map(data => ({ type: MAYA_DATE, from: data.start, to: data.end }))
    ).subscribe(pkg => {
      this.onSelection.next(pkg)
    })

    // react to date time selections
    this.dateTimeSelectedCtrl.valueChanges
      .pipe(
        skip(1),
        filter(o => o.type === MAYA_DATE)
      )
      .subscribe(option => {
        this.relativeDateRangeForm.reset();
        this.customDateRangeForm.reset();
        this.onSelection.next(option)
      });

    this.dateTimeSelectedCtrl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  toMomentString(from: string, to: string): { from: string, to: string } {
    if (!(from || to)) {
      return { from: '', to: '' }
    }
    return {
      from: moment(from ?? '').toDate().toISOString(),
      to: moment(to ?? '').toDate().toISOString(),
    }
  }
}
