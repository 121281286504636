import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FieldInterface, FormBuilderComponent} from '../../../form-builder/form-builder.component';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-bar-create-form',
  templateUrl: './bar-create-form.component.html',
  styleUrls: ['./bar-create-form.component.scss']
})
export class BarCreateFormComponent implements OnInit {
  @Input() query: string;
  widgetFields: FieldInterface[] = [
    {
      name: 'widgetSource',
      type: 'text',
      component: 'input-select',
      placeholder: 'Widget Source',
      label: 'Widget Source',
      flex: '100%',
      defaultValue: 'log',
      validators: [Validators.required],
      options: [
        {value: 'log', friendly: 'log'}
      ],
      signalOnChanged: true,
      hide: false,
    },
    {
      name: 'fieldType',
      type: 'text',
      component: 'input-select',
      placeholder: 'Fields',
      label: 'Fields',
      flex: '150%',
      defaultValue: null,
      showSearch: true,
      validators: [Validators.required],
      options: [],
      signalOnChanged: true,
    },
    {
      name: 'yAxisAction',
      type: 'text',
      component: 'input-select',
      placeholder: 'yAxis',
      label: 'yAxis',
      flex: '150%',
      defaultValue: null,
      validators: [Validators.required],
      options: [],
      signalOnChanged: true,
      disabled: true
    },
    {
      name: 'query',
      type: 'text',
      component: 'input-text',
      placeholder: 'Query',
      label: 'Query',
      flex: '150%',
      defaultValue: '',
      signalOnChanged: true,
    }
  ];
  yAxisField: any = '';
  @Input() categoryField: any;
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  @Output() barFormEvents: EventEmitter<any> = new EventEmitter();
  @Input() actions: any;
  @ViewChild('createBarForm') createBarForm: FormBuilderComponent


  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    // initialise the option of fieldtype with categories
    this.widgetFields.map(f => {
      if (f.name === 'fieldType') {
        f.options = this.categoryField;
      }
      return f;
    });
  }

  // this function is used to obtain the yaxis action based on a type
  private getyAxisAction(typ, list) {
    return list.reduce((acc, cur) => {
      if (cur.type.indexOf(typ) > -1) {
        acc.push({value: cur.value, friendly: cur.friendly});
      }
      return acc;
    }, []);
  }

  // function takes in an event from the user input which is fieldType category and return a yAxisField
  selectAxis(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'fieldType' && event.data.value) {
      this.yAxisField = this.getyAxisAction(event.data.value.type, this.actions);
      this.widgetFields.map(f => {
        if (f.name === 'yAxisAction') {
          f.options = this.yAxisField;
        }
      });

    }

  }

  setDefaultQuery(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'query' && this.query && event.data.value !== this.query) {
      this.createBarForm.form.controls.query.setValue(this.query);
    }
  }

  enableYaxisField(event) {
    if (event.name === 'VALUE_CHANGED' && event.data.field === 'fieldType') {
      const yAixsField = this.createBarForm.form.get('yAxisAction');
      if (!event.data.value) {
        setTimeout(() => yAixsField.disable(), 200);
      } else {
        setTimeout(() => yAixsField.enable(), 200);
      }
    }
  }

  actionHandler(event) {
    this.setDefaultQuery(event);
    this.selectAxis(event);
    this.enableYaxisField(event);
    this.actionEvents.emit(event);
  }

}
