<section class="page-container">
  <section class="filter-container mat-elevation-z1">
    <app-event-filter
      (action)="actionHandler($event)"
      [searching]="searching"
      [activateColumnManager]="columnManager"
    ></app-event-filter>
  </section>

  <section class="event-table mat-elevation-z1">
    <!-- table controler container -->
    <div class="table-controls-container">
      <button mat-icon-button (click)="showColumnManagement()" color="primary">
        <mat-icon>view_column</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="exporter.exportTable('csv', { fileName: 'event-data-table' })"
        [disabled]="dataSource.data.length === 0"
      >
        <mat-icon>file_download</mat-icon>
      </button>
    </div>

    <!-- table wrapper -->
    <div class="table-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortDisableClear
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[0]"
      >
        <ng-container matColumnDef="view" sticky>
          <th mat-header-cell *matHeaderCellDef>View</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="eventDetails(element)">
              <mat-icon>visibility</mat-icon>
            </button>

            <button
              [disabled]="!canSendToMacaw(element) || inMacawFly(element)"
              mat-icon-button
              (click)="sendToMacaw(element)"
              *ngIf="element.jcef_evttype === 'Clue'"
            >
              <mat-icon>send</mat-icon>
            </button>
            <button mat-icon-button (click)="createIncident(element)">
              <mat-icon>bug_report</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="column.value.toLowerCase()"
          *ngFor="let column of selectedColumns"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ column.friendly }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (contextmenu)="
              onContextMenu(
                $event,
                element[column.value.toLowerCase()],
                column.friendly
              )
            "
          >
            {{ element[column.value.toLowerCase()] }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        *ngIf="dataSource.data.length"
      ></mat-paginator>

      <section class="no-records" *ngIf="dataSource.data.length === 0">
        No Records Found
      </section>
      <div
        style="visibility: hidden; position: fixed"
        [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y"
        [matMenuTriggerFor]="contextMenu"
      ></div>
    </div>
  </section>
</section>
<mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onIncludeValue(item)">Include</button>
    <button mat-menu-item (click)="onExcludeValue(item)">Exclude</button>
  </ng-template>
</mat-menu>
