
<div fxLayout="row">
    <div fxLayoutAlign="end" fxLayout="row" fxLayout="10px">
        <div fxLayout="row">
            <mat-icon class="color-red" inline=true>fiber_manual_record</mat-icon>
            <span>Source</span>
        </div>
        <div fxLayout="row">
            <mat-icon class="color-blue" inline=true>fiber_manual_record</mat-icon>
            <span>Destination</span>
        </div>
    </div>
    <div #container class="graph-container"></div>
</div>