import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { Auth } from 'aws-amplify';
import { forkJoin, from, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { Attribute, CompanyDetails, Product } from '@modules/companies/interfaces/companies';
import { CompaniesService } from '@modules/companies/services/companies.service';
import { COMPANY_ACCESS_ATTRIBUTES } from '@modules/companies/constants/companies-constants';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();
  readonly accessAttributes: string[] = [...COMPANY_ACCESS_ATTRIBUTES];
  numSteps = 3;
  loading = false;
  error: string = null;
  @ViewChild(MatHorizontalStepper) stepper;
  selectedIndex: number = 0;

  sourceIPV4CIDRs: string[] = [];
  sourceIPs: string[] = [];
  sourceHosts: string[] = [];

  isLinear = true;
  checkedMap = {};
  products = [];
  productForm: FormGroup;
  configForm: FormGroup;
  generalForm: FormGroup;
  attributes: { [key: string]: Attribute } = {};
  dataSource: MatTreeNestedDataSource<any> = new MatTreeNestedDataSource();
  treeControl: NestedTreeControl<any> = new NestedTreeControl(node => node.children);

  constructor(
    private companiesService: CompaniesService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // should be typed.
  ) {
  }

  ngOnInit(): void {
    // IPV4 config form
    this.configForm = this.fb.group({
      sourceIPV4CIDRs: ['', [Validators.pattern('^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})\/([0-9]|1[0-9]|2[0-9]|3[0-2])$')]],
      sourceIPs: ['', [Validators.pattern('^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})$')]],
      sourceHosts: ['', [Validators.pattern('^[a-zA-Z0-9\.\-]+$')]]
    });

    // general information form
    this.generalForm = this.fb.group({
      name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      parent: ['', [Validators.required]],
      accessAttributes: [[], []],
    });

    forkJoin({
      products: this.companiesService.fetchProducts(),
      attributes: this.companiesService.fetchCompanyAttributes(),
      user: from(Auth.currentAuthenticatedUser())
    })
      .pipe(takeUntil(this.destroyer$))
      .subscribe({
        next: (resp) => {
          const { products, attributes, user } = resp;
          this.products = products;
          this.attributes = attributes;

          const productFields = products.reduce((acc: any, next: Product) => {
            acc[next.name] = [''];
            this.checkedMap[next.name] = this.data?.companyDetails?.products?.includes(next.name) ?? false;
            if (next.attributes && next.attributes.length) {
              const initValue = this.data?.companyDetails?.attributes[next.name] ?? '';
              acc[`${next.name}_input`] = [{ value: initValue, disabled: !this.checkedMap[next.name] }, []];
            }
            return acc;
          }, {});
          this.productForm = this.fb.group({
            ...productFields
          });

          this.dataSource.data = this.buildProductTree(products);
          this.patchForms(this.data.companyDetails, user);
        },
        error: (error) => {
          this.error = error.message;
        }
      });
  }

  patchForms(details: CompanyDetails, user): void {
    if (details) {
      this.sourceHosts = details.sourceHosts;
      this.sourceIPV4CIDRs = details.sourceIPV4CIDRs;
      this.sourceIPs = details.sourceIPs;

      this.generalForm.patchValue({
        name: details.name,
        id: details.id,
        parent: details.parent === 'centurylink' ? 'Lumen' : details.parent, // TODO - Backend should be sending both 
        accessAttributes: details.attributes.accessAttributes
      });
      this.generalForm.controls.id.disable();
      this.generalForm.controls.parent.disable();
    } else {
      // Cannot change the parent company
      // TODO - Make it changeable when user is from Lumen
      this.generalForm.patchValue({ parent: user.attributes.profile });
      this.generalForm.controls.parent.disable();
    }
  }

  hasChild = (_: number, node: any) => {
    return !!node.children && node.children.length > 0;
  }

  buildProductTree(products: Product[]) {
    const roots = [];
    const productTree = products.map((product: Product) => {
      return {
        ...product,
        children: [],
        level: 0
      };
    });
    let counter = 0;
    const nameAndIndices = productTree.reduce((acc, next) => {
      acc[next.name] = counter;
      counter = counter + 1;
      return acc;
    }, {});

    for (let i = 0; i < productTree.length; i++) {
      let node = productTree[i];
      if (node.parent && node.parent[0]) {
        node.level = this.findLevel(productTree, nameAndIndices, node, node.level);
        productTree[nameAndIndices[node.parent[0]]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  findLevel(list, obj, node, count): number {
    count = count || 0;
    if (!node.parent.length) {
      return count;
    } else {
      count += 1;
      const parentNode = list[obj[node.parent[0]]];
      return this.findLevel(list, obj, parentNode, count);
    }
  }

  onCheck(node: Partial<Product & { children: Product[] }>, checked: boolean): void {
    // If a child product is checked, set to true in map
    if (checked) {
      this.checkedMap[node.name] = true;
      // enable attribute input if checkbox checked
      if (this.productForm.controls[`${node.name}_input`]) {
        this.productForm.controls[`${node.name}_input`].enable();
      }
      // exit recursion
      if (!node.parent || !node.parent.length) {
        return;
      }
      const parentNode = this.products.find(product => product.name === node.parent[0]);
      // repeat for parent
      return this.onCheck(parentNode, true);
    } else {
      // If a parent is unchecked, set to false in map
      this.checkedMap[node.name] = false;
      // disable attribute input if checkbox unchecked
      if (this.productForm.controls[`${node.name}_input`]) {
        this.productForm.controls[`${node.name}_input`].disable();
      }
      // exit recursion
      if (!node.children || !node.children.length) {
        return;
      }
      // repeat for children
      node.children.forEach(child => {
        return this.onCheck(child, false);
      });
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  addCidr(): void {
    const control = this.configForm.controls.sourceIPV4CIDRs;
    this.sourceIPV4CIDRs.push(control.value);
    control.setValue('');
  }

  cidrDisabled(): boolean {
    return this.configForm.controls.sourceIPV4CIDRs.invalid || this.configForm.controls.sourceIPV4CIDRs.value.trim().length === 0;
  }

  removeCidr(ip: string): void {
    this.sourceIPV4CIDRs = this.sourceIPV4CIDRs.filter(sourceIP => ip !== sourceIP);
  }

  addIp(): void {
    const control = this.configForm.controls.sourceIPs;
    this.sourceIPs.push(control.value);
    control.setValue('');
  }

  removeIp(sourceIP: string): void {
    this.sourceIPs = this.sourceIPs.filter(ip => ip !== sourceIP);
  }

  disabledIPv4(): boolean {
    return this.configForm.controls.sourceIPs.invalid || this.configForm.controls.sourceIPs.value.trim().length === 0;
  }

  addHostname(): void {
    const control = this.configForm.controls.sourceHosts;
    this.sourceHosts.push(control.value);
    control.setValue('');
  }

  removeHostname(hostname: string): void {
    this.sourceHosts = this.sourceIPs.filter(item => item !== hostname);
  }

  disabledSourceHosts(): boolean {
    return this.configForm.controls.sourceHosts.invalid || this.configForm.controls.sourceHosts.value.trim().length === 0;
  }

  submit(): void {
    this.loading = true;
    const generalFormData = this.generalForm.getRawValue();
    const productFormData = this.productForm.getRawValue();

    const products = Object.keys(this.checkedMap).filter(product => this.checkedMap[product]);
    const accessAttributes = generalFormData.accessAttributes;
    const attributes = { ACCESS_ATTRIBUTES: accessAttributes || [] };

    // iterate thru all products in checkedMap
    for (const field in this.checkedMap) {
      // if its checked, and it has an associated input field for an attribute
      if (this.checkedMap[field] && productFormData[`${field}_input`] && !!productFormData[`${field}_input`]) {
        // find the product that matches the field
        const product = this.products.find(prod => prod.name === field);
        // get the attribute name, which will be a key
        const name = product.attributes[0].S;
        // set key of attributes object to value of attribute input field associated with the product
        attributes[name] = productFormData[`${field}_input`];
      }
    }

    // TODO - Type this payload properly
    let payload: any = {
      attributes,
      sourceIPV4CIDRs: this.sourceIPV4CIDRs,
      sourceIPs: this.sourceIPs,
      sourceHosts: this.sourceHosts,
      products
    };

    if (!this.data.companyDetails) {
      // new company
      payload = {
        ...payload,
        name: generalFormData.name,
        id: generalFormData.id,
        parent: generalFormData.parent
      };

      // perform the create here.
      this.companiesService.create(payload).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
        next: (data: CompanyDetails) => {
          this.dialogRef.close(data);
        },
        error: () => {
          this.error = 'Could not create company';
        }
      })
    } else {
      payload = {
        ...this.data.companyDetails,
        ...payload
      };
      // perform the update here.
      this.companiesService.updateCompany(payload.id, payload).pipe(takeUntil(this.destroyer$), finalize(() => this.loading = false)).subscribe({
        next: (data: CompanyDetails) => {
          this.dialogRef.close(data);
        },
        error: () => {
          this.error = 'Could not update company';
        }
      });
    }
  }

  next(): void {
    this.stepper.next();
  }

  selectionChange(evt: StepperSelectionEvent): void {
    this.selectedIndex = evt.selectedIndex;
  }

  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
