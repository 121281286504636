<app-portal-loader [loadingStatus]="loading"></app-portal-loader>
<div
  fxLayout="row"
  fxLayout.lt-md="column"
  class="layout-padding"
  fxLayoutAlign="space-between start"
  fxLayoutAlign.lt-md="start stretch"
>
  <button
    mat-stroked-button
    type="button"
    routerLink="/admin/users"
    class="account_list--back"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back to Accounts
  </button>
  <mat-card fxFlex.gt-sm="70" fxFlex.lt-md="100" *ngIf="userInfo && !loading">
    <mat-card-header fxLayout="row">
      <div mat-card-avatar>
        <app-user-display [profile]="userInfo.Attributes"></app-user-display>
      </div>
      <div>
        <mat-card-title class="profile_user-name"
          >{{ userInfo.Attributes.given_name }}
          {{ userInfo.Attributes.family_name }}</mat-card-title
        >
        <mat-card-subtitle>{{ userInfo.Attributes.email }}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Company</h3>
        <mat-list-item>
          <p>{{ userInfo.Attributes.profile | titlecase }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Status</h3>
        <mat-list-item>
          <p>{{ userInfo.UserStatus }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Role</h3>
        <mat-list-item>
          <mat-chip-list disabled>
            <mat-chip *ngFor="let role of userRoles">{{ role }}</mat-chip>
          </mat-chip-list>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Login ID</h3>
        <mat-list-item>
          <p>{{ userInfo.Username }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>MFA Enabled</h3>
        <mat-list-item>
          <p>{{ userInfo.Enabled }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Created</h3>
        <mat-list-item>
          <p>{{ userInfo.UserCreateDate | userDate }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Last Modified</h3>
        <mat-list-item>
          <p>{{ userInfo.UserLastModifiedDate | userDate }}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <section
        class="permission-container"
        *ifUserCan="'account.edit-permissions'"
      >
        <h2>Granular Permissions</h2>
        <mat-card-subtitle
          >Below you'll be able to modify user permissions on a granular
          level</mat-card-subtitle
        >
        <app-permission-list
          [user]="user"
          [editable]="false"
        ></app-permission-list>
      </section>
    </mat-card-content>
    <mat-card-actions
      *ifUserCan="['account.edit', 'account.delete']; any: true"
      fxLayout="row wrap"
      fxLayoutAlign="space-around"
    >
      <ng-container *ifUserCan="'account.edit'">
        <button mat-stroked-button (click)="onEditUser(userInfo)">Edit</button>
        <button mat-stroked-button *ngIf="false">Global Logout</button>
        <div
          [matTooltip]="
            !canResetUserPassword()
              ? 'Cannot Reset Password. Email and Phone Number not verified'
              : null
          "
        >
          <button
            [disabled]="!canResetUserPassword()"
            mat-stroked-button
            (click)="onResetPassword(userInfo)"
          >
            Reset Password
          </button>
        </div>
        <button
          *ngIf="userInfo.UserStatus === userStatusEnum.ForceChangePassword"
          mat-stroked-button
          (click)="resendTempCode(userInfo)"
        >
          Re-send Temp Password
        </button>
      </ng-container>
      <ng-container *ifUserCan="'account.delete'">
        <button mat-stroked-button (click)="onDeleteUser()">Delete</button>
      </ng-container>
      <ng-container *ifUserCan="'account.edit'">
        <button
          *ngIf="userInfo.Enabled"
          mat-stroked-button
          (click)="accountActions('disable', userInfo)"
        >
          Disable
        </button>
        <button
          *ngIf="!userInfo.Enabled"
          mat-stroked-button
          (click)="accountActions('enable', userInfo)"
        >
          Enable
        </button>
      </ng-container>
    </mat-card-actions>
  </mat-card>
  <div></div>
</div>
