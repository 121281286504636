<section>
  <app-sweet-loader
    type="SPINNER"
    [message]="loaderMessage"
    fullScreen="true"
    *ngIf="submittingReport"
  ></app-sweet-loader>
  <div class="layout-padding">
    <!--    Uncomment when updated with correct statuses -->
    <!--    <mat-card>-->
    <!--      <div fxLayout="row" fxLayoutAlign="space-between center">-->
    <!--        <mat-card-title>Search</mat-card-title>-->
    <!--      </div>-->
    <!--      <mat-card-content>-->
    <!--        <app-form-builder [fieldDetails]="searchFieldDetails" [loading]="false" #reportsSearch></app-form-builder>-->
    <!--      </mat-card-content>-->
    <!--    </mat-card>-->
    <br />
    <mat-card>
      <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
        <span>Reports</span>
      </mat-card-title>
      <mat-card-content>
        <app-table
          [headerStream]="reportsHeadersStream"
          [data]="reportsDataStream"
          [pageSize]="reportingSvc.paginatorConfig?.pageSize"
          [paginatorConfig]="reportingSvc.paginatorConfig"
          [showFilter]="true"
          [loading]="loadingReports"
          [actionsTemplate]="reportsActionsTemplate"
          (events)="onRowClick($event)"
          #reportsTable
          [headerPaginator]="true"
          (paginationChange)="handlePaginationChange($event)"
        >
          <mat-form-field fxFlex>
            <input
              matInput
              [value]="this.dashboardId"
              (keyup)="reportsTable.applyFilter($event.target.value)"
              placeholder="Filter through results. Ex: Phishing Traffic Detected"
            />
          </mat-form-field>
          <ng-template #reportsActionsTemplate let-report>
            <div>
              <button
                *ifUserCan="reportActions.readDashboard"
                mat-menu-item
                [routerLink]="'/dashboard/' + report.related_dashboard_id"
              >
                <mat-icon>dashboard</mat-icon>View Origin Dashboard
              </button>
              <button mat-menu-item (click)="runReport(report)">
                <mat-icon>run_circle</mat-icon>Run Report Now
              </button>
              <button
                *ifUserCan="reportActions.editReport"
                mat-menu-item
                (click)="onEdit(report)"
              >
                <mat-icon>edit</mat-icon>Edit Report
              </button>
              <ng-container *ifUserCan="reportActions.deleteReport">
                <!-- <button *ngIf="!report.is_active" mat-menu-item (click)="onDelete(report)"> -->
                <button mat-menu-item (click)="onDelete(report)">
                  <mat-icon>delete</mat-icon>Delete Report
                </button>
              </ng-container>
            </div>
          </ng-template>
        </app-table>
      </mat-card-content>
    </mat-card>
  </div>
</section>
