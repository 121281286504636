import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {TableHeader} from '../../../sweet-shared/components/table/table.component';
import {SnackbarService} from '../../../shared-services/snackbar.service';
import {AuthService} from '../../auth/services/auth.service';
import {filter, take, takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActionButtonInterface, FieldInterface, FormBuilderComponent} from '../../../sweet-shared/components/form-builder/form-builder.component';
import {ListActions, ListColumnInterface, ListInterface} from '../list.model';
import {FormControl, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {PermissionService} from '../../../sweet-shared/services/permission.service';
import {UserPreference} from '../../user-preference/store/user-preference.model';
import * as userPreferenceActions from '../../user-preference/store/user-preference.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';
import * as companyActions from '../../admin/store/company.actions';
import {CompaniesService} from '../../../shared-services/companies.service';
import {EventRequest} from '../../events/store/event.model';


@Component({
  selector: 'app-list-management',
  templateUrl: './list-management.component.html',
  styleUrls: ['./list-management.component.scss']
})
export class ListManagementComponent implements OnInit, OnDestroy {

  // contains some of the granular permissions for dashboards
  ListManagementTableActions: ListActions = {
    deleteItemListPermission: 'list-mgmt.delete-item',
    editItemListPermission: 'list-mgmt.edit-item',
    createItemListPermission: 'list-mgmt.add-item',
    viewListPermission: 'list-mgmt.view-lists',
    viewItemListPermission: 'list-mgmt.view-items',
    clearListPermission: 'list-mgmt.clear-list'

  };

  constructor(
    private appService: AppService<any>,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private matDialog: MatDialog,
    private store: Store<State>,
    private companiesService: CompaniesService


  ) { }


  listManagementList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  listHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
  detailListHeader: BehaviorSubject<any[]> = new BehaviorSubject([]);
  finalDetailDataList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  onRowClickEventData: BehaviorSubject<any> = new BehaviorSubject(null);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  loadingDetailList = false;
  loading = false;
  expand: boolean;
  params: any;
  selectedList: ListInterface = null;
  searchCompany = new FormControl('');

  // Form Details
  searchFieldDetails: FieldInterface[] = null;
  userPreferences: UserPreference;
  companies: any[] = [];

  searchActionButtons: ActionButtonInterface;
  selectedCompany: any;

  private static headerBuilder(): TableHeader[] {
    return [
      {
        friendly: 'Name',
        name: 'name',
        description: ''
      },
      {
        friendly: 'Description',
        name: 'description',
        description: ''
      }
    ];
  }

  ngOnInit(): void {
    this.loading = true;
    this.listHeader.next(ListManagementComponent.headerBuilder());
    this.expand = true;
    this.authService.getAuthenticatedUser().pipe(take(1)).subscribe(user => {
      this.params = {
        companyFilter: user.profile,
      };
      this.appService.get('list-management', `list/${this.params.companyFilter}`, null)
        .then((val) => {
          this.listManagementList.next(val);
          this.loading = false;
        })
        .catch(error => {
          this.snackbarService.open(error.message);
        });
    });
    this.getUserPreference();
  }

  onSelectChange(event) {
    this.selectedCompany = event.value;
  }

  searchFilterActionHandler() {
    this.appService.get('list-management', `list/${this.selectedCompany.value}`, null)
      .then((val) => {
        if (val) {
          this.listManagementList.next(val);
        } else {
          this.listManagementList.next([]);
        }
      })
      .catch(error => {
        this.snackbarService.open(error.message);
      });
  }

  private getUserPreference() {
    this.store.dispatch(companyActions.loadCompanies());
    this.store.dispatch(userPreferenceActions.loadUserPreferences());
    // Get the user preferences
    this.store.select(state => state.userPreferences.userPreferences)
      .pipe(
        filter(val => Object.keys(val).length > 0),
        takeUntil(this.destroyed$)
      ).subscribe((userPreferences: UserPreference) => {
        this.userPreferences = userPreferences;
    });
    this.store.select(state => state.companies.companies).pipe(
      filter(c => !!c),
      takeUntil(this.destroyed$)
    ).subscribe(companies => {
      this.companies = this.companiesService.companyNameAndFriendly(companies);
    });
  }



  // single row click event
  onRowClick(event) {
    this.loadingDetailList = true;
    // Clears the header and column value while loading the next list
    this.finalDetailDataList.next([]);
    this.detailListHeader.next([]);
    // Assigns current selected list
    this.selectedList = event.data;
    this.expand = false;

    this.appService.get('list-management', `list/${this.params.companyFilter}/${event.data.id}`, null)
      .then((value => {
        // adding value fr
        this.onRowClickEventData.next(value);
        this.setDetailTableData(value);
        this.loadingDetailList = false;
      }))
      .catch(error => {
        this.snackbarService.open(error.message);
        this.loadingDetailList = false;
      });
  }

  detailListTableHeader(value): any {
    let header: any;
    header = value.columns.push({
      name: 'Actions',
      id: 'actions'
    });
    return header = value.columns.map((e: any) => {
      return {friendly: e.name, description: '', name: e.id};
    });
  }

  detailRowListEvent(eventType, value, headers?){
    if (eventType === 'EDIT_LIST_DATA') {
      this.openAddListItemDialog(value, headers, eventType);
    } else if (eventType === 'DELETE_LIST_DATA') {
      this.loadingDetailList = true;
      const dataToDelete = Object.values(value);
      this.onRowClickEventData.pipe(takeUntil(this.destroyed$)).subscribe(rowEvent => {
      rowEvent.data = rowEvent.data.filter(obj => obj.join('|') !== dataToDelete.join('|'));
      this.appService.post('list-management', `list/${this.params.companyFilter}/${rowEvent.id}`, null, rowEvent.data)
        .then((val => {
          this.loadingDetailList = false;
          const list = this.formatTableData(headers, rowEvent.data);
          this.finalDetailDataList.next(list);
        }))
        .catch(error => {
          this.snackbarService.open(error.message);
          this.loadingDetailList = false;
        });
      });
    }
  }

  clearAllListItems(listId: ListInterface) {
    this.loadingDetailList = true;
    this.appService.delete('list-management', `list/${this.params.companyFilter}/${listId.id}`, null, null).then((res: ListInterface) => {
      this.selectedList.data = [];
      this.finalDetailDataList.next(res.data);
      this.snackbarService.open('List Cleared!');
      this.loadingDetailList = false;
    }).catch(_ => {
      this.snackbarService.open('Something went wrong. Please contact your administrator.');
      this.loadingDetailList = false;
    });
  }

  refreshAllListItems(list: ListInterface): void {
    this.loadingDetailList = true;
    this.appService.get('list-management', `list/${this.params.companyFilter}/${list.id}`, null).then(listRes => {
      this.setDetailTableData(listRes);
      this.snackbarService.open('List successfully refreshed');
    }).catch(listErr => {
      this.snackbarService.open('Unable to refresh list.  Please try again later.');
    }).finally(() => {
      this.loadingDetailList = false;
    });
  }

  openAddListItemDialog(data: any, headers: TableHeader[], eventType?): void {
    const cloneEntrireListDetailTable = _.cloneDeep(this.selectedList.data)
    const dialogRef: MatDialogRef<FormBuilderComponent> = this.matDialog.open(FormBuilderComponent, {
      minWidth: '300px',
      minHeight: '100px',
      disableClose: true,
    });

    const fields: FieldInterface[] = this.selectedList.columns.map(column => {

      return {
        label: column.name,
        name: column.name,
        component: 'input-text',
        defaultValue: data ? data[column['id']] : null,
        validators: [Validators.required, Validators.pattern(this.setPattern(column.type))],
        type: 'text',
        flex: '100%',
        placeholder: column.name
      };
    });

    const formButtons: ActionButtonInterface = {
      flex: 'flex-end',
      buttons: [
        {
          iconName: null,
          color: 'warn',
          label: 'Cancel',
          name: 'CANCEL',
          type: 'basic'
        },
        {
          iconName: null,
          color: 'primary',
          label: 'Submit',
          name: eventType,
          type: 'stroked'
        }
      ]
    };

    dialogRef.componentInstance.title = eventType === 'ADD_LIST_ITEM' ? `Add New List Item` : `Edit List Item`;
    dialogRef.componentInstance.fieldDetails = fields;
    dialogRef.componentInstance.actionButtons = formButtons;
    dialogRef.componentInstance.actionEvents.subscribe(event => {
      if (event.name === 'CANCEL') {
        this.selectedList.data = cloneEntrireListDetailTable;
        dialogRef.close();
      } else if (event.name === 'ADD_LIST_ITEM') {
        dialogRef.componentInstance.loading = true;
        dialogRef.componentInstance.loadingSubject.next(true);
        // const data = this.selectedList.data || [];
        const selectedDataList = this.selectedList.data || [];

        const newItemValues = Object.values(dialogRef.componentInstance.form.value);
        // data.push(newItemValues);

        selectedDataList.push(newItemValues);

        this.appService.post('list-management', `list/${this.params.companyFilter}/${this.selectedList.id}`, null, selectedDataList)
          .then(res => {
            this.snackbarService.open('New item successfully added to list');
            dialogRef.close();
            const headerSelectList = this.selectedList.columns.map((e: any) => {
              return {friendly: e.name, description: '', name: e.id};
            });
            const newList = this.formatTableData(headerSelectList, res.data);
            this.finalDetailDataList.next(newList);
          })
          .catch(err => {
            this.snackbarService.open('Unable to add item to list.  Please try again later.');
          })
          .finally(() => {
            dialogRef.componentInstance.loading = false;
            dialogRef.componentInstance.loadingSubject.next(false);
          });
      }
     else if (event.name === 'EDIT_LIST_DATA') {
        this.loadingDetailList = true;
        dialogRef.componentInstance.loadingSubject.next(true);
        const editedData = Object.values(event.data);
        const temp = this.selectedList.data.map(val => {
          if (val.join(',') === Object.values(data).join(',') ) {
            return editedData;
          } else {
            return val;
          }
        });

      this.appService.post('list-management', `list/${this.params.companyFilter}/${this.selectedList.id}`, null, temp)
        .then((val => {
          this.loadingDetailList = false;
          dialogRef.close();
          const list = this.formatTableData(headers, val.data);
          this.finalDetailDataList.next(list);

        }))
        .catch(error => {
          this.snackbarService.open(error.message);
          this.loadingDetailList = false;
          });

      }
    });
  }

  // Sets the Regex patter for validating list items based on the column 'type'
  setPattern(value: string): RegExp {
    if (value === 'integer') {
      return /^[0-9]*$/;
    } else if (value === 'decimal') {
      return /^\d+(?:[.]\d+)?$/;
    } else if (value === 'ipv4') {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    } else if (value === 'ipv4cidr') {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2][0-9]|[0-9]))$/;
    } else if (value === 'ipv6') {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
    } else if (value === 'ipv6cidr') {
      return /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/(12[0-8]|1[0-1][0-9]|[1-9][0-9]|[0-9]))$/;
    } else {
      return /[\s\S]*/;
    }
  }

  setDetailTableData(detailListData: ListInterface): void {
    if (this.permissionService.hasPermission((Object.values(this.ListManagementTableActions) as any).flat(), true)) {
      (detailListData.columns as Pick<ListColumnInterface, 'name' | 'id'>[]).push({
        name: 'Actions',
        id: 'actions'
      });
    }
    this.selectedList.data = detailListData.data;
    const headers = detailListData.columns.map((e: any) => {
      return {friendly: e.name, description: '', name: e.id};
    });
    this.detailListHeader.next(headers);
    const data = this.formatTableData(headers, detailListData.data);
    this.finalDetailDataList.next(data);
  }

  formatTableData(headers: TableHeader[], data: string[][]): { [key: string]: any }[] {
    return data.map((row: any) => {
      return row.reduce((prev, next, index) => {
        prev[headers[index].name] = next;
        return prev;
      }, {});
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


}
