import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './components/admin/admin.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { ExternalAccountsComponent } from './components/external-accounts/external-accounts.component';
import { AppliancesComponent } from './components/appliances/appliances.component';
import { LogstreamsComponent } from './components/logstreams/logstreams.component';
import { ListsComponent } from './components/lists/lists.component';
import { SecurityPoliciesComponent } from './components/security-policies/security-policies.component';
import { StoreModule } from '@ngrx/store';
import * as fromCompany from './store/company.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CompanyEffects } from './store/company.effects';
import * as fromUser from './store/user/user.reducer';
import { UserEffects } from './store/user/user.effects';
import { SweetSharedModule } from '../../sweet-shared/sweet-shared.module';
import { AccountComponent } from './components/account/account.component';
import { ProfileModule } from '../profile/profile.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { AccountEditComponent } from './components/account-edit/account-edit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { AddUserComponent } from './components/add-user/add-user.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddExternalUserComponent } from "./components/add-external-user/add-external-user.component";
import { ExternalAccountsDetailComponent } from './components/external-accounts-detail/external-accounts-detail.component';
import { FormlyModule } from '@ngx-formly/core';
import { ExternalAccountEditComponent } from './components/external-account-edit/external-account-edit.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PermissionModule } from '@app/modules/permission/permission.module';
import { UserModule } from '@modules/user/user.module';
import { CompaniesRoutingModule } from '@modules/companies/companies-routing.module';


@NgModule({
  declarations: [
    AdminComponent,
    AccountsComponent,
    ExternalAccountsComponent,
    AppliancesComponent,
    LogstreamsComponent,
    ListsComponent,
    SecurityPoliciesComponent,
    AccountComponent,
    AccountEditComponent,
    AddUserComponent,
    AddExternalUserComponent,
    ExternalAccountsDetailComponent,
    ExternalAccountEditComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    AdminRoutingModule,
    CompaniesRoutingModule,
    StoreModule.forFeature(fromCompany.companiesFeatureKey, fromCompany.reducer),
    EffectsModule.forFeature([CompanyEffects, UserEffects]),
    StoreModule.forFeature(fromUser.usersFeatureKey, fromUser.reducer),
    MatTableModule,
    SweetSharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    ProfileModule,
    MatChipsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatCheckboxModule,
    MatStepperModule,
    FormlyModule,
    MatTooltipModule,
    PermissionModule
  ]
})
export class AdminModule { }
