import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {filter, take, takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent implements OnInit, OnDestroy {

  forceChangePassword: FormGroup;
  destroyer$: ReplaySubject<any> = new ReplaySubject<any>();
  noLowerCase = true;
  noUpperCase = true;
  noSpecialChars = true;
  noNumbers = true;
  lessThan14Chars = true;
  passwordsDoNotMatch = true;
  isAuthenticating = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.forceChangePassword = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{14,}')]],
      confirmNewPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{14,}')]]
    });

    this.authService.isAuthenticating().pipe(takeUntil(this.destroyer$)).subscribe(isAuthing => this.isAuthenticating = isAuthing);
  }

  updatePassword() {
    this.authService.getConfirmUserCognitoObject()
      .pipe(filter(user => !!user), takeUntil(this.destroyer$))
      .subscribe(cognitoUser => {
        this.authService.completeNewPassword(cognitoUser, this.forceChangePassword.value.newPassword, null);
    });
  }

  validator() {
    const newPassword = this.forceChangePassword.value.newPassword.trim();
    const passwordConfirm = this.forceChangePassword.value.confirmNewPassword.trim();
    this.noUpperCase = !/[A-Z]/.test(newPassword);
    this.noLowerCase = !/[a-z]/.test(newPassword);
    this.noSpecialChars = !/[@$!%*?&]/.test(newPassword);
    this.noNumbers = !/[0-9]/.test(newPassword);
    this.lessThan14Chars = newPassword.length < 14;
    this.passwordsDoNotMatch = !(newPassword === passwordConfirm);
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

}
