import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventsRoutingModule } from './events-routing.module';
import { EventFilterComponent } from './components/event-filter/event-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ColumnManagerComponent } from './components/column-manager/column-manager.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ColumnItemComponent } from './components/column-item/column-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { EventPageComponent } from './components/event-page/event-page.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CreateIncidentComponent } from './components/create-incident/create-incident.component';


@NgModule({
  declarations: [
    EventFilterComponent,
    ColumnManagerComponent,
    ColumnItemComponent,
    EventPageComponent,
    CreateIncidentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatSortModule,
    MatMenuModule,
    MatInputModule,
    DragDropModule,
    MatRadioModule,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatTableExporterModule,
    EventsRoutingModule,
  ],
  exports: [
    EventFilterComponent
  ]
})
export class EventModule { }
