import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {BehaviorSubject} from 'rxjs';
import { saveAs } from 'file-saver';

import {AppService} from '../../../app.service';
import {TableHeader} from '../../../sweet-shared/components/table/table.component';
import { SnackbarService } from 'src/app/shared-services/snackbar.service';


@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  private listDeviceData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private devicePolicyData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private deviceConfigData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private headerList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private deviceData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private deviceLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private applianceRegistrationCodeData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private error: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private appService: AppService<any>,
    private router: Router,
    private snackbarService: SnackbarService

    ) { }

  getListDevice() {
    return this.listDeviceData;
  }
  deviceError() {
    return this.error;
  }

  getDeviceLoading() {
    return this.deviceLoading;
  }

  getDevicePolicy() {
    return this.devicePolicyData;
  }

  devicePolicyError() {
    return this.error;
  }

  getDeviceConfiguration() {
    return this.deviceConfigData;
  }

  deviceConfigurationError() {
    return this.error;
  }

  getHeaderList() {
    return this.headerList;
  }

  getDevice() {
    return this.deviceData;
  }

  getApplianceCode() {
    return this.applianceRegistrationCodeData;
  }

  device(customerId, deviceId) {
    this.deviceLoading.next(true);
    this.appService.get('devices', `${customerId}/${deviceId}`, null)
      .then((device) => {
        this.deviceData.next(device);
      })
      .catch((error) => {
        this.error.next(error.message);
      })
      .finally(() => {
        this.deviceLoading.next(false);
      });
  }

 deviceList(companyId: string) {
    this.appService.get('devices', companyId, null)
      .then((devices) => {this.listDeviceData.next(devices);
      })
      .catch((error) => {this.error.next(error.message); });
  }

// Get the device policy for a given device
  devicePolicy(customerId, deviceId) {
    this.appService.get('devices', `${customerId}/${deviceId}/policy`, null)
      .then((devices) => {this.devicePolicyData.next(devices); })
      .catch((error) => {this.error.next(error.message); });
  }

  deviceConfiguration(device) {
    this.appService.get('devices', `${device}/configuration`, null)
      .then((devices) => {this.deviceConfigData.next(devices); })
      .catch((error) => {this.error.next(error.message); });
  }

  headerBuilder(): TableHeader[] {
    const deviceHeaders = [
      {
        friendly: 'Name',
        name: 'name',
        description: ''
      },
      {
        friendly: 'Service',
        name: 'service',
        description: ''
      },
      {
        friendly: 'Customer Id',
        name: 'customerId',
        description: ''
      },
      {
        friendly: 'Device Customer Id',
        name: 'deviceCustomerId',
        description: ''
      },
      {
        friendly: 'Device Id',
        name: 'deviceId',
        description: ''
      },
      {
        friendly: 'Device Type',
        name: 'deviceType',
        description: ''
      }
    ];
    this.headerList.next(deviceHeaders);
    return;
  }

  createDevice(deviceCreateData) {
    this.deviceLoading.next(true);
    this.appService.post('device-create', '', null, deviceCreateData)
      .then((res) => {
        this.deviceLoading.next(false);
      })
      .catch((error) => {
        this.error.next(error.message);
        this.deviceLoading.next(false);

        });
    }

  downloadDevice(device): void {
    this.deviceLoading.next(true);
    this.appService.get('devices', `${device.customerId}/${device.id}/configuration`, null)
    .then((deviceConfig) => {
      // checking the device type
      if (device.deviceType === 'CTL Docker LCA'){
        const deviceConfigFile = new Blob([deviceConfig.configuration], {type: 'text/yml'});
        saveAs(deviceConfigFile, 'docker-compose-' + device.name + '.yml');
      }
      else{
        const deviceConfigFile = new Blob([deviceConfig.configuration], {type: 'text/xml'});
        saveAs(deviceConfigFile, 'EXPORT-' + device.name + '.xml');
      }
    }).catch((error: any) => {
      this.deviceLoading.next(false);
      this.snackbarService.open(`Sorry we couldn't download device configuration due to : '${error}`, 'Ok');
   })
   .finally(() => {
    this.deviceConfigData.next(null);
    this.deviceLoading.next(false);
  });
  }

  deleteDevice(device): void {
    this.deviceLoading.next(true);
    this.appService.delete('device-delete', `${device.customerId}/${device.id}`, null, null)
    .then((_) => {
      this.router.navigate(['/devices']).then(() => {});
    }).catch((error: any) => {
      this.deviceLoading.next(false);
      this.snackbarService.open(`Device not deleted due to error: '${error}`, 'Ok');
   })
   .finally(() => {
    this.deviceLoading.next(false);
  });
  }

  updateDevice(device, deviceCustomerId, deviceId): void {
    console.log('In the device service Edit');

    this.deviceLoading.next(true);
    this.appService.put('device', `${deviceCustomerId}/${deviceId}`, null, device)
      .then((_) => {
        this.router.navigate(['/devices']).then(() => {});
      }).catch((error: any) => {
      this.deviceLoading.next(false);
      this.snackbarService.open(`Device not Updated due to error: '${error}`, 'Ok');
    }).finally(() => {
      this.deviceLoading.next(false);
    });
  }
}

