import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Permissions, RolePermission } from '@modules/permission/interfaces/permission';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import uniq from 'lodash/uniq';

import { User } from '@app/pages/admin/store/user/user.model';
import { ExternalUser } from '@modules/user/interfaces/external-user';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PermissionService } from '@modules/permission/services/permission.service';


@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  @Input() editable = false;
  @Input() user: User | ExternalUser = null;
  @Input() userRoles: BehaviorSubject<string[]> = new BehaviorSubject([]);
  @Output() permissionChanged: EventEmitter<{ allowed_permissions: string[], blocked_permissions: string[] }> = new EventEmitter();

  systemPermission$: Observable<Permissions>;
  loadingSystemPermissions$: Observable<boolean>;
  systemPermissionNames$: Observable<string[]>;
  systemRoleBasePermissions: RolePermission[] = [];

  userPermissions: string[] = [];
  userRoleBasePermissions: string[] = [];
  allowedPermissions: string[] = [];
  blockedPermissions: string[] = [];
  ready = false;

  constructor(
    private permissionService: PermissionService
  ) {
    this.systemPermission$ = this.permissionService.systemPermissions$;
    this.loadingSystemPermissions$ = this.permissionService.loadingPermissions$;
    this.systemPermissionNames$ = this.permissionService.systemPermissionNames$;
    this.permissionService.roleBasePermissions$.pipe(takeUntil(this.destroyer$)).subscribe(r => {
      this.systemRoleBasePermissions = r;
      this.ready = true;
    });
  }

  ngOnInit(): void {
    this.loadingSystemPermissions$.pipe(takeUntil(this.destroyer$)).subscribe(
      loading => {
        if (!loading) {
          setTimeout(() => this.getUserPermissions(this.user));
        }
      }
    )
    this.userRoles.pipe(takeUntil(this.destroyer$)).subscribe(roles => this.onRoleChanged(roles))
  }

  private onRoleChanged(roles: string[]): void {
    console.log('role change to ', roles);
    this.userRoleBasePermissions = uniq(roles.reduce((acc: string[], next: string) => {
      const role = this.systemRoleBasePermissions.find(role => role.name === next);
      if (role) {
        acc = acc.concat(role.permissions);
      }
      return acc;
    }, []));
    this.updateUserPermissions();
  }

  getValue(name: string, action: string): string {
    return `${name.toLowerCase()}.${action.toLowerCase()}`;
  }

  hasPermission(name: string, action: string): boolean {
    return this.userPermissions.includes(this.getValue(name, action));
  }

  getUserPermissions(userDetails: User | ExternalUser): void {
    let userRoleBasePermissions;
    if (userDetails) {
      if ('Attributes' in userDetails) {
        userRoleBasePermissions = this.permissionService.getUserRoleBasePermissions(userDetails.Attributes.gender);
        this.userRoleBasePermissions = [...userRoleBasePermissions];
        this.allowedPermissions = userDetails.Attributes.allowed_permissions;
        this.blockedPermissions = userDetails.Attributes.blocked_permissions;
      } else {
        userRoleBasePermissions = this.permissionService.getUserRoleBasePermissions(userDetails.gender);
        this.userRoleBasePermissions = [...userRoleBasePermissions];
        this.allowedPermissions = userDetails.allowed_permissions;
        this.blockedPermissions = userDetails.blocked_permissions;
      }
    }
    this.updateUserPermissions();
  }

  countPermissions(action: string): number {
    return this.userPermissions.filter(permission => permission.toLowerCase().startsWith(`${action.toLowerCase()}.`)).length;
  }

  updateUserPermissions(): void {
    let permissions = [...this.userRoleBasePermissions].concat(this.allowedPermissions);
    permissions = uniq(permissions.filter(permission => !this.blockedPermissions.includes(permission)));
    this.userPermissions = [...permissions];

    // emit new value
    this.permissionChanged.emit({ blocked_permissions: this.blockedPermissions, allowed_permissions: this.allowedPermissions });
  }

  onPermissionChanged(evt: MatSlideToggleChange, name: string, action: string): void {
    const permissionName = `${name.toLowerCase()}.${action.toLowerCase()}`;
    if (evt.checked) {
      // adding the permission to the user
      if (this.userRoleBasePermissions.includes(permissionName)) {
        // the permission is a from a role the user already has, we just need to remove it from the blocked permissions
        this.blockedPermissions = this.blockedPermissions.filter(permission => permission !== permissionName);
      } else {
        // this is not a permission from any of the role of the user, we need to add it to the allowedPermissions
        this.allowedPermissions = [...this.allowedPermissions, permissionName];
        this.blockedPermissions = this.blockedPermissions.filter(permission => permission !== permissionName);
      }
    } else {
      // add it to the blockPermissions
      this.blockedPermissions = [...this.blockedPermissions, permissionName];

    }
    this.updateUserPermissions();
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
