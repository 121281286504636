import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Incident, IncidentAttribute} from './incident.model';
import * as IncidentActions from './incident.actions';

export const incidentsFeatureKey = 'incidents';


export interface State extends EntityState<Incident> {
  // additional entities state properties
  loading: boolean;
  incidents: Incident[];
  fetchingError: string;
  total: number;
  selectedIncident: Incident;
  loadingSelectedIncident: boolean;
  loadingIncidentError: string;

  // update
  updating: boolean;
  updateError: string;

  // Worlogs
  worklogsLoading: boolean;
  worklogsError: string;
  incidentAssigning: boolean;
  incidentAssigningError: string;

  // Incident Attributes
  incidentAttributesLoading: boolean;
  incidentAttributesError: string;
  incidentAttributes: IncidentAttribute;

  // create
  creating: boolean;
  errorCreating: string;

}

export const adapter: EntityAdapter<Incident> = createEntityAdapter<Incident>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  incidents: [],
  fetchingError: null,
  total: 0,
  selectedIncident: null,
  loadingSelectedIncident: false,
  loadingIncidentError: null,

  worklogsLoading: false,
  worklogsError: null,
  incidentAssigning: false,
  incidentAssigningError: null,

  // Incident Attributes
  incidentAttributesLoading: false,
  incidentAttributesError: null,
  incidentAttributes: {},

  // update
  updating: false,
  updateError: null,

  // creating
  creating: false,
  errorCreating: null
});

const incidentReducer = createReducer(
  initialState,
  on(IncidentActions.searchIncidents,
    (state, action) => {
      return {
        ...state,
        loading: true,
        fetchingError: null
      };
    }
  ),
  on(IncidentActions.clearIncidents,
    state => {
      return {
        ...state,
        loading: false,
        fetchingError: null,
        incidents: [],
        total: 0
      };
    }
  ),
  on(IncidentActions.loadIncidentsError,
    (state, action) => {
      return {
        ...state,
        loading: false,
        fetchingError: action.error,
        incidents: [],
        total: 0
      };
    }
  ),
  on(IncidentActions.loadIncidentsSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        fetchingError: null,
        incidents: action.incidents,
        total: action.total
      };
    }
  ),
  on(IncidentActions.getIncident,
    (state, action) => {
      return {
        ...state,
        loadingSelectedIncident: true,
        loadingIncidentError: null,
        selectedIncident: null
      };
    }
  ),
  on(IncidentActions.getIncidentSuccess,
    (state, action) => {
      return {
        ...state,
        loadingSelectedIncident: false,
        loadingIncidentError: null,
        selectedIncident: action.incident
      };
    }
  ),
  on(IncidentActions.getIncidentError,
    (state, action) => {
      return {
        ...state,
        loadingSelectedIncident: false,
        loadingIncidentError: action.error,
        selectedIncident: null
      };
    }
  ),
  on(IncidentActions.clearSelectedIncident,
    (state, action) => {
      return {
        ...state,
        loadingSelectedIncident: false,
        loadingIncidentError: null,
        selectedIncident: null
      };
    }
  ),
  on(IncidentActions.loadWorklog,
    (state, action) => {
      return {
        ...state,
        worklogsLoading: true,
        worklogsError: null
      };
    }
  ),
  on(IncidentActions.loadWorklogSuccess,
    (state, action) => {
      const incidentIndex = state.incidents.findIndex(inc => inc.sys_id === action.incidentId);
      if (incidentIndex >= 0) {
        const incident = {...state.incidents[incidentIndex], worklogs: action.worklogs};
        return {
          ...state,
          worklogsLoading: false,
          worklogsError: null,
          incidents: [...state.incidents.filter(inc => inc.sys_id !== action.incidentId), incident],
          selectedIncident: incident
        };
      } else {
        return {
          ...state,
          worklogsLoading: false,
          selectedIncident: {...state.selectedIncident, worklogs: action.worklogs}
        };
      }
    }
  ),
  on(IncidentActions.loadWorklogError,
    (state, action) => {
      return {
        ...state,
        worklogsLoading: false,
        worklogsError: action.error
      };
    }
  ),
  on(IncidentActions.loadingWorklogs,
    (state, action) => {
      return {
        ...state,
        worklogsLoading: action.loading
      };
  }),
  on(IncidentActions.worklogAssign,
    (state, action) => {
      return {
        ...state,
        incidentAssigning: true,
        incidentAssigningError: null
      };
    }
  ),
  on(IncidentActions.worklogAssignSuccess,
    (state, action) => {
      return {
        ...state,
        incidentAssigning: false,
        incidentAssigningError: null,
        selectedIncident: action.incident,
        incidents: [...state.incidents.filter(inc => inc.sys_id !== action.incident.sys_id), action.incident]
      };
    }
  ),
  on(IncidentActions.worklogAssignError,
    (state, action) => {
      return {
        ...state,
        incidentAssigning: false,
        incidentAssigningError: action.error
      };
    }
  ),
  on(IncidentActions.loadIncidentAttributes,
    (state, action) => {
      return {
        ...state,
        incidentAttributesLoading: true,
        incidentAttributesError: null,
      };
    }
  ),
  on(IncidentActions.incidentAttributesSuccess,
    (state, action) => {
      return {
        ...state,
        incidentAttributesLoading: false,
        incidentAttributes: action.attributes
      };
    }
  ),
  on(IncidentActions.incidentAttributesError,
    (state, action) => {
      return {
        ...state,
        incidentAttributesLoading: false,
        incidentAttributesError: action.error
      };
    }
  ),
  on(IncidentActions.updateIncident,
    (state, action) => {
      return {
        ...state,
        updating: true,
        updateError: null
      };
    }
  ),
  on(IncidentActions.updateIncidentSuccess,
    (state, action) => {
      return {
        ...state,
        updating: false,
        selectedIncident: action.incident,
        incidents: [action.incident, ...state.incidents.filter(inc => inc.sys_id !== action.incident.sys_id)]
      };
    }
  ),
  on(IncidentActions.updateIncidentError,
    (state, action) => {
      return {
        ...state,
        updating: false,
        updateError: action.error
      };
    }
  ),
  on(IncidentActions.incidentCreate,
    (state, action) => {
      return {
        ...state,
        creating: false,
        errorCreating: null
      };
    }
  ),
  on(IncidentActions.incidentCreateSuccess,
    (state, action) => {
      return {
        ...state,
        creating: false,
        selectedIncident: action.incident,
        incidents: [action.incident, ...state.incidents]
      };
    }
  ),
  on(IncidentActions.incidentCreateError,
    (state, action) => {
      return {
        ...state,
        creating: false,
        errorCreating: action.error
      };
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return incidentReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
