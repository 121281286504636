import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as cronParser from 'cron-parser';
import { AppService } from 'src/app/app.service';

export interface ReportScheduleData {
  is_active: boolean;
  frequency: {
    type: 'PRESET' | 'CUSTOM',
    value: string;
  };
}

@Component({
  selector: 'app-report-scheduler-information',
  templateUrl: './report-scheduler-information.component.html',
  styleUrls: ['./report-scheduler-information.component.scss']
})
export class ReportSchedulerInformationComponent implements OnInit {
  private cronRegExp = new RegExp(/^([1-5]?\d) (\*(\/([1-9]|1\d|2[0-3]))?|(([1]?\d|[2][0-3])(\,([1]?\d|[2][0-3]))*)) (\*(\/([1-9]|[1-2]\d|3[0-1]))?|(([1-9]|[1-2]\d|[3][0-1])(\,([1-9]|[1-2]\d|[3][0-1]))*)) (\*(\/([1-9]|[1][0-2]))?|(([1-9]|[1][0-2])(\,([1-9]|[1][0-2]))*)) (\*(\/[0-6])?|([0-6])(\,[0-6])*)$/);

  // Scheduler form data
  @Input() data: any = null;

  // emitter for the validation of the form
  @Output() formValid: EventEmitter<any> = new EventEmitter();

  // Scheduler step form/
  form: FormGroup;

  // matselect control
  @ViewChild(MatSelect) frequencySelect: MatSelect;

  // Controller to show/hide the scheduler details when the report is activate or not.
  // by default, while creating a report, it will be set to active.
  showCustomField = false;
  maxCronExpressionRuns = 5;
  nextRunSchedules = [];
  previousChoice = null;
  badCronExpression = false;

  presetCronValues = [
    {
      id: 1,
      type: 'PRESET',
      friendly: 'Every three hours',
      cron: '0 */3 * * *'
    },
    {
      id: 2,
      type: 'PRESET',
      friendly: 'Every four hours',
      cron: '0 */4 * * *'
    },
    {
      id: 3,
      type: 'PRESET',
      friendly: 'Every six hours',
      cron: '0 */6 * * *'
    },
    {
      id: 4,
      type: 'PRESET',
      friendly: 'Every twelve hours',
      cron: '0 */12 * * *'
    },
    {
      id: 5,
      type: 'PRESET',
      friendly: 'Every day @ 12am',
      cron: '0 0 * * *'
    },
    {
      id: 6,
      type: 'PRESET',
      friendly: 'Every day @ 6am',
      cron: '0 6 * * *'
    },
    {
      id: 7,
      type: 'PRESET',
      friendly: 'Every day @ 12pm',
      cron: '0 12 * * *'
    },
    {
      id: 8,
      type: 'PRESET',
      friendly: 'Every day @ 6pm',
      cron: '0 18 * * *'
    },
    {
      id: 9,
      type: 'PRESET',
      friendly: 'Every Sunday @ 12pm',
      cron: '0 12 * * 0'
    },
    {
      id: 10,
      type: 'PRESET',
      friendly: 'Every Monday @ 12pm',
      cron: '0 12 * * 1'
    },
    {
      id: 11,
      type: 'PRESET',
      friendly: 'Every Tuesday @ 12pm',
      cron: '0 12 * * 2'
    },
    {
      id: 12,
      type: 'PRESET',
      friendly: 'Every Wednesday @ 12pm',
      cron: '0 12 * * 3'
    },
    {
      id: 13,
      type: 'PRESET',
      friendly: 'Every Thursday @ 12pm',
      cron: '0 12 * * 4'
    },
    {
      id: 14,
      type: 'PRESET',
      friendly: 'Every Friday @ 12am',
      cron: '0 12 * * 5'
    },
    {
      id: 15,
      friendly: 'Every Saturday @ 12am',
      cron: '0 12 * * 6'
    },
    {
      id: 28,
      type: 'CUSTOM',
      friendly: 'Custom',
      cron: 'custom'
    }
  ];

  validButton = false;

  constructor(private fb: FormBuilder, private appService: AppService<any>) {
  }

  ngOnInit(): void {
    // set the form needed for this step
    this.form = this.fb.group({
      is_active: [this.data?.is_active ?? true, []],
      frequency: [this.data?.params?.scheduling?.frequency ?? null, [Validators.required]],
      schedule_rate: this.fb.group({
        run_forever: [this.data?.params?.scheduling?.run_forever ?? false, []],
        date_from: [new Date(), []],
        date_to: [new Date(), []]
      })
    });

    // activate the triggers
    this.addFormTriggers();
  }

  /**
   * Add the form trigger.
   * This step is collecting the scheduling information of the report to be created.
   * It will work as follow
   *    1. If report is active, the frequency will be required (both type and value)
   *        a. If the use pick CUSTOM (which is the default, then it will be presented with a text field to enter the CRON Expression)
   *        b. If the user pick from the list of PRESET value, we will set the type to PRESET and the corresponding value to that of the preset rules
   *    2. If the report is inactive, all the fields of this form become not required and will be hidden from the user
   */
  private addFormTriggers(): void {
    // trigger for is_valid
    this.addIsActiveTriggers();

    // trigger on run forever
    this.addRunForeverTriggers();

    // add frequency trigger
    this.addFrequencyTriggers();


    // triggers out changes
    this.form.valueChanges.subscribe(value => {
      // based on what has changed let update the validity
      if (this.form.valid && !this.badCronExpression) {
        this.formValid.emit({ valid: this.form.valid, data: { scheduling: value } });
      } else {
        this.formValid.emit({ valid: false, data: { scheduling: value } });
      }
    });
  }

  private addFrequencyTriggers(): void {
    this.form.get('frequency').valueChanges.subscribe(frequency => {
      if (this.previousChoice === frequency) return;
      this.previousChoice = frequency;
      // check if it is a valid options
      const option = this.presetCronValues.find(option => option.cron === frequency);
      if (!option) {
        this.frequencySelect.value = 'custom';
      }
      // check if the frequency is preset or custom
      this.validateCronExpression(frequency);
    });
  }

  private addIsActiveTriggers(): void {
    this.form.controls['is_active'].valueChanges.subscribe(isActive => {
      if (!isActive) {
        // remove all the validator
        this.form.get('frequency').clearValidators();
      } else {
        // the user must provide a frequency
        this.form.get('frequency').setValidators([Validators.required]);
      }
      this.form.get('frequency').updateValueAndValidity();
    });
  }

  private addRunForeverTriggers(): void {
    const scheduleRate = this.form.get('schedule_rate') as FormGroup;
    scheduleRate.get('run_forever').valueChanges.subscribe(runForever => {
      if (runForever) {
        // if report is to be run forever, then date from and date to are no longer required
        scheduleRate.get('date_from').clearValidators();
        scheduleRate.get('date_to').clearValidators();
      } else {
        scheduleRate.get('date_from').setValidators([Validators.required]);
        scheduleRate.get('date_to').setValidators([Validators.required]);
      }
      scheduleRate.updateValueAndValidity();
    });
  }

  validateCronExpression(expression: string): void {
    // reset the list of next runs, getting it ready to hold the next runs
    this.badCronExpression = false;
    this.nextRunSchedules = [];
    if (this.cronRegExp.test(expression)) {
      // here we can validate. We stopped at 5 because currently the backend does not support the sixth argument
      try {
        var interval = cronParser.parseExpression(expression, { utc: true });
        this.nextRunSchedules = [...Array(this.maxCronExpressionRuns)].map((_, i) => {
          const nextRun = interval.next().toString();
          return nextRun;
        });

        // if we reach here it means that the expression is a proper expression, we can set it as the value
        this.form.patchValue({ frequency: expression });
      } catch (e) {
        // set error here for user to see that expression is invalid
        this.badCronExpression = true;
      }
    } else {
      // set error here for user to let them know that the expression does not match our pattern
      this.badCronExpression = true;
    }
  }

  updateCronExpression(evt): void {
    const selectedValue = evt.value;
    // check if the type is a preset and set the value accordingly
    this.showCustomField = true;
    // this.validateCronExpression(selectedValue);
    if (selectedValue !== 'custom') {
      this.form.patchValue({ frequency: selectedValue });
    }
  }


}
