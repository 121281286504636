<mat-sidenav-container [ngClass]="{'open': sidebarOpened && isAuthenticated && !smallScreen, 'logged-out': !isAuthenticated}">
  <mat-sidenav mode='side' opened *ngIf="isAuthenticated && !smallScreen" class='big-screen'>
    <div class='nav-content'>
      <mat-nav-list>
        <ng-container *ngFor="let link of links">
          <ng-container *appNotProdReady="link.notProdReady">
            <a [routerLink]="link.navigation">
              <mat-list-item *ifUserCan="link.requiredPermission" routerLinkActive="active-sidenav-link">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap='15px' >
                  <mat-icon class="material-icons-round"  [matTooltip]="link.toolTip">{{link.icon}}</mat-icon>
                  <span>{{ link.friendly }}</span>
                </div>
              </mat-list-item>
            </a>
          </ng-container>
        </ng-container>
      </mat-nav-list>
      <div class='bottom' fxLayout='row' fxLayoutAlign='start center' (click)='toggleSidebar()' fxHide.xs>
        <mat-divider></mat-divider>
        <button  mat-icon-button aria-label="Collapse Sidenav">
          <mat-icon [ngClass]="{'open': sidebarOpened}">double_arrow</mat-icon>
        </button>
        <h4>Collapse sidebar</h4>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav #drawer mode='over' *ngIf="isAuthenticated && smallScreen" class='small-screen'>
    <mat-nav-list>
      <ng-container  *ngFor="let link of links">
        <ng-container *appNotProdReady="link.notProdReady">
          <a [routerLink]="link.navigation">
            <mat-list-item routerLinkActive="active-sidenav-link" (click)='drawer.toggle()'>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap='15px'>
                <mat-icon class="material-icons-round">{{link.icon}}</mat-icon>
                <span>{{ link.friendly }}</span>
              </div>
            </mat-list-item>
          </a>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'big-screen': !smallScreen, 'small-screen': smallScreen}" style="overflow: auto">
    <router-outlet style="overflow: auto"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
