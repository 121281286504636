import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserPreferenceRoutingModule } from './user-preference-routing.module';
import { StoreModule } from '@ngrx/store';
import * as fromUserPreference from './store/user-preference.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserPreferenceEffects } from './store/user-preference.effects';
import { UserPreferenceComponent } from './components/user-preference/user-preference.component';
import {FlexModule} from '@angular/flex-layout';
import {SnackbarComponent} from '../../sweet-shared/components/snackbar/snackbar.component';
import {SweetSharedModule} from "../../sweet-shared/sweet-shared.module";


@NgModule({
  declarations: [UserPreferenceComponent],
    imports: [
        CommonModule,
        UserPreferenceRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatSelectModule,
        MatListModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        StoreModule.forFeature(fromUserPreference.userPreferencesFeatureKey, fromUserPreference.reducer),
        EffectsModule.forFeature([UserPreferenceEffects]),
        FlexModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        SweetSharedModule
    ]
})
export class UserPreferenceModule { }
